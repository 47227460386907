import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CrudFilters,
  IResourceComponentsProps,
  useBack,
  usePermissions,
  useTranslate,
} from "@refinedev/core";
import {
  List,
  useTable,
  EditButton,
  TextField,
  CreateButton,
} from "@refinedev/antd";
import { Button, Form, Input, Space, Table } from "antd";
import {
  ArrowLeftOutlined,
  CaretDownOutlined,
  FilterFilled,
} from "@ant-design/icons";

import { IListApartments } from "interfaces";
import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_SALE,
  ROLE_TEAM_LEADER,
  formatPrice,
  getParamFilter,
  getParamFilterPrice,
  hasChecked,
  onCheckFilter,
  onClearFilter,
} from "helper";

import {
  getFilterBuildingsApartments,
  getSelectedRowKeysApartment,
  setFilterBuildingsApartments,
  setSelectedRowKeysApartment,
} from "helper/local-storage";
import { FilterNormal, FilterPrice } from "components/modal";
import { RANGE_PRICE, TYPE_ROOM } from "../../apartments/constant";
import { STATUS_APARTMENT } from "../constant";

export const BuildingsApartmentsList: React.FC<
  IResourceComponentsProps
> = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const goBack = useBack();

  const filter = JSON.parse(getFilterBuildingsApartments() || "null");

  const [keyword, setKeyword] = useState<string>(filter?.keyword || "");
  const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);
  const [isOpenModalType, setIsOpenModalType] = useState<boolean>(false);
  const [isOpenModalPrice, setIsOpenModalPrice] = useState<boolean>(false);
  const [listStatus, setListStatus] = useState<any>(
    filter?.listStatus || STATUS_APARTMENT
  );
  const [listType, setListType] = useState<any>(filter?.listType || TYPE_ROOM);
  const [listRangePrice, setListRangePrice] = useState<any>(
    filter?.listRangePrice || RANGE_PRICE
  );
  const [rangePriceCustom, setRangePriceCustom] = useState<any>(
    filter?.rangePriceCustom || null
  );

  const location = useLocation();
  const permissions = usePermissions<string>();
  const isLoggedBySale = permissions?.data === ROLE_SALE;
  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissions?.data === ROLE_DATA_ADMIN;
  const isLoggedbyTeamLeader = permissions?.data === ROLE_TEAM_LEADER;

  const { searchFormProps, tableProps, setFilters } = useTable<IListApartments>(
    {
      resource: `apartments${location?.search}`,
      onSearch: (params: any) => {
        const filters: CrudFilters = [];
        const { newKeyword } = params;

        filters.push({
          field: "keyword",
          operator: "eq",
          value: newKeyword || null,
        });
        setFilterBuildingsApartments(
          JSON.stringify({ ...filter, keyword: newKeyword })
        );
        return filters;
      },
    }
  );
  const dataSource: any = tableProps?.dataSource;
  let landlordsTableProps = {
    ...tableProps,
    dataSource: dataSource?.data,
  };

  const selectRowKeysApartmentsLocalStorage = JSON.parse(
    getSelectedRowKeysApartment() || "[]"
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState<any>(
    selectRowKeysApartmentsLocalStorage ?? []
  );

  const onSelectedRowKeysChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRowKeysApartment(selectedRowKeys);
  };

  const selectRow = (record: any) => {
    const selectedKeys = [...selectedRowKeys];

    if (selectedKeys.indexOf(record.key) >= 0) {
      selectedKeys.splice(selectedKeys.indexOf(record.key), 1);
    } else {
      selectedKeys.push(record.key);
    }
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectedRowKeysChange,
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchFormProps.form?.submit();
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    const statusParams = getParamFilter(listStatus);
    const typeParams = getParamFilter(listType);
    const rangePriceParam = getParamFilterPrice(
      listRangePrice,
      rangePriceCustom
    );

    setFilters(
      [
        {
          field: "status",
          operator: "eq",
          value: statusParams,
        },
        {
          field: "types",
          operator: "eq",
          value: typeParams,
        },
        {
          field: "min_price",
          operator: "eq",
          value: rangePriceParam?.from || null,
        },
        {
          field: "max_price",
          operator: "eq",
          value: rangePriceParam?.to || null,
        },
      ],
      "merge"
    );
    setFilterBuildingsApartments(
      JSON.stringify({
        ...filter,
        listStatus,
        listType,
        listRangePrice,
        rangePriceCustom,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listStatus, listType, listRangePrice, rangePriceCustom]);

  return (
    <List
      title={
        <Space style={{ display: "flex", flexDirection: "row" }}>
          <ArrowLeftOutlined onClick={() => goBack()} />
          <div style={{ whiteSpace: "pre-wrap" }}>
            {translate("pages.cartManagement.apartments.listOfBuilding") +
              ` ${
                dataSource?.data?.length > 0
                  ? dataSource?.data[0].building?.name
                  : ""
              }`}
          </div>
        </Space>
      }
      headerButtons={({ createButtonProps }) => (
        <>
          <Form {...searchFormProps}>
            <Form.Item
              name="newKeyword"
              initialValue={keyword}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={translate("placeholder.searchByNameApartments")}
                onChange={(e) => setKeyword(e.target.value)}
                allowClear={true}
              />
            </Form.Item>
          </Form>
          {/*
          <Button
            onClick={() =>
              navigate("/appointments/create", {
                state: { selectedRowKeys: selectedRowKeys },
              })
            }
          >
            {translate("pages.cartManagement.apartments.createAppointment")}
          </Button> */}
          {(isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyTeamLeader) &&
          createButtonProps ? (
            <CreateButton {...createButtonProps} />
          ) : null}
        </>
      )}
    >
      <Space className="listFilter">
        <Button style={{ pointerEvents: "none" }}>
          <FilterFilled />
          {translate("buttons.filter")}
        </Button>
        <Button
          onClick={() => setIsOpenModalStatus(true)}
          className={hasChecked(listStatus) ? "isEnabled" : ""}
        >
          {translate("pages.cartManagement.apartments.status")}
          <CaretDownOutlined />
        </Button>

        {isOpenModalStatus && (
          <FilterNormal
            isOpen={isOpenModalStatus}
            setIsOpen={setIsOpenModalStatus}
            title={translate("pages.cartManagement.apartments.status")}
            data={listStatus}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listStatus, true);
              setListStatus(newData);
            }}
            onClear={() => setListStatus([...STATUS_APARTMENT])}
            multiple={true}
          />
        )}
        <Button
          onClick={() => setIsOpenModalType(true)}
          className={hasChecked(listType) ? "isEnabled" : ""}
        >
          {translate("pages.cartManagement.apartments.type")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalType && (
          <FilterNormal
            isOpen={isOpenModalType}
            setIsOpen={setIsOpenModalType}
            title={translate("pages.cartManagement.apartments.type")}
            data={listType}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listType, true);
              setListType(newData);
            }}
            onClear={() => setListType([...TYPE_ROOM])}
            multiple={true}
          />
        )}
        <Button
          onClick={() => setIsOpenModalPrice(true)}
          className={
            hasChecked(listRangePrice) || rangePriceCustom ? "isEnabled" : ""
          }
        >
          {translate("pages.cartManagement.apartments.price")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalPrice && (
          <FilterPrice
            isOpen={isOpenModalPrice}
            setIsOpen={setIsOpenModalPrice}
            title={translate("pages.cartManagement.apartments.price")}
            data={listRangePrice}
            onClear={() => {
              const newData = onClearFilter(listRangePrice);
              setListRangePrice(newData);
            }}
            setRangePriceCustom={setRangePriceCustom}
            rangePriceCustom={rangePriceCustom}
            setListRangePrice={setListRangePrice}
          />
        )}
      </Space>

      <Table
        {...landlordsTableProps}
        rowKey="id"
        rowSelection={rowSelection}
        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
      >
        <Table.Column
          dataIndex="name"
          title={translate("pages.cartManagement.apartments.name")}
        />
        <Table.Column
          dataIndex="building"
          title={translate("pages.cartManagement.apartments.building")}
          key="building"
          render={(value) => {
            return <TextField value={value?.name} />;
          }}
        />
        <Table.Column
          dataIndex="square"
          title={translate("pages.cartManagement.apartments.square")}
        />
        <Table.Column
          dataIndex="price"
          title={translate("pages.cartManagement.apartments.price")}
          key="price"
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />
        <Table.Column
          dataIndex="deposit_price"
          title={translate("pages.cartManagement.apartments.depositPrice")}
          key="deposit_price"
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />
        <Table.Column
          dataIndex="windows"
          title={translate("pages.cartManagement.apartments.windows")}
          key="windows"
          render={(value) => {
            return (
              <TextField
                value={
                  value ? translate("const.true") : translate("const.false")
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="balconies"
          title={translate("pages.cartManagement.apartments.balconies")}
          key="balconies"
          render={(value) => {
            return (
              <TextField
                value={
                  value ? translate("const.true") : translate("const.false")
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="status"
          title={translate("pages.cartManagement.apartments.status")}
          key="status"
          render={(value) => {
            return (
              <TextField
                value={
                  value === "vacant"
                    ? translate("const.vacant")
                    : value === "deposited"
                    ? translate("const.deposited")
                    : value === "occupied"
                    ? translate("const.occupied")
                    : translate("const.nearVacant")
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="type"
          title={translate("pages.cartManagement.apartments.type")}
          key="type"
          render={(value) => {
            return (
              <TextField
                value={
                  value === "duplex"
                    ? translate("const.duplex")
                    : value === "studio"
                    ? translate("const.studio")
                    : value === "one_bedroom"
                    ? translate("const.oneBedroom")
                    : translate("const.twoBedroom")
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="furniture"
          title={translate("pages.cartManagement.apartments.furniture")}
          key="furniture"
          render={(value) => {
            return <TextField value={translate(`const.${value}`)} />;
          }}
        />
        {!isLoggedBySale && (
          <>
            <Table.Column
              dataIndex="six_months_commission_pct"
              title={translate(
                "pages.cartManagement.apartments.sixMonthsCommissionPct"
              )}
              key="six_months_commission_pct"
              render={(value) => {
                if (value) {
                  return <TextField value={value + " %"} />;
                } else {
                  return <TextField value={0 + " %"} />;
                }
              }}
            />
            <Table.Column
              dataIndex="one_year_commission_pct"
              title={translate(
                "pages.cartManagement.apartments.oneYearCommissionPct"
              )}
              key="one_year_commission_pct"
              render={(value) => {
                if (value) {
                  return <TextField value={value + " %"} />;
                } else {
                  return <TextField value={0 + " %"} />;
                }
              }}
            />
          </>
        )}
        {!isLoggedBySale ? (
          <Table.Column<IListApartments>
            title={translate("table.actions")}
            dataIndex="actions"
            fixed="right"
            render={(_, record) => {
              return (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                </Space>
              );
            }}
          />
        ) : null}
      </Table>
    </List>
  );
};
