/* eslint-disable jsx-a11y/img-redundant-alt */
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  IResourceComponentsProps,
  useBack,
  useList,
  usePermissions,
  useTranslate,
} from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import {
  Form,
  Input,
  InputNumber,
  Modal,
  TreeSelect,
  Select,
  Upload,
  message,
} from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";

import { IListApartments, IListBuildings } from "interfaces";
import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_SALE,
  ROLE_TEAM_LEADER,
  formatInputPrice,
  getBase64,
  handleFilterOption,
  handleFurnitureType,
  handleNotIncreaseDecreaseNumber,
  handleSortOption,
  isValidPercent,
  isValidPrice,
  isValidQuantity,
  parseInputPrice,
  uploadButton,
} from "helper";
import {
  setListFurnitureSessionStorage,
  setSelectedBasicSessionStorage,
  setSelectedEmptySessionStorage,
  setSelectedFullSessionStorage,
  getListFurnitureSessionStorage,
  getSelectedBasicSessionStorage,
  getSelectedEmptySessionStorage,
  getSelectedFullSessionStorage,
} from "helper/session-storage";
import {
  STATUS_BOOLEANS_OPTIONS,
  STATUS_APARTMENTS_OPTIONS,
  TYPE_BEDROOMS_APARTMENTS_OPTIONS,
} from "const";
import {
  CATEGORIES_FURNITURE_FULL,
  CATEGORIES_FURNITURE_OPTIONAL,
} from "./constant";

import { API_URL } from "api";

export const RoomsCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const permissions = usePermissions<string>();
  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissions?.data === ROLE_DATA_ADMIN;
  const isLoggedbyTeamLeader = permissions?.data === ROLE_TEAM_LEADER;
  const isLoggedbySale = permissions?.data === ROLE_SALE;
  const location = useLocation();

  const navigate = useNavigate();
  const currentBuilding = location?.state?.currentBuilding;
  const [buildingsOptions, setBuildingsOptions] = useState<any>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<any>();
  const [imageUrls, setImageUrls] = useState<any>([]);

  const { formProps, saveButtonProps, onFinish, form } =
    useForm<IListApartments>({
      resource: "apartments",
      redirect: false,
      onMutationSuccess: (data) => {
        const { status } = data?.data;
        if (status) {
          navigate("/rooms");
        }
      },
    });

  const allCategoriesFurniture: any = CATEGORIES_FURNITURE_FULL?.concat(
    CATEGORIES_FURNITURE_OPTIONAL
  ).map(({ label, value }) => ({
    title: translate(label),
    value: value,
  }));

  const categoriesFurniture: any = CATEGORIES_FURNITURE_FULL?.map(
    ({ label, value }) => ({
      title: translate(label),
      value: value,
    })
  );
  const allFurniture: any = categoriesFurniture?.map(({ value }: any) => value);

  // get building data
  const { data: buildingsData } = useList<IListBuildings>({
    resource: "buildings",
    errorNotification: false,
  });

  const uploadImage = async (options: any) => {
    const { file, onSuccess, onError, onProgress } = options;
    const formData = new FormData();
    formData.append("mFiles", file);
    const response: any = await axios.post<{ url: string }>(
      `${API_URL}/s3/upload`,
      formData,
      {
        withCredentials: false,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round(
            (progressEvent?.loaded * 100) / progressEvent?.total
          );
          onProgress({ percent: percentCompleted });
        },
      }
    );

    if (response?.status) {
      // Handle success
      message.success(translate("notifications.imageUploadSuccess"));
      onSuccess(response?.data, file);
      setImageUrls((prev: any) => [...prev, ...response?.data?.data]);
    } else {
      // Handle error
      message.error(translate("notifications.imageUploadFailed"));
      onError();
    }
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const handleOnFinish: any = async (values: IListApartments) => {
    // get value furniture from session storage
    const listFurniture = JSON.parse(getListFurnitureSessionStorage() || "[]");
    const selectedFull = JSON.parse(getSelectedFullSessionStorage() || "");
    const selectedEmpty = JSON.parse(getSelectedEmptySessionStorage() || "");
    const selectedBasic = JSON.parse(getSelectedBasicSessionStorage() || "");

    onFinish({
      ...values,
      building_id: Number(values?.building_id),
      square: Number(values?.square),
      price: Number(values?.price),
      deposit_price: Number(values?.deposit_price),
      six_months_commission_pct: Number(values?.six_months_commission_pct),
      one_year_commission_pct: Number(values?.one_year_commission_pct),
      notes: values?.notes?.trim(),
      images: imageUrls?.map((item: any) => item),
      furniture: handleFurnitureType(
        listFurniture,
        categoriesFurniture,
        selectedFull
          ? selectedFull
          : selectedBasic
          ? selectedBasic
          : selectedEmpty
      ),
      fridge: listFurniture?.includes("fridge") ? true : false,
      air_conditioner: listFurniture?.includes("air_conditioner")
        ? true
        : false,
      television: listFurniture?.includes("television") ? true : false,
      washing_machine: listFurniture?.includes("washing_machine")
        ? true
        : false,
      in_room_washing_machine: listFurniture?.includes(
        "in_room_washing_machine"
      )
        ? true
        : false,
      water_heater: listFurniture?.includes("water_heater") ? true : false,
      bed: listFurniture?.includes("bed") ? true : false,
      mattress: listFurniture?.includes("mattress") ? true : false,
      kitchen_shelf: listFurniture?.includes("kitchen_shelf") ? true : false,
      sink: listFurniture?.includes("sink") ? true : false,
      wardrobe: listFurniture?.includes("wardrobe") ? true : false,
    });
  };

  const TreeSelectFurniture = () => {
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const [selectedEmpty, setSelectedEmpty] = useState<boolean>(false);
    const [selectedBasic, setSelectedBasic] = useState<boolean>(false);
    const [selectedFull, setSelectedFull] = useState<boolean>(false);
    // save to session storage
    setListFurnitureSessionStorage(selectedValues);
    setSelectedEmptySessionStorage(selectedEmpty);
    setSelectedBasicSessionStorage(selectedBasic);
    setSelectedFullSessionStorage(selectedFull);

    return (
      <TreeSelect
        allowClear={true}
        placeholder={translate("placeholder.selectAOneOrManyFurniture")}
        treeCheckable={true}
        showCheckedStrategy={TreeSelect.SHOW_CHILD}
        style={{ width: "100%" }}
        dropdownStyle={{ maxHeight: "300px" }}
        onChange={(ids) => setSelectedValues(ids)}
        value={selectedValues}
        maxTagCount={3}
        maxTagPlaceholder={(omittedValues) =>
          `+ ${omittedValues.length} ${translate(
            "pages.cartManagement.apartments.furniture"
          )} ...`
        }
        treeData={[
          {
            title: (
              <Fragment>
                <span
                  onClick={() => {
                    setSelectedValues(allFurniture);
                    setSelectedBasic(false);
                    setSelectedEmpty(false);
                    setSelectedFull(true);
                  }}
                  style={{
                    display: "inline-block",
                    color: selectedFull ? "#ccc" : "#286FBE",
                    cursor: selectedFull ? "not-allowed" : "pointer",
                    pointerEvents: selectedFull ? "none" : "visible",
                  }}
                >
                  {translate("const.full")}
                </span>
                &nbsp;&nbsp;&nbsp;
                <span
                  onClick={() => {
                    setSelectedValues([]);
                    setSelectedBasic(true);
                    setSelectedEmpty(false);
                    setSelectedFull(false);
                  }}
                  style={{
                    display: "inline-block",
                    color: selectedBasic ? "#ccc" : "#286FBE",
                    cursor: selectedBasic ? "not-allowed" : "pointer",
                    pointerEvents: selectedBasic ? "none" : "visible",
                  }}
                >
                  {translate("const.basic")}
                </span>
                &nbsp;&nbsp;&nbsp;
                <span
                  onClick={() => {
                    setSelectedValues([]);
                    setSelectedBasic(false);
                    setSelectedEmpty(true);
                    setSelectedFull(false);
                  }}
                  style={{
                    display: "inline-block",
                    color: selectedEmpty ? "#ccc" : "#286FBE",
                    cursor: selectedEmpty ? "not-allowed" : "pointer",
                    pointerEvents: selectedEmpty ? "none" : "visible",
                  }}
                >
                  {translate("const.empty")}
                </span>
              </Fragment>
            ),
            disableCheckbox: true,
            disabled: true,
          },
          ...allCategoriesFurniture,
        ]}
      />
    );
  };

  useEffect(() => {
    if (buildingsData) {
      const buildingOptionsData: any = buildingsData?.data;
      setBuildingsOptions(
        buildingOptionsData?.data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      );
    }
  }, [buildingsData]);

  return (
    <Create
      title={translate("pages.cartManagement.apartments.createNewApartment")}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        layout="vertical"
        initialValues={{
          square: 0,
          price: 0,
          building_id: currentBuilding?.data?.id
            ? currentBuilding?.data?.id
            : null,
          deposit_price: 0,
          windows: false,
          balconies: false,
          six_months_commission_pct: 0,
          one_year_commission_pct: 0,
          notes: "",
        }}
        form={form}
      >
        <Form.Item label="Images">
          <Form.Item name="images" noStyle>
            <Upload
              multiple={true}
              accept="image/*"
              customRequest={uploadImage}
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {uploadButton}
            </Upload>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="images apartments"
                style={{ width: "100%" }}
                src={previewImage}
              />
            </Modal>
          </Form.Item>
        </Form.Item>

        <Form.Item
          name="name"
          label={translate("pages.cartManagement.apartments.name")}
          rules={[
            {
              required: true,
              message: translate("validations.name"),
            },
          ]}
        >
          <Input placeholder={translate("placeholder.pleaseEnterName")} />
        </Form.Item>
        <Form.Item
          name="building_id"
          label={translate("pages.cartManagement.apartments.building")}
          rules={[
            {
              required: true,
              message: translate("validations.building"),
            },
            () => ({
              validator(_, value) {
                if (isValidQuantity(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidQuantity(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select
            disabled={currentBuilding?.isAuthenticated}
            placeholder={translate("placeholder.selectABuilding")}
            options={buildingsOptions}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <Form.Item
          name="square"
          label={translate("pages.cartManagement.apartments.square")}
          rules={[
            () => ({
              validator(_, value) {
                if (isValidQuantity(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidQuantity(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            type="number"
            onWheel={(e) => handleNotIncreaseDecreaseNumber(e)}
          />
        </Form.Item>
        <Form.Item
          name="price"
          label={translate("pages.cartManagement.apartments.price")}
          rules={[
            {
              required: true,
              message: translate("validations.price"),
            },
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
          />
        </Form.Item>
        <Form.Item
          name="deposit_price"
          label={translate("pages.cartManagement.apartments.depositPrice")}
          rules={[
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
          />
        </Form.Item>
        <Form.Item
          name="windows"
          label={translate("pages.cartManagement.apartments.windows")}
        >
          <Select
            placeholder={translate("placeholder.selectStatus")}
            options={STATUS_BOOLEANS_OPTIONS?.map((item) => {
              return {
                label: translate(item.label),
                value: item.value,
              };
            })}
          />
        </Form.Item>
        <Form.Item
          name="balconies"
          label={translate("pages.cartManagement.apartments.balconies")}
        >
          <Select
            placeholder={translate("placeholder.selectStatus")}
            options={STATUS_BOOLEANS_OPTIONS?.map((item) => {
              return {
                label: translate(item.label),
                value: item.value,
              };
            })}
          />
        </Form.Item>
        <Form.Item
          name="type"
          label={translate("pages.cartManagement.apartments.type")}
          rules={[
            {
              required: true,
              message: translate("validations.typeRoom"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectTypeRental")}
            options={TYPE_BEDROOMS_APARTMENTS_OPTIONS?.map((item) => {
              return {
                label: translate(item.label),
                value: item.value,
              };
            })}
          />
        </Form.Item>
        <Form.Item
          name="status"
          label={translate("pages.cartManagement.apartments.status")}
        >
          <Select
            placeholder={translate("placeholder.selectStatus")}
            options={STATUS_APARTMENTS_OPTIONS?.map((item) => {
              return {
                label: translate(item.label),
                value: item.value,
              };
            })}
          />
        </Form.Item>
        <Form.Item
          name="furniture"
          label={translate("pages.cartManagement.apartments.furniture")}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "100% 1fr",
            }}
          >
            <div style={{ gridRow: "3 / 4", gridColumn: "1 / 2" }}>
              <TreeSelectFurniture />
            </div>
            <div style={{ gridRow: "3 / 4", gridColumn: "2 / 3" }}></div>
          </div>
        </Form.Item>
        {isLoggedbyAdmin ||
        isLoggedbyDataAdmin ||
        isLoggedbyTeamLeader ||
        isLoggedbySale ? (
          <>
            {" "}
            <Form.Item
              name="six_months_commission_pct"
              label={translate(
                "pages.cartManagement.apartments.sixMonthsCommissionPct"
              )}
              rules={[
                {
                  required: true,
                  message: translate("validations.sixMonthsCommissionPct"),
                },
                () => ({
                  validator(_, value) {
                    if (isValidPercent(value)) {
                      return Promise.reject(
                        new Error(translate(value && isValidPercent(value)))
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber prefix="%" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="one_year_commission_pct"
              label={translate(
                "pages.cartManagement.apartments.oneYearCommissionPct"
              )}
              rules={[
                {
                  required: true,
                  message: translate("validations.oneYearCommissionPct"),
                },
                () => ({
                  validator(_, value) {
                    if (isValidPercent(value)) {
                      return Promise.reject(
                        new Error(translate(value && isValidPercent(value)))
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber prefix="%" style={{ width: "100%" }} />
            </Form.Item>
          </>
        ) : null}

        <Form.Item
          name="notes"
          label={translate("pages.cartManagement.apartments.notes")}
        >
          <Input placeholder={translate("placeholder.pleaseEnterNotes")} />
        </Form.Item>
      </Form>
    </Create>
  );
};
