import {
  IResourceComponentsProps,
  useParsed,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { EditButton, Show, TextField } from "@refinedev/antd";
import { useNavigate } from "react-router-dom";

import { Button, Space, Tag, Typography } from "antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { formatPrice, parsePhoneToVNWithoutSpace } from "helper";
import { IListTransactions } from "interfaces/transactions";
import { handleTranslateType } from "pages/customer-management/appointments/constant";
import { handleTranslateStatus } from "./constant";

const { Title } = Typography;

export const CommissionShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { id } = useParsed();

  const { queryResult } = useShow<IListTransactions>({
    resource: "transactions",
    id,
  });

  const { data, isLoading } = queryResult;
  const record: any = data?.data;

  return (
    <Show
      isLoading={isLoading}
      title={translate("pages.financialManagement.commissions.showCommission")}
      headerButtons={({
        listButtonProps,
        editButtonProps,
        refreshButtonProps,
      }) => (
        <>
          <Button
            onClick={() =>
              navigate(`/appointments/show/${record?.data?.appointment?.id}`)
            }
          >
            {translate("pages.financialManagement.commissions.showAppointment")}
          </Button>
          {editButtonProps && (
            <EditButton
              {...editButtonProps}
              title={translate(
                "pages.financialManagement.commissions.editCommission"
              )}
            />
          )}
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
    >
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.commissions.id")}:
        </Title>
        <TextField value={record?.data?.id} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.commissions.type")}:
        </Title>
        <TextField value={handleTranslateType(record?.data?.type, translate)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.commissions.amount")}:
        </Title>
        <TextField value={formatPrice(record?.data?.amount)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.commissions.status")}:
        </Title>
        <TextField
          value={handleTranslateStatus(record?.data?.status, translate)}
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.commissions.apartment")}:
        </Title>
        <TextField
          value={
            <TextField
              value={record?.data?.appointment?.apartments.map(
                (item: any, idx: number) => {
                  return (
                    <Tag key={idx}>
                      <TextField
                        value={item?.name + ` (${item?.building?.name})`}
                      />
                    </Tag>
                  );
                }
              )}
            />
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.commissions.sale")}:
        </Title>
        <TextField value={record?.data?.appointment?.sale?.full_name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.commissions.zalo")}:
        </Title>
        <TextField
          value={
            record?.data?.appointment?.customer?.phone
              ? parsePhoneToVNWithoutSpace(
                  record?.data?.appointment?.customer?.phone
                )
              : ""
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.commissions.notes")}:
        </Title>
        <TextField value={record?.data?.notes} />
      </Space>
    </Show>
  );
};
