import {
  IResourceComponentsProps,
  usePermissions,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { EditButton, Show, TextField, useTable } from "@refinedev/antd";

import { Button, Space, Table, Tag, Typography } from "antd";
import dayjs from "dayjs";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IListApointments, IMoneyTransferRequest } from "interfaces";
import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_FINANCE,
  ROLE_SALE,
  ROLE_TEAM_LEADER,
  formatDateMonthYear,
  formatPrice,
  formatTime,
} from "helper";
import { handleTranslate, handleTranslateType, statusColors } from "./constant";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

export const AppointmentsShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IListApointments>();
  const { data, isLoading } = queryResult;
  const record: any = data?.data;
  const navigate = useNavigate();
  const permissions = usePermissions<string>();
  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissions?.data === ROLE_DATA_ADMIN;
  const isLoggedbyTeamLeader = permissions?.data === ROLE_TEAM_LEADER;
  const isLoggedbyFinance = permissions?.data === ROLE_FINANCE;
  const isLoggedbySale = permissions?.data === ROLE_SALE;

  // get request transfer data
  const { tableProps } = useTable<IMoneyTransferRequest>({
    resource: `transactions/?appointment_id=${record?.data?.id}&`,
  });

  const dataSource: any = tableProps?.dataSource;

  const dataTransactions = dataSource?.data?.filter(
    (item: any) => item.appointment?.id === record?.data?.id
  );

  const dataTransactionsFormProps = {
    ...tableProps,
    dataSource: dataTransactions ? [...dataTransactions] : [],
  };

  const sortedData = dataTransactionsFormProps?.dataSource?.sort(
    (a, b) =>
      new Date(b?.created_at).getTime() - new Date(a.created_at).getTime()
  );
  const dataTransactionsTableProps = {
    ...dataTransactionsFormProps,
    dataSource: sortedData,
  };

  return (
    <Show
      isLoading={isLoading}
      title={translate("pages.customerManagement.appointments.showAppointment")}
      headerButtons={({
        listButtonProps,
        editButtonProps,
        refreshButtonProps,
      }) => (
        <>
          {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbySale ? (
            <>
              <Button
                onClick={() =>
                  navigate(`/customers/show/${record?.data?.customer_id}`)
                }
              >
                {translate(
                  "pages.customerManagement.appointments.viewCustomter"
                )}
              </Button>
              <Button
                onClick={() =>
                  navigate(
                    `/apartments/show/${record?.data?.apartments[0]?.id}`
                  )
                }
              >
                {translate(
                  "pages.customerManagement.appointments.viewApartment"
                )}
              </Button>
            </>
          ) : null}
          {editButtonProps && (
            <EditButton
              {...editButtonProps}
              title={translate(
                "pages.customerManagement.appointments.editAppointment"
              )}
            />
          )}
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
    >
      {isLoggedbyAdmin ||
      isLoggedbyDataAdmin ||
      isLoggedbyTeamLeader ||
      isLoggedbySale ? (
        <div>
          {!isLoggedbySale ? (
            <>
              <Space>
                <Title level={5}>
                  {translate("pages.customerManagement.appointments.sale")}:
                </Title>
                <TextField value={record?.data?.sale?.full_name} />
              </Space>
              <br />
            </>
          ) : null}
          <Space>
            <Title level={5}>
              {translate("pages.customerManagement.appointments.supportSales")}:
            </Title>
            <TextField
              value={record?.data?.supported_sales?.map(
                (item: any, idx: number) => {
                  return (
                    <Tag key={idx}>
                      <TextField value={item?.full_name} />
                    </Tag>
                  );
                }
              )}
            />
          </Space>
          <br />
        </div>
      ) : null}
      {isLoggedbyAdmin ||
      isLoggedbyDataAdmin ||
      isLoggedbyTeamLeader ||
      isLoggedbyFinance ||
      isLoggedbySale ? (
        <div>
          <Space>
            <Title level={5}>
              {translate("pages.customerManagement.appointments.supportAdmins")}
              :
            </Title>
            <TextField
              value={record?.data?.supportAdmins?.map(
                (item: any, idx: number) => {
                  return (
                    <Tag key={idx}>
                      <TextField value={item?.full_name} />
                    </Tag>
                  );
                }
              )}
            />
          </Space>
          <br />
        </div>
      ) : null}

      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.appointments.apartments")}:
        </Title>
        <TextField
          value={record?.data?.apartments.map((item: any, idx: number) => {
            return (
              <Tag key={idx}>
                <TextField value={item?.name + ` (${item?.building?.name})`} />
              </Tag>
            );
          })}
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.appointments.customers")}:
        </Title>
        <TextField value={record?.data?.customer?.full_name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.appointments.time")}:
        </Title>
        <TextField
          value={record?.data.time ? formatTime(record?.data.time) : ""}
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.appointments.checkInDate")}:
        </Title>
        <TextField
          value={
            record?.data?.check_in_date
              ? formatTime(record?.data?.check_in_date)
              : ""
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.appointments.status")}:
        </Title>
        <TextField
          value={
            <Tag bordered={false} color={statusColors(record?.data?.status)}>
              {handleTranslate(record?.data?.status, translate)}
            </Tag>
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate(
            "pages.customerManagement.appointments.depositCompletionDate"
          )}
          :
        </Title>
        <TextField
          value={
            record?.data.complete_deposit_date
              ? dayjs(record?.data.complete_deposit_date).format(
                  formatDateMonthYear
                )
              : ""
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.appointments.notes")}:
        </Title>
        <TextField value={record?.data?.notes} />
      </Space>
      <br />
      <br />

      <Title level={4}>
        {translate("pages.customerManagement.appointments.tableTransactions")}:
      </Title>

      <Table {...dataTransactionsTableProps} rowKey="id">
        <Table.Column
          dataIndex="amount"
          title={translate("pages.customerManagement.appointments.amount")}
          key="amount"
          render={(value) => {
            return formatPrice(value);
          }}
        />
        <Table.Column
          dataIndex="type"
          title={translate("pages.customerManagement.appointments.type")}
          render={(value, idx: number) => {
            return (
              <TextField
                key={idx}
                value={handleTranslateType(value, translate)}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="status"
          title={translate("pages.customerManagement.appointments.status")}
          render={(value, idx: number) => {
            return (
              <TextField
                key={idx}
                value={
                  value === "processing" || value === "paid" ? (
                    <Tag bordered={false} color="yellow">
                      {translate(
                        value === "paid"
                          ? "status.paidLandlord"
                          : `status.${value}`
                      )}
                    </Tag>
                  ) : (
                    <>
                      {value === "completed" ? (
                        <Tag bordered={false} color="success">
                          {translate(`status.${value}`)}
                        </Tag>
                      ) : (
                        <Tag bordered={false} color="error">
                          {translate(`status.${value}`)}
                        </Tag>
                      )}
                    </>
                  )
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="notes"
          title={translate("pages.customerManagement.appointments.notes")}
          render={(value) => {
            return <TextField value={value} />;
          }}
        />
        <Table.Column
          dataIndex="created_at"
          title={translate("pages.createdAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column
          width={400}
          dataIndex="updated_at"
          title={translate("pages.updatedAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
      </Table>
    </Show>
  );
};
