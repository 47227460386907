export const setListFurnitureSessionStorage = (list: any) => {
  localStorage.setItem("listFurniture", JSON.stringify(list));
};
export const getListFurnitureSessionStorage = () => {
  return localStorage.getItem("listFurniture");
};
export const setSelectedEmptySessionStorage = (select: any) => {
  localStorage.setItem("selectedEmpty", JSON.stringify(select));
};
export const getSelectedEmptySessionStorage = () => {
  return localStorage.getItem("selectedEmpty");
};
export const setSelectedBasicSessionStorage = (select: any) => {
  localStorage.setItem("selectedBasic", JSON.stringify(select));
};
export const getSelectedBasicSessionStorage = () => {
  return localStorage.getItem("selectedBasic");
};
export const setSelectedFullSessionStorage = (select: any) => {
  localStorage.setItem("selectedFull", JSON.stringify(select));
};
export const getSelectedFullSessionStorage = () => {
  return localStorage.getItem("selectedFull");
};
