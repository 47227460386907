import {
  CrudFilters,
  IResourceComponentsProps,
  useTranslate,
  useGetIdentity,
} from "@refinedev/core";
import {
  List,
  useTable,
  TextField,
  EditButton,
  ShowButton,
  CreateButton,
} from "@refinedev/antd";
import { Button, Form, Input, Space, Table, Tag } from "antd";
import { useState, useEffect } from "react";
import { CaretDownOutlined, FilterFilled } from "@ant-design/icons";

import { IListApointments, IListUser } from "interfaces";
import {
  formatTime,
  getParamFilter,
  hasChecked,
  onCheckFilter,
  parsePhoneToVNWithoutSpace,
} from "helper";
import { STATUS_CUSTOMER } from "./constant";
import { FilterNormal } from "components/modal";
import {
  getCurrentPagination,
  getFilterCustomers,
  setCurrentPagination,
  setFilterCustomers,
} from "helper/local-storage";

export const CustomersList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const filter = JSON.parse(getFilterCustomers() || "{}");
  const [keyword, setKeyword] = useState<string>(filter?.keyword || "");
  const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);
  const [listStatus, setListStatus] = useState<any>(
    filter?.listStatus || STATUS_CUSTOMER
  );
  const [pagination, setPagination] = useState(
    JSON.parse(getCurrentPagination() || "{}") || {
      current: 1,
      pageSize: 10,
    }
  );

  const { data: user } = useGetIdentity<{
    roles: any;
    phone: string;
    name: string;
    avatar: string;
  }>();

  // get list customers
  const { searchFormProps, tableProps, setFilters } =
    useTable<IListApointments>({
      resource: `customers?role_id=${user?.roles?.id}&`,
      onSearch: (params: any) => {
        const filters: CrudFilters = [];
        const { newKeyword } = params;

        filters.push({
          field: "keyword",
          operator: "eq",
          value: newKeyword || null,
        });
        setFilterCustomers(JSON.stringify({ ...filter, keyword: newKeyword }));
        return filters;
      },
    });
  const dataSource: any = tableProps?.dataSource;
  let customersTableProps = {
    ...tableProps,
    pagination: pagination,
    dataSource: dataSource?.data,
  };

  const handleTableChange = (pagination: any) => {
    setPagination({ current: pagination, pageSize: 10 });
  };

  useEffect(() => {
    setCurrentPagination(JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    const storedPagination = getCurrentPagination();
    if (storedPagination) {
      setPagination(JSON.parse(storedPagination));
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchFormProps.form?.submit();
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    const statusParam = getParamFilter(listStatus);
    setFilters(
      [
        {
          field: "status",
          operator: "eq",
          value: statusParam,
        },
      ],
      "merge"
    );
    setFilterCustomers(JSON.stringify({ ...filter, listStatus }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listStatus]);

  return (
    <List
      title={translate("pages.customerManagement.customers.list")}
      headerButtons={({ createButtonProps }) => (
        <>
          <Form {...searchFormProps}>
            <Form.Item
              name="newKeyword"
              initialValue={keyword}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={translate("placeholder.searchByNameCustomers")}
                onChange={(e) => setKeyword(e.target.value)}
                allowClear={true}
              />
            </Form.Item>
          </Form>
          {createButtonProps && <CreateButton />}
        </>
      )}
    >
      <Space className="listFilter">
        <Button style={{ pointerEvents: "none" }}>
          <FilterFilled />
          {translate("buttons.filter")}
        </Button>
        <Button
          onClick={() => setIsOpenModalStatus(true)}
          className={hasChecked(listStatus) ? "isEnabled" : ""}
        >
          {translate("pages.customerManagement.appointments.status")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalStatus && (
          <FilterNormal
            isOpen={isOpenModalStatus}
            setIsOpen={setIsOpenModalStatus}
            title={translate("pages.customerManagement.appointments.status")}
            data={listStatus}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listStatus, false);
              setListStatus(newData);
            }}
            onClear={() => setListStatus([...STATUS_CUSTOMER])}
            multiple={false}
          />
        )}
      </Space>
      <Table
        {...customersTableProps}
        pagination={{
          defaultPageSize: 10,
          // showSizeChanger: true,
          // pageSizeOptions: ["10", "20", "30", "40"],
          current: pagination.current,
          onChange: handleTableChange,
        }}
        rowKey="id"
        className="list-users"
      >
        <Table.Column
          dataIndex="full_name"
          title={translate("pages.users.fullName")}
        />
        <Table.Column
          dataIndex="phone"
          title={translate("pages.users.phone")}
          render={(value) => {
            return value ? (
              <TextField value={parsePhoneToVNWithoutSpace(value)} />
            ) : (
              ""
            );
          }}
        />
        <Table.Column
          dataIndex="second_phone"
          title={translate("pages.users.secondPhone")}
          render={(value) => {
            return value ? (
              <TextField value={parsePhoneToVNWithoutSpace(value)} />
            ) : (
              ""
            );
          }}
        />
        <Table.Column
          dataIndex="email"
          title={translate("pages.users.email")}
        />
        <Table.Column
          dataIndex="telegram"
          title={translate("pages.users.telegram")}
        />
        <Table.Column
          dataIndex="zalo"
          title={translate("pages.users.zalo")}
          render={(value) => {
            return value ? (
              <TextField value={parsePhoneToVNWithoutSpace(value)} />
            ) : (
              ""
            );
          }}
        />
        <Table.Column
          dataIndex="identity_card_number"
          title={translate("pages.users.identityCardNumber")}
        />
        <Table.Column
          dataIndex="address"
          title={translate("pages.users.address")}
        />
        <Table.Column
          dataIndex="province"
          title={translate("pages.users.province")}
          key="province"
          render={(value) => {
            return <TextField value={value?.name} />;
          }}
        />
        <Table.Column
          dataIndex="district"
          title={translate("pages.users.district")}
          key="district"
          render={(value) => {
            return <TextField value={value?.name} />;
          }}
        />
        <Table.Column
          dataIndex="ward"
          title={translate("pages.users.ward")}
          key="ward"
          render={(value) => {
            return <TextField value={value?.name} />;
          }}
        />
        <Table.Column<IListUser>
          title={translate("pages.users.status")}
          dataIndex="is_active"
          render={(value) => {
            return (
              <TextField
                strong
                value={
                  value === true ? (
                    <Tag bordered={false} color="success">
                      {translate("pages.users.active")}
                    </Tag>
                  ) : (
                    <Tag bordered={false} color="error">
                      {translate("pages.users.inactive")}
                    </Tag>
                  )
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="created_at"
          title={translate("pages.createdAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column
          width={400}
          dataIndex="updated_at"
          title={translate("pages.updatedAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column<IListUser>
          title={translate("table.actions")}
          dataIndex="actions"
          fixed="right"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
