import { useState, useEffect } from "react";
import {
  IResourceComponentsProps,
  useTranslate,
  useList,
} from "@refinedev/core";
import {
  List,
  useTable,
  TextField,
  EditButton,
  ShowButton,
} from "@refinedev/antd";
import { Button, Input, Space, Table, Tag } from "antd";
import { CaretDownOutlined, FilterFilled } from "@ant-design/icons";

import {
  formatPrice,
  getParamFilter,
  hasChecked,
  onCheckFilter,
  onClearFilter,
  parsePhoneToVNWithoutSpace,
} from "helper";

import { IListBuildings, IListUser } from "interfaces";
import { FilterNormal } from "components/modal";
import {
  getFilterTransactions,
  setFilterTransactions,
} from "helper/local-storage";
import { IListTransactions } from "interfaces/transactions";
import { handleTranslateType } from "pages/customer-management/appointments/constant";
import { STATUS_TRANSACTIONS, handleTranslateStatus } from "./constant";

export const TransactionList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const filter = JSON.parse(getFilterTransactions() || "{}");
  const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);
  const [isOpenModalBuilding, setIsOpenModalBuilding] =
    useState<boolean>(false);
  const [listStatus, setListStatus] = useState<any>(
    filter?.listStatus || STATUS_TRANSACTIONS
  );
  const [listBuilding, setListBuilding] = useState<any>(
    filter?.listBuilding || []
  );
  const [saleName, setSaleName] = useState<string>(filter?.saleName || "");
  const [apartmentName, setApartmentName] = useState<any>(
    filter?.apartmentName || ""
  );
  const [customerPhone, setCustomerPhone] = useState<any>(
    filter?.customerPhone || ""
  );

  // get building data
  const { data: buildingsData } = useList<IListBuildings>({
    resource: "buildings",
    errorNotification: false,
  });

  const { tableProps, setFilters } = useTable<IListTransactions>({
    resource: "transactions?type=deposit&",
  });
  const dataSource: any = tableProps?.dataSource;
  let transactionsTableProps = {
    ...tableProps,
    dataSource: dataSource?.data,
  };

  useEffect(() => {
    if (buildingsData && !hasChecked(listBuilding)) {
      const buildingOptionsData: any = buildingsData?.data;
      setListBuilding(
        buildingOptionsData?.data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            isChecked: false,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingsData]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setFilters(
        [
          {
            field: "room_name",
            operator: "eq",
            value: apartmentName?.trim() || null,
          },
          {
            field: "customer_phone",
            operator: "eq",
            value: customerPhone?.trim() || null,
          },
          {
            field: "sale_name",
            operator: "eq",
            value: saleName?.trim() || null,
          },
        ],
        "merge"
      );
    }, 1000);
    setFilterTransactions(
      JSON.stringify({ ...filter, apartmentName, customerPhone, saleName })
    );
    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apartmentName, customerPhone, saleName]);

  useEffect(() => {
    const statusParam = getParamFilter(listStatus);
    const buildingParam = getParamFilter(listBuilding);
    setFilters(
      [
        {
          field: "status",
          operator: "eq",
          value: statusParam,
        },
        {
          field: "building_id",
          operator: "eq",
          value: buildingParam,
        },
      ],
      "merge"
    );
    setFilterTransactions(
      JSON.stringify({ ...filter, listStatus, listBuilding })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listStatus, listBuilding]);

  return (
    <List title={translate("pages.financialManagement.transactions.list")}>
      <Space className="listFilter">
        <Button style={{ pointerEvents: "none" }}>
          <FilterFilled />
          {translate("buttons.filter")}
        </Button>
        <Button
          onClick={() => setIsOpenModalStatus(true)}
          className={hasChecked(listStatus) ? "isEnabled" : ""}
        >
          {translate("pages.financialManagement.transactions.status")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalStatus && (
          <FilterNormal
            isOpen={isOpenModalStatus}
            setIsOpen={setIsOpenModalStatus}
            title={translate("pages.financialManagement.transactions.status")}
            data={listStatus}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listStatus, false);
              setListStatus(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listStatus);
              setListStatus(newData);
            }}
            multiple={false}
          />
        )}

        <Button
          onClick={() => setIsOpenModalBuilding(true)}
          className={hasChecked(listBuilding) ? "isEnabled" : ""}
        >
          {translate("pages.financialManagement.transactions.building")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalBuilding && (
          <FilterNormal
            isOpen={isOpenModalBuilding}
            setIsOpen={setIsOpenModalBuilding}
            title={translate("pages.financialManagement.transactions.building")}
            data={listBuilding}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listBuilding, false);
              setListBuilding(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listBuilding);
              setListBuilding(newData);
            }}
            multiple={false}
          />
        )}
      </Space>
      <Space className="listFilter">
        <Space>
          <TextField
            value={
              translate(
                "pages.financialManagement.transactions.apartmentName"
              ) + ":"
            }
          />
          <Input
            value={apartmentName}
            placeholder={translate("placeholder.searchByApartmentName")}
            onChange={(e) => setApartmentName(e.target.value)}
            allowClear
          ></Input>
        </Space>
        <Space>
          <TextField
            value={
              translate(
                "pages.financialManagement.transactions.customerPhone"
              ) + ":"
            }
          />
          <Input
            value={customerPhone}
            placeholder={translate("placeholder.searchByPhoneNumber")}
            onChange={(e) => setCustomerPhone(e.target.value)}
            allowClear
          ></Input>
        </Space>
        <Space>
          <TextField
            value={
              translate("pages.financialManagement.transactions.saleName") + ":"
            }
          />
          <Input
            value={saleName}
            placeholder={translate("placeholder.searchBySaleName")}
            onChange={(e) => setSaleName(e.target.value)}
            allowClear
          ></Input>
        </Space>
      </Space>
      <Table {...transactionsTableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          title={translate("pages.financialManagement.transactions.id")}
        />
        <Table.Column
          dataIndex="type"
          title={translate("pages.financialManagement.transactions.type")}
          key="type"
          render={(value) => {
            return (
              <Tag>
                <TextField value={handleTranslateType(value, translate)} />
              </Tag>
            );
          }}
        />
        <Table.Column
          dataIndex="amount"
          title={translate("pages.financialManagement.transactions.amount")}
          key="amount"
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />
        <Table.Column
          dataIndex="status"
          title={translate("pages.financialManagement.transactions.status")}
          key="status"
          render={(value, idx: number) => {
            return (
              <TextField
                key={idx}
                value={
                  value === "processing" || value === "paid" ? (
                    <Tag bordered={false} color="yellow">
                      {translate(
                        value === "paid"
                          ? "status.paidLandlord"
                          : `status.${value}`
                      )}
                    </Tag>
                  ) : (
                    <>
                      {value === "completed" ? (
                        <Tag bordered={false} color="success">
                          {translate(`status.${value}`)}
                        </Tag>
                      ) : (
                        <Tag bordered={false} color="error">
                          {translate(`status.${value}`)}
                        </Tag>
                      )}
                    </>
                  )
                }
              />
            );
          }}
        />

        <Table.Column
          dataIndex="appointment"
          title={translate("pages.financialManagement.transactions.building")}
          key="building"
          render={(value) => {
            return value?.apartments?.map((item: any, idx: number) => {
              return (
                <Tag key={idx}>
                  <TextField value={item?.building?.name} />
                </Tag>
              );
            });
          }}
        />
        <Table.Column
          dataIndex="appointment"
          title={translate("pages.financialManagement.transactions.apartment")}
          key="apartment"
          render={(value) => {
            return value?.apartments?.map((item: any, idx: number) => {
              return (
                <Tag key={idx}>
                  <TextField value={item?.name} />
                </Tag>
              );
            });
          }}
        />

        <Table.Column
          dataIndex="appointment"
          title={translate("pages.financialManagement.transactions.sale")}
          key="sale"
          render={(value) => {
            return <TextField value={value?.sale?.full_name} />;
          }}
        />
        <Table.Column
          dataIndex="appointment"
          title={translate("pages.financialManagement.transactions.zalo")}
          key="zalo"
          render={(value) => {
            return value ? (
              <TextField
                value={parsePhoneToVNWithoutSpace(value?.customer?.phone)}
              />
            ) : (
              ""
            );
          }}
        />
        <Table.Column
          dataIndex="actor"
          title={translate(
            "pages.financialManagement.transactions.peopleCreate"
          )}
          key="actor"
          render={(value) => {
            return <TextField value={value?.full_name} />;
          }}
        />
        <Table.Column
          dataIndex="notes"
          title={translate("pages.financialManagement.transactions.notes")}
        />

        <Table.Column<IListUser>
          title={translate("table.actions")}
          dataIndex="actions"
          fixed="right"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
