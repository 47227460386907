export const STATUS_APPOINTMENT = [
  {
    value: "partial_deposit",
    label: "status.partialDeposit",
    isChecked: false,
  },
  {
    value: "fulfilled_deposit",
    label: "status.fulfilledDeposit",
    isChecked: false,
  },
  {
    value: "cancel_deposit",
    label: "status.cancelDeposit",
    isChecked: false,
  },
  {
    value: "cancel_deposit_fee",
    label: "status.cancelDepositFee",
    isChecked: false,
  },
  {
    value: "checked_in",
    label: "status.checkedIn",
    isChecked: false,
  },
];

// type loggin by admin
export const TYPE_TRANSACTIONS_MONEY_FULL_ACCESS = [
  {
    value: "deposit",
    label: "const.deposit",
  },
  {
    value: "cancel_deposit",
    label: "const.cancelDeposit",
  },
  {
    value: "commission",
    label: "const.commission",
  },
  {
    value: "other_fee",
    label: "const.otherFee",
  },
];

// type login by data admin & team leader
export const TYPE_TRANSACTIONS_MONEY_WITHOUT_COMMISSION = [
  {
    value: "deposit",
    label: "const.deposit",
  },
  {
    value: "cancel_deposit",
    label: "const.cancelDeposit",
  },
  {
    value: "other_fee",
    label: "const.otherFee",
  },
];

// type login by sale
export const TYPE_TRANSACTIONS_MONEY_DEPOSIT = [
  {
    value: "deposit",
    label: "const.deposit",
  },
  {
    value: "other_fee",
    label: "const.otherFee",
  },
];

// type login by Finance
export const TYPE_TRANSACTIONS_MONEY_COMMISSION = [
  {
    value: "cancel_deposit",
    label: "const.cancelDeposit",
  },
  {
    value: "commission",
    label: "const.commission",
  },
];

export const STATUS_TRANSACTIONS_MONEY = [
  {
    value: "pending",
    label: "status.pending",
    isChecked: false,
  },
  {
    value: "approved",
    label: "status.approved",
    isChecked: false,
  },
  {
    value: "rejected",
    label: "status.rejected",
    isChecked: false,
  },
];

export const statusColors = (value: string) => {
  switch (value) {
    case "pending":
      return "default";
    case "customer_changed_plan":
    case "partial_deposit":
      return "yellow";
    case "seen_waiting":
    case "fulfilled_deposit":
    case "checked_in":
      return "success";
    case "not_seen_canceled":
    case "seen_failed":
    case "cancel_deposit":
    case "cancel_deposit_fee":
      return "error";
  }
};

export const handleTranslate = (value: string, translate: any) => {
  switch (value) {
    case "pending":
      return translate("status.pendingAppointment");
    case "customer_changed_plan":
      return translate("status.customerChangedPlan");
    case "not_seen_canceled":
      return translate("status.notSeenCanceled");
    case "seen_waiting":
      return translate("status.seenWaiting");
    case "seen_failed":
      return translate("status.seenFailed");
    case "partial_deposit":
      return translate("status.partialDeposit");
    case "fulfilled_deposit":
      return translate("status.fulfilledDeposit");
    case "cancel_deposit":
      return translate("status.cancelDepositNoFee");
    case "cancel_deposit_fee":
      return translate("status.cancelDepositFee");
    case "checked_in":
      return translate("status.checkedIn");
    default:
      break;
  }
};

export const handleTranslateType = (value: string, translate: any) => {
  switch (value) {
    case "deposit":
      return translate("const.deposit");
    case "cancel_deposit":
      return translate("const.cancelDeposit");
    case "commission":
      return translate("const.commission");
    case "other_fee":
      return translate("const.otherFee");
    default:
      break;
  }
};

export const STATUS_APPOINTMENT_DEFAULT =
  "partial_deposit,fulfilled_deposit,cancel_deposit,cancel_deposit_fee,checked_in";
