export const TYPE_TRANSACTIONS = [
  {
    value: "deposit",
    label: "const.deposit",
    isChecked: false,
  },
  {
    value: "cancel_deposit",
    label: "const.cancelDeposit",
    isChecked: false,
  },
  {
    value: "commission",
    label: "const.commission",
    isChecked: false,
  },
  {
    value: "other_fee",
    label: "const.otherFee",
    isChecked: false,
  },
];

export const STATUS_TRANSACTIONS = [
  {
    value: "processing",
    label: "status.processing",
    isChecked: false,
  },
  {
    value: "paid",
    label: "status.paidLandlord",
    isChecked: false,
  },
  {
    value: "completed",
    label: "status.completed",
    isChecked: false,
  },
  {
    value: "rejected",
    label: "status.rejected",
    isChecked: false,
  },
];

export const handleTranslateStatus = (value: string, translate: any) => {
  switch (value) {
    case "processing":
      return translate("status.processing");
    case "paid":
      return translate("status.paidLandlord");
    case "completed":
      return translate("status.completed");
    case "rejected":
      return translate("status.rejected");
    default:
      break;
  }
};
