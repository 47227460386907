import { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  useList,
  usePermissions,
  useTranslate,
} from "@refinedev/core";
import { Edit, SaveButton, useForm } from "@refinedev/antd";
import { Form, Input, Select } from "antd";

import { IListBuildings, IListBuildingGroups } from "interfaces";
import { STATUS_ACTIVE_INACTIVE } from "const";
import { handleFilterOption, handleSortOption } from "helper";

export const BuildingGroupsEdit: React.FC<IResourceComponentsProps> = (
  props
) => {
  const translate = useTranslate();
  const { data: permissionsData }: any = usePermissions();
  const { formProps, queryResult, saveButtonProps, onFinish, form } =
    useForm<IListBuildingGroups>({
      resource: "building-groups",
    });
  const buildingGroupsData: any = queryResult?.data?.data ?? "";

  // get buildings data
  const { data: buildingsData } = useList<IListBuildings>({
    resource: "buildings",
    errorNotification: false,
  });

  const [optionsBuildings, setOptionsBuildings] = useState<any>([]);
  const [selectedItemsBuildings, setSelectedItemsBuildings] = useState<
    string[]
  >([]);

  const handleOnFinish: any = (values: IListBuildingGroups) => {
    onFinish({
      ...values,
    });
  };

  useEffect(() => {
    if (buildingsData) {
      const optionsBuildingsData: any = buildingsData?.data;
      setOptionsBuildings(
        optionsBuildingsData?.data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      );
    }
  }, [buildingsData]);

  const filteredOptionsBuildings = optionsBuildings.filter(
    (o: any) => !selectedItemsBuildings.includes(o)
  );

  return (
    <Edit
      {...props}
      title={translate(
        "pages.cartManagement.saleRightsManagement.buildingGroups.editBuildingGroups"
      )}
      canDelete={permissionsData?.includes["admin"]}
      headerButtons={({ listButtonProps, refreshButtonProps }) => (
        <>
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} />
        </>
      )}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        layout="vertical"
        initialValues={{
          ...buildingGroupsData?.data,
          building_ids: buildingGroupsData?.data?.buildings?.map(
            (item: any) => item.id
          ),
        }}
        form={form}
      >
        <Form.Item
          name="name"
          label={translate(
            "pages.cartManagement.saleRightsManagement.buildingGroups.name"
          )}
          rules={[
            {
              required: true,
              message: translate("validations.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="building_ids"
          label={translate(
            "pages.cartManagement.saleRightsManagement.buildingGroups.buildings"
          )}
          rules={[
            {
              required: true,
              message: translate("validations.buildings"),
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={translate("placeholder.selectAOneOrManyBuildings")}
            value={selectedItemsBuildings}
            onChange={setSelectedItemsBuildings}
            options={filteredOptionsBuildings}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <Form.Item
          name="status"
          label={translate(
            "pages.cartManagement.saleRightsManagement.buildingGroups.status"
          )}
          rules={[
            {
              required: true,
              message: translate("validations.status"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectStatus")}
            showSearch
            options={STATUS_ACTIVE_INACTIVE?.map((item) => {
              return {
                label: translate(item.label),
                value: item.value,
              };
            })}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
