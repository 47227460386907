import _ from "lodash";
import type { RcFile } from "antd/es/upload";
import { remove } from "diacritics";
import dayjs from "dayjs";
import {
  parsePhoneNumber,
  isPossiblePhoneNumber,
  parseIncompletePhoneNumber,
} from "libphonenumber-js";
import { PlusOutlined } from "@ant-design/icons";
import copy from "copy-to-clipboard";

export function queryStringify(params: any) {
  let query = "";
  for (const key in params) {
    query += `${query ? "&" : "?"}${key}=${
      params[key] != null ? params[key] : ""
    }`;
  }
  return query;
}

export const groupBy = (arr: string[]) => {
  const retVal: { [key: string]: number } = {};
  for (const key of arr) {
    if (retVal[key]) {
      retVal[key] += 1;
    } else {
      retVal[key] = 1;
    }
  }
  return retVal;
};

export const parsePhone = (phone: any) => {
  const phoneNumber = parsePhoneNumber(phone, "VN");

  return (
    phoneNumber &&
    parseIncompletePhoneNumber(phoneNumber?.formatInternational())
  );
};

export const parsePhoneToVN = (phone: any) => {
  const phoneNumber = parsePhoneNumber(phone, "VN");
  return phoneNumber?.formatNational();
};

export const parsePhoneToVNWithoutSpace = (phone: any) => {
  if (isNaN(phone)) {
    return phone;
  }
  const phoneNumber = parsePhoneNumber(phone, "VN");
  const formattedNumber = phoneNumber?.formatNational();
  const numberWithoutSpaces = formattedNumber?.replace(/\s/g, "");
  return numberWithoutSpaces;
};

export const isReallyPhone = (phone: string) => {
  if (phone?.length >= 8 && Number(phone)) {
    const phoneNumber = parsePhoneNumber(phone, "VN");
    return phoneNumber && isPossiblePhoneNumber(phoneNumber?.number);
  }
  return null;
};

export const isValidPassword = (password: any, lengthCheck?: boolean) => {
  const isNonWhiteSpace = /^\S*$/;
  if (!isNonWhiteSpace.test(password)) {
    return "validations.passwordMustContainWhitespaces";
  }

  const isContainsUppercase = /^(?=.*[A-Z]).*$/;
  if (!isContainsUppercase.test(password)) {
    return "validations.passwordMustOneUppercase";
  }

  const isContainsLowercase = /^(?=.*[a-z]).*$/;
  if (!isContainsLowercase.test(password)) {
    return "validations.passwordMustOneLowercase";
  }

  const isContainsNumber = /^(?=.*[0-9]).*$/;
  if (!isContainsNumber.test(password)) {
    return "validations.passwordMustContainOneDigit";
  }

  // eslint-disable-next-line no-useless-escape
  const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
  if (!isContainsSymbol.test(password)) {
    return "validations.passwordMustContainOneSpecialSymbol";
  }

  const isValidLength = /^.{8,16}$/;
  if (lengthCheck) {
    if (!isValidLength.test(password)) {
      return "validations.passwordMustContainOneCharactersLong";
    }
  }
};

export const isValidOTP = (otp: string) => {
  const isContainsNumber = /[0-9]{2}\s*-?\s*[0-9]{2}\s*-?\s*[0-9]{2}/;
  if (!isContainsNumber.test(otp)) {
    return "validations.otpNotValid";
  }
};

export const validateEmail = (emailString: string) => {
  // eslint-disable-next-line no-useless-escape
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailString))
    return true;
  return false;
};

export const formatDateMonthYear = "DD/MM/YYYY";
export const formatHour = "HH:mm";
export const formatTime = (value: any) => {
  const time = dayjs(value).format(`${formatHour} - ${formatDateMonthYear}`);
  return time;
};
export const formatDate = (value: any) => {
  const time = dayjs(value).format(`${formatDateMonthYear}`);
  return time;
};

export function getTimestampStartDate(date: Date) {
  return dayjs(date).startOf("day").valueOf();
}

export function getTimestampEndDate(date: Date) {
  return dayjs(date).endOf("day").valueOf();
}

export const isValidPrice = (price: number) => {
  if (price < 0) {
    return "validations.priceMustBeMoreThanOrEqual0";
  }
};

export const isValidPercent = (percent: number) => {
  if (percent < 0 || percent > 100) {
    return "validations.priceMust0To100";
  }
};

export const isValidQuantity = (quantity: number) => {
  if (quantity < 0) {
    return "validations.quantityMustBeMoreThanOrEqual0";
  }
};

const locale = "vi-VN";

export const formatPrice = (value: any) => {
  let VND = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "VND",
  });
  return VND.format(value);
};

export const formatInputPrice = (value: any) => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const parseInputPrice = (value: any) => {
  return `${value}`.replace(/(\.*)|/g, "");
};

export const handleFilterOption = (input: any, option: any) => {
  const inputValue = input?.toLowerCase() && remove(input?.toLowerCase());
  const optionValue =
    option?.label?.toLowerCase() && remove(option?.label?.toLowerCase());
  const optionValueSub =
    option?.subLabel?.toLowerCase() && remove(option?.subLabel?.toLowerCase());
  return (
    optionValue?.indexOf(inputValue) >= 0 ||
    optionValueSub?.indexOf(inputValue) >= 0
  );
};

export const handleSortOption = (a: any, b: any) => {
  const aValue = a?.label?.toLowerCase() && remove(a?.label?.toLowerCase());
  const bValue = b?.label?.toLowerCase() && remove(b?.label?.toLowerCase());
  const cValue =
    a?.subLabel?.toLowerCase() && remove(a?.subLabel?.toLowerCase());
  const dValue =
    b?.subLabel?.toLowerCase() && remove(b?.subLabel?.toLowerCase());
  return aValue?.localeCompare(bValue) || cValue?.localeCompare(dValue);
};

export const handleNotIncreaseDecreaseNumber = (e: any) => {
  return e.currentTarget.blur();
};

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);

export const hasChecked = (data: any) => {
  return data?.find((item: any) => item?.isChecked);
};

export const getParamFilter = (data: any) => {
  const params: any = [];
  // eslint-disable-next-line array-callback-return
  data?.map((item: any) => {
    if (item.isChecked) {
      params.push(item.value);
    }
  });
  if (params?.length > 0) {
    return params.join(",");
  } else {
    return null;
  }
};

export function formatParamsString(inputString: any) {
  // Split the input string by commas
  if (!inputString) {
    return;
  }
  const statuses = inputString.split(",");

  // Trim any leading or trailing whitespace from each status and enclose each status in double quotes
  const formattedArray = statuses.map((status: any) => `"${status.trim()}"`);

  return `[${formattedArray.join(", ")}]`;
}

export function formatParamsToNumberArray(inputString: any) {
  // Split the input string by commas
  const numbersAsString = inputString.split(",");

  // Convert each string element to a number
  const numbers = numbersAsString.map((str: any) => parseFloat(str.trim())); // Use parseInt() for integers

  return `[${numbers.join(", ")}]`;
}

export const getParamFilterPrice = (
  listRangePrice: any,
  rangePriceCustom: any
) => {
  const selectedRangePrice = listRangePrice.find((item: any) => item.isChecked);
  if (selectedRangePrice) {
    return selectedRangePrice;
  }
  if (rangePriceCustom?.from) {
    return rangePriceCustom;
  }
  return null;
};

export const onClearFilter = (data: any) => {
  const newData = data?.map((item: any) => ({
    ...item,
    isChecked: false,
  }));
  return newData;
};

export const onCheckFilter = (value: any, data: any, multiple: boolean) => {
  const newData = data?.map((item: any) => {
    if (multiple) {
      const isChecked = value?.includes(item.value);
      if (isChecked) {
        return { ...item, isChecked: true };
      } else {
        return { ...item, isChecked: false };
      }
    } else {
      if (item.value === value) {
        return { ...item, isChecked: true };
      } else {
        return { ...item, isChecked: false };
      }
    }
  });

  return newData;
};

export const onCopyPath = (data: any) => {
  if (data?.length === 1) {
    copy(data[0]);
  } else {
    const result = data?.join(",");
    copy(result);
  }
};

export const isToday = (date: any) => {
  const today = new Date();
  const dateCompare = new Date(date);
  if (today.toDateString() === dateCompare.toDateString()) {
    return true;
  }
  return false;
};

export const handleFurnitureType = (
  lists: [],
  categories: [],
  selectType: string
) => {
  if (lists?.length === categories?.length || selectType === "full") {
    return "full";
  } else if (
    (lists?.length === 0 && selectType === "empty") ||
    lists?.length === 0 ||
    selectType === "empty"
  ) {
    return "empty";
  } else {
    if (
      (lists?.length > 0 && lists?.length < categories?.length) ||
      selectType === "basic"
    ) {
      return "basic";
    }
  }
};

export const fetchAndDownloadImage = async (imageUrl: any, index: number) => {
  try {
    const response = await fetch(imageUrl);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const blob = await response.blob();

    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `downloaded_image_${index}.jpeg`;
    downloadLink.textContent = `Download Image ${index + 1}`;
    document.body.appendChild(downloadLink);

    // Trigger the download
    downloadLink.click();

    // Remove the download link from the document
    document.body.removeChild(downloadLink);
  } catch (error) {
    console.error(`Error fetching image ${index + 1}:`, error);
  }
};

export const responsiveCarousel = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1023, min: 768 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1.5,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const toCamelCase = (object: Object): any => {
  let camelCaseObject = _.cloneDeep(object);

  if (_.isArray(camelCaseObject)) {
    return _.map(camelCaseObject, toCamelCase);
  } else {
    camelCaseObject = _.mapKeys(camelCaseObject, (value, key) => {
      return _.camelCase(key);
    });

    // Recursively apply throughout object
    return _.mapValues(camelCaseObject, (value: any) => {
      if (_.isPlainObject(value)) {
        return toCamelCase(value);
      } else if (_.isArray(value)) {
        return _.map(value, toCamelCase);
      } else {
        return value;
      }
    });
  }
};

export const mergeArraysByProperty = (arr1: any, arr2: any, prop: any) => [
  ...arr1,
  ...arr2?.filter(
    (item2: any) => !arr1?.find((item1: any) => item1[prop] === item2[prop])
  ),
];

export const getHighestPrice = (array: any) => {
  if (array?.length === 0) {
    return null; // Return null if the array is empty
  }

  let highestPriceItem = array && array[0]; // Initialize with the first item

  for (let i = 1; i < array?.length; i++) {
    if (array[i]?.price > highestPriceItem?.price) {
      highestPriceItem = array[i];
    }
  }

  return highestPriceItem;
};
export const normalizeString = (str: string) => {
  return str
    .normalize("NFD") // Normalize to decomposed form
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .toLowerCase(); // Convert to lowercase
};

export const ROLE_ADMIN = "admin";
export const ROLE_DATA_ADMIN = "data-admin";
export const ROLE_FINANCE = "finance";
export const ROLE_TEAM_LEADER = "team-leader";
export const ROLE_SALE = "sale";
export const ROLE_LANDLORD = "landlord";
export const ROLE_CUSTOMER = "customer";
