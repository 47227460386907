import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { EditButton, Show, TextField } from "@refinedev/antd";
import { Button, Space, Tag, Typography } from "antd";
import { useNavigate } from "react-router-dom";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IListTeams } from "interfaces";
import { formatTime } from "helper";
import { handleTranslate, statusColors } from "./constant";

const { Title } = Typography;

export const ReservationsShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { queryResult } = useShow<IListTeams>();
  const { data, isLoading } = queryResult;
  const record: any = data?.data;

  return (
    <Show
      isLoading={isLoading}
      title={translate("pages.operation.reservations.showReservation")}
      headerButtons={({
        listButtonProps,
        editButtonProps,
        refreshButtonProps,
      }) => (
        <>
          {editButtonProps && (
            <EditButton
              {...editButtonProps}
              title={translate("pages.operation.reservations.editArea")}
            />
          )}
          <Button
            onClick={() =>
              navigate(`/contracts?reservationId=${record?.data?.id}`)
            }
          >
            {translate("pages.operation.reservations.contracts")}
          </Button>
          <Button
            onClick={() =>
              navigate(
                `/contracts/create?reservationId=${record?.data?.id}`
              )
            }
          >
            {translate("pages.operation.reservations.createContract")}
          </Button>
          <Button
            onClick={() =>
              navigate(
                `/bills?reservationId=${record?.data?.id}`
              )
            }
          >
            {translate("pages.operation.reservations.bills")}
          </Button>
          <Button onClick={() => navigate(`/bills/create?reservationId=${record?.data?.id}`)}>
            {translate("pages.operation.reservations.createBill")}
          </Button>
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
    >
      <Space>
        <Title level={5}>{translate("pages.operation.reservations.id")}:</Title>
        <TextField value={record?.data?.id} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.operation.reservations.apartment")}:
        </Title>
        <TextField
          value={
            record?.data?.apartment?.name +
            ` (${record?.data?.apartment?.building?.name})`
          }
        />
      </Space>
      <br />

      <Space>
        <Title level={5}>
          {translate("pages.operation.reservations.customer")}:
        </Title>
        <TextField value={record?.data?.customer?.full_name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.operation.reservations.period")}:
        </Title>
        <TextField value={translate(`const.${record?.data?.period}`)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.operation.reservations.status")}:
        </Title>
        <TextField
          value={
            <Tag bordered={false} color={statusColors(record?.data?.status)}>
              {handleTranslate(record?.data?.status, translate)}
            </Tag>
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.createdAt")}:</Title>
        <TextField value={formatTime(record?.data?.created_at)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.updatedAt")}:</Title>
        <TextField value={formatTime(record?.data?.updated_at)} />
      </Space>
      <br />
    </Show>
  );
};
