import { useState } from "react";
import { useTranslate } from "@refinedev/core";
import { Button, Input, Modal, Slider, Space } from "antd";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

import { formatPrice } from "helper";
import "./styles.css";
import { RANGE_PRICE } from "pages/cart-management/apartments/constant";

interface IFilterPrice {
  isOpen: boolean;
  setIsOpen: (value: any) => void;
  title: string;
  data: any;
  onClear: () => void;
  setRangePriceCustom: (value: any) => void;
  setListRangePrice: (value: any) => void;
  rangePriceCustom: any;
}

export const FilterPrice = ({
  isOpen,
  setIsOpen,
  title,
  data,
  onClear,
  setListRangePrice,
  setRangePriceCustom,
  rangePriceCustom,
}: IFilterPrice) => {
  const translate = useTranslate();
  const [minMaxPrice, setMinMaxPrice] = useState<any>(
    rangePriceCustom && [rangePriceCustom?.from, rangePriceCustom?.to]
  );

  const onCheck = (value: any) => {
    const newData = data?.map((item: any) => {
      if (item.from === value.from) {
        return { ...item, isChecked: true };
      } else {
        return { ...item, isChecked: false };
      }
    });
    setListRangePrice([...newData]);
  };

  return (
    <Modal
      open={isOpen}
      title={title}
      onCancel={() => {
        setIsOpen(false);
      }}
      footer={[
        <Button
          key="clear"
          danger
          onClick={() => {
            setRangePriceCustom(null);
            setMinMaxPrice(null);
            onClear();
            setIsOpen(false);
          }}
        >
          {translate("buttons.clear")}
        </Button>,
        <Button key="seeResult" type="primary" onClick={() => setIsOpen(false)}>
          {translate("buttons.seeResult")}
        </Button>,
      ]}
      className="filterPrice"
    >
      <div className="listItem">
        {data?.map((item: any, index: number) => (
          <Button
            className={`filterWrapper ${item.isChecked ? "isEnabled" : ""}`}
            onClick={() => {
              setRangePriceCustom(null);
              setMinMaxPrice(null);
              onCheck(item);
            }}
            key={index}
          >
            {formatPrice(item.from) + " - " + formatPrice(item.to)}
          </Button>
        ))}
      </div>
      <div
        onClick={() => {
          if (rangePriceCustom) {
            setRangePriceCustom(null);
            setMinMaxPrice(null);
          } else {
            setListRangePrice(RANGE_PRICE);
            setMinMaxPrice([4000000, 5900000]);
            setRangePriceCustom({ from: 4000000, to: 5900000 });
          }
        }}
        className="titleCustom"
      >
        {translate("pages.cartManagement.apartments.choosePriceSuitable")}
        {rangePriceCustom ? (
          <CaretUpOutlined className="icon" />
        ) : (
          <CaretDownOutlined className="icon" />
        )}
      </div>

      {rangePriceCustom && (
        <div className="rangePriceWrapper">
          <Space className="rangePrice">
            <Input
              className="rangeItem"
              value={formatPrice(minMaxPrice[0])}
              readOnly
            />
            <Space />
            <Input
              className="rangeItem"
              value={formatPrice(minMaxPrice[1])}
              readOnly
            />
          </Space>
          <Slider
            range
            min={1000000}
            max={50000000}
            onChange={(e) => {
              setMinMaxPrice(e);
            }}
            onAfterChange={(e) => {
              setRangePriceCustom({ from: e[0], to: e[1] });
            }}
            defaultValue={minMaxPrice}
            step={500000}
            tooltip={{ open: false }}
          />
        </div>
      )}
    </Modal>
  );
};
