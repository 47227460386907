import { useEffect, useState } from "react";
import {
  CrudFilters,
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import {
  List,
  useTable,
  EditButton,
  TextField,
  ShowButton,
  CreateButton,
} from "@refinedev/antd";
import { Button, Form, Input, Space, Table, Tag } from "antd";

import { IListApartments, IListBuildings, IListContracts } from "interfaces";
import {
  formatPrice,
  formatTime,
  getParamFilter,
  hasChecked,
  onCheckFilter,
  onClearFilter,
} from "helper";
import {
  getCurrentPagination,
  setCurrentPagination,
  setFilterTransactions,
  getFilterTransactions,
} from "helper/local-storage";
import { CaretDownOutlined, FilterFilled } from "@ant-design/icons";
import { FilterNormal } from "components/modal";
import {
  CASH_FLOW_LANDLORD_TRANSACTIONS,
  TYPES_LANDLORD_TRANSACTIONS,
} from "./const";

export const LandlordTransactionsList: React.FC<
  IResourceComponentsProps
> = () => {
  const translate = useTranslate();
  const filter = JSON.parse(getFilterTransactions() || "null");
  const { searchFormProps, tableProps, setFilters } = useTable<IListContracts>({
    resource: "landlord-transactions",
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { newKeyword } = params;

      filters.push({
        field: "keyword",
        operator: "eq",
        value: newKeyword || null,
      });
      setFilterTransactions(JSON.stringify({ ...filter, keyword: newKeyword }));
      return filters;
    },
  });

  // get building data
  const { data: buildingsData } = useList<IListBuildings>({
    resource: "buildings",
    errorNotification: false,
  });

  // get apartments data
  const { data: apartmentsData } = useList<IListApartments>({
    resource: "apartments",
    errorNotification: false,
  });

  const [isOpenModalBuilding, setIsOpenModalBuilding] =
    useState<boolean>(false);
  const [isOpenModalApartment, setIsOpenModalApartment] =
    useState<boolean>(false);
  const [isOpenModalType, setIsOpenModalType] = useState<boolean>(false);
  const [isOpenModalCashFlow, setIsOpenModalCashFlow] =
    useState<boolean>(false);

  const [keyword, setKeyword] = useState<string>("");
  const [listBuilding, setListBuilding] = useState<any>(
    filter?.listBuilding || []
  );
  const [listApartments, setListApartments] = useState<any>(
    filter?.listApartment || []
  );
  const [listType, setListType] = useState<any>(
    filter?.listType || TYPES_LANDLORD_TRANSACTIONS
  );
  const [listCashFlow, setListCashFlow] = useState<any>(
    filter?.listCashFlow || CASH_FLOW_LANDLORD_TRANSACTIONS
  );

  const [pagination, setPagination] = useState(
    JSON.parse(getCurrentPagination() || "{}") || {
      current: 1,
      pageSize: 10,
    }
  );
  const dataSource: any = tableProps?.dataSource;
  let contractsTableProps = {
    ...tableProps,
    pagination: pagination,
    dataSource: dataSource?.data,
  };

  const handleTranslate = (value: string) => {
    switch (value) {
      case "deposit":
        return translate("status.deposit");
      case "rental-bill":
        return translate("status.rentalBill");
      case "maintenance":
        return translate("status.maintenance");
      case "refund-deposit":
        return translate("status.refundDeposit");
      case "electricity-bill":
        return translate("status.electricityBill");
      case "water-bill":
        return translate("status.waterBill");
      case "tax":
        return translate("status.tax");
      case "other":
        return translate("status.other");
      default:
        break;
    }
  };

  const handleTranslateCashFlow = (value: string) => {
    switch (value) {
      case "cash-in":
        return translate("status.cashIn");
      case "cash-out":
        return translate("status.cashOut");
      default:
        break;
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination({ current: pagination, pageSize: 10 });
  };

  useEffect(() => {
    if (buildingsData && !hasChecked(listBuilding)) {
      const buildingOptionsData: any = buildingsData?.data;
      setListBuilding(
        buildingOptionsData?.data?.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            isChecked: false,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingsData]);

  useEffect(() => {
    if (apartmentsData && !hasChecked(listApartments)) {
      const apartmentsOptionsData: any = apartmentsData?.data;
      setListApartments(
        apartmentsOptionsData?.data?.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            isChecked: false,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apartmentsData]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchFormProps.form?.submit();
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    const buildingParam = getParamFilter(listBuilding);
    const apartmentParam = getParamFilter(listApartments);
    const typeParam = getParamFilter(listType);
    const cashFlowParam = getParamFilter(listCashFlow);

    setFilters(
      [
        {
          field: "buildingId",
          operator: "eq",
          value: buildingParam,
        },
        {
          field: "apartmentId",
          operator: "eq",
          value: apartmentParam,
        },
        {
          field: "type",
          operator: "eq",
          value: typeParam,
        },
        {
          field: "cashFlow",
          operator: "eq",
          value: cashFlowParam,
        },
      ],
      "merge"
    );
    setFilterTransactions(
      JSON.stringify({
        ...filter,
        listBuilding,
        listApartments,
        listType,
        listCashFlow,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listBuilding, listApartments, listType, listCashFlow]);

  useEffect(() => {
    setCurrentPagination(JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    const storedPagination = getCurrentPagination();
    if (storedPagination) {
      setPagination(JSON.parse(storedPagination));
    }
  }, []);

  return (
    <List
      title={translate("pages.payment.landlordTransactions.list")}
      headerButtons={({ createButtonProps }) => (
        <>
          <Form {...searchFormProps}>
            <Form.Item
              name="newKeyword"
              initialValue={keyword}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={translate("placeholder.searchInContract")}
                onChange={(e) => setKeyword(e.target.value)}
                allowClear={true}
              />
            </Form.Item>
          </Form>
          {/* <Button
          onClick={() =>
            navigate("/appointments/create", {
              state: { selectedRowKeys: selectedRowKeys },
            })
          }
        >
          {translate("pages.cartManagement.apartments.createAppointment")}
        </Button> */}

          <CreateButton {...createButtonProps} />
        </>
      )}
    >
      <Space className="listFilter">
        <Button style={{ pointerEvents: "none" }}>
          <FilterFilled />
          {translate("buttons.filter")}
        </Button>

        <Button
          onClick={() => setIsOpenModalBuilding(true)}
          className={hasChecked(listBuilding) ? "isEnabled" : ""}
        >
          {translate("pages.payment.contracts.building")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalBuilding && (
          <FilterNormal
            isOpen={isOpenModalBuilding}
            setIsOpen={setIsOpenModalBuilding}
            title={translate("pages.payment.contracts.building")}
            data={listBuilding}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listBuilding, false);
              setListBuilding(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listBuilding);
              setListBuilding(newData);
            }}
            multiple={false}
          />
        )}

        {/* <Button
          onClick={() => setIsOpenModalApartment(true)}
          className={hasChecked(listApartments) ? "isEnabled" : ""}
        >
          {translate("pages.payment.contracts.apartment")}
          <CaretDownOutlined />
        </Button> */}
        {/* {isOpenModalApartment && (
          <FilterNormal
            isOpen={isOpenModalApartment}
            setIsOpen={setIsOpenModalApartment}
            title={translate("pages.payment.contracts.apartment")}
            data={listApartments}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listApartments, false);
              setListApartments(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listApartments);
              setListApartments(newData);
            }}
            multiple={false}
          />
        )} */}

        <Button
          onClick={() => setIsOpenModalType(true)}
          className={hasChecked(listType) ? "isEnabled" : ""}
        >
          {translate("pages.payment.landlordTransactions.types")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalType && (
          <FilterNormal
            isOpen={isOpenModalType}
            setIsOpen={setIsOpenModalType}
            title={translate("pages.payment.landlordTransactions.types")}
            data={listType}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listType, false);
              setListType(newData);
            }}
            onClear={() => setListType([...TYPES_LANDLORD_TRANSACTIONS])}
            multiple={false}
          />
        )}

        <Button
          onClick={() => setIsOpenModalCashFlow(true)}
          className={hasChecked(listCashFlow) ? "isEnabled" : ""}
        >
          {translate("pages.payment.landlordTransactions.cashFlow")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalCashFlow && (
          <FilterNormal
            isOpen={isOpenModalCashFlow}
            setIsOpen={setIsOpenModalCashFlow}
            title={translate("pages.payment.landlordTransactions.cashFlow")}
            data={listCashFlow}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listCashFlow, false);
              setListCashFlow(newData);
            }}
            onClear={() =>
              setListCashFlow([...CASH_FLOW_LANDLORD_TRANSACTIONS])
            }
            multiple={false}
          />
        )}
      </Space>
      <Table
        {...contractsTableProps}
        pagination={{
          defaultPageSize: 10,
          // showSizeChanger: true,
          // pageSizeOptions: ["10", "20", "30", "40"],
          current: pagination.current,
          onChange: handleTableChange,
        }}
        rowKey="id"
        className="list-users"
      >
        <Table.Column
          dataIndex="bill"
          title={translate("pages.payment.landlordTransactions.bill")}
          render={(value) => {
            return <TextField value={value?.name} />;
          }}
        />
        <Table.Column
          dataIndex="building"
          title={translate("pages.payment.landlordTransactions.building")}
          render={(value) => {
            return <TextField value={value?.name} />;
          }}
        />
        <Table.Column
          title={translate("pages.payment.landlordTransactions.types")}
          dataIndex="type"
          render={(value) => <TextField value={handleTranslate(value)} />}
        />
        <Table.Column
          title={translate("pages.payment.landlordTransactions.cashFlow")}
          dataIndex="cashFlow"
          render={(value) => (
            <TextField value={handleTranslateCashFlow(value)} />
          )}
        />
        <Table.Column
          title={translate("pages.payment.landlordTransactions.amount")}
          dataIndex="amount"
          render={(value) => <TextField value={formatPrice(value)} />}
        />
        <Table.Column
          dataIndex="createdAt"
          title={translate("pages.createdAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column
          width={400}
          dataIndex="updatedAt"
          title={translate("pages.updatedAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column<IListContracts>
          title={translate("table.actions")}
          dataIndex="actions"
          fixed="right"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
