import { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Button, Form, Select, Tag, message } from "antd";

import { IListApartments, IListUser } from "interfaces";
import {
  handleFilterOption,
  handleSortOption,
  parsePhone,
  parsePhoneToVN,
  parsePhoneToVNWithoutSpace,
} from "helper";
import { IListTeams } from "interfaces";
import {
  PERIOD_RESERVATIONS,
  STATUS_RESERVATIONS,
  handleTranslate,
} from "./constant";
import { CreateNewCustomer } from "components/modal";
import axios from "axios";
import { API_URL } from "api";

export const ReservationsCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  const [optionsApartments, setOptionsApartments] = useState<[]>([]);
  const [optionsCustomers, setOptionsCustomers] = useState<[]>([]);
  const [isOpenModalCreateNewCustomer, setIsOpenModalCreateNewCustomer] =
    useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const { formProps, saveButtonProps, onFinish, form } = useForm<IListUser>({
    errorNotification: (data) => {
      const errorMessage: any = data?.response;
      const message: any = errorMessage?.data?.data?.errors[0].message;
      return {
        message: message,
        description: "Error",
        type: "error",
      };
    },
  });

  // get apartments data
  const { data: apartmentsData } = useList<IListApartments>({
    resource: "apartments?status=vacant,near_vacant&",
    errorNotification: false,
  });

  // get customers data
  const { data: customersData } = useList<IListUser>({
    resource: "customers",
    errorNotification: false,
  });

  const onCreateNewCustomer = async () => {
    const response: any = await axios
      .post(
        `${API_URL}/customers`,
        {
          full_name: name,
          phone: phone ? parsePhone(phone) : "",
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((data) => data.data)
      .catch((error) => {
        const { response } = error;
        message.error(translate("notifications.createNewCustomerError"));
        response?.data?.data?.errors?.map((item: any) => {
          return message.error(item?.message);
        });
        console.error(error);
      });

    if (response?.status) {
      // Handle success
      message.success(translate("notifications.createNewCustomerSuccess"));
      setIsOpenModalCreateNewCustomer(false);
      getNewCustomerList();
    }
  };

  const getNewCustomerList = async () => {
    return await axios
      .get(`${API_URL}/customers`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const { data } = res.data;
        setOptionsCustomers(
          data?.map((item: any) => {
            return {
              value: item?.id,
              label: item?.full_name,
              subLabel:
                parsePhoneToVN(item?.phone) +
                " " +
                parsePhoneToVNWithoutSpace(item?.phone) +
                " " +
                item?.phone,
            };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (apartmentsData) {
      const optionsApartmentsData: any = apartmentsData?.data;
      setOptionsApartments(
        optionsApartmentsData?.data?.map((item: any) => {
          return {
            value: item.id,
            label: item?.name + ` (${item?.building?.name})`,
          };
        })
      );
    }
  }, [apartmentsData]);

  useEffect(() => {
    if (customersData) {
      const optionsCustomersData: any = customersData?.data;
      setOptionsCustomers(
        optionsCustomersData?.data?.map((item: any) => {
          return {
            value: item?.id,
            label: item?.full_name,
            subLabel:
              parsePhoneToVN(item?.phone) +
              " " +
              parsePhoneToVNWithoutSpace(item?.phone) +
              " " +
              item?.phone,
          };
        })
      );
    }
  }, [customersData]);

  const handleOnFinish: any = (values: IListTeams) => {
    onFinish({
      ...values,
    });
  };

  return (
    <Create
      title={translate("pages.operation.reservations.createNewReservation")}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        initialValues={{ period: "monthly", status: "waiting-for-deposit" }}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label={translate("pages.operation.reservations.apartment")}
          name="apartmentId"
          rules={[
            {
              required: true,
              message: translate("validations.apartments"),
            },
          ]}
        >
          <Select
            options={optionsApartments}
            placeholder={translate("placeholder.selectAApartment")}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <div
          style={{
            minWidth: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Form.Item
            style={{ width: "100%" }}
            label={translate("pages.operation.reservations.customer")}
            name="customerId"
            rules={[
              {
                required: true,
                message: translate("validations.customers"),
              },
            ]}
          >
            <Select
              options={optionsCustomers}
              placeholder={translate("placeholder.selectACustomer")}
              showSearch
              filterOption={(input, option: any) =>
                handleFilterOption(input, option)
              }
              filterSort={(optionA, optionB) =>
                handleSortOption(optionA, optionB)
              }
            />
          </Form.Item>
          <Button
            style={{ marginTop: "5px" }}
            onClick={() => setIsOpenModalCreateNewCustomer(true)}
          >
            {translate("pages.operation.reservations.createNewCustomer")}
          </Button>
        </div>
        <Form.Item
          label={translate("pages.operation.reservations.period")}
          name="period"
          rules={[
            {
              required: true,
              message: translate("validations.period"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectPeriod")}
            options={PERIOD_RESERVATIONS?.map((item) => {
              return {
                label: translate(item.label),
                value: item.value,
              };
            })}
          />
        </Form.Item>
        <Form.Item
          label={translate("pages.operation.reservations.status")}
          name="status"
          rules={[
            {
              required: true,
              message: translate("validations.status"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectStatus")}
            options={STATUS_RESERVATIONS?.map((item) => {
              return {
                label: (
                  <Tag
                    color={
                      item.value === "checked-in" || item.value === "deposited"
                        ? "success"
                        : item.value === "waiting-for-deposit"
                        ? "warning"
                        : item.value === "canceled"
                        ? "error"
                        : "geekblue"
                    }
                  >
                    {handleTranslate(item.value, translate)}
                  </Tag>
                ),
                value: item.value,
              };
            })}
          />
        </Form.Item>
      </Form>
      {isOpenModalCreateNewCustomer ? (
        <CreateNewCustomer
          isOpen={isOpenModalCreateNewCustomer}
          setIsOpen={setIsOpenModalCreateNewCustomer}
          title={translate("pages.operation.reservations.createNewCustomer")}
          onSubmit={onCreateNewCustomer}
          loading={false}
          name={name}
          setName={setName}
          phone={phone}
          setPhone={setPhone}
        />
      ) : null}
    </Create>
  );
};
