import { useEffect, useState } from "react";
import axios from "axios";
import {
  IResourceComponentsProps,
  usePermissions,
  useTranslate,
  CrudFilters,
  useList,
} from "@refinedev/core";
import {
  List,
  useTable,
  TextField,
  EditButton,
  ShowButton,
} from "@refinedev/antd";
import {
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import { CaretDownOutlined, FilterFilled } from "@ant-design/icons";
import dayjs from "dayjs";

import { IListApointments, IListBuildings } from "interfaces";
import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_FINANCE,
  ROLE_SALE,
  ROLE_TEAM_LEADER,
  formatDateMonthYear,
  formatTime,
  getParamFilter,
  handleFilterOption,
  handleSortOption,
  hasChecked,
  onCheckFilter,
  onClearFilter,
} from "helper";
import {
  STATUS_APPOINTMENT,
  STATUS_APPOINTMENT_DEFAULT,
  handleTranslate,
  statusColors,
} from "./constant";
import { FilterTimeFromTo, FilterNormal, FilterTime } from "components/modal";
import {
  getCurrentPagination,
  getFilterDeals,
  setCurrentPagination,
  setFilterDeals,
} from "helper/local-storage";
import { API_URL } from "api";

export const DealsTeamList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const permissions = usePermissions<string>();
  const filter = JSON.parse(getFilterDeals() || "{}");
  const [keyword, setKeyword] = useState<string>(filter?.keyword || "");

  const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);
  const [isOpenModalTime, setIsOpenModalTime] = useState<boolean>(false);
  const [isOpenModalCheckIn, setIsOpenModalCheckIn] = useState<boolean>(false);
  const [isOpenModalDepositCompletion, setIsOpenModalDepositCompletion] =
    useState<boolean>(false);
  const [isOpenModalBuilding, setIsOpenModalBuilding] =
    useState<boolean>(false);
  const [listStatus, setListStatus] = useState<any>(
    filter?.listStatus || STATUS_APPOINTMENT
  );
  const [listBuilding, setListBuilding] = useState<any>(
    filter?.listBuilding || []
  );

  const [fromTime, setFromTime] = useState<number>(filter?.fromTime || 0);
  const [toTime, setToTime] = useState<number>(filter?.toTime || 0);
  const [timeCheckIn, setTimeCheckIn] = useState<number>(
    filter?.timeCheckIn || 0
  );
  const [selectedFromDate, setSelectedFromDate] = useState<any>("");
  const [selectedToDate, setSelectedToDate] = useState<any>("");
  const [buildingsOptions, setBuildingsOptions] = useState<[]>([]);
  const [currentBuildingOption, setCurrentBuildingOption] = useState<number>();
  const [timeDepositCompletion, setTimeDepositCompletion] = useState<number>(
    filter?.timeDepositCompletion || 0
  );

  const onChangeDateFromDate = (value: DatePickerProps["value"]) => {
    setSelectedFromDate(value?.format("YYYY-MM-DD"));
  };

  const onChangeDateToDate = (value: DatePickerProps["value"]) => {
    setSelectedToDate(value?.format("YYYY-MM-DD"));
  };

  const [pagination, setPagination] = useState(
    JSON.parse(getCurrentPagination() || "{}") || {
      current: 1,
      pageSize: 10,
    }
  );

  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissions?.data === ROLE_DATA_ADMIN;
  const isLoggedbyTeamLeader = permissions?.data === ROLE_TEAM_LEADER;
  const isLoggedbySale = permissions?.data === ROLE_SALE;
  const isLoggedbyFinance = permissions?.data === ROLE_FINANCE;
  const { searchFormProps, setFilters, tableProps } =
    useTable<IListApointments>({
      resource: "appointments",
      onSearch: (params: any) => {
        const filters: CrudFilters = [];
        const { newKeyword } = params;

        filters.push({
          field: "keyword",
          operator: "eq",
          value: newKeyword || null,
        });
        setFilterDeals(JSON.stringify({ ...filter, keyword: newKeyword }));
        return filters;
      },
      filters: {
        initial: [
          {
            field: "status",
            operator: "eq",
            value: STATUS_APPOINTMENT_DEFAULT,
          },
        ],
      },
    });

  // get building data
  const { data: buildingsData } = useList<IListBuildings>({
    resource: "buildings",
    errorNotification: false,
  });

  const dataSource: any = tableProps?.dataSource;
  let dealsTableProps = {
    ...tableProps,
    pagination: pagination,
    dataSource: dataSource?.data,
  };

  const handleTableChange = (pagination: any) => {
    setPagination({ current: pagination, pageSize: 10 });
  };

  useEffect(() => {
    setCurrentPagination(JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    const storedPagination = getCurrentPagination();
    if (storedPagination) {
      setPagination(JSON.parse(storedPagination));
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchFormProps.form?.submit();
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    const statusParams = getParamFilter(listStatus);
    const buildingParam = getParamFilter(listBuilding);
    setFilters(
      [
        {
          field: "status",
          operator: "eq",
          value: statusParams || STATUS_APPOINTMENT_DEFAULT,
        },
        {
          field: "from_time",
          operator: "eq",
          value: fromTime ? fromTime / 1000 : null,
        },
        {
          field: "to_time",
          operator: "eq",
          value: toTime ? toTime / 1000 : null,
        },
        {
          field: "check_in_date",
          operator: "eq",
          value: timeCheckIn ? timeCheckIn / 1000 : null,
        },
        {
          field: "deposit_completion_date",
          operator: "eq",
          value: timeDepositCompletion ? timeDepositCompletion / 1000 : null,
        },
        {
          field: "building_id",
          operator: "eq",
          value: buildingParam,
        },
      ],
      "merge"
    );
    setFilterDeals(
      JSON.stringify({
        ...filter,
        listStatus,
        fromTime,
        toTime,
        timeCheckIn,
        timeDepositCompletion,
        listBuilding,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listStatus,
    fromTime,
    toTime,
    timeCheckIn,
    timeDepositCompletion,
    listBuilding,
  ]);

  useEffect(() => {
    if (buildingsData) {
      const buildingOptionsData: any = buildingsData?.data;
      setBuildingsOptions(
        buildingOptionsData?.data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      );
    }
    if (buildingsData && !hasChecked(listBuilding)) {
      const buildingOptionsData: any = buildingsData?.data;
      setListBuilding(
        buildingOptionsData?.data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            isChecked: false,
          };
        })
      );
    }
  }, [buildingsData]);

  const onImportExcel = () => {
    if (!selectedFromDate) {
      message.error(translate("validations.notSelectFromDateYet"));
      return;
    }
    if (!selectedToDate) {
      message.error(translate("validations.notSelectToDateYet"));
      return;
    }
    axios
      .get(
        `${API_URL}/excel/export/deals?building_id=${
          currentBuildingOption ? currentBuildingOption : ""
        }&from_date=${selectedFromDate}&to_date=${selectedToDate}`,
        {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        // Create a Blob from the response data
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a download link
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `deals_${selectedFromDate}_${selectedToDate}.xlsx`;

        // Append the link to the body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Remove the link from the body
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error exporting deals:", error);
      });
  };

  return (
    <List
      title={translate("pages.financialManagement.deals.list")}
      headerButtons={() => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Form {...searchFormProps}>
            <Form.Item
              name="newKeyword"
              initialValue={keyword}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={translate("placeholder.searchByInfoAppointment")}
                onChange={(e) => setKeyword(e.target.value)}
                allowClear={true}
              />
            </Form.Item>
          </Form>
          {isLoggedbyFinance || isLoggedbyAdmin ? (
            <div style={{ marginTop: 10, alignSelf: "flex-end" }}>
              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ marginBottom: 10 }}>
                  <Select
                    style={{ width: "100%" }}
                    placeholder={translate("placeholder.selectABuilding")}
                    options={buildingsOptions}
                    showSearch
                    allowClear
                    filterOption={(input, option: any) =>
                      handleFilterOption(input, option)
                    }
                    filterSort={(optionA, optionB) =>
                      handleSortOption(optionA, optionB)
                    }
                    value={currentBuildingOption}
                    onChange={(value) => setCurrentBuildingOption(value)}
                  />
                </div>
                <div>
                  <Space direction="vertical" size={12}>
                    <DatePicker
                      inputReadOnly={true}
                      placeholder={translate("placeholder.selectFromDate")}
                      onChange={onChangeDateFromDate}
                      getPopupContainer={(trigger: any) => trigger.parentNode}
                    />
                  </Space>
                  <Space direction="vertical" size={12}>
                    <DatePicker
                      inputReadOnly={true}
                      placeholder={translate("placeholder.selectToDate")}
                      onChange={onChangeDateToDate}
                      getPopupContainer={(trigger: any) => trigger.parentNode}
                    />
                  </Space>
                </div>
                <Button onClick={onImportExcel} style={{ marginTop: 10 }}>
                  {translate("pages.financialManagement.deals.exportExcel")}
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      )}
    >
      <Space className="listFilter">
        <Button style={{ pointerEvents: "none" }}>
          <FilterFilled />
          {translate("buttons.filter")}
        </Button>
        <Button
          onClick={() => setIsOpenModalStatus(true)}
          className={hasChecked(listStatus) ? "isEnabled" : ""}
        >
          {translate("pages.financialManagement.deals.status")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalStatus && (
          <FilterNormal
            isOpen={isOpenModalStatus}
            setIsOpen={setIsOpenModalStatus}
            title={translate("pages.financialManagement.deals.status")}
            data={listStatus}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listStatus, true);
              setListStatus(newData);
            }}
            onClear={() => setListStatus([...STATUS_APPOINTMENT])}
            multiple={true}
          />
        )}
        <Button
          onClick={() => setIsOpenModalTime(true)}
          className={fromTime || toTime ? "isEnabled" : ""}
        >
          {translate("pages.financialManagement.deals.date")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalTime && (
          <FilterTimeFromTo
            isOpen={isOpenModalTime}
            setIsOpen={setIsOpenModalTime}
            title={translate("pages.financialManagement.deals.date")}
            fromTime={fromTime}
            setFromTime={setFromTime}
            toTime={toTime}
            setToTime={setToTime}
          />
        )}
        <Button
          onClick={() => setIsOpenModalCheckIn(true)}
          className={timeCheckIn ? "isEnabled" : ""}
        >
          {translate("pages.financialManagement.deals.checkInDate")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalCheckIn && (
          <FilterTime
            isOpen={isOpenModalCheckIn}
            setIsOpen={setIsOpenModalCheckIn}
            title={translate("pages.financialManagement.deals.checkInDate")}
            time={timeCheckIn}
            setTime={setTimeCheckIn}
          />
        )}
        <Button
          onClick={() => setIsOpenModalDepositCompletion(true)}
          className={timeDepositCompletion ? "isEnabled" : ""}
        >
          {translate("pages.financialManagement.deals.depositCompletionDate")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalDepositCompletion && (
          <FilterTime
            isOpen={isOpenModalDepositCompletion}
            setIsOpen={setIsOpenModalDepositCompletion}
            title={translate(
              "pages.financialManagement.deals.depositCompletionDate"
            )}
            time={timeDepositCompletion}
            setTime={setTimeDepositCompletion}
          />
        )}
        <Button
          onClick={() => setIsOpenModalBuilding(true)}
          className={hasChecked(listBuilding) ? "isEnabled" : ""}
        >
          {translate("pages.financialManagement.deals.buildings")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalBuilding && (
          <FilterNormal
            isOpen={isOpenModalBuilding}
            setIsOpen={setIsOpenModalBuilding}
            title={translate("pages.financialManagement.deals.buildings")}
            data={listBuilding}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listBuilding, false);
              setListBuilding(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listBuilding);
              setListBuilding(newData);
            }}
            multiple={false}
          />
        )}
      </Space>
      <Table
        {...dealsTableProps}
        pagination={{
          ...tableProps.pagination,
          showTotal: (total: number, range: [number, number]) => (
            <>
              <Space style={{ fontWeight: 600, fontSize: 18 }}>
                {translate("const.total")}
                {total}
                {translate("const.deals")}
              </Space>
            </>
          ),
          defaultPageSize: 10,
          // showSizeChanger: true,
          // pageSizeOptions: ["10", "20", "30", "40"],
          current: pagination.current,
          onChange: handleTableChange,
        }}
        rowKey="id"
        className="list-users"
      >
        {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyTeamLeader ? (
          <Table.Column
            dataIndex="sale"
            title={translate("pages.financialManagement.deals.sale")}
            key="sale"
            render={(item, idx: number) => {
              return (
                <Tag key={idx}>
                  <TextField value={item?.full_name} />
                </Tag>
              );
            }}
          />
        ) : null}
        <Table.Column
          dataIndex="apartments"
          title={translate("pages.financialManagement.deals.apartments")}
          key="apartments"
          render={(value) => {
            return value?.map((item: any, idx: number) => {
              return (
                <Tag key={idx}>
                  <TextField value={item?.name} />
                </Tag>
              );
            });
          }}
        />
        <Table.Column
          dataIndex="apartments"
          title={translate("pages.financialManagement.deals.buildings")}
          key="apartments"
          render={(value) => {
            return value?.map((item: any, idx: number) => {
              return (
                <Tag key={idx}>
                  <TextField value={item?.building?.name} />
                </Tag>
              );
            });
          }}
        />
        <Table.Column
          dataIndex="customer"
          title={translate("pages.financialManagement.deals.customers")}
          key="customer"
          render={(value) => {
            return (
              value?.full_name && (
                <Tag>
                  <TextField value={value?.full_name} />
                </Tag>
              )
            );
          }}
        />
        <Table.Column
          dataIndex="become_deal_at"
          title={translate("pages.financialManagement.deals.time")}
          render={(value) => {
            return <TextField value={value ? formatTime(value) : ""} />;
          }}
        />
        {isLoggedbySale ? (
          <Table.Column
            dataIndex="role"
            title={translate("pages.financialManagement.deals.role")}
            render={(value) => {
              return value === "sale" ? (
                <Tag>
                  <TextField
                    value={translate(
                      "pages.financialManagement.deals.inCharge"
                    )}
                  />
                </Tag>
              ) : (
                <Tag>
                  <TextField
                    value={translate("pages.financialManagement.deals.support")}
                  />
                </Tag>
              );
            }}
          />
        ) : null}
        <Table.Column
          dataIndex="status"
          title={translate("pages.financialManagement.deals.status")}
          render={(value, idx: number) => {
            return (
              <TextField
                key={idx}
                value={
                  <Tag bordered={false} color={statusColors(value)}>
                    {handleTranslate(value, translate)}
                  </Tag>
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="complete_deposit_date"
          title={translate(
            "pages.financialManagement.deals.depositCompletionDate"
          )}
          render={(value) => {
            return (
              <TextField
                value={value ? dayjs(value).format(formatDateMonthYear) : ""}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="check_in_date"
          title={translate("pages.financialManagement.deals.checkInDate")}
          render={(value) => {
            return (
              <TextField
                value={value ? dayjs(value).format(formatDateMonthYear) : ""}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="notes"
          title={translate("pages.financialManagement.deals.notes")}
          render={(value) => {
            return <TextField value={value} />;
          }}
        />
        <Table.Column<IListApointments>
          title={translate("table.actions")}
          dataIndex="actions"
          fixed="right"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
