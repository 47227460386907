import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useGetLocale, useSetLocale, useTranslate } from "@refinedev/core";
import { DownOutlined } from "@ant-design/icons";
import { Space, Button, Dropdown, Avatar } from "antd";

import { ColorModeContext } from "contexts/color-mode";

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const translate = useTranslate();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { mode } = useContext(ColorModeContext);

  const currentLocale = locale();

  const handleSwichLabel = (lang: string | undefined) => {
    switch (lang) {
      case "vi":
        return translate("languages.vietnamese");
      case "en":
        return translate("languages.english");
      default:
        break;
    }
  };

  const menuItems: any = [...(i18n.languages || [])]
    .sort()
    .map((lang: string) => ({
      key: lang,
      onClick: () => changeLanguage(lang),
      icon: (
        <span style={{ marginRight: 8 }}>
          <Avatar size={16} src={`/images/flags/${lang}.svg`} />
        </span>
      ),
      label: handleSwichLabel(lang),
    }));

  return (
    <Dropdown
      menu={{
        className: `${mode === "light" ? "isLight" : "isDark"}`,
        items: menuItems,
        selectedKeys: currentLocale ? [currentLocale] : [],
      }}
    >
      <Button type="text">
        <Space>
          <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />
          {handleSwichLabel(currentLocale)}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
