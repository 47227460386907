import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  CrudFilters,
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import {
  List,
  useTable,
  EditButton,
  TextField,
  ShowButton,
  CreateButton,
} from "@refinedev/antd";

import { Button, Form, Input, Space, Table } from "antd";
import { CaretDownOutlined, FilterFilled } from "@ant-design/icons";

import { IListApartments, IListBuildings, IProvinces } from "interfaces";
import {
  formatPrice,
  getParamFilter,
  getParamFilterPrice,
  hasChecked,
  onCheckFilter,
  onClearFilter,
} from "helper";

import {
  getFilterApartments,
  getCurrentPagination,
  getSelectedRowKeysApartment,
  setCurrentPagination,
  setFilterApartments,
  setSelectedRowKeysApartment,
} from "helper/local-storage";
import { FilterNormal, FilterPrice } from "components/modal";
import {
  RANGE_PRICE,
  STATUS_BALCONIES,
  STATUS_WINDOW,
  TYPE_ROOM,
} from "./constant";
import { getDistricts, getWards } from "api";
import { STATUS_APARTMENT } from "../buildings/constant";

export const RoomsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  const filter = JSON.parse(getFilterApartments() || "null");

  const [keyword, setKeyword] = useState<string>(filter?.keyword || "");
  const [isOpenModalType, setIsOpenModalType] = useState<boolean>(false);
  const [isOpenModalBuilding, setIsOpenModalBuilding] =
    useState<boolean>(false);
  const [isOpenModalPrice, setIsOpenModalPrice] = useState<boolean>(false);
  const [isOpenModalProvince, setIsOpenModalProvince] =
    useState<boolean>(false);
  const [isOpenModalDistrict, setIsOpenModalDistrict] =
    useState<boolean>(false);
  const [isOpenModalWard, setIsOpenModalWard] = useState<boolean>(false);
  const [isOpenModalWindow, setIsOpenModalWindow] = useState<boolean>(false);
  const [isOpenModalBalconies, setIsOpenModalBalconies] =
    useState<boolean>(false);

  const [listType, setListType] = useState<any>(filter?.listType || TYPE_ROOM);
  const [listBuilding, setListBuilding] = useState<any>(
    filter?.listBuilding || []
  );
  const [listRangePrice, setListRangePrice] = useState<any>(
    filter?.listRangePrice || RANGE_PRICE
  );
  const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);
  const [listStatus, setListStatus] = useState<any>(
    filter?.listStatus || STATUS_APARTMENT
  );
  const [rangePriceCustom, setRangePriceCustom] = useState<any>(
    filter?.rangePriceCustom || null
  );
  const [listProvince, setListProvince] = useState<any>(
    filter?.listProvince || []
  );
  const [listDistrict, setListDistrict] = useState<any>(
    filter?.listDistrict || []
  );
  const [listWard, setListWard] = useState<any>(filter?.listWard || []);
  const [listWindow, setListWindow] = useState<any>(
    filter?.listWindow || STATUS_WINDOW
  );
  const [listBalconies, setListBalconies] = useState<any>(
    filter?.listBalconies || STATUS_BALCONIES
  );
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  const [skipGetWardsFirst, setSkipGetWardsFirst] = useState<boolean>(
    filter ? true : false
  );
  const [pagination, setPagination] = useState(
    JSON.parse(getCurrentPagination() || "{}") || {
      current: 1,
      pageSize: 50,
    }
  );

  // get building data
  const { data: buildingsData } = useList<IListBuildings>({
    resource: "buildings",
    errorNotification: false,
  });

  // get province data
  const { data: provincesData } = useList<IProvinces>({
    resource: "addresses/provinces",
    errorNotification: false,
  });

  const { searchFormProps, tableProps, setFilters } = useTable<IListApartments>(
    {
      resource: "apartments",
      onSearch: (params: any) => {
        const filters: CrudFilters = [];
        const { newKeyword } = params;

        filters.push({
          field: "keyword",
          operator: "eq",
          value: newKeyword || null,
        });
        setFilterApartments(JSON.stringify({ ...filter, keyword: newKeyword }));
        return filters;
      },
    }
  );
  const dataSource: any = tableProps?.dataSource;

  let apartmentsTableProps = {
    ...tableProps,
    pagination: pagination,
    dataSource: dataSource?.data,
  };

  const selectRowKeysApartmentsLocalStorage = JSON.parse(
    getSelectedRowKeysApartment() || "[]"
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState<any>(
    selectRowKeysApartmentsLocalStorage ?? []
  );

  const onSelectedRowKeysChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRowKeysApartment(selectedRowKeys);
  };

  const selectRow = (record: any) => {
    const selectedKeys = [...selectedRowKeys];

    if (selectedKeys.indexOf(record.key) >= 0) {
      selectedKeys.splice(selectedKeys.indexOf(record.key), 1);
    } else {
      selectedKeys.push(record.key);
    }
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectedRowKeysChange,
  };

  const handleTableChange = (pagination: any) => {
    setPagination({ current: pagination, pageSize: 50 });
  };

  useEffect(() => {
    setCurrentPagination(JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    const storedPagination = getCurrentPagination();
    if (storedPagination) {
      setPagination(JSON.parse(storedPagination));
    }
  }, []);

  useEffect(() => {
    if (buildingsData && !hasChecked(listBuilding)) {
      const buildingOptionsData: any = buildingsData?.data;
      setListBuilding(
        buildingOptionsData?.data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            isChecked: false,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingsData]);

  useEffect(() => {
    if (!hasChecked(listProvince)) {
      const provinces: any = provincesData?.data ?? [];
      const queryResult = provinces?.data;
      if (provinces?.status) {
        const optionsProvinces = queryResult?.map((item: any) => ({
          label: item.name,
          value: item.id,
          isChecked: false,
        }));
        setListProvince(optionsProvinces);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provincesData]);

  useEffect(() => {
    const selectedProvince = listProvince?.find((item: any) => item.isChecked);
    if (
      selectedProvince &&
      selectedProvince?.value !== listDistrict[0]?.provinceId
    ) {
      getDistricts(selectedProvince?.value).then((response) => {
        const optionsDistricts = response?.data.map((item: any) => ({
          label: item.name,
          value: item.id,
          provinceId: item.province_id,
          isChecked: false,
        }));
        setListDistrict(optionsDistricts);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listProvince]);

  useEffect(() => {
    if (skipGetWardsFirst) {
      setSkipGetWardsFirst(false);
      return;
    }
    const districtChecked = listDistrict?.find(
      (item: any) => item.value === selectedDistrict
    );
    if (districtChecked) {
      if (districtChecked.isChecked) {
        // eslint-disable-next-line array-callback-return
        getWards(districtChecked.value).then((response) => {
          const optionsWards = response?.data.map((item: any) => ({
            label: item.name,
            value: item.id,
            districtId: item.district_id,
            isChecked: false,
          }));
          setListWard([...listWard, ...optionsWards]);
        });
      } else {
        const newListWard = listWard.filter(
          (item: any) => item.districtId !== districtChecked.value
        );
        setListWard(newListWard);
      }
    } else {
      setListWard([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listDistrict, selectedDistrict]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchFormProps.form?.submit();
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    const typeParams = getParamFilter(listType);
    const rangePriceParam = getParamFilterPrice(
      listRangePrice,
      rangePriceCustom
    );
    const buildingParam = getParamFilter(listBuilding);
    const provinceParam = getParamFilter(listProvince);
    const districtParams = getParamFilter(listDistrict);
    const wardParams = getParamFilter(listWard);
    const windowParams = getParamFilter(listWindow);
    const balconiesParams = getParamFilter(listBalconies);
    const statusParams = getParamFilter(listStatus);

    setFilters(
      [
        {
          field: "types",
          operator: "eq",
          value: typeParams,
        },
        {
          field: "min_price",
          operator: "eq",
          value: rangePriceParam?.from || null,
        },
        {
          field: "max_price",
          operator: "eq",
          value: rangePriceParam?.to || null,
        },
        {
          field: "building_id",
          operator: "eq",
          value: buildingParam,
        },
        {
          field: "province_id",
          operator: "eq",
          value: provinceParam,
        },
        {
          field: "district_ids",
          operator: "eq",
          value: districtParams,
        },
        {
          field: "ward_ids",
          operator: "eq",
          value: wardParams,
        },
        {
          field: "windows",
          operator: "eq",
          value: windowParams,
        },
        {
          field: "balconies",
          operator: "eq",
          value: balconiesParams,
        },
        {
          field: "status",
          operator: "eq",
          value: statusParams,
        },
      ],
      "merge"
    );
    setFilterApartments(
      JSON.stringify({
        ...filter,
        listType,
        listBuilding,
        listRangePrice,
        rangePriceCustom,
        listProvince,
        listDistrict,
        listWard,
        listWindow,
        listBalconies,
        listStatus,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listType,
    listBuilding,
    listRangePrice,
    rangePriceCustom,
    listProvince,
    listDistrict,
    listWard,
    listWindow,
    listBalconies,
    listStatus,
  ]);

  return (
    <List
      title={translate("pages.realEstate.rooms.list")}
      headerButtons={({ createButtonProps }) => (
        <>
          <Form {...searchFormProps}>
            <Form.Item
              name="newKeyword"
              initialValue={keyword}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={translate("placeholder.searchByNameApartments")}
                onChange={(e) => setKeyword(e.target.value)}
                allowClear={true}
              />
            </Form.Item>
          </Form>
          {/* <Button
            onClick={() =>
              navigate("/appointments/create", {
                state: { selectedRowKeys: selectedRowKeys },
              })
            }
          >
            {translate("pages.cartManagement.apartments.createAppointment")}
          </Button> */}
          {createButtonProps ? <CreateButton {...createButtonProps} /> : null}
        </>
      )}
    >
      <Space className="listFilter">
        <Button style={{ pointerEvents: "none" }}>
          <FilterFilled />
          {translate("buttons.filter")}
        </Button>
        <Button
          onClick={() => setIsOpenModalType(true)}
          className={hasChecked(listType) ? "isEnabled" : ""}
        >
          {translate("pages.cartManagement.apartments.type")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalType && (
          <FilterNormal
            isOpen={isOpenModalType}
            setIsOpen={setIsOpenModalType}
            title={translate("pages.cartManagement.apartments.type")}
            data={listType}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listType, true);
              setListType(newData);
            }}
            onClear={() => setListType([...TYPE_ROOM])}
            multiple={true}
          />
        )}
        <Button
          onClick={() => setIsOpenModalBuilding(true)}
          className={hasChecked(listBuilding) ? "isEnabled" : ""}
        >
          {translate("pages.cartManagement.apartments.building")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalBuilding && (
          <FilterNormal
            isOpen={isOpenModalBuilding}
            setIsOpen={setIsOpenModalBuilding}
            title={translate("pages.cartManagement.apartments.building")}
            data={listBuilding}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listBuilding, false);
              setListBuilding(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listBuilding);
              setListBuilding(newData);
            }}
            multiple={false}
          />
        )}
        <Button
          onClick={() => setIsOpenModalPrice(true)}
          className={
            hasChecked(listRangePrice) || rangePriceCustom ? "isEnabled" : ""
          }
        >
          {translate("pages.cartManagement.apartments.price")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalPrice && (
          <FilterPrice
            isOpen={isOpenModalPrice}
            setIsOpen={setIsOpenModalPrice}
            title={translate("pages.cartManagement.apartments.price")}
            data={listRangePrice}
            onClear={() => {
              const newData = onClearFilter(listRangePrice);
              setListRangePrice(newData);
            }}
            setRangePriceCustom={setRangePriceCustom}
            rangePriceCustom={rangePriceCustom}
            setListRangePrice={setListRangePrice}
          />
        )}

        <Button
          onClick={() => setIsOpenModalWindow(true)}
          className={hasChecked(listWindow) ? "isEnabled" : ""}
        >
          {translate("pages.cartManagement.apartments.windows")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalWindow && (
          <FilterNormal
            isOpen={isOpenModalWindow}
            setIsOpen={setIsOpenModalWindow}
            title={translate("pages.cartManagement.apartments.windows")}
            data={listWindow}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listWindow, false);
              setListWindow(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listWindow);
              setListWindow(newData);
            }}
            multiple={false}
          />
        )}

        <Button
          onClick={() => setIsOpenModalBalconies(true)}
          className={hasChecked(listBalconies) ? "isEnabled" : ""}
        >
          {translate("pages.cartManagement.apartments.balconies")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalBalconies && (
          <FilterNormal
            isOpen={isOpenModalBalconies}
            setIsOpen={setIsOpenModalBalconies}
            title={translate("pages.cartManagement.apartments.balconies")}
            data={listBalconies}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listBalconies, false);
              setListBalconies(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listBalconies);
              setListBalconies(newData);
            }}
            multiple={false}
          />
        )}

        <Button
          onClick={() => setIsOpenModalStatus(true)}
          className={hasChecked(listStatus) ? "isEnabled" : ""}
        >
          {translate("pages.cartManagement.apartments.status")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalStatus && (
          <FilterNormal
            isOpen={isOpenModalStatus}
            setIsOpen={setIsOpenModalStatus}
            title={translate("pages.cartManagement.apartments.status")}
            data={listStatus}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listStatus, true);
              setListStatus(newData);
            }}
            onClear={() => setListStatus([...STATUS_APARTMENT])}
            multiple={true}
          />
        )}

        <Button
          onClick={() => setIsOpenModalProvince(true)}
          className={hasChecked(listProvince) ? "isEnabled" : ""}
        >
          {translate("pages.cartManagement.apartments.province")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalProvince && (
          <FilterNormal
            isOpen={isOpenModalProvince}
            setIsOpen={setIsOpenModalProvince}
            title={translate("pages.cartManagement.apartments.province")}
            data={listProvince}
            onCheck={(value) => {
              setListDistrict([]);
              setListWard([]);
              const newData = onCheckFilter(value, listProvince, false);
              setListProvince(newData);
            }}
            onClear={() => {
              setListDistrict([]);
              setListWard([]);
              const newData = onClearFilter(listProvince);
              setListProvince(newData);
            }}
            multiple={false}
          />
        )}
        <Button
          onClick={() => setIsOpenModalDistrict(true)}
          className={hasChecked(listDistrict) ? "isEnabled" : ""}
          disabled={hasChecked(listProvince) ? false : true}
        >
          {translate("pages.cartManagement.apartments.district")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalDistrict && (
          <FilterNormal
            isOpen={isOpenModalDistrict}
            setIsOpen={setIsOpenModalDistrict}
            title={translate("pages.cartManagement.apartments.district")}
            data={listDistrict}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listDistrict, true);
              setListDistrict(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listDistrict);
              setListDistrict(newData);
            }}
            multiple={true}
            setSelectedValue={setSelectedDistrict}
          />
        )}
        <Button
          onClick={() => setIsOpenModalWard(true)}
          className={hasChecked(listWard) ? "isEnabled" : ""}
          disabled={hasChecked(listDistrict) ? false : true}
        >
          {translate("pages.cartManagement.apartments.ward")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalWard && (
          <FilterNormal
            isOpen={isOpenModalWard}
            setIsOpen={setIsOpenModalWard}
            title={translate("pages.cartManagement.apartments.ward")}
            data={listWard}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listWard, true);
              setListWard(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listWard);
              setListWard(newData);
            }}
            multiple={true}
          />
        )}
      </Space>

      <Table
        {...apartmentsTableProps}
        pagination={{
          defaultPageSize: 50,
          // showSizeChanger: true,
          // pageSizeOptions: ["10", "20", "30", "40"],
          current: pagination.current,
          onChange: handleTableChange,
        }}
        rowKey="id"
        rowSelection={rowSelection}
        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
      >
        <Table.Column
          dataIndex="building"
          title={translate("pages.cartManagement.apartments.building")}
          key="building"
          render={(value) => {
            return <TextField value={value?.name} />;
          }}
        />
        <Table.Column
          dataIndex="price"
          title={translate("pages.cartManagement.apartments.price")}
          key="price"
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />
        <Table.Column
          dataIndex="type"
          title={translate("pages.cartManagement.apartments.type")}
          key="type"
          render={(value) => {
            return (
              <TextField
                value={
                  value === "duplex"
                    ? translate("const.duplex")
                    : value === "studio"
                    ? translate("const.studio")
                    : value === "one_bedroom"
                    ? translate("const.oneBedroom")
                    : translate("const.twoBedroom")
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="name"
          title={translate("pages.cartManagement.apartments.name")}
        />
        <Table.Column
          dataIndex="windows"
          title={translate("pages.cartManagement.apartments.windows")}
          key="windows"
          render={(value) => {
            return (
              <TextField
                value={
                  value ? translate("const.true") : translate("const.false")
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="balconies"
          title={translate("pages.cartManagement.apartments.balconies")}
          key="balconies"
          render={(value) => {
            return (
              <TextField
                value={
                  value ? translate("const.true") : translate("const.false")
                }
              />
            );
          }}
        />

        <Table.Column
          dataIndex="square"
          title={translate("pages.cartManagement.apartments.square")}
        />

        <Table.Column
          dataIndex="deposit_price"
          title={translate("pages.cartManagement.apartments.depositPrice")}
          key="deposit_price"
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />

        <Table.Column
          dataIndex="status"
          title={translate("pages.cartManagement.apartments.status")}
          key="status"
          render={(value) => {
            return (
              <TextField
                value={
                  value === "vacant"
                    ? translate("const.vacant")
                    : value === "deposited"
                    ? translate("const.deposited")
                    : value === "occupied"
                    ? translate("const.occupied")
                    : translate("const.nearVacant")
                }
              />
            );
          }}
        />

        <Table.Column
          dataIndex="furniture"
          title={translate("pages.cartManagement.apartments.furniture")}
          key="furniture"
          render={(value) => {
            return <TextField value={translate(`const.${value}`)} />;
          }}
        />
        <Table.Column<IListApartments>
          title={translate("table.actions")}
          dataIndex="actions"
          fixed="right"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
