import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { EditButton, Show, TextField } from "@refinedev/antd";

import { Space, Tag, Typography } from "antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IListUser } from "interfaces";
import { formatTime, parsePhoneToVNWithoutSpace } from "helper";

const { Title } = Typography;

export const CustomersShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IListUser>();
  const { data, isLoading } = queryResult;
  const record: any = data?.data;

  return (
    <Show
      isLoading={isLoading}
      title={translate("pages.customerManagement.customers.showCustomer")}
      headerButtons={({
        listButtonProps,
        editButtonProps,
        refreshButtonProps,
      }) => (
        <>
          {editButtonProps && (
            <EditButton
              {...editButtonProps}
              title={translate(
                "pages.customerManagement.customers.editCustomer"
              )}
            />
          )}
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
    >
      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.customers.fullName")}:
        </Title>
        <TextField strong value={record?.data?.full_name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.customers.phone")}:
        </Title>
        <TextField
          value={
            record?.data?.phone
              ? parsePhoneToVNWithoutSpace(record?.data?.phone)
              : ""
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.customers.secondPhone")}:
        </Title>

        <TextField
          value={
            record?.data?.second_phone
              ? parsePhoneToVNWithoutSpace(record?.data?.second_phone)
              : ""
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.customers.email")}:
        </Title>
        <TextField value={record?.data?.email} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.customers.telegram")}:
        </Title>
        <TextField value={record?.data?.telegram} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.customers.zalo")}:
        </Title>
        <TextField
          value={
            record?.data?.zalo
              ? parsePhoneToVNWithoutSpace(record?.data?.zalo)
              : ""
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.customers.identityCardNumber")}:
        </Title>
        <TextField value={record?.data?.identity_card_number} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.customers.address")}:
        </Title>
        <TextField value={record?.data?.address} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.customers.province")}:
        </Title>
        <TextField value={record?.data?.province?.name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.customers.district")}:
        </Title>
        <TextField value={record?.data?.district?.name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.customers.ward")}:
        </Title>
        <TextField value={record?.data?.ward?.name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.customerManagement.customers.status")}:
        </Title>
        <TextField
          strong
          value={
            record?.data?.is_active === true ? (
              <Tag bordered={false} color="success">
                {translate("pages.customerManagement.customers.active")}
              </Tag>
            ) : (
              <Tag bordered={false} color="error">
                {translate("pages.customerManagement.customers.inactive")}
              </Tag>
            )
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.createdAt")}:</Title>
        <TextField value={formatTime(record?.data?.created_at)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.updatedAt")}:</Title>
        <TextField value={formatTime(record?.data?.updated_at)} />
      </Space>
      <br />
    </Show>
  );
};
