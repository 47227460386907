import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IResourceComponentsProps,
  useParsed,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { EditButton, Show, TextField } from "@refinedev/antd";
import { Button, Space, Tag, Typography } from "antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IListContracts } from "interfaces";
import { formatPrice, formatDate } from "helper";
import Slider from "components/slider-image";
import CustomImageModal from "components/modal/ImageDetail";

const { Title } = Typography;

export const LandlordBillsShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { id } = useParsed();
  const { queryResult } = useShow<IListContracts>({ resource: "bills", id });
  const { data, isLoading } = queryResult;
  const record: any = data?.data;

  const [currentOpenModalImage, setCurrentOpenModalImage] =
    useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<any>(0);

  const handleCancel = () => {
    setCurrentOpenModalImage(false);
  };

  const handleOpenCurrentImageModal = (index: number) => {
    setCurrentImageIndex(index);
    setCurrentOpenModalImage(true);
  };

  const handleTranslate = (value: string) => {
    switch (value) {
      case "unpaid":
        return translate("status.unpaid");
      case "partial_paid":
        return translate("status.partialPaid");
      case "paid":
        return translate("status.paid");
      default:
        break;
    }
  };

  return (
    <Show
      isLoading={isLoading}
      title={translate("pages.payment.landlordBills.showBill")}
      headerButtons={({
        listButtonProps,
        editButtonProps,
        refreshButtonProps,
      }) => (
        <>
          {editButtonProps && (
            <EditButton
              {...editButtonProps}
              title={translate("pages.payment.landlordBills.editBill")}
            />
          )}

          <Button
            onClick={() =>
              navigate(`/reservations/show/${record?.data?.reservationId}`)
            }
          >
            {translate("pages.operation.reservations.showReservation")}
          </Button>
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
    >
      <CustomImageModal
        open={currentOpenModalImage}
        onCancel={handleCancel}
        images={record?.data?.images}
        initialIndex={currentImageIndex}
      />
      <Slider
        item={record?.data?.images}
        handleOpenCurrentIndex={(index: number) =>
          handleOpenCurrentImageModal(index)
        }
      />
      {record?.data?.images?.length > 0 ? <br /> : null}

      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.name")}:
        </Title>
        <TextField value={record?.data?.name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.reservation")}:
        </Title>
        <TextField
          value={`${record?.data?.reservation?.apartment?.name} ${record?.data?.reservation?.apartment?.building?.name}`}
        />
      </Space>
      <br />

      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.fromDate")}:
        </Title>
        <TextField value={formatDate(record?.data?.fromDate)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.toDate")}:
        </Title>
        <TextField value={formatDate(record?.data?.toDate)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.status")}:
        </Title>
        <TextField
          value={
            <Tag
              bordered={false}
              color={
                record?.data?.status === "paid"
                  ? "success"
                  : record?.data?.status === "partial_paid"
                  ? "yellow"
                  : "error"
              }
            >
              {handleTranslate(record?.data?.status)}
            </Tag>
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.apartmentRentalFee")}:
        </Title>
        <TextField value={formatPrice(record?.data?.apartmentRentalFee)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.previousElectricityIndex")}:
        </Title>
        <TextField value={record?.data?.previousElectricityIndex} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.currentElectricityIndex")}:
        </Title>
        <TextField value={record?.data?.currentElectricityIndex} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.usedElectricityIndex")}:
        </Title>
        <TextField
          value={
            record?.data?.currentElectricityIndex -
              record?.data?.previousElectricityIndex >
            0
              ? record?.data?.currentElectricityIndex -
                record?.data?.previousElectricityIndex
              : 0
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.electricityPrice")}:
        </Title>
        <TextField value={formatPrice(record?.data?.electricityPrice)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.electricityFee")}:
        </Title>
        <TextField
          value={
            record?.data?.electricityPrice ||
            (record?.data?.currentElectricityIndex > 0 &&
              record?.data?.previousElectricityIndex > 0)
              ? formatPrice(
                  record?.data?.electricityPrice *
                    (record?.data?.currentElectricityIndex -
                      record?.data?.previousElectricityIndex)
                )
              : formatPrice(0)
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.previousWaterIndex")}:
        </Title>
        <TextField value={record?.data?.previousWaterIndex} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.currentWaterIndex")}:
        </Title>
        <TextField value={record?.data?.currentWaterIndex} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.usedWaterIndex")}:
        </Title>
        <TextField
          value={
            record?.data?.currentWaterIndex - record?.data?.previousWaterIndex >
            0
              ? record?.data?.currentWaterIndex -
                record?.data?.previousWaterIndex
              : 0
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.waterPrice")}:
        </Title>
        <TextField
          value={
            record?.data?.waterPrice
              ? formatPrice(record?.data?.waterPrice)
              : formatPrice(0)
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.waterFee")}:
        </Title>
        <TextField
          value={
            record?.data?.waterPrice ||
            (record?.data?.currentWaterIndex > 0 &&
              record?.data?.previousWaterIndex > 0)
              ? formatPrice(
                  record?.data?.waterPrice *
                    (record?.data?.currentWaterIndex -
                      record?.data?.previousWaterIndex)
                )
              : formatPrice(0)
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.parkingFee")}:
        </Title>
        <TextField value={formatPrice(record?.data?.parkingFee)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.managementFee")}:
        </Title>
        <TextField value={formatPrice(record?.data?.managementFee)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.internetFee")}:
        </Title>
        <TextField value={formatPrice(record?.data?.internetFee)} />
      </Space>

      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.otherFee")}:
        </Title>
        <TextField value={formatPrice(record?.data?.otherFee)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.paidValue")}:
        </Title>
        <TextField value={formatPrice(record?.data?.paidValue)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordBills.totalFee")}:
        </Title>
        <TextField value={formatPrice(record?.data?.totalFee)} />
      </Space>
      <br />
    </Show>
  );
};
