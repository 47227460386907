import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import { Edit, SaveButton, useForm } from "@refinedev/antd";

import { Button, Form, Input, Select, Tag } from "antd";
import "react-mde/lib/styles/css/react-mde-all.css";
import {
  IListApartments,
  IListReservation,
  IListTeams,
  IListUser,
} from "interfaces";
import {
  handleFilterOption,
  handleSortOption,
  parsePhoneToVN,
  parsePhoneToVNWithoutSpace,
} from "helper";
import {
  PERIOD_RESERVATIONS,
  STATUS_RESERVATIONS,
  handleTranslate,
} from "./constant";

export const ReservationsEdit: React.FC<IResourceComponentsProps> = (props) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { formProps, queryResult, saveButtonProps, onFinish, form } =
    useForm<IListTeams>({
      resource: "reservations",
      errorNotification: (data) => {
        const errorMessage: any = data?.response;
        const message: any = errorMessage?.data?.data?.errors[0].message;
        return {
          message: message,
          description: "Error",
          type: "error",
        };
      },
    });
  const reservationsData: any = queryResult?.data?.data ?? "";
  const [optionsApartments, setOptionsApartments] = useState<any>([]);
  const [selectedItemsApartments, setSelectedItemsApartments] = useState<any>(
    []
  );
  const [optionsCustomers, setOptionsCustomers] = useState<[]>([]);

  // get apartments data
  const { data: apartmentsData } = useList<IListApartments>({
    resource: "apartments?status=vacant,near_vacant&",
    errorNotification: false,
  });

  // get customers data
  const { data: customersData } = useList<IListUser>({
    resource: "customers",
    errorNotification: false,
  });

  useEffect(() => {
    if (apartmentsData) {
      const optionsApartmentsData: any = apartmentsData?.data;
      setOptionsApartments(
        optionsApartmentsData?.data?.map((item: any) => {
          return {
            value: item.id,
            label: item?.name + ` (${item?.building?.name})`,
          };
        })
      );
    }
  }, [apartmentsData]);

  useEffect(() => {
    if (customersData) {
      const optionsCustomersData: any = customersData?.data;
      setOptionsCustomers(
        optionsCustomersData?.data?.map((item: any) => {
          return {
            value: item?.id,
            label: item?.full_name,
            subLabel:
              parsePhoneToVN(item?.phone) +
              " " +
              parsePhoneToVNWithoutSpace(item?.phone) +
              " " +
              item?.phone,
          };
        })
      );
    }
  }, [customersData]);

  useEffect(() => {
    if (reservationsData) {
      const optionApartment = [
        {
          label: reservationsData?.data?.apartment?.name,
          value: reservationsData?.data?.apartment?.id,
        },
      ];

      setSelectedItemsApartments([...optionApartment]);
    }
  }, [reservationsData]);

  const handleOnFinish: any = (values: IListReservation) => {
    onFinish({
      ...values,
      apartmentId: Number(values?.apartmentId),
      customerId: values?.customerId,
    });
  };

  return (
    <Edit
      {...props}
      title={translate("pages.operation.reservations.editReservation")}
      headerButtons={({ listButtonProps, refreshButtonProps }) => (
        <>
          {listButtonProps && null}
          {refreshButtonProps && null}
          <Button
            onClick={() =>
              navigate(`/contracts?reservationId=${reservationsData?.data?.id}`)
            }
          >
            {translate("pages.operation.reservations.contracts")}
          </Button>
          <Button
            onClick={() =>
              navigate(
                `/contracts/create?reservationId=${reservationsData?.data?.id}`
              )
            }
          >
            {translate("pages.operation.reservations.createContract")}
          </Button>
          <Button
            onClick={() =>
              navigate(
                `/bills?reservationId=${reservationsData?.data?.id}`
              )
            }
          >
            {translate("pages.operation.reservations.bills")}
          </Button>
          <Button
            onClick={() =>
              navigate(
                `/bills/create?reservationId=${reservationsData?.data?.id}`
              )
            }
          >
            {translate("pages.operation.reservations.createBill")}
          </Button>
        </>
      )}
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} />
        </>
      )}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        initialValues={{
          ...reservationsData?.data,
        }}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label={translate("pages.operation.reservations.id")}
          name="id"
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label={translate("pages.operation.reservations.apartment")}
          name="apartmentId"
          rules={[
            {
              required: true,
              message: translate("validations.apartments"),
            },
          ]}
        >
          <Select
            value={selectedItemsApartments}
            options={optionsApartments}
            onChange={setSelectedItemsApartments}
            placeholder={translate("placeholder.selectAApartment")}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>

        <Form.Item
          label={translate("pages.operation.reservations.period")}
          name="period"
          rules={[
            {
              required: true,
              message: translate("validations.period"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectPeriod")}
            options={PERIOD_RESERVATIONS?.map((item) => {
              return {
                label: translate(item.label),
                value: item.value,
              };
            })}
          />
        </Form.Item>
        <Form.Item
          label={translate("pages.operation.reservations.customer")}
          name="customerId"
          rules={[
            {
              required: true,
              message: translate("validations.customers"),
            },
          ]}
        >
          <Select
            options={optionsCustomers}
            placeholder={translate("placeholder.selectACustomer")}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <Form.Item
          label={translate("pages.operation.reservations.status")}
          name="status"
          rules={[
            {
              required: true,
              message: translate("validations.status"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectStatus")}
            options={STATUS_RESERVATIONS?.map((item) => {
              return {
                label: (
                  <Tag
                    color={
                      item.value === "checked-in" || item.value === "deposited"
                        ? "success"
                        : item.value === "waiting-for-deposit"
                        ? "warning"
                        : item.value === "canceled"
                        ? "error"
                        : "geekblue"
                    }
                  >
                    {handleTranslate(item.value, translate)}
                  </Tag>
                ),
                value: item.value,
              };
            })}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
