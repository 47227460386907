export const TYPE_BEDROOMS_APARTMENTS_OPTIONS = [
  {
    value: "duplex",
    label: "const.duplex",
  },
  {
    value: "studio",
    label: "const.studio",
  },
  {
    value: "one_bedroom",
    label: "const.oneBedroom",
  },
  {
    value: "two_bedroom",
    label: "const.twoBedroom",
  },
];

export const STATUS_APARTMENTS_OPTIONS = [
  {
    value: "vacant",
    label: "const.vacant",
  },
  {
    value: "deposited",
    label: "const.deposited",
  },
  {
    value: "occupied",
    label: "const.occupied",
  },
  {
    value: "near_vacant",
    label: "const.nearVacant",
  },
];

export const STATUS_BOOLEANS_OPTIONS = [
  {
    value: true,
    label: "const.true",
  },
  {
    value: false,
    label: "const.false",
  },
];
