export const BUILDING_GATE_GUARD = [
  {
    value: "magnetic_card",
    label: "const.magneticCard",
  },
  {
    value: "guard",
    label: "const.guard",
  },
  {
    value: "no_guard",
    label: "const.noGuard",
  },
];
