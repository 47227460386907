import {
  CrudFilters,
  IResourceComponentsProps,
  useTranslate,
} from "@refinedev/core";
import {
  CreateButton,
  EditButton,
  List,
  ShowButton,
  TextField,
  useTable,
} from "@refinedev/antd";
import { Button, Form, Input, Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { CaretDownOutlined, FilterFilled } from "@ant-design/icons";

import { IListBuildingGroups } from "interfaces";
import { STATUS_SALE_GROUP } from "./constant";
import { getParamFilter, hasChecked, onCheckFilter } from "helper";
import { FilterNormal } from "components/modal";
import {
  getCurrentPagination,
  getFilterSaleGroups,
  setCurrentPagination,
  setFilterSaleGroups,
} from "helper/local-storage";

export const SaleGroupsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const filter = JSON.parse(getFilterSaleGroups() || "{}");
  const [keyword, setKeyword] = useState<string>(filter?.keyword || "");
  const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);
  const [listStatus, setListStatus] = useState<any>(
    filter?.listStatus || STATUS_SALE_GROUP
  );
  const [pagination, setPagination] = useState(
    JSON.parse(getCurrentPagination() || "{}") || {
      current: 1,
      pageSize: 10,
    }
  );

  const { searchFormProps, tableProps, setFilters } =
    useTable<IListBuildingGroups>({
      resource: "sale-groups",
      onSearch: (params: any) => {
        const filters: CrudFilters = [];
        const { newKeyword } = params;

        filters.push({
          field: "keyword",
          operator: "eq",
          value: newKeyword || null,
        });
        setFilterSaleGroups(JSON.stringify({ ...filter, keyword: newKeyword }));
        return filters;
      },
    });
  const dataSource: any = tableProps?.dataSource;
  let saleGroupsTableProps = {
    ...tableProps,
    pagination: pagination,
    dataSource: dataSource?.data,
  };

  const handleTableChange = (pagination: any) => {
    setPagination({ current: pagination, pageSize: 10 });
  };

  useEffect(() => {
    setCurrentPagination(JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    const storedPagination = getCurrentPagination();
    if (storedPagination) {
      setPagination(JSON.parse(storedPagination));
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchFormProps.form?.submit();
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    const statusParam = getParamFilter(listStatus);
    setFilters(
      [
        {
          field: "status",
          operator: "eq",
          value: statusParam,
        },
      ],
      "merge"
    );
    setFilterSaleGroups(JSON.stringify({ ...filter, listStatus }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listStatus]);

  return (
    <List
      title={translate(
        "pages.cartManagement.saleRightsManagement.saleGroups.list"
      )}
      headerButtons={({ createButtonProps }) => (
        <>
          <Form {...searchFormProps}>
            <Form.Item
              name="newKeyword"
              initialValue={keyword}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={translate("placeholder.searchByNameSaleGroup")}
                onChange={(e) => setKeyword(e.target.value)}
                allowClear={true}
              />
            </Form.Item>
          </Form>
          {createButtonProps && <CreateButton {...createButtonProps} />}
        </>
      )}
    >
      <Space className="listFilter">
        <Button style={{ pointerEvents: "none" }}>
          <FilterFilled />
          {translate("buttons.filter")}
        </Button>
        <Button
          onClick={() => setIsOpenModalStatus(true)}
          className={hasChecked(listStatus) ? "isEnabled" : ""}
        >
          {translate(
            "pages.cartManagement.saleRightsManagement.saleGroups.status"
          )}
          <CaretDownOutlined />
        </Button>
        {isOpenModalStatus && (
          <FilterNormal
            isOpen={isOpenModalStatus}
            setIsOpen={setIsOpenModalStatus}
            title={translate(
              "pages.cartManagement.saleRightsManagement.saleGroups.status"
            )}
            data={listStatus}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listStatus, false);
              setListStatus(newData);
            }}
            onClear={() => setListStatus([...STATUS_SALE_GROUP])}
            multiple={false}
          />
        )}
      </Space>
      <Table
        {...saleGroupsTableProps}
        pagination={{
          defaultPageSize: 10,
          // showSizeChanger: true,
          // pageSizeOptions: ["10", "20", "30", "40"],
          current: pagination.current,
          onChange: handleTableChange,
        }}
        rowKey="id"
      >
        <Table.Column
          dataIndex="name"
          title={translate(
            "pages.cartManagement.saleRightsManagement.saleGroups.name"
          )}
        />
        <Table.Column
          dataIndex="status"
          title={translate(
            "pages.cartManagement.saleRightsManagement.saleGroups.status"
          )}
          render={(value) => {
            return (
              <TextField
                strong
                value={
                  value === "active" ? (
                    <Tag bordered={false} color="success">
                      {translate(
                        "pages.cartManagement.saleRightsManagement.saleGroups.active"
                      )}
                    </Tag>
                  ) : (
                    <Tag bordered={false} color="error">
                      {translate(
                        "pages.cartManagement.saleRightsManagement.saleGroups.inactive"
                      )}
                    </Tag>
                  )
                }
              />
            );
          }}
        />
        <Table.Column<IListBuildingGroups>
          title={translate("table.actions")}
          dataIndex="actions"
          fixed="right"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
