export const setSelectedRowKeysApartment = (row: any) => {
  localStorage.setItem("selectedRowKeysApartment", JSON.stringify(row));
};
export const getSelectedRowKeysApartment = () => {
  return localStorage.getItem("selectedRowKeysApartment");
};
export const setBuildingId = (buildingId: any) => {
  localStorage.setItem("buildingId", buildingId);
};
export const getBuildingId = () => {
  return localStorage.getItem("buildingId");
};
export const setSelectedPage = (selectedPage: any) => {
  localStorage.setItem("selectedPage", selectedPage);
};
export const getSelectedPage = () => {
  return localStorage.getItem("selectedPage");
};
export const setFilterApartments = (filter: any) => {
  localStorage.setItem("filterApartments", filter);
};
export const getFilterApartments = () => {
  return localStorage.getItem("filterApartments");
};
export const setFilterBuildings = (filter: any) => {
  localStorage.setItem("filterBuildings", filter);
};
export const getFilterBuildings = () => {
  return localStorage.getItem("filterBuildings");
};
export const setFilterLandlords = (filter: any) => {
  localStorage.setItem("filterLandlords", filter);
};
export const getFilterLandlords = () => {
  return localStorage.getItem("filterLandlords");
};
export const setFilterBuildingGroups = (filter: any) => {
  localStorage.setItem("filterBuildingGroups", filter);
};
export const getFilterBuildingGroups = () => {
  return localStorage.getItem("filterBuildingGroups");
};
export const setFilterSaleGroups = (filter: any) => {
  localStorage.setItem("filterSaleGroups", filter);
};
export const getFilterSaleGroups = () => {
  return localStorage.getItem("filterSaleGroups");
};
export const setFilterSaleRights = (filter: any) => {
  localStorage.setItem("filterSaleRights", filter);
};
export const getFilterSaleRights = () => {
  return localStorage.getItem("filterSaleRights");
};
export const setFilterAppointments = (filter: any) => {
  localStorage.setItem("filterAppointments", filter);
};
export const getFilterAppointments = () => {
  return localStorage.getItem("filterAppointments");
};
export const setFilterCustomers = (filter: any) => {
  localStorage.setItem("filterCustomers", filter);
};
export const getFilterCustomers = () => {
  return localStorage.getItem("filterCustomers");
};
export const setFilterUsers = (filter: any) => {
  localStorage.setItem("filterUsers", filter);
};
export const getFilterUsers = () => {
  return localStorage.getItem("filterUsers");
};
export const setFilterTransactions = (filter: any) => {
  localStorage.setItem("filterTransactions", filter);
};
export const getFilterTransactions = () => {
  return localStorage.getItem("filterTransactions");
};
export const setFilterCommissions = (filter: any) => {
  localStorage.setItem("filterCommissions", filter);
};
export const getFilterCommissions = () => {
  return localStorage.getItem("filterCommissions");
};
export const setFilterDeals = (filter: any) => {
  localStorage.setItem("filterDeals", filter);
};
export const getFilterDeals = () => {
  return localStorage.getItem("filterDeals");
};
export const setFilterBuildingsApartments = (filter: any) => {
  localStorage.setItem("filterBuildingsApartments", filter);
};
export const getFilterBuildingsApartments = () => {
  return localStorage.getItem("filterBuildingsApartments");
};
export const setFilterContracts = (filter: any) => {
  localStorage.setItem("filterContracts", filter);
};
export const getFilterContracts = () => {
  return localStorage.getItem("filterContracts");
};
export const setFilterBills = (filter: any) => {
  localStorage.setItem("filterBills", filter);
};
export const getFilterBills = () => {
  return localStorage.getItem("filterBills");
};
export const setFilterLandlordTransactions = (filter: any) => {
  localStorage.setItem("filterLandlordTransactions", filter);
};
export const getFilterLandlordTransactions = () => {
  return localStorage.getItem("filterLandlordTransactions");
};
export const setFilterReservations = (filter: any) => {
  localStorage.setItem("filterReservations", filter);
};
export const getFilterReservations = () => {
  return localStorage.getItem("filterReservations");
};
export const setSelectedRowKeysBills = (row: any) => {
  localStorage.setItem("selectedRowKeysBill", JSON.stringify(row));
};
export const getSelectedRowKeysBills = () => {
  return localStorage.getItem("selectedRowKeysBill");
};
export const setCurrentPagination = (pagination: any) => {
  localStorage.setItem("pagination", pagination);
};
export const getCurrentPagination = () => {
  return localStorage.getItem("pagination");
};
export const resetFilter = () => {
  localStorage.removeItem("filterApartments");
  localStorage.removeItem("filterBuildings");
  localStorage.removeItem("filterLandlords");
  localStorage.removeItem("filterBuildingGroups");
  localStorage.removeItem("filterSaleGroups");
  localStorage.removeItem("filterSaleRights");
  localStorage.removeItem("filterAppointments");
  localStorage.removeItem("filterCustomers");
  localStorage.removeItem("filterUsers");
  localStorage.removeItem("filterTransactions");
  localStorage.removeItem("filterCommissions");
  localStorage.removeItem("filterDeals");
  localStorage.removeItem("filterBuildingsApartments");
  localStorage.removeItem("filterContracts");
  localStorage.removeItem("filterLandlordTransactions");
  localStorage.removeItem("filterBills");
  localStorage.removeItem("filterReservations");
  localStorage.removeItem("selectedRowKeysBill");
  localStorage.removeItem("pagination");
};
