import {
  IResourceComponentsProps,
  usePermissions,
  useTranslate,
} from "@refinedev/core";
import {
  List,
  useTable,
  TextField,
  EditButton,
  ShowButton,
  CreateButton,
} from "@refinedev/antd";
import { Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";

import { ROLE_ADMIN, formatTime } from "helper";

import { IListUser } from "interfaces";
import {
  getCurrentPagination,
  setCurrentPagination,
} from "helper/local-storage";

export const TeamsManagementList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const permissions = usePermissions<string>();
  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const [pagination, setPagination] = useState(
    JSON.parse(getCurrentPagination() || "{}") || {
      current: 1,
      pageSize: 10,
    }
  );

  const { tableProps } = useTable<IListUser>({
    resource: `teams`,
  });

  const dataSource: any = tableProps?.dataSource;
  let areasTableProps = {
    ...tableProps,
    pagination: pagination,
    dataSource: dataSource?.data,
  };

  const handleTableChange = (pagination: any) => {
    setPagination({ current: pagination, pageSize: 10 });
  };

  useEffect(() => {
    setCurrentPagination(JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    const storedPagination = getCurrentPagination();
    if (storedPagination) {
      setPagination(JSON.parse(storedPagination));
    }
  }, []);

  return (
    <List
      title={translate("pages.teamsManagement.list")}
      headerButtons={({ createButtonProps }) => (
        <>{createButtonProps && isLoggedbyAdmin && <CreateButton />}</>
      )}
    >
      {/* <Space className="listFilter">
        <Button style={{ pointerEvents: "none" }}>
          <FilterFilled />
          {translate("buttons.filter")}
        </Button>
        <Button
          onClick={() => setIsOpenModalStatus(true)}
          className={hasChecked(listStatus) ? "isEnabled" : ""}
        >
          {translate("pages.teamsManagement.status")}
          <CaretDownOutlined />
        </Button>
      </Space> */}
      <Table
        {...areasTableProps}
        pagination={{
          defaultPageSize: 10,
          // showSizeChanger: true,
          // pageSizeOptions: ["10", "20", "30", "40"],
          current: pagination.current,
          onChange: handleTableChange,
        }}
        rowKey="id"
        className="list-users"
      >
        <Table.Column
          dataIndex="name"
          title={translate("pages.teamsManagement.name")}
        />
        <Table.Column
          dataIndex="leader"
          title={translate("pages.teamsManagement.teamLeader")}
          render={(value) => {
            return <TextField value={value ? value?.full_name : ""} />;
          }}
        />
        <Table.Column
          dataIndex="sales"
          title={translate("pages.teamsManagement.sales")}
          key="sales"
          render={(value) =>
            value?.map((item: any, idx: number) => {
              return (
                <Tag key={idx}>
                  <TextField value={item?.full_name} />
                  <br />
                </Tag>
              );
            })
          }
        />

        <Table.Column<IListUser>
          title={translate("pages.teamsManagement.status")}
          dataIndex="status"
          render={(value) => {
            return (
              <TextField
                strong
                value={
                  value === true ? (
                    <Tag bordered={false} color="success">
                      {translate("status.active")}
                    </Tag>
                  ) : (
                    <Tag bordered={false} color="error">
                      {translate("status.inactive")}
                    </Tag>
                  )
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="created_at"
          title={translate("pages.createdAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column
          width={400}
          dataIndex="updated_at"
          title={translate("pages.updatedAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column<IListUser>
          title={translate("table.actions")}
          dataIndex="actions"
          fixed="right"
          render={(_, record) => {
            return (
              <Space>
                {isLoggedbyAdmin ? (
                  <EditButton hideText size="small" recordItemId={record.id} />
                ) : null}
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
