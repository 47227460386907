import axios from "axios";

import { API_URL } from "./apiUrl";
import { parsePhone } from "helper";

export const getOtp = async (phone: number) => {
  return await axios
    .post(`${API_URL}/sms/otp`, {
      phone: parsePhone(phone),
    })
    .then((data) => data.data)
    .catch((error) => {
      console.error(error);
    });
};
