import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import { Edit, SaveButton, useForm } from "@refinedev/antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Upload,
  message,
} from "antd";
import type { UploadFile } from "antd/es/upload/interface";

import { usePermissions } from "@refinedev/core";
import "react-mde/lib/styles/css/react-mde-all.css";

import { IListBuildings, IListUser, IProvinces } from "interfaces";
import { API_URL, getDistricts, getWards } from "api";
import { BUILDING_GATE_GUARD } from "const";
import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_LANDLORD,
  ROLE_TEAM_LEADER,
  formatInputPrice,
  getBase64,
  handleFilterOption,
  handleSortOption,
  isValidPercent,
  isValidPrice,
  onCopyPath,
  parseInputPrice,
  uploadButton,
} from "helper";
import { CHARGE_WATER } from "./constant";
import Slider from "components/slider-image";
import CustomImageModal from "components/modal/ImageDetail";

export const BuildingsEdit: React.FC<IResourceComponentsProps> = (props) => {
  const { formProps, queryResult, saveButtonProps, onFinish, form } =
    useForm<IListBuildings>({
      resource: "buildings",
      redirect: false,
    });
  const buildingsData: any = queryResult?.data?.data ?? "";
  const { data: provincesData } = useList<IProvinces>({
    resource: "addresses/provinces",
    errorNotification: false,
  });

  // get landlord data
  const { data: landLordsData } = useList<IListUser>({
    resource: "landlords",
    errorNotification: false,
  });

  const { data: permissionsData }: any = usePermissions();
  const translate = useTranslate();
  const navigate = useNavigate();
  const auth = JSON.parse(localStorage.getItem("auth") || "{}");
  const [selectedProvince, setSelectedProvince] = useState<any>(
    buildingsData?.data?.province_id
  );
  const [selectedDistrict, setSelectedDistrict] = useState<any>(
    buildingsData?.data?.district_id
  );
  const [selectedWard, setSelectedWard] = useState<any>(
    buildingsData?.data?.ward_id
  );
  const [optionsProvinces, setOptionProvinces] = useState<[]>([]);
  const [optionsDistricts, setOptionsDistricts] = useState<[]>([]);
  const [optionsWards, setOptionsWards] = useState<[]>([]);
  const [optionsLandlords, setOptionsLandlords] = useState<[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<any>();
  const [imageUrls, setImageUrls] = useState<any>([]);
  const [currentOpenModalImage, setCurrentOpenModalImage] =
    useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<any>(0);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [fileApartment, setFileApartment] = useState<any>();
  const [initialActor, setInitialActor] = useState<string>(
    buildingsData?.data?.actor?.full_name
  );

  const permissions = usePermissions<string>();
  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissions?.data === ROLE_DATA_ADMIN;
  const isLoggedbyTeamLeader = permissions?.data === ROLE_TEAM_LEADER;

  const uploadImage = async (options: any) => {
    const { file, onSuccess, onError, onProgress } = options;
    const formData = new FormData();
    formData.append("mFiles", file);

    const response: any = await axios.post<{ url: string }>(
      `${API_URL}/s3/upload`,
      formData,
      {
        withCredentials: false,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round(
            (progressEvent?.loaded * 100) / progressEvent?.total
          );
          onProgress({ percent: percentCompleted });
        },
      }
    );

    if (response?.status) {
      // Handle success
      message.success(translate("notifications.imageUploadSuccess"));
      onSuccess(response?.data, file);
      setImageUrls((prev: any) => [...prev, ...response?.data?.data]);
    } else {
      // Handle error
      message.error(translate("notifications.imageUploadFailed"));
      onError();
    }
  };

  const handleCancel = () => {
    setCurrentOpenModalImage(false);
    setPreviewOpen(false);
  };

  const handleOpenCurrentImageModal = (index: number) => {
    setCurrentImageIndex(index);
    setCurrentOpenModalImage(true);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const handleRemove = (fileId: any) => {
    setImageUrls(imageUrls.filter((item: any) => item !== fileId));
    message.success(translate("notifications.imageDeletedSuccess"));
  };

  const handleOnFinish: any = async (values: IListBuildings) => {
    if (fileApartment) {
      const statusUpload = await uploadFile(fileApartment);
      if (!statusUpload) {
        return;
      }
    }

    onFinish({
      ...values,
      approved: isActive,
      electricity_price: Number(values?.electricity_price),
      water_price: Number(values?.water_price),
      internet_price: Number(values?.internet_price),
      parking_price: Number(values?.parking_price),
      management_price: Number(values?.management_price),
      images: imageUrls,
      six_months_commission_pct: Number(values?.six_months_commission_pct),
      one_year_commission_pct: Number(values?.one_year_commission_pct),
    });
  };

  const uploadFile = async (options: any) => {
    const { file, onSuccess, onError, onProgress } = options;
    const formData = new FormData();

    const blob = new Blob([file as BlobPart], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    formData.append("file", new File([blob], file.name));

    const response: any = await axios
      .post<{ url: string }>(`${API_URL}/excel/import-apartments`, formData, {
        withCredentials: false,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent?.total
          );
          onProgress({ percent: percentCompleted });
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error?.response?.data;
      });

    if (response?.status) {
      // Handle success
      message.success(translate("notifications.fileUploadSuccess"));
      onSuccess(response.data, file);
      return true;
    } else {
      // Handle error
      message.error(response?.data?.errors[0].message, 3);
      onError();
      return false;
    }
  };

  useEffect(() => {
    const provinces: any = provincesData?.data ?? [];
    const queryResult = provinces?.data;
    if (provinces?.status) {
      const optionsProvinces = queryResult?.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
      setOptionProvinces(optionsProvinces);
    }
  }, [provincesData]);

  useEffect(() => {
    if (selectedProvince) {
      getDistricts(selectedProvince).then((response) => {
        const optionsDistricts = response?.data.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
        setOptionsDistricts(optionsDistricts);
      });
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (selectedDistrict) {
      getWards(selectedDistrict).then((response) => {
        const optionsWards = response?.data.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
        setOptionsWards(optionsWards);
      });
    }
  }, [selectedDistrict]);

  useEffect(() => {
    const landlords: any = landLordsData?.data ?? [];
    const queryResult = landlords?.data;
    if (landlords?.status) {
      const optionsLandlords = queryResult?.map((item: any) => ({
        label: item?.full_name,
        value: item.id,
      }));
      setOptionsLandlords(optionsLandlords);
    }
  }, [landLordsData]);

  // set user data again from backend
  useEffect(() => {
    setSelectedProvince(buildingsData?.data?.province_id);
    setSelectedDistrict(buildingsData?.data?.district_id);
    setSelectedWard(buildingsData?.data?.ward_id);
    if (buildingsData?.data?.images?.length > 0) {
      setImageUrls(buildingsData?.data?.images);
    }
    setIsActive(buildingsData?.data?.approved);

    if (buildingsData?.data?.actor?.full_name) {
      const initialActorValue = buildingsData?.data?.actor?.full_name || "";
      setInitialActor(initialActorValue);
    }
  }, [buildingsData]);

  useEffect(() => {
    // redirect if not authen access data
    if (
      auth?.role?.name === ROLE_ADMIN ||
      auth?.role?.name === ROLE_DATA_ADMIN ||
      auth?.role?.name === ROLE_LANDLORD
    ) {
      return;
    } else {
      if (buildingsData?.data) {
        // redirect if building already approved
        if (auth?.id !== buildingsData?.data?.actor_id) {
          navigate("/apartments");
        }
        if (
          auth?.id === buildingsData?.data?.actor_id &&
          buildingsData?.data?.approved
        ) {
          navigate("/apartments");
        }
      }
    }
  }, [auth, buildingsData]);

  const onChangeSelectedProvince = (value: any) => {
    setSelectedProvince(value);
    setSelectedDistrict(null);
    setSelectedWard(null);
    form.setFieldValue("district_id", null);
    form.setFieldValue("ward_id", null);
  };

  return (
    <Edit
      {...props}
      title={translate("pages.cartManagement.buildings.editBuilding")}
      canDelete={permissionsData?.includes["admin"]}
      headerButtons={({ listButtonProps, refreshButtonProps }) => (
        <>
          {listButtonProps && null}
          {refreshButtonProps && null}
          <Button
            onClick={() =>
              navigate("/apartments/create", {
                state: {
                  currentBuilding: {
                    ...buildingsData,
                    isAuthenticated: auth?.id === buildingsData?.data?.actor_id,
                  },
                },
              })
            }
          >
            {translate("pages.cartManagement.apartments.createNewApartment")}
          </Button>
        </>
      )}
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} />
        </>
      )}
    >
      <Form
        {...formProps}
        initialValues={{
          ...buildingsData?.data,
          actor: initialActor,
        }}
        layout="vertical"
        onFinish={handleOnFinish}
        form={form}
      >
        <Form.Item label="Images">
          <Form.Item name="images" noStyle>
            <Upload
              multiple={true}
              accept="image/*"
              customRequest={uploadImage}
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {uploadButton}
            </Upload>
            {["admin", "data-admin"].includes(permissionsData) &&
              buildingsData?.data?.images?.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    onCopyPath(buildingsData?.data?.images);
                    message.success(
                      translate("notifications.copyImagePathSuccess")
                    );
                  }}
                  style={{
                    marginBottom: 8,
                    whiteSpace: "pre-wrap",
                    height: "auto",
                  }}
                >
                  {translate("pages.cartManagement.buildings.copyImagesPath")}
                </Button>
              )}

            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="images apartments"
                style={{ width: "100%", height: "100%" }}
                src={previewImage}
              />
            </Modal>

            <CustomImageModal
              open={currentOpenModalImage}
              onCancel={handleCancel}
              images={imageUrls}
              initialIndex={currentImageIndex}
            />

            {/* Render images from backend as picture-cards */}
            <Slider
              item={imageUrls}
              handleOpenCurrentIndex={(index: number) =>
                handleOpenCurrentImageModal(index)
              }
              isRemove={true}
              handleRemove={handleRemove}
            />
          </Form.Item>
        </Form.Item>

        {["admin", "data-admin"].includes(permissionsData) && (
          <Form.Item
            label={translate(
              "pages.cartManagement.buildings.fileApartmentOnlyXlsx"
            )}
          >
            <Form.Item name="fileApartment" noStyle>
              <Upload
                multiple={false}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                customRequest={(e: any) => {
                  setFileApartment(e);
                  e.onSuccess("", "");
                }}
                onChange={(e) => {
                  if (e?.fileList?.length === 0) {
                    setFileApartment(undefined);
                  }
                }}
                listType="picture-card"
                maxCount={1}
              >
                {uploadButton}
              </Upload>
            </Form.Item>
          </Form.Item>
        )}

        <Form.Item
          name="id"
          label={translate("pages.cartManagement.buildings.id")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber type="number" disabled />
        </Form.Item>
        <Form.Item
          name="name"
          label={translate("pages.cartManagement.buildings.name")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyTeamLeader ? (
          <Form.Item label={translate("pages.cartManagement.buildings.status")}>
            <Form.Item valuePropName="checked">
              <Checkbox
                disabled={isLoggedbyTeamLeader}
                style={{
                  fontSize: "12px",
                }}
                onChange={() => setIsActive(true)}
                checked={isActive ? true : false}
              >
                {translate("status.approved")}
              </Checkbox>
              <Checkbox
                disabled={isLoggedbyTeamLeader}
                style={{
                  fontSize: "12px",
                }}
                onChange={() => setIsActive(false)}
                checked={!isActive ? true : false}
              >
                {translate("status.notApprovedYet")}
              </Checkbox>
            </Form.Item>
          </Form.Item>
        ) : null}
        <Form.Item
          name="description"
          label={translate("pages.cartManagement.buildings.description")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {isLoggedbyAdmin || isLoggedbyDataAdmin ? (
          <Form.Item
            name="amount_apartments"
            label={translate("pages.cartManagement.buildings.amountApartments")}
          >
            <Input disabled />
          </Form.Item>
        ) : null}

        <Form.Item
          name="amount_vacant_apartments"
          label={translate(
            "pages.cartManagement.buildings.amountVacantApartments"
          )}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="link_google_map"
          label={translate("pages.cartManagement.buildings.linkGoogleMap")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="address"
          label={translate("pages.cartManagement.buildings.address")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="gate_guard"
          label={translate("pages.cartManagement.buildings.gateGuard")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.gateGuard")}
            showSearch
            options={BUILDING_GATE_GUARD.map((item: any) => {
              return {
                value: item.value,
                label: translate(item.label),
              };
            })}
          />
        </Form.Item>
        {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyTeamLeader ? (
          <Form.Item
            label={translate("pages.cartManagement.buildings.owner")}
            name="landlord_id"
          >
            <Select
              placeholder={translate("placeholder.selectALandlord")}
              options={optionsLandlords}
              showSearch
              filterOption={(input, option: any) =>
                handleFilterOption(input, option)
              }
              filterSort={(optionA, optionB) =>
                handleSortOption(optionA, optionB)
              }
            />
          </Form.Item>
        ) : null}

        <Form.Item
          label={translate("pages.cartManagement.buildings.province")}
          name={["province_id"]}
          rules={[
            {
              required: true,
              message: translate("validations.province"),
            },
          ]}
        >
          <Select
            options={optionsProvinces}
            placeholder={translate("placeholder.selectAProvince")}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
            onChange={onChangeSelectedProvince}
          />
        </Form.Item>

        {selectedProvince ? (
          <Form.Item
            label={translate("pages.cartManagement.buildings.district")}
            name={["district_id"]}
            rules={[
              {
                required: true,
                message: translate("validations.district"),
              },
            ]}
          >
            <Select
              placeholder={translate("placeholder.selectADistrict")}
              options={optionsDistricts}
              showSearch
              filterOption={(input, option: any) =>
                handleFilterOption(input, option)
              }
              filterSort={(optionA, optionB) =>
                handleSortOption(optionA, optionB)
              }
              value={selectedDistrict}
              onChange={(val) => {
                setSelectedDistrict(val);
                form.setFieldValue("ward_id", null);
              }}
            />
          </Form.Item>
        ) : null}
        {selectedDistrict ? (
          <Form.Item
            label={translate("pages.cartManagement.buildings.ward")}
            name={["ward_id"]}
            rules={[
              {
                required: true,
                message: translate("validations.ward"),
              },
            ]}
          >
            <Select
              placeholder={translate("placeholder.selectAWard")}
              options={optionsWards}
              showSearch
              filterOption={(input, option: any) =>
                handleFilterOption(input, option)
              }
              filterSort={(optionA, optionB) =>
                handleSortOption(optionA, optionB)
              }
              value={selectedWard}
              onChange={(val) => setSelectedWard(val)}
            />
          </Form.Item>
        ) : null}
        <Form.Item
          name="electricity_price"
          label={translate("pages.cartManagement.buildings.electricityPrice")}
          rules={[
            {
              required: true,
              message: translate("validations.electricityPrice"),
            },
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
          />
        </Form.Item>
        <Form.Item
          label={translate("pages.cartManagement.buildings.chargeWater")}
          name="charge_water"
          rules={[
            {
              required: true,
              message: translate("validations.chargeWater"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectAChargeWater")}
            options={CHARGE_WATER.map((item: any) => {
              return {
                value: item.value,
                label: translate(item.label),
              };
            })}
          />
        </Form.Item>
        <Form.Item
          name="water_price"
          label={translate("pages.cartManagement.buildings.waterPrice")}
          rules={[
            {
              required: true,
              message: translate("validations.waterPrice"),
            },
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
          />
        </Form.Item>
        <Form.Item
          name="internet_price"
          label={translate("pages.cartManagement.buildings.internetPrice")}
          rules={[
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
          />
        </Form.Item>
        <Form.Item
          name="parking_price"
          label={translate("pages.cartManagement.buildings.parkingPrice")}
          rules={[
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
          />
        </Form.Item>
        <Form.Item
          name="management_price"
          label={translate("pages.cartManagement.buildings.managementPrice")}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
          />
        </Form.Item>
        <Form.Item
          name="pet"
          label={translate("pages.cartManagement.buildings.pet")}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="rinse_clothesline"
          label={translate("pages.cartManagement.buildings.rinseClothesline")}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="amount_people_vehicle"
          label={translate(
            "pages.cartManagement.buildings.amountPeopleVehicle"
          )}
        >
          <Input />
        </Form.Item>
        {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyTeamLeader ? (
          <>
            {" "}
            <Form.Item
              name="six_months_commission_pct"
              label={translate(
                "pages.cartManagement.buildings.sixMonthsCommissionPct"
              )}
              rules={[
                {
                  required: true,
                  message: translate("validations.sixMonthsCommissionPct"),
                },
                () => ({
                  validator(_, value) {
                    if (isValidPercent(value)) {
                      return Promise.reject(
                        new Error(translate(value && isValidPercent(value)))
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber prefix="%" style={{ width: "100%" }} type="number" />
            </Form.Item>
            <Form.Item
              name="one_year_commission_pct"
              label={translate(
                "pages.cartManagement.buildings.oneYearCommissionPct"
              )}
              rules={[
                {
                  required: true,
                  message: translate("validations.oneYearCommissionPct"),
                },
                () => ({
                  validator(_, value) {
                    if (isValidPercent(value)) {
                      return Promise.reject(
                        new Error(translate(value && isValidPercent(value)))
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber prefix="%" style={{ width: "100%" }} type="number" />
            </Form.Item>
          </>
        ) : null}

        <Form.Item
          name="notes"
          label={translate("pages.cartManagement.buildings.notes")}
        >
          <Input />
        </Form.Item>
        {initialActor ? (
          <Form.Item
            name="actor"
            label={translate("pages.cartManagement.buildings.actor")}
          >
            <Input value={initialActor} disabled placeholder={initialActor} />
          </Form.Item>
        ) : null}
      </Form>
    </Edit>
  );
};
