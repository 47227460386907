export const COLORS = {
  BLACK: "#000000",
  WHITE: "#FFFFFF",
  ORANGE: "#FC673D",
  GRAY: "#F2F2F2",
  GRAY_2: "#D8D8D8",
  BLUE: "#0C56BD",
  RED: "#FF5733",
  YELLOW_1: "#876809",
};

export const FONT_WEIGHT = {
  THIN: "100",
  LIGHT: "300",
  REGULAR: "400",
  MEDIUM: "500",
  MEDIUM_BIG: "600",
  BOLD: "700",
  BLACK: "900",
};

export const FONT_SIZE = {
  XXS: "10px",
  XS: "12px",
  S: "14px",
  M: "16px",
  L: "18px",
  ML: "20px",
  XL: "24px",
  XXL: "32px",
  XXLS: "36px",
  XXLM: "40px",
  XXXL: "48px",
};

export const SCREEN_SIZE_DEVICES = {
  MOBILE: 480,
  DESKTOP: 769,
};
