import React from "react";
import CurrencyInput from "react-currency-input-field";

import "./index.css";

interface IInputNumber {
  value?: number;
  onChange?: (value: number | undefined) => void;
  disabled?: boolean;
}

const NumberInput: React.FC<IInputNumber> = ({
  value = 0,
  onChange,
  disabled,
  ...rest
}) => {
  const handleChange = (formattedValue: string | undefined) => {
    const numericValue: any = formattedValue
      ? parseFloat(formattedValue.replace(/[^0-9.]/g, ""))
      : undefined;

    if (!isNaN(numericValue) || numericValue === undefined) {
      onChange?.(numericValue);
    }
  };

  return (
    <div className="currency-input-container">
      <CurrencyInput
        disabled={disabled}
        prefix="₫"
        value={value !== undefined ? value.toString() : undefined}
        onValueChange={handleChange}
        decimalSeparator=","
        groupSeparator="."
        {...rest}
      />
    </div>
  );
};

export default NumberInput;
