import React, { useContext, useState } from "react";
import {
  ITreeMenu,
  CanAccess,
  useIsExistAuthentication,
  useTranslate,
  useLogout,
  useMenu,
  useWarnAboutChange,
  usePermissions,
} from "@refinedev/core";
import { Link } from "react-router-dom";
import { Sider, ThemedTitleV2 } from "@refinedev/antd";
import { Layout as AntdLayout, Menu, Grid, theme, Button, Image } from "antd";
import {
  LogoutOutlined,
  UnorderedListOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { antLayoutSider, antLayoutSiderMobile } from "./styles";
import { ColorModeContext } from "contexts/color-mode";
import {
  ROLE_ADMIN,
  ROLE_CUSTOMER,
  ROLE_DATA_ADMIN,
  ROLE_FINANCE,
  ROLE_LANDLORD,
  ROLE_SALE,
  ROLE_TEAM_LEADER,
} from "helper";
import {
  getSelectedPage,
  resetFilter,
  setSelectedPage,
} from "helper/local-storage";

const { useToken } = theme;

export const CustomSider: typeof Sider = ({ render }) => {
  const { token } = useToken();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const isExistAuthentication = useIsExistAuthentication();
  const { warnWhen, setWarnWhen } = useWarnAboutChange();
  const { mutate: mutateLogout } = useLogout();
  const translate = useTranslate();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const { SubMenu } = Menu;
  const breakpoint = Grid.useBreakpoint();
  const permissions = usePermissions<string>();
  let isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  let isLoggedbyDataAdmin = permissions?.data === ROLE_DATA_ADMIN;
  let isLoggedbyFinance = permissions?.data === ROLE_FINANCE;
  let isLoggedbyTeamLeader = permissions?.data === ROLE_TEAM_LEADER;
  let isLoggedbySale = permissions?.data === ROLE_SALE;
  let isLoggedbyLandlord = permissions?.data === ROLE_LANDLORD;
  let isLoggedbyCustomer = permissions?.data === ROLE_CUSTOMER;
  const { mode, setMode } = useContext(ColorModeContext);

  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    const treeLoggedByAdmin: ITreeMenu[] = tree.filter((item: ITreeMenu) =>
      item?.meta?.role.find((item: any) => item === "admin")
    );
    const treeLoggedByDataAdmin: ITreeMenu[] = tree.filter((item: ITreeMenu) =>
      item?.meta?.role.find((item: any) => item === "data-admin")
    );
    const treeLoggedByFinance: ITreeMenu[] = tree.filter((item: ITreeMenu) =>
      item?.meta?.role.find((item: any) => item === "finance")
    );
    const treeLoggedByTeamLeader: ITreeMenu[] = tree.filter((item: ITreeMenu) =>
      item?.meta?.role.find((item: any) => item === "team-leader")
    );
    const treeLoggedBySale: ITreeMenu[] = tree.filter((item: ITreeMenu) =>
      item?.meta?.role.find((item: any) => item === "sale")
    );
    const treeLoggedByLandlord: ITreeMenu[] = tree.filter((item: ITreeMenu) =>
      item?.meta?.role.find((item: any) => item === "landlord")
    );
    const treeLoggedByAnonymous: ITreeMenu[] = tree.filter((item: ITreeMenu) =>
      item?.meta?.role.find((item: any) => item === "anonymous")
    );
    const treeLoggedByCustomer: ITreeMenu[] = tree.filter((item: ITreeMenu) =>
      item?.meta?.role.find((item: any) => item === "customer")
    );

    let treeChanged: ITreeMenu[] = isLoggedbyAdmin
      ? treeLoggedByAdmin
      : isLoggedbyDataAdmin
      ? treeLoggedByDataAdmin
      : isLoggedbyFinance
      ? treeLoggedByFinance
      : isLoggedbyTeamLeader
      ? treeLoggedByTeamLeader
      : isLoggedbySale
      ? treeLoggedBySale
      : isLoggedbyLandlord
      ? treeLoggedByLandlord
      : isLoggedbyCustomer
      ? treeLoggedByCustomer
      : treeLoggedByAnonymous;

    return treeChanged.map((item: ITreeMenu) => {
      const { name, children, meta, key, list } = item;

      const icon = meta?.icon;
      const label = meta?.label ?? name;
      const parent = meta?.parent;
      const route =
        typeof list === "string"
          ? list
          : typeof list !== "function"
          ? list?.path
          : key;

      if (children.length > 0) {
        return (
          <SubMenu
            key={key}
            icon={icon ?? <UnorderedListOutlined />}
            title={label}
          >
            {renderTreeView(children, selectedKey)}
          </SubMenu>
        );
      }

      const isSelected = key === selectedKey;
      const isRoute = !(parent !== undefined && children.length === 0);

      return (
        <CanAccess
          key={route}
          resource={name.toLowerCase()}
          action="list"
          params={{ resource: item }}
        >
          <Menu.Item
            className={`${isSelected ? "sub-menu-item-selected" : ""} ${
              mode === "light" ? "isLight" : "isDark"
            }`}
            key={route}
            style={{
              textTransform: "capitalize",
            }}
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
          >
            {route ? (
              <Link
                to={route || "/"}
                style={{
                  fontWeight: isSelected ? "bold" : "normal",
                }}
              >
                {label}
              </Link>
            ) : (
              label
            )}
            {!collapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  const handleLogout = () => {
    if (warnWhen) {
      const confirm = window.confirm(
        translate(
          "warnWhenUnsavedChanges",
          "Are you sure you want to leave? You have unsaved changes."
        )
      );

      if (confirm) {
        setWarnWhen(false);
        setMode("light");
        mutateLogout();
      }
    } else {
      setMode("light");
      mutateLogout();
    }
  };

  const logout = isExistAuthentication && (
    <Menu.Item key="logout" onClick={handleLogout} icon={<LogoutOutlined />}>
      {translate("buttons.logout", "Logout")}
    </Menu.Item>
  );

  const items = renderTreeView(menuItems, selectedKey);

  const renderSider = () => {
    if (render) {
      return render({
        dashboard: null,
        items,
        logout,
        collapsed,
      });
    }
    return (
      <>
        {items}
        {logout}
      </>
    );
  };

  const siderStyle = isMobile ? antLayoutSiderMobile : antLayoutSider;

  return (
    <AntdLayout.Sider
      className={`${mode === "light" ? "isLight" : "isDark"}`}
      width={275}
      collapsible
      collapsedWidth={isMobile ? 0 : 80}
      collapsed={collapsed}
      breakpoint="lg"
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      style={{
        ...siderStyle,
        backgroundColor: token.colorBgContainer,
        borderRight: `1px solid ${token.colorBgElevated}`,
      }}
      trigger={
        !isMobile && (
          <Button
            type="text"
            style={{
              borderRadius: 0,
              height: "100%",
              width: "100%",
              backgroundColor: token.colorBgElevated,
            }}
          >
            {collapsed ? (
              <RightOutlined
                style={{
                  color: token.colorPrimary,
                }}
              />
            ) : (
              <LeftOutlined
                style={{
                  color: token.colorPrimary,
                }}
              />
            )}
          </Button>
        )
      }
    >
      <div
        className={`${mode === "light" ? "isLight" : "isDark"}`}
        style={{
          width: collapsed ? "80px" : "275px",
          padding: collapsed ? "0" : "0 16px",
          display: "flex",
          justifyContent: collapsed ? "center" : "flex-start",
          alignItems: "center",
          height: "64px",
          backgroundColor: token.colorBgElevated,
          fontSize: "14px",
        }}
      >
        {/* logo and name of project here */}
        <ThemedTitleV2
          icon={<Image src="/favicon.jpeg" preview={false} />}
          text="Domus RMS Home"
          collapsed={collapsed}
        />
      </div>
      <Menu
        defaultOpenKeys={defaultOpenKeys}
        selectedKeys={[selectedKey]}
        mode="inline"
        style={{
          marginTop: "8px",
          border: "none",
        }}
        onClick={(e) => {
          const key = getSelectedPage() || "";
          if (key !== e?.key) {
            resetFilter();
          }
          setSelectedPage(e?.key);
          if (!breakpoint.lg) {
            setCollapsed(true);
          }
        }}
      >
        {renderSider()}
      </Menu>
    </AntdLayout.Sider>
  );
};
