import { useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash";
import {
  IResourceComponentsProps,
  usePermissions,
  useTranslate,
  CrudFilters,
  useList,
} from "@refinedev/core";
import {
  List,
  useTable,
  TextField,
  EditButton,
  ShowButton,
} from "@refinedev/antd";
import { Button, Form, Input, Space, Table, Tag } from "antd";
import { CaretDownOutlined, FilterFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { useDebounce } from "use-debounce";

import { IListApointments, IListBuildings, IListUser } from "interfaces";
import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_FINANCE,
  ROLE_SALE,
  ROLE_TEAM_LEADER,
  formatDateMonthYear,
  formatParamsString,
  formatTime,
  getParamFilter,
  hasChecked,
  onCheckFilter,
  onClearFilter,
  formatParamsToNumberArray,
  queryStringify,
} from "helper";
import { STATUS_APPOINTMENT, handleTranslate, statusColors } from "./constant";
import { FilterTimeFromTo, FilterNormal, FilterTime } from "components/modal";
import {
  getCurrentPagination,
  getFilterDeals,
  setCurrentPagination,
  setFilterDeals,
} from "helper/local-storage";
import { API_URL } from "api";

export const DealsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const permissions = usePermissions<string>();
  const filter = JSON.parse(getFilterDeals() || "{}");
  const [keyword, setKeyword] = useState<string>(filter?.keyword || "");

  const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);
  const [isOpenModalTime, setIsOpenModalTime] = useState<boolean>(false);
  const [isOpenModalCheckIn, setIsOpenModalCheckIn] = useState<boolean>(false);
  const [isOpenModalDepositCompletion, setIsOpenModalDepositCompletion] =
    useState<boolean>(false);
  const [isOpenModalBuilding, setIsOpenModalBuilding] =
    useState<boolean>(false);
  const [listStatus, setListStatus] = useState<any>(
    filter?.listStatus || STATUS_APPOINTMENT
  );
  const [listBuilding, setListBuilding] = useState<any>(
    filter?.listBuilding || []
  );
  const [searchBuilding, setSearchBuilding] = useState<string>("");
  const [checkedItems, setCheckedItems] = useState<any[]>([]);

  const [keywordSearchBuilding] = useDebounce(searchBuilding, 1000);
  const [fromTime, setFromTime] = useState<number>(filter?.fromTime || 0);
  const [toTime, setToTime] = useState<number>(filter?.toTime || 0);
  const [timeCheckIn, setTimeCheckIn] = useState<number>(
    filter?.timeCheckIn || 0
  );
  const [buildingsOptions, setBuildingsOptions] = useState<[]>([]);
  const [timeDepositCompletion, setTimeDepositCompletion] = useState<number>(
    filter?.timeDepositCompletion || 0
  );

  const [isOpenAdminSupport, setIsOpenAdminSupport] = useState<boolean>(false);
  const [listAdminSupport, setListAdminSupport] = useState<any>(
    filter?.listAdminSupport || []
  );

  const [pagination, setPagination] = useState(
    JSON.parse(getCurrentPagination() || "{}") || {
      current: 1,
      pageSize: 10,
    }
  );

  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissions?.data === ROLE_DATA_ADMIN;
  const isLoggedbyTeamLeader = permissions?.data === ROLE_TEAM_LEADER;
  const isLoggedbySale = permissions?.data === ROLE_SALE;
  const isLoggedbyFinance = permissions?.data === ROLE_FINANCE;
  const { searchFormProps, setFilters, tableProps } =
    useTable<IListApointments>({
      resource: "deals",
      onSearch: (params: any) => {
        const filters: CrudFilters = [];
        const { newKeyword } = params;

        filters.push({
          field: "keyword",
          operator: "eq",
          value: newKeyword || null,
        });
        setFilterDeals(JSON.stringify({ ...filter, keyword: newKeyword }));
        return filters;
      },
    });

  // get building data
  const { data: buildingsData } = useList<IListBuildings>({
    resource: "buildings",
    errorNotification: false,
  });

  // get Admin data
  const { data: adminsData } = useList<IListUser>({
    resource: "users?role_ids=1,2",
    errorNotification: false,
  });

  const dataSource: any = tableProps?.dataSource;
  let dealsTableProps = {
    ...tableProps,
    pagination: pagination,
    dataSource: dataSource?.data,
  };

  const handleTableChange = (pagination: any) => {
    setPagination({ current: pagination, pageSize: 10 });
  };

  const handleCheck = (value: any) => {
    setCheckedItems(value);
  };

  const getBuildingList = async (params: any) => {
    const query = queryStringify(params);
    try {
      const response = await axios.get(`${API_URL}/buildings${query}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const results = response?.data?.data?.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setListBuilding([...checkedItems.concat(results)]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setCurrentPagination(JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    const storedPagination = getCurrentPagination();
    if (storedPagination) {
      setPagination(JSON.parse(storedPagination));
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchFormProps.form?.submit();
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    const statusParams = getParamFilter(listStatus);
    const buildingParam = getParamFilter(listBuilding);
    const supportAdminParam = getParamFilter(listAdminSupport);

    setFilters(
      [
        {
          field: "statuses",
          operator: "eq",
          value: statusParams ? formatParamsString(statusParams) : null,
        },
        {
          field: "becomeDealFrom",
          operator: "eq",
          value: fromTime ? fromTime : null,
        },
        {
          field: "becomeDealTo",
          operator: "eq",
          value: toTime ? toTime : null,
        },
        {
          field: "checkInFrom",
          operator: "eq",
          value: timeCheckIn ? timeCheckIn : null,
        },
        {
          field: "depositCompletionDate",
          operator: "eq",
          value: timeDepositCompletion ? timeDepositCompletion : null,
        },
        {
          field: "buildingIds",
          operator: "eq",
          value: buildingParam
            ? formatParamsToNumberArray(buildingParam)
            : null,
        },
        {
          field: "supportAdminIds",
          operator: "eq",
          value: supportAdminParam
            ? formatParamsToNumberArray(supportAdminParam)
            : null,
        },
      ],
      "merge"
    );
    setFilterDeals(
      JSON.stringify({
        ...filter,
        listStatus,
        fromTime,
        toTime,
        timeCheckIn,
        timeDepositCompletion,
        listBuilding,
        listAdminSupport,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listStatus,
    fromTime,
    toTime,
    timeCheckIn,
    timeDepositCompletion,
    listBuilding,
    listAdminSupport,
  ]);

  useEffect(() => {
    if (buildingsData) {
      const buildingOptionsData: any = buildingsData?.data;
      const buildingOptions = buildingOptionsData?.data.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
          isChecked: false,
        };
      });
      setBuildingsOptions(buildingOptions);
      setListBuilding(buildingOptions);
    }
  }, [buildingsData]);

  useEffect(() => {
    if (adminsData) {
      const adminsOptionsData: any = adminsData?.data;
      setListAdminSupport(
        adminsOptionsData?.data?.map((item: any) => {
          return {
            value: item.id,
            label: item.full_name,
            isChecked: false,
          };
        })
      );
    }
  }, [adminsData]);

  useEffect(() => {
    if (keywordSearchBuilding) {
      getBuildingList({ keyword: keywordSearchBuilding });
    }

    if (!keywordSearchBuilding && checkedItems.length === 0) {
      setListBuilding([...buildingsOptions]);
    }
    if (checkedItems.length > 0 && !keywordSearchBuilding) {
      // Filter out checked items with isChecked set to true
      const checkedTrue = checkedItems.filter((item) => item.isChecked);

      // Filter out checked items with isChecked set to false
      const checkedFalse = checkedItems.filter((item) => !item.isChecked);

      // Merge the arrays with priority to checkedTrue items
      const mergedArray = [
        ...checkedTrue,
        ...checkedFalse,
        ...buildingsOptions,
      ];

      // Remove duplicates based on the value property
      const uniqueMergedArray = mergedArray.reduce((acc, curr) => {
        const existing = acc.find((item: any) => item.value === curr.value);
        if (!existing) {
          acc.push(curr);
        }
        return acc;
      }, []);

      // Set listBuilding to the merged array with priority to checkedTrue items and without duplicates
      setListBuilding(uniqueMergedArray);
    }
  }, [keywordSearchBuilding, buildingsOptions]);

  const onExportExcel = () => {
    const statusParams = getParamFilter(listStatus);
    const buildingParam = getParamFilter(listBuilding);
    const supportAdminParam = getParamFilter(listAdminSupport);

    const queryParams: { [key: string]: any } = {};

    if (keyword) {
      queryParams.keyword = keyword;
    }

    if (statusParams) {
      queryParams.statuses = formatParamsString(statusParams);
    }

    if (fromTime) {
      queryParams.becomeDealFrom = fromTime;
    }

    if (toTime) {
      queryParams.becomeDealTo = toTime;
    }

    if (timeCheckIn) {
      queryParams.checkInFrom = timeCheckIn;
    }

    if (timeDepositCompletion) {
      queryParams.completedDepositFrom = timeDepositCompletion;
    }

    if (buildingParam) {
      queryParams.buildingIds = formatParamsToNumberArray(buildingParam);
    }

    if (supportAdminParam) {
      queryParams.supportAdminIds =
        formatParamsToNumberArray(supportAdminParam);
    }

    axios
      .get(`${API_URL}/deals/excel-report`, {
        params: queryParams,
        responseType: "arraybuffer",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        // Create a Blob from the response data
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a download link
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `deals.xlsx`;

        // Append the link to the body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Remove the link from the body
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error exporting deals:", error);
      });
  };

  const checkedItemCount = listBuilding?.filter(
    (item: any) => item.isChecked
  ).length;

  return (
    <List
      title={translate("pages.financialManagement.deals.list")}
      headerButtons={() => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Form {...searchFormProps}>
            <Form.Item
              name="newKeyword"
              initialValue={keyword}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={translate("placeholder.searchByInfoAppointment")}
                onChange={(e) => setKeyword(e.target.value)}
                allowClear={true}
              />
            </Form.Item>
          </Form>
        </div>
      )}
    >
      <Space className="listFilter">
        <Button style={{ pointerEvents: "none" }}>
          <FilterFilled />
          {translate("buttons.filter")}
        </Button>
        <Button
          onClick={() => setIsOpenModalStatus(true)}
          className={hasChecked(listStatus) ? "isEnabled" : ""}
        >
          {translate("pages.financialManagement.deals.status")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalStatus && (
          <FilterNormal
            isOpen={isOpenModalStatus}
            setIsOpen={setIsOpenModalStatus}
            title={translate("pages.financialManagement.deals.status")}
            data={listStatus}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listStatus, true);
              setListStatus(newData);
            }}
            onClear={() => setListStatus([...STATUS_APPOINTMENT])}
            multiple={true}
          />
        )}

        <Button
          onClick={() => setIsOpenModalBuilding(true)}
          className={hasChecked(listBuilding) ? "isEnabled" : ""}
        >
          {translate("pages.financialManagement.deals.buildings")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalBuilding && (
          <FilterNormal
            isHaveSearchBar={true}
            valueSearch={searchBuilding}
            setValueSearch={setSearchBuilding}
            placeholderSearch={translate("placeholder.searchByNameBuildings")}
            isShowLabelSelected={true}
            checkedItemCount={checkedItemCount}
            isOpen={isOpenModalBuilding}
            setIsOpen={setIsOpenModalBuilding}
            title={translate("pages.financialManagement.deals.buildings")}
            data={listBuilding}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listBuilding, true);
              setListBuilding(newData);
              handleCheck(newData?.filter((item: any) => item.isChecked));
            }}
            onClear={() => {
              const newData = onClearFilter(listBuilding);
              setListBuilding(newData);
              setCheckedItems([]);
            }}
            multiple={true}
          />
        )}
        <Button
          onClick={() => setIsOpenModalTime(true)}
          className={fromTime || toTime ? "isEnabled" : ""}
        >
          {translate("pages.financialManagement.deals.date")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalTime && (
          <FilterTimeFromTo
            isOpen={isOpenModalTime}
            setIsOpen={setIsOpenModalTime}
            title={translate("pages.financialManagement.deals.date")}
            fromTime={fromTime}
            setFromTime={setFromTime}
            toTime={toTime}
            setToTime={setToTime}
          />
        )}
        <Button
          onClick={() => setIsOpenModalDepositCompletion(true)}
          className={timeDepositCompletion ? "isEnabled" : ""}
        >
          {translate("pages.financialManagement.deals.depositCompletionDate")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalDepositCompletion && (
          <FilterTime
            isOpen={isOpenModalDepositCompletion}
            setIsOpen={setIsOpenModalDepositCompletion}
            title={translate(
              "pages.financialManagement.deals.depositCompletionDate"
            )}
            time={timeDepositCompletion}
            setTime={setTimeDepositCompletion}
          />
        )}
        <Button
          onClick={() => setIsOpenModalCheckIn(true)}
          className={timeCheckIn ? "isEnabled" : ""}
        >
          {translate("pages.financialManagement.deals.checkInDate")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalCheckIn && (
          <FilterTime
            isOpen={isOpenModalCheckIn}
            setIsOpen={setIsOpenModalCheckIn}
            title={translate("pages.financialManagement.deals.checkInDate")}
            time={timeCheckIn}
            setTime={setTimeCheckIn}
          />
        )}
        <Button
          onClick={() => setIsOpenAdminSupport(true)}
          className={hasChecked(listAdminSupport) ? "isEnabled" : ""}
        >
          {translate("pages.financialManagement.deals.supportAdmins")}
          <CaretDownOutlined />
        </Button>
        {isOpenAdminSupport && (
          <FilterNormal
            isOpen={isOpenAdminSupport}
            setIsOpen={setIsOpenAdminSupport}
            title={translate("pages.financialManagement.deals.supportAdmins")}
            data={listAdminSupport}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listAdminSupport, true);
              setListAdminSupport(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listAdminSupport);
              setListAdminSupport(newData);
            }}
            multiple={true}
          />
        )}
        {isLoggedbyFinance || isLoggedbyAdmin ? (
          <div>
            <Button onClick={onExportExcel} type="primary">
              {translate("pages.financialManagement.deals.exportExcel")}
            </Button>
          </div>
        ) : null}
      </Space>
      <Table
        {...dealsTableProps}
        pagination={{
          ...tableProps.pagination,
          showTotal: (total: number, range: [number, number]) => (
            <>
              <Space style={{ fontWeight: 600, fontSize: 18 }}>
                {translate("const.total")}
                {total}
                {translate("const.deals")}
              </Space>
            </>
          ),
          defaultPageSize: 10,
          // showSizeChanger: true,
          // pageSizeOptions: ["10", "20", "30", "40"],
          current: pagination.current,
          onChange: handleTableChange,
        }}
        rowKey="id"
        className="list-users"
      >
        {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyTeamLeader ? (
          <Table.Column
            dataIndex="sale"
            title={translate("pages.financialManagement.deals.sale")}
            key="sale"
            render={(item, idx: number) => {
              return (
                <Tag key={idx}>
                  <TextField value={item?.full_name} />
                </Tag>
              );
            }}
          />
        ) : null}
        {isLoggedbyAdmin ||
        isLoggedbyDataAdmin ||
        isLoggedbyTeamLeader ||
        isLoggedbyFinance ? (
          <>
            <Table.Column
              dataIndex="supported_sales"
              title={translate("pages.financialManagement.deals.supportSales")}
              key="supported_sales"
              render={(supportSaleList) =>
                supportSaleList?.map((item: any, idx: number) => {
                  return (
                    <Tag key={idx}>
                      <TextField value={item?.full_name} />
                    </Tag>
                  );
                })
              }
            />
            <Table.Column
              dataIndex="supportAdmins"
              title={translate("pages.financialManagement.deals.supportAdmins")}
              key="supportAdmins"
              render={(supportAdminList) =>
                supportAdminList?.map((item: any, idx: number) => {
                  return (
                    <Tag key={idx}>
                      <TextField value={item?.full_name} />
                    </Tag>
                  );
                })
              }
            />
          </>
        ) : null}

        <Table.Column
          dataIndex="apartments"
          title={translate("pages.financialManagement.deals.apartments")}
          key="apartments"
          render={(value) => {
            return value?.map((item: any, idx: number) => {
              return (
                <Tag key={idx}>
                  <TextField value={item?.name} />
                </Tag>
              );
            });
          }}
        />
        <Table.Column
          dataIndex="apartments"
          title={translate("pages.financialManagement.deals.buildings")}
          key="apartments"
          render={(value) => {
            return value?.map((item: any, idx: number) => {
              return (
                <Tag key={idx}>
                  <TextField value={item?.building?.name} />
                </Tag>
              );
            });
          }}
        />
        <Table.Column
          dataIndex="customer"
          title={translate("pages.financialManagement.deals.customers")}
          key="customer"
          render={(value) => {
            return (
              value?.full_name && (
                <Tag>
                  <TextField value={value?.full_name} />
                </Tag>
              )
            );
          }}
        />
        <Table.Column
          dataIndex="become_deal_at"
          title={translate("pages.financialManagement.deals.time")}
          render={(value) => {
            return <TextField value={value ? formatTime(value) : ""} />;
          }}
        />
        {isLoggedbySale ? (
          <Table.Column
            dataIndex="role"
            title={translate("pages.financialManagement.deals.role")}
            render={(value) => {
              return value === "sale" ? (
                <Tag>
                  <TextField
                    value={translate(
                      "pages.financialManagement.deals.inCharge"
                    )}
                  />
                </Tag>
              ) : (
                <Tag>
                  <TextField
                    value={translate("pages.financialManagement.deals.support")}
                  />
                </Tag>
              );
            }}
          />
        ) : null}
        <Table.Column
          dataIndex="status"
          title={translate("pages.financialManagement.deals.status")}
          render={(value, idx: number) => {
            return (
              <TextField
                key={idx}
                value={
                  <Tag bordered={false} color={statusColors(value)}>
                    {handleTranslate(value, translate)}
                  </Tag>
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="complete_deposit_date"
          title={translate(
            "pages.financialManagement.deals.depositCompletionDate"
          )}
          render={(value) => {
            return (
              <TextField
                value={value ? dayjs(value).format(formatDateMonthYear) : ""}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="check_in_date"
          title={translate("pages.financialManagement.deals.checkInDate")}
          render={(value) => {
            return (
              <TextField
                value={value ? dayjs(value).format(formatDateMonthYear) : ""}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="notes"
          title={translate("pages.financialManagement.deals.notes")}
          render={(value) => {
            return <TextField value={value} />;
          }}
        />
        <Table.Column<IListApointments>
          title={translate("table.actions")}
          dataIndex="actions"
          fixed="right"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
