import { useEffect, useState } from "react";
import _ from "lodash";
import {
  CrudFilters,
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import {
  List,
  useTable,
  EditButton,
  TextField,
  ShowButton,
  CreateButton,
} from "@refinedev/antd";
import { Button, Form, Input, Space, Table, Tag } from "antd";
import { CaretDownOutlined, FilterFilled } from "@ant-design/icons";

import {
  IListApartments,
  IListBuildings,
  IListContracts,
  IListReservation,
  IListUser,
} from "interfaces";
import {
  formatTime,
  getParamFilter,
  hasChecked,
  mergeArraysByProperty,
  onCheckFilter,
  onClearFilter,
} from "helper";
import {
  getCurrentPagination,
  getFilterContracts,
  setCurrentPagination,
  setFilterContracts,
} from "helper/local-storage";
import { STATUS_CONTRACTS, handleTranslate } from "./const";
import { FilterNormal, FilterTimeFromTo } from "components/modal";

export const ContractsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const filter = JSON.parse(getFilterContracts() || "null");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const reservationIdUrlParam = urlParams.get("reservationId");

  const { searchFormProps, tableProps, setFilters } = useTable<IListContracts>({
    resource: "contracts",
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { newKeyword } = params;

      filters.push({
        field: "keyword",
        operator: "eq",
        value: newKeyword || null,
      });
      setFilterContracts(JSON.stringify({ ...filter, keyword: newKeyword }));
      return filters;
    },
  });

  // get building data
  const { data: buildingsData } = useList<IListBuildings>({
    resource: "buildings",
    errorNotification: false,
  });

  // get apartments data
  const { data: apartmentsData } = useList<IListApartments>({
    resource: "apartments",
    errorNotification: false,
  });

  // get customers data
  const { data: customersData } = useList<IListUser>({
    resource: "customers",
    errorNotification: false,
  });

  // get reservations data
  const { data: reservationsData } = useList<IListReservation>({
    resource: "reservations",
    errorNotification: false,
  });

  const [pagination, setPagination] = useState(
    JSON.parse(getCurrentPagination() || "{}") || {
      current: 1,
      pageSize: 10,
    }
  );
  const dataSource: any = tableProps?.dataSource;
  let contractsTableProps = {
    ...tableProps,
    pagination: pagination,
    dataSource: dataSource?.data,
  };

  const [isOpenModalBuilding, setIsOpenModalBuilding] =
    useState<boolean>(false);
  const [isOpenModalApartment, setIsOpenModalApartment] =
    useState<boolean>(false);
  const [isOpenModalCustomer, setIsOpenModalCustomer] =
    useState<boolean>(false);
  const [isOpenModalTime, setIsOpenModalTime] = useState<boolean>(false);
  const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);
  const [isOpenModalReservation, setIsOpenModalReservation] =
    useState<boolean>(false);

  const [keyword, setKeyword] = useState<string>("");
  const [listBuilding, setListBuilding] = useState<any>(
    filter?.listBuilding || []
  );
  const [listApartments, setListApartments] = useState<any>(
    filter?.listApartment || []
  );
  const [listCustomers, setListCustomers] = useState<any>(
    filter?.listCustomer || []
  );
  const [listStatus, setListStatus] = useState<any>(
    filter?.listStatus || STATUS_CONTRACTS
  );
  const [listReservation, setListReservation] = useState<any>(
    filter?.listReservation || []
  );

  const [validFrom, setValidFrom] = useState<number>(filter?.validFrom || 0);
  const [validTo, setValidTo] = useState<number>(filter?.validTo || 0);

  const handleTableChange = (pagination: any) => {
    setPagination({ current: pagination, pageSize: 10 });
  };

  useEffect(() => {
    setCurrentPagination(JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    const storedPagination = getCurrentPagination();
    if (storedPagination) {
      setPagination(JSON.parse(storedPagination));
    }
  }, []);

  useEffect(() => {
    if (buildingsData && !hasChecked(listBuilding)) {
      const buildingOptionsData: any = buildingsData?.data;
      setListBuilding(
        buildingOptionsData?.data?.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            isChecked: false,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingsData]);

  useEffect(() => {
    if (apartmentsData && !hasChecked(listApartments)) {
      const apartmentsOptionsData: any = apartmentsData?.data;
      setListApartments(
        apartmentsOptionsData?.data?.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            isChecked: false,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apartmentsData]);

  useEffect(() => {
    const customersOptionsData: any = customersData?.data;
    if (customersData && !hasChecked(listCustomers)) {
      setListCustomers(
        customersOptionsData?.data?.map((item: any) => {
          return {
            value: item.id,
            label: item.full_name,
            isChecked: false,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersData]);

  useEffect(() => {
    const reservationsOptionsData: any = reservationsData?.data;
    const reservationOption2 = reservationsOptionsData?.data;

    if (reservationOption2?.length > 0 && !hasChecked(listReservation)) {
      setListReservation(
        reservationOption2.map((item: any) => {
          return {
            value: item.id,
            label: `#${item.id}-${item.apartment.name}-${item.apartment.building.name}`,
            isChecked: false,
          };
        })
      );
    }
    if (reservationIdUrlParam) {
      const currentReservationListFull = reservationOption2?.map(
        (item: any) => {
          return {
            value: item.id,
            label: `#${item.id}-${item.apartment.name}-${item.apartment.building.name}`,
            isChecked: false,
          };
        }
      );

      const currentReservation = currentReservationListFull
        ?.filter((item: any) => item.value === +reservationIdUrlParam)
        .map((item: any) => {
          return {
            ...item,
            isChecked: true,
          };
        });

      if (
        currentReservation?.length > 0 &&
        currentReservationListFull?.length > 0
      ) {
        // merged current isChecked = true into current array isChecked = false
        const newReservationList = mergeArraysByProperty(
          currentReservation,
          currentReservationListFull,
          "value"
        );
        setListReservation(newReservationList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationsData, reservationIdUrlParam]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchFormProps.form?.submit();
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    const buildingParam = getParamFilter(listBuilding);
    const apartmentParam = getParamFilter(listApartments);
    const customerParam = getParamFilter(listCustomers);
    const statusParam = getParamFilter(listStatus);
    const reservationParam = getParamFilter(listReservation);

    const dateValidFrom = new Date(validFrom);
    const dateValidTo = new Date(validTo);

    setFilters(
      [
        {
          field: "buildingId",
          operator: "eq",
          value: buildingParam,
        },
        {
          field: "apartmentId",
          operator: "eq",
          value: apartmentParam,
        },
        {
          field: "customerId",
          operator: "eq",
          value: customerParam,
        },
        {
          field: "status",
          operator: "eq",
          value: statusParam,
        },
        {
          field: "validFrom",
          operator: "eq",
          value: validFrom ? dateValidFrom : null,
        },
        {
          field: "validTo",
          operator: "eq",
          value: validTo ? dateValidTo : null,
        },
        {
          field: "reservationId",
          operator: "eq",
          value: reservationParam,
        },
      ],
      "merge"
    );
    setFilterContracts(
      JSON.stringify({
        ...filter,
        listBuilding,
        listApartments,
        listCustomers,
        listStatus,
        validFrom,
        validTo,
        listReservation,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listBuilding,
    listApartments,
    listCustomers,
    listStatus,
    validFrom,
    validTo,
    listReservation,
  ]);

  return (
    <List
      title={translate("pages.payment.contracts.list")}
      headerButtons={({ createButtonProps }) => (
        <>
          <Form {...searchFormProps}>
            <Form.Item
              name="newKeyword"
              initialValue={keyword}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={translate("placeholder.searchInContract")}
                onChange={(e) => setKeyword(e.target.value)}
                allowClear={true}
              />
            </Form.Item>
          </Form>
          {/* <Button
            onClick={() =>
              navigate("/appointments/create", {
                state: { selectedRowKeys: selectedRowKeys },
              })
            }
          >
            {translate("pages.cartManagement.apartments.createAppointment")}
          </Button> */}

          <CreateButton {...createButtonProps} />
        </>
      )}
    >
      <Space className="listFilter">
        <Button style={{ pointerEvents: "none" }}>
          <FilterFilled />
          {translate("buttons.filter")}
        </Button>

        <Button
          onClick={() => setIsOpenModalBuilding(true)}
          className={hasChecked(listBuilding) ? "isEnabled" : ""}
        >
          {translate("pages.payment.contracts.building")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalBuilding && (
          <FilterNormal
            isOpen={isOpenModalBuilding}
            setIsOpen={setIsOpenModalBuilding}
            title={translate("pages.payment.contracts.building")}
            data={listBuilding}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listBuilding, false);
              setListBuilding(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listBuilding);
              setListBuilding(newData);
            }}
            multiple={false}
          />
        )}

        <Button
          onClick={() => setIsOpenModalApartment(true)}
          className={hasChecked(listApartments) ? "isEnabled" : ""}
        >
          {translate("pages.payment.contracts.apartment")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalApartment && (
          <FilterNormal
            isOpen={isOpenModalApartment}
            setIsOpen={setIsOpenModalApartment}
            title={translate("pages.payment.contracts.apartment")}
            data={listApartments}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listApartments, false);
              setListApartments(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listApartments);
              setListApartments(newData);
            }}
            multiple={false}
          />
        )}

        <Button
          onClick={() => setIsOpenModalCustomer(true)}
          className={hasChecked(listCustomers) ? "isEnabled" : ""}
        >
          {translate("pages.payment.contracts.customer")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalCustomer && (
          <FilterNormal
            isOpen={isOpenModalCustomer}
            setIsOpen={setIsOpenModalCustomer}
            title={translate("pages.payment.contracts.customer")}
            data={listCustomers}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listCustomers, false);
              setListCustomers(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listCustomers);
              setListCustomers(newData);
            }}
            multiple={false}
          />
        )}

        <Button
          onClick={() => setIsOpenModalStatus(true)}
          className={hasChecked(listStatus) ? "isEnabled" : ""}
        >
          {translate("pages.payment.landlordBills.status")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalStatus && (
          <FilterNormal
            isOpen={isOpenModalStatus}
            setIsOpen={setIsOpenModalStatus}
            title={translate("pages.cartManagement.landlords.status")}
            data={listStatus}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listStatus, false);
              setListStatus(newData);
            }}
            onClear={() => setListStatus([...STATUS_CONTRACTS])}
            multiple={false}
          />
        )}

        <Button
          onClick={() => setIsOpenModalTime(true)}
          className={validFrom || validTo ? "isEnabled" : ""}
        >
          {translate("pages.payment.contracts.date")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalTime && (
          <FilterTimeFromTo
            isOpen={isOpenModalTime}
            setIsOpen={setIsOpenModalTime}
            title={translate("pages.payment.contracts.date")}
            fromTime={validFrom}
            setFromTime={setValidFrom}
            toTime={validTo}
            setToTime={setValidTo}
          />
        )}

        <Button
          onClick={() => setIsOpenModalReservation(true)}
          className={hasChecked(listReservation) ? "isEnabled" : ""}
        >
          {translate("pages.payment.contracts.reservation")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalReservation && (
          <FilterNormal
            isOpen={isOpenModalReservation}
            setIsOpen={setIsOpenModalReservation}
            title={translate("pages.payment.contracts.reservation")}
            data={listReservation}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listReservation, false);
              setListReservation(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listReservation);
              setListReservation(newData);
            }}
            multiple={false}
          />
        )}
      </Space>

      <Table
        {...contractsTableProps}
        pagination={{
          defaultPageSize: 10,
          // showSizeChanger: true,
          // pageSizeOptions: ["10", "20", "30", "40"],
          current: pagination.current,
          onChange: handleTableChange,
        }}
        rowKey="id"
        className="list-table-first"
      >
        <Table.Column
          dataIndex="id"
          title={translate("pages.payment.contracts.id")}
        />
        <Table.Column
          dataIndex="reservation"
          title={translate("pages.payment.contracts.room")}
          render={(value) => {
            return <TextField value={value?.apartment?.name} />;
          }}
        />
        <Table.Column
          dataIndex="reservation"
          title={translate("pages.payment.contracts.building")}
          render={(value) => {
            return <TextField value={value?.apartment?.building?.name} />;
          }}
        />
        <Table.Column
          title={translate("pages.payment.contracts.customer")}
          dataIndex="reservation"
          render={(value) => {
            return <TextField value={value?.customer?.full_name} />;
          }}
        />
        <Table.Column
          title={translate("pages.users.status")}
          dataIndex="status"
          render={(value) => {
            return (
              <TextField
                strong
                value={
                  <Tag
                    bordered={false}
                    color={
                      value === "active"
                        ? "success"
                        : value === "pending"
                        ? "yellow"
                        : value === "terminated"
                        ? "error"
                        : "blue"
                    }
                  >
                    {handleTranslate(value, translate)}
                  </Tag>
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="validFrom"
          title={translate("pages.payment.contracts.validFrom")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column
          dataIndex="validTo"
          title={translate("pages.payment.contracts.validTo")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column
          dataIndex="createdAt"
          title={translate("pages.createdAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column
          width={400}
          dataIndex="updatedAt"
          title={translate("pages.updatedAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column<IListContracts>
          title={translate("table.actions")}
          dataIndex="actions"
          fixed="right"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
