import { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import styled from "styled-components";
import { S3_IMG_URL } from "api";

const ModalContent = styled.div`
  text-align: center;
`;

const CustomImageModal = ({ open, onCancel, images, initialIndex }: any) => {
  if (!images) {
    return;
  }
  const [currentIndex, setCurrentIndex] = useState(initialIndex || 0);

  useEffect(() => {
    if (open && initialIndex !== undefined) {
      setCurrentIndex(initialIndex);
    }
  }, [open, initialIndex, images]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex: any) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex: any) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handleClose = () => {
    setCurrentIndex(0);
    onCancel();
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      footer={null}
      width={800}
      centered
    >
      <ModalContent>
        <img
          style={{ maxWidth: "100%", maxHeight: "70vh" }}
          src={S3_IMG_URL + "/" + images[currentIndex]}
          alt={`Image ${currentIndex + 1}`}
        />
        <div style={{ marginTop: 16 }}>
          <Button onClick={handlePrev} icon={<GoChevronLeft />} />
          <span style={{ margin: "0 8px" }}>
            {currentIndex + 1} of {images.length}
          </span>
          <Button onClick={handleNext} icon={<GoChevronRight />} />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default CustomImageModal;
