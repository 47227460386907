import { useEffect, useState } from "react";
import axios from "axios";
import {
  IResourceComponentsProps,
  useList,
  usePermissions,
  useTranslate,
} from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Upload,
  message,
} from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";

import { IListBuildings, IListUser, IProvinces } from "interfaces";
import { API_URL, getDistricts, getWards } from "api";
import { BUILDING_GATE_GUARD } from "const";
import {
  isValidPrice,
  formatInputPrice,
  parseInputPrice,
  handleFilterOption,
  handleSortOption,
  getBase64,
  uploadButton,
  isValidPercent,
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_TEAM_LEADER,
  ROLE_LANDLORD,
} from "helper";
import { CHARGE_WATER } from "./constant";

export const BuildingsCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const permissions = usePermissions<string>();
  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissions?.data === ROLE_DATA_ADMIN;
  const isLoggedbyTeamLeader = permissions?.data === ROLE_TEAM_LEADER;
  const isLoggedbyLandlord = permissions?.data === ROLE_LANDLORD;
  const auth = JSON.parse(localStorage.getItem("auth") || "{}");

  const [selectedProvice, setSelectedProvince] = useState<any>(2);
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  const [selectedWard, setSelectedWard] = useState<any>();
  const [optionsProvinces, setOptionProvinces] = useState<[]>([]);
  const [optionsDistricts, setOptionsDistricts] = useState<[]>([]);
  const [optionsWards, setOptionsWards] = useState<[]>([]);
  const [optionsLandlords, setOptionsLandlords] = useState<[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<any>();
  const [imageUrls, setImageUrls] = useState<any>([]);
  const [isActive, setIsActive] = useState<boolean>(true);

  const { formProps, saveButtonProps, onFinish, form } =
    useForm<IListBuildings>();

  const { data: provincesData } = useList<IProvinces>({
    resource: "addresses/provinces",
    errorNotification: false,
  });

  // get landlord data
  const { data: landLordsData } = useList<IListUser>({
    resource: "landlords",
    errorNotification: false,
  });

  useEffect(() => {
    const provinces: any = provincesData?.data ?? [];
    const queryResult = provinces?.data;
    if (provinces?.status) {
      const optionsProvinces = queryResult?.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
      setOptionProvinces(optionsProvinces);
    }
  }, [provincesData]);

  useEffect(() => {
    const landlords: any = landLordsData?.data ?? [];
    const queryResult = landlords?.data;
    if (landlords?.status) {
      const optionsLandlords = queryResult?.map((item: any) => ({
        label: item.full_name,
        value: item.id,
      }));
      setOptionsLandlords(optionsLandlords);
    }
  }, [landLordsData]);

  useEffect(() => {
    if (selectedProvice) {
      getDistricts(selectedProvice).then((response) => {
        const optionsDistricts = response?.data.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
        setOptionsDistricts(optionsDistricts);
      });
    }
  }, [selectedProvice]);

  useEffect(() => {
    if (selectedDistrict) {
      getWards(selectedDistrict).then((response) => {
        const optionsWards = response?.data.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
        setOptionsWards(optionsWards);
      });
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (isLoggedbyAdmin || isLoggedbyDataAdmin) {
      setIsActive(true);
    }
    if (isLoggedbyTeamLeader || isLoggedbyLandlord) {
      setIsActive(false);
    }
  }, [isLoggedbyAdmin, isLoggedbyDataAdmin, isLoggedbyTeamLeader]);

  const onChangeSelectedProvince = (value: any) => {
    setSelectedProvince(value);
    setSelectedDistrict(null);
    setSelectedWard(null);
    form.setFieldValue("district_id", null);
    form.setFieldValue("ward_id", null);
  };

  const uploadImage = async (options: any) => {
    const { file, onSuccess, onError, onProgress } = options;
    const formData = new FormData();
    formData.append("mFiles", file);
    const response: any = await axios.post<{ url: string }>(
      `${API_URL}/s3/upload`,
      formData,
      {
        withCredentials: false,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round(
            (progressEvent?.loaded * 100) / progressEvent?.total
          );
          onProgress({ percent: percentCompleted });
        },
      }
    );

    if (response?.status) {
      // Handle success
      message.success(translate("notifications.imageUploadSuccess"));
      onSuccess(response?.data, file);
      setImageUrls((prev: any) => [...prev, ...response?.data?.data]);
    } else {
      // Handle error
      message.error(translate("notifications.imageUploadFailed"));
      onError();
    }
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const handleOnFinish: any = (values: IListBuildings) => {
    onFinish({
      ...values,
      approved: isActive,
      electricity_price: Number(values?.electricity_price),
      water_price: Number(values?.water_price),
      internet_price: Number(values?.internet_price),
      parking_price: Number(values?.parking_price),
      management_price: Number(values?.management_price),
      images: imageUrls?.map((item: any) => item),
      six_months_commission_pct: Number(values?.six_months_commission_pct),
      one_year_commission_pct: Number(values?.one_year_commission_pct),
    });
  };

  return (
    <Create
      title={translate("pages.cartManagement.buildings.createNewBuilding")}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          approved: true,
          gate_guard: "magnetic_card",
          electricity_price: 0,
          water_price: 0,
          internet_price: 0,
          parking_price: 0,
          management_price: 0,
          six_months_commission_pct: 0,
          one_year_commission_pct: 0,
          landlord_id: isLoggedbyLandlord && auth?.id,
        }}
        onFinish={handleOnFinish}
        form={form}
      >
        <Form.Item label="Images">
          <Form.Item name="images" noStyle>
            <Upload
              multiple={true}
              accept="image/*"
              customRequest={uploadImage}
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {uploadButton}
            </Upload>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="images apartments"
                style={{ width: "100%" }}
                src={previewImage}
              />
            </Modal>
          </Form.Item>
        </Form.Item>
        <Form.Item
          label={translate("pages.cartManagement.buildings.name")}
          name="name"
          rules={[
            {
              required: true,
              message: translate("validations.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyTeamLeader ? (
          <Form.Item label={translate("pages.cartManagement.buildings.status")}>
            <Form.Item valuePropName="checked">
              <Checkbox
                disabled={isLoggedbyTeamLeader}
                style={{
                  fontSize: "12px",
                }}
                onChange={() => setIsActive(true)}
                checked={isActive ? true : false}
              >
                {translate("status.approved")}
              </Checkbox>
              <Checkbox
                disabled={isLoggedbyTeamLeader}
                style={{
                  fontSize: "12px",
                }}
                onChange={() => setIsActive(false)}
                checked={!isActive ? true : false}
              >
                {translate("status.notApprovedYet")}
              </Checkbox>
            </Form.Item>
          </Form.Item>
        ) : null}

        <Form.Item
          label={translate("pages.cartManagement.buildings.description")}
          name="description"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("pages.cartManagement.buildings.linkGoogleMap")}
          name="link_google_map"
          rules={[
            {
              required: true,
              message: translate("validations.linkGoogleMap"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("pages.cartManagement.buildings.address")}
          name="address"
          rules={[
            {
              required: true,
              message: translate("validations.address"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("pages.cartManagement.buildings.province")}
          name={["province_id"]}
          rules={[
            {
              required: true,
              message: translate("validations.province"),
            },
          ]}
        >
          <Select
            options={optionsProvinces}
            placeholder={translate("placeholder.selectAProvince")}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
            onChange={onChangeSelectedProvince}
          />
        </Form.Item>

        {selectedProvice ? (
          <Form.Item
            label={translate("pages.cartManagement.buildings.district")}
            name={["district_id"]}
            rules={[
              {
                required: true,
                message: translate("validations.district"),
              },
            ]}
          >
            <Select
              placeholder={translate("placeholder.selectADistrict")}
              options={optionsDistricts}
              showSearch
              filterOption={(input, option: any) =>
                handleFilterOption(input, option)
              }
              filterSort={(optionA, optionB) =>
                handleSortOption(optionA, optionB)
              }
              value={selectedDistrict}
              onChange={(val) => {
                setSelectedDistrict(val);
                form.setFieldValue("ward_id", null);
              }}
            />
          </Form.Item>
        ) : null}
        {selectedDistrict ? (
          <Form.Item
            label={translate("pages.cartManagement.buildings.ward")}
            name={["ward_id"]}
            rules={[
              {
                required: true,
                message: translate("validations.ward"),
              },
            ]}
          >
            <Select
              placeholder={translate("placeholder.selectAWard")}
              options={optionsWards}
              showSearch
              filterOption={(input, option: any) =>
                handleFilterOption(input, option)
              }
              filterSort={(optionA, optionB) =>
                handleSortOption(optionA, optionB)
              }
              value={selectedWard}
              onChange={(val) => setSelectedWard(val)}
            />
          </Form.Item>
        ) : null}
        <Form.Item
          label={translate("pages.cartManagement.buildings.gateGuard")}
          name="gate_guard"
          rules={[
            {
              required: true,
              message: translate("validations.gateGuard"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.gateGuard")}
            showSearch
            options={BUILDING_GATE_GUARD.map((item: any) => {
              return {
                value: item.value,
                label: translate(item.label),
              };
            })}
          />
        </Form.Item>
        {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyTeamLeader ? (
          <Form.Item
            label={translate("pages.cartManagement.buildings.owner")}
            name="landlord_id"
            rules={[
              {
                required: true,
                message: translate("validations.landlord"),
              },
            ]}
          >
            <Select
              placeholder={translate("placeholder.selectALandlord")}
              options={optionsLandlords}
              showSearch
              filterOption={(input, option: any) =>
                handleFilterOption(input, option)
              }
              filterSort={(optionA, optionB) =>
                handleSortOption(optionA, optionB)
              }
            />
          </Form.Item>
        ) : null}
        <Form.Item
          name="electricity_price"
          label={translate("pages.cartManagement.buildings.electricityPrice")}
          rules={[
            {
              required: true,
              message: translate("validations.electricityPrice"),
            },
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
          />
        </Form.Item>
        <Form.Item
          label={translate("pages.cartManagement.buildings.chargeWater")}
          name="charge_water"
          rules={[
            {
              required: true,
              message: translate("validations.chargeWater"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectAChargeWater")}
            options={CHARGE_WATER.map((item: any) => {
              return {
                value: item.value,
                label: translate(item.label),
              };
            })}
          />
        </Form.Item>
        <Form.Item
          name="water_price"
          label={translate("pages.cartManagement.buildings.waterPrice")}
          rules={[
            {
              required: true,
              message: translate("validations.waterPrice"),
            },
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
          />
        </Form.Item>
        <Form.Item
          name="internet_price"
          label={translate("pages.cartManagement.buildings.internetPrice")}
          rules={[
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
          />
        </Form.Item>
        <Form.Item
          name="parking_price"
          label={translate("pages.cartManagement.buildings.parkingPrice")}
          rules={[
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
          />
        </Form.Item>
        <Form.Item
          name="management_price"
          label={translate("pages.cartManagement.buildings.managementPrice")}
          rules={[
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
          />
        </Form.Item>
        <Form.Item
          name="pet"
          label={translate("pages.cartManagement.buildings.pet")}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="rinse_clothesline"
          label={translate("pages.cartManagement.buildings.rinseClothesline")}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="amount_people_vehicle"
          label={translate(
            "pages.cartManagement.buildings.amountPeopleVehicle"
          )}
        >
          <Input />
        </Form.Item>
        {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyTeamLeader ? (
          <>
            {" "}
            <Form.Item
              name="six_months_commission_pct"
              label={translate(
                "pages.cartManagement.buildings.sixMonthsCommissionPct"
              )}
              rules={[
                {
                  required: true,
                  message: translate("validations.sixMonthsCommissionPct"),
                },
                () => ({
                  validator(_, value) {
                    if (isValidPercent(value)) {
                      return Promise.reject(
                        new Error(translate(value && isValidPercent(value)))
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber prefix="%" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="one_year_commission_pct"
              label={translate(
                "pages.cartManagement.buildings.oneYearCommissionPct"
              )}
              rules={[
                {
                  required: true,
                  message: translate("validations.oneYearCommissionPct"),
                },
                () => ({
                  validator(_, value) {
                    if (isValidPercent(value)) {
                      return Promise.reject(
                        new Error(translate(value && isValidPercent(value)))
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber prefix="%" style={{ width: "100%" }} />
            </Form.Item>
          </>
        ) : null}

        <Form.Item
          label={translate("pages.cartManagement.buildings.notes")}
          name="notes"
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
