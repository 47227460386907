export const STATUS_TRANSACTIONS = [
  {
    value: "processing",
    label: "status.processing",
    isChecked: false,
  },
  {
    value: "completed",
    label: "status.completed",
    isChecked: false,
  },
  {
    value: "rejected",
    label: "status.rejected",
    isChecked: false,
  },
];

export const handleTranslateStatus = (value: string, translate: any) => {
  switch (value) {
    case "processing":
      return translate("status.processing");
    case "completed":
      return translate("status.completed");
    case "rejected":
      return translate("status.rejected");
    default:
      break;
  }
};
