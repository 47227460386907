import { useTranslate } from "@refinedev/core";
import { Button, DatePicker, Modal, Space } from "antd";
import dayjs from "dayjs";

import "./styles.css";
import { getTimestampEndDate, getTimestampStartDate } from "helper";

interface IFilterTimeFromTo {
  isOpen: boolean;
  setIsOpen: (value: any) => void;
  title: string;
  fromTime: number;
  setFromTime: (value: any) => void;
  toTime: number;
  setToTime: (value: any) => void;
}

export const FilterTimeFromTo = ({
  isOpen,
  setIsOpen,
  title,
  fromTime,
  setFromTime,
  toTime,
  setToTime,
}: IFilterTimeFromTo) => {
  const translate = useTranslate();

  return (
    <Modal
      open={isOpen}
      title={title}
      onCancel={() => setIsOpen(false)}
      footer={[
        <Button
          key="clear"
          danger
          onClick={() => {
            setFromTime(0);
            setToTime(0);
            setIsOpen(false);
          }}
        >
          {translate("buttons.clear")}
        </Button>,
        <Button key="seeResult" type="primary" onClick={() => setIsOpen(false)}>
          {translate("buttons.seeResult")}
        </Button>,
      ]}
      className="filterTime"
    >
      <div className="listItem">
        <Space className="wrapper" size={12}>
          {translate("pages.fromDate")}
        </Space>
        <Space className="wrapper" direction="vertical" size={12}>
          <DatePicker
            inputReadOnly={true}
            placeholder={translate("placeholder.selectDateTime")}
            onChange={(e: any) => {
              if (e) {
                setFromTime(getTimestampStartDate(new Date(e)));
              } else {
                setFromTime(0);
              }
            }}
            value={fromTime ? dayjs(fromTime) : null}
            getPopupContainer={(trigger: any) => trigger.parentNode}
          />
        </Space>
        <Space className="wrapper">{translate("pages.toDate")}</Space>
        <Space
          className="datePickerContainerToDate"
          direction="vertical"
          size={12}
        >
          <DatePicker
            inputReadOnly={true}
            placeholder={translate("placeholder.selectDateTime")}
            onChange={(e: any) => {
              if (e) {
                setToTime(getTimestampEndDate(new Date(e)));
              } else {
                setToTime(0);
              }
            }}
            value={toTime ? dayjs(toTime) : null}
            getPopupContainer={(trigger: any) => trigger.parentNode}
          />
        </Space>
      </div>
    </Modal>
  );
};
