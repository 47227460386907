export const STATUS_OPTIONS = [
  {
    value: "pending",
    label: "status.pendingAppointment",
  },
  {
    value: "customer_changed_plan",
    label: "status.customerChangedPlan",
  },
  {
    value: "not_seen_canceled",
    label: "status.notSeenCanceled",
  },
  {
    value: "seen_waiting",
    label: "status.seenWaiting",
  },
  {
    value: "seen_failed",
    label: "status.seenFailed",
  },
  {
    value: "partial_deposit",
    label: "status.partialDeposit",
  },
  {
    value: "fulfilled_deposit",
    label: "status.fulfilledDeposit",
  },
  {
    value: "cancel_deposit",
    label: "status.cancelDepositNoFee",
  },
  {
    value: "cancel_deposit_fee",
    label: "status.cancelDepositFee",
  },
  {
    value: "checked_in",
    label: "status.checkedIn",
  },
];
export const STATUS_OPTIONS_LIMIT = [
  {
    value: "pending",
    label: "status.pendingAppointment",
  },
  {
    value: "partial_deposit",
    label: "status.partialDeposit",
  },
  {
    value: "fulfilled_deposit",
    label: "status.fulfilledDeposit",
  },
  {
    value: "checked_in",
    label: "status.checkedIn",
  },
];

export const STATUS_TRANSFER_MONEY_OPTIONS = [
  {
    value: "processing",
    label: "status.processing",
  },
  {
    value: "completed",
    label: "status.completed",
  },
  {
    value: "rejected",
    label: "status.rejected",
  },
];

export const STATUS_TRANSFER_MONEY_OPTIONS_DEPOSIT = [
  {
    value: "processing",
    label: "status.processing",
  },
  {
    value: "paid",
    label: "status.paidLandlord",
  },
  {
    value: "completed",
    label: "status.completed",
  },
  {
    value: "rejected",
    label: "status.rejected",
  },
];