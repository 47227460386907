import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { EditButton, Show, TextField } from "@refinedev/antd";

import { Space, Tag, Typography } from "antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IListBills } from "interfaces";
import { formatPrice } from "helper";

const { Title } = Typography;

export const BillsShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IListBills>();
  const { data, isLoading } = queryResult;
  const record: any = data?.data;

  const handleTranslate = (value: string) => {
    switch (value) {
      case "pending":
        return translate("status.pending");
      case "paid":
        return translate("status.paid");
      case "overdue":
        return translate("status.overdue");
      default:
        break;
    }
  };

  return (
    <Show
      isLoading={isLoading}
      title={translate("pages.financialManagement.bills.showBill")}
      headerButtons={({
        listButtonProps,
        editButtonProps,
        refreshButtonProps,
      }) => (
        <>
          {editButtonProps && (
            <EditButton
              {...editButtonProps}
              title={translate("pages.financialManagement.bills.editBill")}
            />
          )}
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
    >
     
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.bills.electricityPrice")}:
        </Title>
        <TextField value={formatPrice(record?.data?.electricity_price)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.bills.electricityUnits")}:
        </Title>
        <TextField value={record?.data?.electricity_units} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.bills.waterPrice")}:
        </Title>
        <TextField value={formatPrice(record?.data?.water_price)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.bills.waterUnits")}:
        </Title>
        <TextField value={record?.data?.water_units} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.bills.parkingPrice")}:
        </Title>
        <TextField value={formatPrice(record?.data?.parking_price)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.bills.parkingUnits")}:
        </Title>
        <TextField value={record?.data?.parking_units} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.bills.internetPrice")}:
        </Title>
        <TextField value={formatPrice(record?.data?.internet_price)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.bills.managementPrice")}:
        </Title>
        <TextField value={formatPrice(record?.data?.management_price)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.bills.contractID")}:
        </Title>
        <TextField strong value={record?.data?.contract_id} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.bills.status")}:
        </Title>
        <TextField
          strong
          value={
            record?.data?.status === "paid" ? (
              <Tag bordered={false} color="success">
                {handleTranslate(record?.data?.status)}
              </Tag>
            ) : (
              <>
                {record?.data?.status === "pending" ? (
                  <Tag bordered={false} color="yellow">
                    {handleTranslate(record?.data?.status)}
                  </Tag>
                ) : (
                  <Tag bordered={false} color="red">
                    {handleTranslate(record?.data?.status)}
                  </Tag>
                )}
              </>
            )
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.bills.fromDate")}:
        </Title>
        <TextField value={record?.data?.from_date} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.financialManagement.bills.toDate")}:
        </Title>
        <TextField value={record?.data?.to_date} />
      </Space>
      <br />
    </Show>
  );
};
