import { useTranslate } from "@refinedev/core";
import { Button, Modal } from "antd";

import "./styles.css";

interface IModalConfirm {
  isOpen: boolean;
  title: string;
  subTitle: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ModalConfirm = ({
  isOpen,
  title,
  subTitle,
  onCancel,
  onConfirm,
}: IModalConfirm) => {
  const translate = useTranslate();

  return (
    <Modal
      open={isOpen}
      title={title}
      onCancel={onCancel}
      maskClosable={false}
      footer={[
        <Button key="cancel" danger onClick={onCancel}>
          {translate("status.statusPartialDeposit")}
        </Button>,
        <Button key="confirm" type="primary" onClick={onConfirm}>
          {translate("status.statusFulfilledDeposit")}
        </Button>,
      ]}
      className="filterNormal"
    >
      <div className="listItem">{subTitle}</div>
    </Modal>
  );
};
