import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { EditButton, Show, TextField } from "@refinedev/antd";
import { Space, Typography } from "antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IListLandlordBills } from "interfaces";
import { formatPrice, formatTime } from "helper";
import CustomImageModal from "components/modal/ImageDetail";
import { useState } from "react";
import Slider from "components/slider-image";

const { Title } = Typography;

export const LandlordTransactionsShow: React.FC<
  IResourceComponentsProps
> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IListLandlordBills>();
  const { data, isLoading } = queryResult;
  const [currentOpenModalImage, setCurrentOpenModalImage] =
    useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<any>(0);
  const record: any = data?.data;

  const handleCancel = () => {
    setCurrentOpenModalImage(false);
  };

  const handleOpenCurrentImageModal = (index: number) => {
    setCurrentImageIndex(index);
    setCurrentOpenModalImage(true);
  };

  const handleTranslate = (value: string) => {
    switch (value) {
      case "deposit":
        return translate("status.deposit");
      case "rental-bill":
        return translate("status.rentalBill");
      case "maintenance":
        return translate("status.maintenance");
      case "refund-deposit":
        return translate("status.refundDeposit");
      case "electricity-bill":
        return translate("status.electricityBill");
      case "water-bill":
        return translate("status.waterBill");
      case "tax":
        return translate("status.tax");
      case "other":
        return translate("status.other");
      default:
        break;
    }
  };

  const handleTranslateCashFlow = (value: string) => {
    switch (value) {
      case "cash-in":
        return translate("status.cashIn");
      case "cash-out":
        return translate("status.cashOut");
      default:
        break;
    }
  };

  return (
    <Show
      isLoading={isLoading}
      title={translate("pages.payment.landlordTransactions.show")}
      headerButtons={({
        listButtonProps,
        editButtonProps,
        refreshButtonProps,
      }) => (
        <>
          {editButtonProps && (
            <EditButton
              {...editButtonProps}
              title={translate("pages.payment.landlordTransactions.edit")}
            />
          )}
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
    >
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordTransactions.bill")}:
        </Title>
        <TextField value={record?.data?.bill?.name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordTransactions.building")}:
        </Title>
        <TextField value={record?.data?.building?.name} />
      </Space>
      <br />

      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordTransactions.types")}:
        </Title>
        <TextField value={handleTranslate(record?.data?.type)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordTransactions.cashFlow")}:
        </Title>
        <TextField value={handleTranslateCashFlow(record?.data?.cashFlow)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordTransactions.amount")}:
        </Title>
        <TextField value={formatPrice(record?.data?.amount)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.landlordTransactions.notes")}:
        </Title>
        <TextField value={record?.data?.notes} />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.createdAt")}:</Title>
        <TextField value={formatTime(record?.data?.createdAt)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.updatedAt")}:</Title>
        <TextField value={formatTime(record?.data?.updatedAt)} />
      </Space>
      <br />
      <br />

      <CustomImageModal
        open={currentOpenModalImage}
        onCancel={handleCancel}
        images={record?.data?.images}
        initialIndex={currentImageIndex}
      />
      <Slider
        item={record?.data?.images}
        handleOpenCurrentIndex={(index: number) =>
          handleOpenCurrentImageModal(index)
        }
      />
    </Show>
  );
};
