import { useEffect, useState } from "react";
import {
  CrudFilters,
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import {
  List,
  useTable,
  TextField,
  EditButton,
  ShowButton,
  CreateButton,
} from "@refinedev/antd";
import { Button, Form, Input, Space, Table, Tag } from "antd";
import { CaretDownOutlined, FilterFilled } from "@ant-design/icons";

import {
  formatTime,
  getParamFilter,
  hasChecked,
  onCheckFilter,
  onClearFilter,
} from "helper";
import { IListApartments, IListBuildings, IListReservation } from "interfaces";
import {
  getCurrentPagination,
  setCurrentPagination,
  setFilterReservations,
  getFilterReservations,
} from "helper/local-storage";
import {
  PERIOD_RESERVATIONS,
  STATUS_RESERVATIONS,
  handleTranslate,
} from "./constant";
import { FilterNormal } from "components/modal";

export const ReservationsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const filter = JSON.parse(getFilterReservations() || "null");
  const [pagination, setPagination] = useState(
    JSON.parse(getCurrentPagination() || "{}") || {
      current: 1,
      pageSize: 10,
    }
  );

  const { searchFormProps, tableProps, setFilters } =
    useTable<IListReservation>({
      resource: "reservations",
      onSearch: (params: any) => {
        const filters: CrudFilters = [];
        const { newKeyword } = params;

        filters.push({
          field: "keyword",
          operator: "eq",
          value: newKeyword || null,
        });
        setFilterReservations(
          JSON.stringify({ ...filter, keyword: newKeyword })
        );
        return filters;
      },
    });

  const dataSource: any = tableProps?.dataSource;
  let areasTableProps = {
    ...tableProps,
    pagination: pagination,
    dataSource: dataSource?.data,
  };

  // get building data
  const { data: buildingsData } = useList<IListBuildings>({
    resource: "buildings",
    errorNotification: false,
  });

  // get apartments data
  const { data: apartmentsData } = useList<IListApartments>({
    resource: "apartments",
    errorNotification: false,
  });

  const [isOpenModalBuilding, setIsOpenModalBuilding] =
    useState<boolean>(false);
  const [isOpenModalApartment, setIsOpenModalApartment] =
    useState<boolean>(false);
  const [isOpenModalPeriod, setIsOpenModalPeriod] = useState<boolean>(false);
  const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);

  const [keyword, setKeyword] = useState<string>("");
  const [listBuilding, setListBuilding] = useState<any>(
    filter?.listBuilding || []
  );
  const [listApartments, setListApartments] = useState<any>(
    filter?.listApartment || []
  );
  const [timePeriod, setTimePeriod] = useState<any>(
    filter?.listPeriod || PERIOD_RESERVATIONS
  );
  const [listStatus, setListStatus] = useState<any>(
    filter?.listStatus || STATUS_RESERVATIONS
  );

  const handleTableChange = (pagination: any) => {
    setPagination({ current: pagination, pageSize: 10 });
  };

  useEffect(() => {
    if (buildingsData && !hasChecked(listBuilding)) {
      const buildingOptionsData: any = buildingsData?.data;
      setListBuilding(
        buildingOptionsData?.data?.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            isChecked: false,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingsData]);

  useEffect(() => {
    if (apartmentsData && !hasChecked(listApartments)) {
      const apartmentsOptionsData: any = apartmentsData?.data;
      setListApartments(
        apartmentsOptionsData?.data?.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            isChecked: false,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apartmentsData]);

  useEffect(() => {
    const buildingParam = getParamFilter(listBuilding);
    const apartmentParam = getParamFilter(listApartments);
    const statusParam = getParamFilter(listStatus);
    const periodParam = getParamFilter(timePeriod);

    setFilters(
      [
        {
          field: "buildingId",
          operator: "eq",
          value: buildingParam,
        },
        {
          field: "apartmentId",
          operator: "eq",
          value: apartmentParam,
        },
        {
          field: "status",
          operator: "eq",
          value: statusParam,
        },
        {
          field: "period",
          operator: "eq",
          value: periodParam,
        },
      ],
      "merge"
    );
    setFilterReservations(
      JSON.stringify({
        ...filter,
        listBuilding,
        listApartments,
        listStatus,
        timePeriod,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listBuilding, listApartments, listStatus, timePeriod]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchFormProps.form?.submit();
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    setCurrentPagination(JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    const storedPagination = getCurrentPagination();
    if (storedPagination) {
      setPagination(JSON.parse(storedPagination));
    }
  }, []);

  return (
    <List
      title={translate("pages.operation.reservations.list")}
      headerButtons={({ createButtonProps }) => (
        <>
          <Form {...searchFormProps}>
            <Form.Item
              name="newKeyword"
              initialValue={keyword}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={translate("placeholder.searchInContract")}
                onChange={(e) => setKeyword(e.target.value)}
                allowClear={true}
              />
            </Form.Item>
          </Form>
          <CreateButton {...createButtonProps} />
        </>
      )}
    >
      <Space className="listFilter">
        <Button style={{ pointerEvents: "none" }}>
          <FilterFilled />
          {translate("buttons.filter")}
        </Button>

        <Button
          onClick={() => setIsOpenModalBuilding(true)}
          className={hasChecked(listBuilding) ? "isEnabled" : ""}
        >
          {translate("pages.payment.contracts.building")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalBuilding && (
          <FilterNormal
            isOpen={isOpenModalBuilding}
            setIsOpen={setIsOpenModalBuilding}
            title={translate("pages.payment.contracts.building")}
            data={listBuilding}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listBuilding, false);
              setListBuilding(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listBuilding);
              setListBuilding(newData);
            }}
            multiple={false}
          />
        )}

        <Button
          onClick={() => setIsOpenModalApartment(true)}
          className={hasChecked(listApartments) ? "isEnabled" : ""}
        >
          {translate("pages.payment.contracts.apartment")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalApartment && (
          <FilterNormal
            isOpen={isOpenModalApartment}
            setIsOpen={setIsOpenModalApartment}
            title={translate("pages.payment.contracts.apartment")}
            data={listApartments}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listApartments, false);
              setListApartments(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listApartments);
              setListApartments(newData);
            }}
            multiple={false}
          />
        )}

        <Button
          onClick={() => setIsOpenModalPeriod(true)}
          className={hasChecked(timePeriod) ? "isEnabled" : ""}
        >
          {translate("pages.operation.reservations.period")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalPeriod && (
          <FilterNormal
            isOpen={isOpenModalPeriod}
            setIsOpen={setIsOpenModalPeriod}
            title={translate("pages.operation.reservations.period")}
            data={timePeriod}
            onCheck={(value) => {
              const newData = onCheckFilter(value, timePeriod, false);
              setTimePeriod(newData);
            }}
            onClear={() => setTimePeriod([...PERIOD_RESERVATIONS])}
            multiple={false}
          />
        )}
        <Button
          onClick={() => setIsOpenModalStatus(true)}
          className={hasChecked(listStatus) ? "isEnabled" : ""}
        >
          {translate("pages.payment.landlordBills.status")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalStatus && (
          <FilterNormal
            isOpen={isOpenModalStatus}
            setIsOpen={setIsOpenModalStatus}
            title={translate("pages.operation.reservations.status")}
            data={listStatus}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listStatus, false);
              setListStatus(newData);
            }}
            onClear={() => setListStatus([...STATUS_RESERVATIONS])}
            multiple={false}
          />
        )}
      </Space>
      <Table
        {...areasTableProps}
        pagination={{
          defaultPageSize: 10,
          current: pagination.current,
          onChange: handleTableChange,
        }}
        rowKey="id"
        className="list-table-first"
      >
        <Table.Column
          dataIndex="id"
          title={translate("pages.operation.reservations.id")}
        />
        <Table.Column
          dataIndex="apartment"
          title={translate("pages.operation.reservations.apartment")}
          render={(value) => {
            return (
              <TextField
                value={`${value?.name + ` (${value?.building?.name})`}`}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="customer"
          title={translate("pages.operation.reservations.customer")}
          render={(value) => {
            return <TextField value={value?.full_name} />;
          }}
        />
        <Table.Column
          dataIndex="created_at"
          title={translate("pages.createdAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column
          dataIndex="period"
          title={translate("pages.operation.reservations.period")}
          render={(value) => {
            return <TextField value={translate(`const.${value}`)} />;
          }}
        />
        <Table.Column
          title={translate("pages.operation.reservations.status")}
          dataIndex="status"
          render={(value, idx: number) => {
            return (
              <TextField
                key={idx}
                value={
                  <Tag
                    color={
                      value === "checked-in" || value === "deposited"
                        ? "success"
                        : value === "waiting-for-deposit"
                        ? "warning"
                        : value === "canceled"
                        ? "error"
                        : "geekblue"
                    }
                  >
                    {handleTranslate(value, translate)}
                  </Tag>
                }
              />
            );
          }}
        />
        <Table.Column
          width={400}
          dataIndex="checkedInAt"
          title={translate("const.checkedIn")}
          render={(value) => {
            return <TextField value={value ? formatTime(value) : ""} />;
          }}
        />
        <Table.Column
          width={400}
          dataIndex="checkedOutAt"
          title={translate("const.checkedOut")}
          render={(value) => {
            return <TextField value={value ? formatTime(value) : ""} />;
          }}
        />
        <Table.Column
          width={400}
          dataIndex="createdAt"
          title={translate("pages.createdAt")}
          render={(value) => {
            return <TextField value={value ? formatTime(value) : ""} />;
          }}
        />
        <Table.Column
          width={400}
          dataIndex="updatedAt"
          title={translate("pages.updatedAt")}
          render={(value) => {
            return <TextField value={value ? formatTime(value) : ""} />;
          }}
        />
        <Table.Column<IListReservation>
          title={translate("table.actions")}
          dataIndex="actions"
          fixed="right"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
