import { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  useList,
  usePermissions,
  useTranslate,
} from "@refinedev/core";
import { Edit, SaveButton, useForm } from "@refinedev/antd";
import { Form, Input, Select } from "antd";

import {
  IListSaleGroups,
  IListSaleRights,
  IListBuildingGroups,
} from "interfaces";
import { STATUS_ACTIVE_INACTIVE } from "const";
import { handleFilterOption, handleSortOption } from "helper";

export const SaleRightsEdit: React.FC<IResourceComponentsProps> = (props) => {
  const translate = useTranslate();
  const { data: permissionsData }: any = usePermissions();
  const { formProps, queryResult, saveButtonProps, onFinish, form } =
    useForm<IListSaleRights>({
      resource: "sale-rights",
    });
  const saleRightsData: any = queryResult?.data?.data ?? "";

  // get building groups data
  const { data: buildingGroupsData } = useList<IListBuildingGroups>({
    resource: "building-groups",
    errorNotification: false,
  });
  // get sale groups data
  const { data: saleGroupsData } = useList<IListSaleGroups>({
    resource: "sale-groups",
    errorNotification: false,
  });

  const [optionsBuildingGroups, setOptionsBuildingGroups] = useState<any>([]);
  const [selectedItemsBuildingGroups, setSelectedItemsBuildingGroups] =
    useState<string[]>([]);
  const [optionsSaleGroups, setOptionsSaleGroups] = useState<any>([]);
  const [selectedItemsSaleGroups, setSelectedItemsSaleGroups] = useState<
    string[]
  >([]);

  const handleOnFinish: any = (values: IListSaleRights) => {
    onFinish({
      ...values,
    });
  };

  useEffect(() => {
    if (buildingGroupsData) {
      const optionsBuildingGroupsData: any = buildingGroupsData?.data;
      setOptionsBuildingGroups(
        optionsBuildingGroupsData?.data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      );
    }
  }, [buildingGroupsData]);

  useEffect(() => {
    if (saleGroupsData) {
      const optionsSaleGroupsData: any = saleGroupsData?.data;
      setOptionsSaleGroups(
        optionsSaleGroupsData?.data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      );
    }
  }, [saleGroupsData]);

  const filteredOptionsBuildingGroups = optionsBuildingGroups.filter(
    (o: any) => !selectedItemsBuildingGroups.includes(o)
  );

  const filteredOptionsSaleGroups = optionsSaleGroups.filter(
    (o: any) => !selectedItemsSaleGroups.includes(o)
  );

  return (
    <Edit
      {...props}
      title={translate(
        "pages.cartManagement.saleRightsManagement.saleRights.editSaleRights"
      )}
      canDelete={permissionsData?.includes["admin"]}
      headerButtons={({ listButtonProps, refreshButtonProps }) => (
        <>
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} />
        </>
      )}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        layout="vertical"
        initialValues={{
          ...saleRightsData?.data,
          building_group_ids: saleRightsData?.data?.building_groups?.map(
            (item: any) => item.id
          ),
          sale_group_ids: saleRightsData?.data?.sale_groups?.map(
            (item: any) => item.id
          ),
        }}
        form={form}
      >
        <Form.Item
          name="name"
          label={translate(
            "pages.cartManagement.saleRightsManagement.saleRights.name"
          )}
          rules={[
            {
              required: true,
              message: translate("validations.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="building_group_ids"
          label={translate(
            "pages.cartManagement.saleRightsManagement.saleRights.buildingGroups"
          )}
          rules={[
            {
              required: true,
              message: translate("validations.buildingGroups"),
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={translate(
              "placeholder.selectAOneOrManyBuildingGroups"
            )}
            value={selectedItemsBuildingGroups}
            onChange={setSelectedItemsBuildingGroups}
            options={filteredOptionsBuildingGroups}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <Form.Item
          name="sale_group_ids"
          label={translate(
            "pages.cartManagement.saleRightsManagement.saleRights.saleGroups"
          )}
          rules={[
            {
              required: true,
              message: translate("validations.saleGroups"),
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={translate("placeholder.selectAOneOrManySaleGroups")}
            value={selectedItemsSaleGroups}
            onChange={setSelectedItemsSaleGroups}
            options={filteredOptionsSaleGroups}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <Form.Item
          name="status"
          label={translate(
            "pages.cartManagement.saleRightsManagement.saleRights.status"
          )}
          rules={[
            {
              required: true,
              message: translate("validations.status"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectStatus")}
            options={STATUS_ACTIVE_INACTIVE?.map((item) => {
              return {
                label: translate(item.label),
                value: item.value,
              };
            })}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
