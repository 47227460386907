import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import {
  List,
  useTable,
  EditButton,
  TextField,
  ShowButton,
} from "@refinedev/antd";
import { Space, Table, Tag } from "antd";

import { IListBills } from "interfaces";
import { formatPrice } from "helper";
import { useEffect, useState } from "react";
import {
  getCurrentPagination,
  setCurrentPagination,
} from "helper/local-storage";

export const BillsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const [pagination, setPagination] = useState(
    JSON.parse(getCurrentPagination() || "{}") || {
      current: 1,
      pageSize: 10,
    }
  );
  const { tableProps } = useTable<IListBills>({
    resource: "bills",
  });
  const dataSource: any = tableProps?.dataSource;
  let billsTableProps = {
    ...tableProps,
    pagination: pagination,
    dataSource: dataSource?.data,
  };

  const handleTranslate = (value: string) => {
    switch (value) {
      case "pending":
        return translate("status.pending");
      case "paid":
        return translate("status.paid");
      case "overdue":
        return translate("status.overdue");
      default:
        break;
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination({ current: pagination, pageSize: 10 });
  };

  useEffect(() => {
    setCurrentPagination(JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    const storedPagination = getCurrentPagination();
    if (storedPagination) {
      setPagination(JSON.parse(storedPagination));
    }
  }, []);

  return (
    <List title={translate("pages.financialManagement.bills.list")}>
      <Table
        {...billsTableProps}
        pagination={{
          defaultPageSize: 10,
          // showSizeChanger: true,
          // pageSizeOptions: ["10", "20", "30", "40"],
          current: pagination.current,
          onChange: handleTableChange,
        }}
        rowKey="id"
      >
        <Table.Column
          dataIndex="electricity_price"
          title={translate("pages.financialManagement.bills.electricityPrice")}
          key="electricity_price"
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />
        <Table.Column
          dataIndex="electricity_units"
          title={translate("pages.financialManagement.bills.electricityUnits")}
        />
        <Table.Column
          dataIndex="water_price"
          title={translate("pages.financialManagement.bills.waterPrice")}
          key="water_price"
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />
        <Table.Column
          dataIndex="water_units"
          title={translate("pages.financialManagement.bills.waterUnits")}
        />
        <Table.Column
          dataIndex="parking_price"
          title={translate("pages.financialManagement.bills.parkingPrice")}
          key="parking_price"
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />
        <Table.Column
          dataIndex="parking_units"
          title={translate("pages.financialManagement.bills.parkingUnits")}
        />
        <Table.Column
          dataIndex="management_price"
          title={translate("pages.financialManagement.bills.managementPrice")}
          key="management_price"
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />
        <Table.Column
          dataIndex="internet_price"
          title={translate("pages.financialManagement.bills.internetPrice")}
          key="internet_price"
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />
        <Table.Column
          dataIndex="contract_id"
          title={translate("pages.financialManagement.bills.contractID")}
        />
        <Table.Column
          title={translate("pages.financialManagement.bills.status")}
          dataIndex="status"
          render={(value) => {
            // return <TextField  value={handleTranslate(value)} />;
            return (
              <TextField
                strong
                value={
                  value === "paid" ? (
                    <Tag bordered={false} color="success">
                      {handleTranslate(value)}
                    </Tag>
                  ) : (
                    <>
                      {value === "pending" ? (
                        <Tag bordered={false} color="yellow">
                          {handleTranslate(value)}
                        </Tag>
                      ) : (
                        <Tag bordered={false} color="red">
                          {handleTranslate(value)}
                        </Tag>
                      )}
                    </>
                  )
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="from_date"
          title={translate("pages.financialManagement.bills.fromDate")}
        />
        <Table.Column
          dataIndex="to_date"
          title={translate("pages.financialManagement.bills.toDate")}
        />
        <Table.Column<IListBills>
          title={translate("table.actions")}
          dataIndex="actions"
          fixed="right"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
