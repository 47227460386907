export const TYPE_ROOM = [
  { label: "const.duplex", value: "duplex", isChecked: false },
  {
    label: "const.studio",
    value: "studio",
    isChecked: false,
  },
  {
    label: "const.oneBedroom",
    value: "one_bedroom",
    isChecked: false,
  },
  {
    label: "const.twoBedroom",
    value: "two_bedroom",
    isChecked: false,
  },
];

export const TYPE_FURNITURE = [
  { label: "const.empty", value: "empty" },
  { label: "const.basic", value: "basic" },
  { label: "const.full", value: "full" },
];
export const CATEGORIES_FURNITURE_FULL = [
  { label: "const.fridge", value: "fridge" },
  { label: "const.airConditioner", value: "air_conditioner" },
  { label: "const.waterHeater", value: "water_heater" },
  { label: "const.bed", value: "bed" },
  { label: "const.mattress", value: "mattress" },
  { label: "const.kitchenShelf", value: "kitchen_shelf" },
  { label: "const.sink", value: "sink" },
  { label: "const.wardrobe", value: "wardrobe" },
];

export const CATEGORIES_FURNITURE_OPTIONAL = [
  { label: "const.television", value: "television" },
  { label: "const.washingMachine", value: "washing_machine" },
  {
    label: "const.inRoomWashingMachine",
    value: "in_room_washing_machine",
  },
];

export const RANGE_PRICE = [
  { from: 4000000, to: 5900000, isChecked: false },
  { from: 6000000, to: 7900000, isChecked: false },
  { from: 8000000, to: 9900000, isChecked: false },
  { from: 10000000, to: 14000000, isChecked: false },
];

export const STATUS_WINDOW = [
  { label: "status.yes", value: true, isChecked: false },
  {
    label: "status.no",
    value: false,
    isChecked: false,
  },
];

export const STATUS_BALCONIES = [
  { label: "status.yes", value: true, isChecked: false },
  {
    label: "status.no",
    value: false,
    isChecked: false,
  },
];
