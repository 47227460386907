import { useTranslate } from "@refinedev/core";
import { Button, Checkbox, Input, Modal, Radio } from "antd";

import "./styles.css";

interface IFilterNormal {
  isHaveSearchBar?: boolean;
  valueSearch?: string;
  setValueSearch?: (value: string) => void;
  placeholderSearch?: string;
  isShowLabelSelected?: boolean;
  checkedItemCount?: number;
  isOpen: boolean;
  setIsOpen: (value: any) => void;
  title: string;
  data: any;
  onCheck: (value: any) => void;
  onClear: () => void;
  multiple: boolean;
  setSelectedValue?: (value: any) => void;
}

export const FilterNormal = ({
  isHaveSearchBar,
  valueSearch,
  setValueSearch,
  placeholderSearch,
  isShowLabelSelected,
  checkedItemCount,
  isOpen,
  setIsOpen,
  title,
  data,
  onCheck,
  onClear,
  multiple,
  setSelectedValue,
}: IFilterNormal) => {
  const translate = useTranslate();

  const handleValueChecked = () => {
    if (multiple) {
      const listChecked = [];
      for (let i = 0; i < data?.length; i++) {
        if (data[i].isChecked) {
          listChecked.push(data[i].value);
        }
      }
      return listChecked;
    } else {
      const itemChecked = data?.find((item: any) => item.isChecked);
      return itemChecked?.value;
    }
  };

  return (
    <Modal
      open={isOpen}
      title={title}
      onCancel={() => setIsOpen(false)}
      footer={[
        <Button
          key="clear"
          danger
          onClick={() => {
            setSelectedValue?.(null);
            onClear();
            setIsOpen(false);
          }}
        >
          {translate("buttons.clear")}
        </Button>,
        <Button key="seeResult" type="primary" onClick={() => setIsOpen(false)}>
          {translate("buttons.seeResult")}
        </Button>,
      ]}
      className="filterNormal"
    >
      {isHaveSearchBar ? (
        <div>
          <Input
            allowClear
            value={valueSearch}
            onChange={(e) => setValueSearch?.(e.target.value)}
            placeholder={placeholderSearch}
          />
        </div>
      ) : null}
      {isShowLabelSelected ? (
        <div
          style={{ padding: "10px 0 0", fontWeight: "700", fontSize: "18px" }}
        >
          <label htmlFor="">
            {translate("placeholder.numberOfSelectedBuildings")}
          </label>
          <label htmlFor="">{checkedItemCount}</label>
        </div>
      ) : null}
      <div className="listItem">
        {multiple ? (
          <Checkbox.Group
            onChange={(e) => onCheck(e)}
            value={handleValueChecked()}
          >
            {data?.map((item: any, index: number) => (
              <div className="filterWrapper" key={index}>
                <Checkbox
                  className={"filterItem"}
                  value={item.value}
                  checked={item.isChecked}
                  onChange={() => {
                    setSelectedValue?.(item.value);
                  }}
                >
                  {translate(item.label)}
                </Checkbox>
              </div>
            ))}
          </Checkbox.Group>
        ) : (
          <Radio.Group
            onChange={(e) => onCheck(e.target.value)}
            value={handleValueChecked()}
          >
            {data?.map((item: any, index: number) => (
              <div className="filterWrapper" key={index}>
                <Radio
                  className={"filterItem"}
                  value={item.value}
                  checked={item.isChecked}
                >
                  {translate(item.label)}
                </Radio>
              </div>
            ))}
          </Radio.Group>
        )}
        {isHaveSearchBar && data?.length === 0 ? (
          <div>{translate("placeholder.noResultFoundBuilding")}</div>
        ) : null}
      </div>
    </Modal>
  );
};
