import { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input, Select } from "antd";

import { IListBuildings, IListBuildingGroups } from "interfaces";
import { STATUS_ACTIVE_INACTIVE } from "const";
import { handleFilterOption, handleSortOption } from "helper";

export const BuildingGroupsCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, onFinish, form } =
    useForm<IListBuildingGroups>();
  const [optionsBuildings, setOptionsBuildings] = useState<any>([]);
  const [selectedItemsBuildings, setSelectedItemsBuildings] = useState<
    string[]
  >([]);

  // get Buildings data
  const { data: buildingsData } = useList<IListBuildings>({
    resource: "buildings",
    errorNotification: false,
  });

  const handleOnFinish: any = (values: IListBuildings) => {
    onFinish({
      ...values,
    });
  };

  useEffect(() => {
    if (buildingsData) {
      const optionsBuildingsData: any = buildingsData?.data;
      setOptionsBuildings(
        optionsBuildingsData?.data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      );
    }
  }, [buildingsData]);

  const filteredOptionsBuildings = optionsBuildings.filter(
    (o: any) => !selectedItemsBuildings.includes(o)
  );

  return (
    <Create
      title={translate(
        "pages.cartManagement.saleRightsManagement.buildingGroups.createNewBuildingGroups"
      )}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        layout="vertical"
        initialValues={{
          status: "active",
        }}
        form={form}
      >
        <Form.Item
          name="name"
          label={translate(
            "pages.cartManagement.saleRightsManagement.buildingGroups.name"
          )}
          rules={[
            {
              required: true,
              message: translate("validations.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="building_ids"
          label={translate(
            "pages.cartManagement.saleRightsManagement.buildingGroups.buildings"
          )}
          rules={[
            {
              required: true,
              message: translate("validations.buildings"),
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={translate("placeholder.selectAOneOrManyBuildings")}
            value={selectedItemsBuildings}
            onChange={setSelectedItemsBuildings}
            options={filteredOptionsBuildings}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <Form.Item
          name="status"
          label={translate(
            "pages.cartManagement.saleRightsManagement.buildingGroups.status"
          )}
          rules={[
            {
              required: true,
              message: translate("validations.status"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectStatus")}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
            options={STATUS_ACTIVE_INACTIVE?.map((item) => {
              return {
                label: translate(item.label),
                value: item.value,
              };
            })}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
