export const TYPES_LANDLORD_TRANSACTIONS = [
  // [ deposit, rental-bill, maintenance, refund-deposit, electricity-bill, water-bill, tax, other ]
  {
    value: "deposit",
    label: "status.deposit",
    isChecked: false,
  },
  {
    value: "rental-bill",
    label: "status.rentalBill",
    isChecked: false,
  },
  {
    value: "maintenance",
    label: "status.maintenance",
    isChecked: false,
  },
  {
    value: "refund-deposit",
    label: "status.refundDeposit",
    isChecked: false,
  },
  {
    value: "electricity-bill",
    label: "status.electricityBill",
    isChecked: false,
  },
  {
    value: "water-bill",
    label: "status.waterBill",
    isChecked: false,
  },
  {
    value: "tax",
    label: "status.tax",
    isChecked: false,
  },
  {
    value: "other",
    label: "status.other",
    isChecked: false,
  },
];

export const CASH_FLOW_LANDLORD_TRANSACTIONS = [
  {
    value: "cash-in",
    label: "status.cashIn",
    isChecked: false,
  },
  {
    value: "cash-out",
    label: "status.cashOut",
    isChecked: false,
  },
];
