import { useEffect, useState } from "react";
import axios from "axios";
import {
  IResourceComponentsProps,
  useCustomMutation,
  useList,
  useTranslate,
} from "@refinedev/core";
import { EditButton, TextField, useTable } from "@refinedev/antd";
import { Edit, SaveButton, useForm } from "@refinedev/antd";

import {
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Select,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import { usePermissions } from "@refinedev/core";
import "react-mde/lib/styles/css/react-mde-all.css";
import {
  IListApartments,
  IListApointments,
  IListUser,
  IMoneyTransferRequest,
} from "interfaces";
import { STATUS_OPTIONS, STATUS_OPTIONS_LIMIT } from "const";

import dayjs from "dayjs";
import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_FINANCE,
  ROLE_SALE,
  ROLE_TEAM_LEADER,
  formatDateMonthYear,
  formatHour,
  formatPrice,
  formatTime,
  handleFilterOption,
  handleSortOption,
  isToday,
  parsePhoneToVN,
  parsePhoneToVNWithoutSpace,
} from "helper";
import { ModalConfirm, MoneyTransaction } from "components/modal";
import { API_URL } from "api";
import {
  handleStatusRoleSale,
  handleTranslateType,
  statusColors,
} from "./constant";

const { Title } = Typography;

export const AppointmentsEdit: React.FC<IResourceComponentsProps> = (props) => {
  const { formProps, queryResult, saveButtonProps, onFinish, form, redirect } =
    useForm<IListApointments>({
      resource: "appointments",
      redirect: false,
    });
  const appointmentsData: any = queryResult?.data?.data ?? "";

  // get apartments data
  const { data: apartmentsData } = useList<IListApartments>({
    resource: "apartments?status=vacant,near_vacant&",
    errorNotification: false,
  });

  const { mutate: transactionMoney, isLoading } =
    useCustomMutation<IListApartments>();

  const { mutate: patchAppointment } = useCustomMutation<IListApointments>();

  // get Sale data
  const { data: salesData } = useList<IListUser>({
    resource: "users?role_ids=4,5",
    errorNotification: false,
  });

  // get Admin data
  const { data: adminsData } = useList<IListUser>({
    resource: "users?role_ids=1,2",
    errorNotification: false,
  });

  // get customers data
  const { data: customersData } = useList<IListUser>({
    resource: "customers",
    errorNotification: false,
  });

  // get request transfer data
  const { tableProps, setFilters } = useTable<IMoneyTransferRequest>({
    resource: `transactions/?appointment_id=${appointmentsData?.data?.id}&`,
  });

  const dataSource: any = tableProps?.dataSource;

  const dataTransactions = dataSource?.data?.filter(
    (item: any) => item.appointment?.id === appointmentsData?.data?.id
  );

  const dataTransactionsFormProps = {
    ...tableProps,
    dataSource: dataTransactions ? [...dataTransactions] : [],
  };

  const sortedData = dataTransactionsFormProps?.dataSource?.sort(
    (a, b) =>
      new Date(b?.created_at).getTime() - new Date(a.created_at).getTime()
  );
  const dataTransactionsTableProps = {
    ...dataTransactionsFormProps,
    dataSource: sortedData,
  };

  const { data: permissionsData }: any = usePermissions();
  const permissions = usePermissions<string>();
  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissions?.data === ROLE_DATA_ADMIN;
  const isLoggedbyTeamLeader = permissions?.data === ROLE_TEAM_LEADER;
  const isLoggedbySale = permissions?.data === ROLE_SALE;
  const isLoggedbyFinance = permissions?.data === ROLE_FINANCE;
  const user = localStorage.getItem("auth");
  const { id } = JSON.parse(user || "");
  const translate = useTranslate();

  const [optionsSales, setOptionsSales] = useState<[]>();
  const [optionsApartments, setOptionsApartments] = useState<[]>([]);
  const [optionsCustomers, setOptionsCustomers] = useState<[]>([]);
  const [optionsSalesSupport, setOptionsSalesSupport] = useState<[]>([]);
  const [selectedItemsApartments, setSelectedItemsApartments] = useState<
    string[]
  >(appointmentsData?.data?.apartments?.map((item: any) => item.id));
  const [selectedItemsSalesSupport, setSelectedItemsSalesSupport] = useState<
    string[]
  >(appointmentsData?.data?.supported_sales?.map((item: any) => item.id));
  const [optionsAdminsSupport, setOptionsAdminsSupport] = useState<[]>([]);
  const [selectedItemsAdminsSupport, setSelectedItemsAdminsSupport] = useState<
    string[]
  >(appointmentsData?.data?.supportAdmins?.map((item: any) => item.id));
  const [selectedDateTime, setSelectedDateTime] = useState<any>(
    appointmentsData?.data?.time
  );
  const [selectedCheckInDate, setSelectedCheckInDate] = useState<any>(
    appointmentsData?.data?.check_in_date
  );
  const [selectedDepositCompletionDate, setSelectedDepositCompletionDate] =
    useState<any>(appointmentsData?.data?.complete_deposit_date);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [amount, setAmount] = useState<any>("");
  const [status, setStatus] = useState<string>("processing");
  const [term, setTerm] = useState<any>("");
  const [notes, setNotes] = useState<string>("");
  const [type, setType] = useState<string>("deposit");
  const [zalo, setZalo] = useState<string>(
    appointmentsData?.data?.customer?.phone
  );
  const [fullName, setFullName] = useState<string>(
    appointmentsData?.data?.customer?.full_name
  );
  const [currentIdTransactions, setCurrentIdTransactions] = useState<any>();
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [statusInitial, setStatusInitial] = useState<string>("");
  const [disableChange, setDisableChange] = useState<boolean>(false);

  const statusAppointment = formProps?.initialValues?.data?.status;

  const statusRoleSale = handleStatusRoleSale(statusAppointment);

  const onChangeDate = (value: DatePickerProps["value"]) => {
    setSelectedDateTime(value?.toISOString());
  };

  const onChangeCheckInDate = (value: DatePickerProps["value"]) => {
    setSelectedCheckInDate(value?.toISOString());
  };

  const onChangeDepositCompletionDate = (value: DatePickerProps["value"]) => {
    setSelectedDepositCompletionDate(value?.toISOString());
  };

  const currentDate = new Date().getTime();
  const currentSelectDateTime = new Date(selectedDateTime).getTime();
  const currentSelectCheckInDate = new Date(selectedCheckInDate).getTime();
  const currentSelectDepositCompletionDate = new Date(
    selectedDepositCompletionDate
  ).getTime();

  const handleOnFinish: any = async (values: IListApartments) => {
    if (
      (isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyFinance) &&
      (statusAppointment === "partial_deposit" ||
        statusAppointment === "fulfilled_deposit") &&
      values?.status === "cancel_deposit_fee"
    ) {
      if (selectedItemsApartments?.length === 1) {
        setDisableChange(true);
        setIsEditModal(false);
        setIsOpenModal(true);
        setType("cancel_deposit");
        setStatus("processing");
      } else if (selectedItemsApartments?.length > 1) {
        message.error(translate("validations.apartmentsOnlySelectOne"));
      }
    } else {
      if (
        (isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyFinance) &&
        statusAppointment === "fulfilled_deposit" &&
        values?.status === "checked_in"
      ) {
        if (selectedItemsApartments?.length === 1) {
          setDisableChange(true);
          setIsEditModal(false);
          setIsOpenModal(true);
          setType("commission");
          setStatus("processing");
        } else if (selectedItemsApartments?.length > 1) {
          message.error(translate("validations.apartmentsOnlySelectOne"));
        }
      } else {
        await onFinish({
          ...values,
          time: selectedDateTime,
          check_in_date: selectedCheckInDate,
          apartment_ids: selectedItemsApartments,
          term: term ? Number(term) : null,
          complete_deposit_date: selectedDepositCompletionDate,
          supported_sale_ids: selectedItemsSalesSupport,
          supportAdminIds: selectedItemsAdminsSupport,
        });
        redirect("list");
      }
    }
  };

  const onTransactionMoney = () => {
    if (!amount) {
      message.error(translate("validations.amountMoneyMustBeMoreThanOrEqual0"));
      return;
    }
    if (!term) {
      message.error(translate("validations.term"));
      return;
    }
    if (!selectedDepositCompletionDate && type === "deposit") {
      message.error(translate("validations.depositCompletionDate"));
      return;
    }
    if (!selectedCheckInDate && type === "deposit") {
      message.error(translate("validations.checkInDate"));
      return;
    }
    if (!zalo?.trim() && type === "deposit") {
      message.error(translate("validations.zalo"));
      return;
    }

    transactionMoney(
      {
        url: isEditModal
          ? `${API_URL}/transactions/${currentIdTransactions}`
          : `${API_URL}/transactions`,
        method: isEditModal ? "patch" : "post",
        values:
          type === "deposit"
            ? {
                appointment_id: appointmentsData?.data?.id,
                apartment_id:
                  selectedItemsApartments?.length === 1
                    ? selectedItemsApartments[0]
                    : null,
                type: type,
                amount: amount,
                zalo: zalo,
                fullName: fullName,
                term: term ? Number(term) : null,
                check_in_date: selectedCheckInDate,
                complete_deposit_date: selectedDepositCompletionDate,
                notes: notes,
                status: status,
              }
            : {
                appointment_id: appointmentsData?.data?.id,
                apartment_id:
                  selectedItemsApartments?.length === 1
                    ? selectedItemsApartments[0]
                    : null,
                type: type,
                amount: amount,
                term: term ? Number(term) : null,
                notes: notes,
                status: status,
              },
      },
      {
        onError: (error) => {
          error?.response?.data?.data?.errors.map((error: any) => {
            message.error(error?.message);
          });
        },
        onSuccess: (data: any) => {
          if (data?.data?.status ?? data?.data?.data?.status) {
            message.success(
              translate("notifications.transactionsMoneySuccess")
            );
          }

          setIsOpenModal(false);
          const rand = new Date().getTime(); //Tips to refresh the table money transfer request
          setFilters([{ field: "rand", operator: "eq", value: rand }]);
          setDisableChange(false);
          setType("deposit");
          if (
            type === "deposit" &&
            status === "completed" &&
            status !== statusInitial
          ) {
            setIsOpenConfirmModal(true);
          } else {
            queryResult?.refetch();
          }
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
      }
    );
  };

  const handleUpdateCompletedAppointmentStatus: any = async (values: any) => {
    await onFinish({
      apartment_ids: values?.apartments?.map((item: any) => item.id),
      check_in_date: values?.check_in_date,
      complete_deposit_date: values?.complete_deposit_date,
      customer_id: values?.customer?.id,
      sale_id: values?.sale_id,
      status: "fulfilled_deposit",
      supported_sale_ids: values?.supported_sales?.map((item: any) => item.id),
      supportAdminIds: values?.supportAdmins?.map((item: any) => item.id),
      time: values?.time,
    });
    setIsOpenConfirmModal(false);
    redirect("list");
  };

  const getTransactions = async (id: any) => {
    return await axios
      .get(`${API_URL}/transactions/${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((data) => data.data)
      .catch((error) => {
        console.error(error);
      });
  };

  const filteredOptionsApartments = optionsApartments?.filter(
    (o: any) => !selectedItemsApartments?.includes(o)
  );

  const filteredOptionsSalesSupport = optionsSalesSupport?.filter(
    (o: any) => !selectedItemsSalesSupport?.includes(o)
  );

  const filteredOptionsAdminsSupport = optionsAdminsSupport?.filter(
    (o: any) => !selectedItemsAdminsSupport?.includes(o)
  );

  useEffect(() => {
    if (apartmentsData) {
      const optionsApartmentsData: any = apartmentsData?.data;
      setOptionsApartments(
        optionsApartmentsData?.data?.map((item: any) => {
          return {
            value: item?.id,
            label: item?.name + ` (${item?.building?.name})`,
          };
        })
      );
    }
  }, [apartmentsData]);

  useEffect(() => {
    if (customersData) {
      const optionsCustomersData: any = customersData?.data;
      setOptionsCustomers(
        optionsCustomersData?.data?.map((item: any) => {
          return {
            value: item?.id,
            label: item?.full_name,
            subLabel:
              parsePhoneToVN(item?.phone) +
              " " +
              parsePhoneToVNWithoutSpace(item?.phone) +
              " " +
              item?.phone,
          };
        })
      );
    }
  }, [customersData]);

  useEffect(() => {});

  useEffect(() => {
    if (salesData) {
      const salesOptionsData: any = salesData?.data;
      setOptionsSales(
        salesOptionsData?.data?.map((item: any) => {
          return {
            value: item.id,
            label: item.full_name,
          };
        })
      );
      setOptionsSalesSupport(
        salesOptionsData?.data?.map((item: any) => {
          return {
            value: item.id,
            label: item.full_name,
          };
        })
      );
    }
  }, [salesData]);

  useEffect(() => {
    if (adminsData) {
      const adminsOptionsData: any = adminsData?.data;
      setOptionsAdminsSupport(
        adminsOptionsData?.data?.map((item: any) => {
          return {
            value: item.id,
            label: item.full_name,
          };
        })
      );
    }
  }, [adminsData]);

  useEffect(() => {
    if (appointmentsData) {
      if (appointmentsData?.data?.time) {
        setSelectedDateTime(appointmentsData?.data?.time);
      }

      if (appointmentsData?.data?.check_in_date) {
        setSelectedCheckInDate(appointmentsData?.data?.check_in_date);
      }

      if (appointmentsData?.data?.apartments?.length > 0) {
        setSelectedItemsApartments(
          appointmentsData?.data?.apartments?.map((item: any) => item.id)
        );
      }
      if (appointmentsData?.data?.customer?.phone) {
        setZalo(appointmentsData?.data?.customer?.phone);
      }
      if (appointmentsData?.data?.customer?.full_name) {
        setFullName(appointmentsData?.data?.customer?.full_name);
      }
      if (appointmentsData?.data?.complete_deposit_date) {
        setSelectedDepositCompletionDate(
          appointmentsData?.data?.complete_deposit_date
        );
      }
      if (appointmentsData?.data?.term) {
        setTerm(appointmentsData?.data?.term);
      }
      if (appointmentsData?.data?.supported_sales) {
        setSelectedItemsSalesSupport(
          appointmentsData?.data?.supported_sales?.map((item: any) => item.id)
        );
      }
      if (appointmentsData?.data?.supportAdmins) {
        setSelectedItemsAdminsSupport(
          appointmentsData?.data?.supportAdmins?.map((item: any) => item.id)
        );
      }
    }
  }, [appointmentsData]);

  const handleOpenModalEditTransaction = (currentIdTransactions: any) => {
    getTransactions(currentIdTransactions).then((response: any) => {
      if (response?.status) {
        setAmount(response?.data?.amount);
        setType(response?.data?.type);
        setStatus(response?.data?.status);
        setTerm(response?.data?.term);
        setNotes(response?.data?.notes);
        setStatusInitial(response?.data?.status);
        setIsOpenModal(true);
        setIsEditModal(true);
      } else {
        message.error(response?.message);
      }
    });
  };

  // const handleChangeApartments = (newApartments: any) => {
  //   const values = formProps?.initialValues?.data;
  //   patchAppointment(
  //     {
  //       url: `${API_URL}/appointments/${values?.id}`,
  //       method: "patch",
  //       values: {
  //         apartment_ids: newApartments,
  //         check_in_date: values?.check_in_date,
  //         complete_deposit_date: values?.complete_deposit_date,
  //         customer_id: values?.customer?.id,
  //         sale_id: values?.sale_id,
  //         status: values?.status,
  //         supported_sale_ids: values?.supported_sales?.map(
  //           (item: any) => item.id
  //         ),
  //         time: values?.time,
  //       },
  //     },
  //     {
  //       onError: (error) => {
  //         message.error(error?.response?.data?.data?.errors[0]?.message);
  //       },
  //       onSuccess: () => {
  //         setSelectedItemsApartments(newApartments);
  //       },
  //     }
  //   );
  // };

  return (
    <Edit
      {...props}
      title={translate("pages.customerManagement.appointments.editAppointment")}
      canDelete={permissionsData?.includes["admin"]}
      headerButtons={({ listButtonProps, refreshButtonProps }) => (
        <>
          <Button
            onClick={() => {
              setAmount("");
              if (selectedItemsApartments?.length === 1) {
                setIsOpenModal(true);
                setIsEditModal(false);
              } else if (selectedItemsApartments?.length > 1) {
                message.error(translate("validations.apartmentsOnlySelectOne"));
              }
            }}
            disabled={
              isLoggedbySale &&
              statusAppointment !== "pending" &&
              statusAppointment !== "customer_changed_plan" &&
              statusAppointment !== "seen_waiting" &&
              statusAppointment !== "partial_deposit"
            }
          >
            {translate(
              "pages.customerManagement.appointments.createTransaction"
            )}
          </Button>
          {isOpenModal && (
            <MoneyTransaction
              isOpen={isOpenModal}
              setIsOpen={setIsOpenModal}
              title={
                isEditModal
                  ? translate(
                      "pages.customerManagement.appointments.editTransaction"
                    )
                  : translate(
                      "pages.customerManagement.appointments.createTransaction"
                    )
              }
              amount={amount}
              setAmount={setAmount}
              status={status}
              setStatus={setStatus}
              term={term}
              setTerm={setTerm}
              notes={notes}
              setNotes={setNotes}
              type={type}
              setType={setType}
              loading={isLoading}
              zalo={zalo}
              setZalo={setZalo}
              fullName={fullName}
              setFullName={setFullName}
              depositCompletionDate={selectedDepositCompletionDate}
              onChangeDepositCompletionDate={onChangeDepositCompletionDate}
              checkInDate={selectedCheckInDate}
              onClick={onTransactionMoney}
              onChangeCheckInDate={onChangeCheckInDate}
              isEdit={isEditModal}
              disableChange={disableChange}
              setDisableChange={setDisableChange}
            />
          )}
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} />
        </>
      )}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        layout="vertical"
        initialValues={{
          ...appointmentsData?.data,
          apartment_ids: appointmentsData?.data?.apartments?.map(
            (item: any) => {
              return {
                value: item.id,
                label: item.name + ` (${item?.building?.name})`,
              };
            }
          ),
          customer_id: appointmentsData?.data?.customer?.id,
          supported_sale_ids: appointmentsData?.data?.supported_sales?.map(
            (item: any) => {
              return {
                value: item.id,
                label: item.full_name,
              };
            }
          ),
          supportAdminIds: appointmentsData?.data?.supportAdmins?.map(
            (item: any) => {
              return {
                value: item.id,
                label: item.full_name,
              };
            }
          ),
        }}
        form={form}
      >
        <Form.Item
          name="apartment_ids"
          label={translate("pages.customerManagement.appointments.apartments")}
          rules={[
            {
              required: true,
              message: translate("validations.apartments"),
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={translate("placeholder.selectAOneOrManyApartments")}
            value={selectedItemsApartments}
            onChange={setSelectedItemsApartments}
            options={filteredOptionsApartments}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
            disabled={dataTransactions?.length > 0}
          />
        </Form.Item>
        <div
          style={{
            minWidth: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Form.Item
            style={{ width: "100%" }}
            name="customer_id"
            label={translate(
              "pages.customerManagement.appointments.customersAlreadyHave"
            )}
            rules={[
              {
                required: true,
                message: translate("validations.customers"),
              },
            ]}
          >
            <Select
              placeholder={translate("placeholder.selectACustomer")}
              options={optionsCustomers}
              showSearch
              filterOption={(input, option: any) =>
                handleFilterOption(input, option)
              }
              filterSort={(optionA, optionB) =>
                handleSortOption(optionA, optionB)
              }
              onChange={(value) => {
                const optionsCustomersData: any = customersData?.data;
                const selectedItem = optionsCustomersData.data?.find(
                  (item: any) => item?.id === value
                );
                setZalo(selectedItem?.zalo);
              }}
            />
          </Form.Item>
          {appointmentsData?.data?.customer?.phone ? (
            <Button
              style={{ marginTop: "5px" }}
              onClick={() => {
                window.open(
                  `tel:${parsePhoneToVNWithoutSpace(
                    appointmentsData?.data?.customer?.phone
                  )}`
                );
              }}
            >
              {translate("pages.customerManagement.appointments.callCustomer")}
            </Button>
          ) : null}
        </div>

        {isLoggedbyAdmin ||
        isLoggedbyDataAdmin ||
        isLoggedbyTeamLeader ||
        appointmentsData?.data?.sale?.id !== id ? (
          <>
            <Form.Item
              name="sale_id"
              label={translate("pages.customerManagement.appointments.sale")}
              rules={[
                {
                  required: true,
                  message: translate("validations.sale"),
                },
              ]}
            >
              <Select
                disabled={
                  isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyTeamLeader
                    ? false
                    : true
                }
                placeholder={translate("placeholder.selectASale")}
                options={optionsSales}
                showSearch
                filterOption={(input, option: any) =>
                  handleFilterOption(input, option)
                }
                filterSort={(optionA, optionB) =>
                  handleSortOption(optionA, optionB)
                }
              />
            </Form.Item>
          </>
        ) : null}
        <Form.Item
          name="supported_sale_ids"
          label={translate(
            "pages.customerManagement.appointments.supportSales"
          )}
        >
          <Select
            mode="multiple"
            placeholder={translate("placeholder.selectAOneOrManySales")}
            value={selectedItemsSalesSupport}
            onChange={setSelectedItemsSalesSupport}
            options={filteredOptionsSalesSupport}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <Form.Item
          name="supportAdminIds"
          label={translate(
            "pages.customerManagement.appointments.supportAdmins"
          )}
        >
          <Select
            disabled={
              isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyFinance
                ? false
                : true
            }
            mode="multiple"
            placeholder={translate("placeholder.selectAOneOrManyAdmins")}
            value={selectedItemsAdminsSupport}
            onChange={setSelectedItemsAdminsSupport}
            options={filteredOptionsAdminsSupport}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <Form.Item
          name="time"
          label={translate("pages.customerManagement.appointments.time")}
          rules={[
            () => ({
              validator() {
                if (
                  // eslint-disable-next-line eqeqeq
                  appointmentsData?.data?.time != selectedDateTime
                ) {
                  if (!selectedDateTime) {
                    return Promise.reject(
                      new Error(translate("validations.date"))
                    );
                  }
                  if (currentDate > currentSelectDateTime) {
                    return Promise.reject(
                      new Error(
                        translate("validations.canNotChooseDateThePast")
                      )
                    );
                  }
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Space direction="vertical" size={12}>
            <DatePicker
              inputReadOnly={true}
              format={`${formatDateMonthYear} ${formatHour}`}
              defaultValue={dayjs(selectedDateTime)}
              showTime={{ format: formatHour }}
              onChange={onChangeDate}
              getPopupContainer={(trigger: any) => trigger.parentNode}
            />
          </Space>
        </Form.Item>
        <Form.Item
          name="status"
          label={translate("pages.customerManagement.appointments.status")}
          rules={[
            {
              required: true,
              message: translate("validations.status"),
            },
            () => ({
              validator(_, value) {
                if (
                  (isLoggedbyDataAdmin && value === "cancel_deposit_fee") ||
                  (isLoggedbyDataAdmin && value === "checked_in")
                ) {
                  return Promise.reject(
                    new Error(translate("validations.notAllowToAccess"))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyTeamLeader ? (
            <Select
              placeholder={translate("placeholder.selectStatus")}
              options={STATUS_OPTIONS?.map((item, idx) => {
                return {
                  label: (
                    <TextField
                      key={idx}
                      value={
                        <Tag bordered={false} color={statusColors(item.value)}>
                          {translate(item.label)}
                        </Tag>
                      }
                    />
                  ),
                  value: item?.value,
                };
              })}
            />
          ) : isLoggedbySale ? (
            <Select
              placeholder={translate("placeholder.selectStatus")}
              options={statusRoleSale?.map((item, idx) => {
                return {
                  label: (
                    <TextField
                      key={idx}
                      value={
                        <Tag bordered={false} color={statusColors(item.value)}>
                          {translate(item.label)}
                        </Tag>
                      }
                    />
                  ),
                  value: item?.value,
                };
              })}
              disabled={
                statusAppointment !== "pending" &&
                statusAppointment !== "customer_changed_plan" &&
                statusAppointment !== "seen_waiting"
              }
            />
          ) : (
            <Select
              placeholder={translate("placeholder.selectStatus")}
              options={STATUS_OPTIONS_LIMIT?.map((item) => {
                return {
                  label: translate(item.label),
                  value: item.value,
                };
              })}
            />
          )}
        </Form.Item>

        <Form.Item
          name="complete_deposit_date"
          label={translate(
            "pages.customerManagement.appointments.depositCompletionDate"
          )}
          rules={[
            () => ({
              validator() {
                if (
                  // eslint-disable-next-line eqeqeq
                  appointmentsData?.data?.complete_deposit_date !=
                  selectedDepositCompletionDate
                ) {
                  if (!selectedDepositCompletionDate) {
                    return Promise.reject(
                      new Error(translate("validations.date"))
                    );
                  }
                  if (
                    !isToday(selectedDepositCompletionDate) &&
                    currentDate > currentSelectDepositCompletionDate
                  ) {
                    return Promise.reject(
                      new Error(
                        translate("validations.canNotChooseDateThePast")
                      )
                    );
                  }
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Space direction="vertical" size={12}>
            <DatePicker
              inputReadOnly={true}
              format={formatDateMonthYear}
              onChange={onChangeDepositCompletionDate}
              value={
                selectedDepositCompletionDate
                  ? dayjs(selectedDepositCompletionDate)
                  : null
              }
              placeholder={translate("placeholder.selectDate")}
              showTime={false}
              getPopupContainer={(trigger: any) => trigger.parentNode}
            />
          </Space>
        </Form.Item>
        <Form.Item
          name="check_in_date"
          label={translate("pages.customerManagement.appointments.checkInDate")}
          rules={[
            () => ({
              validator() {
                if (
                  // eslint-disable-next-line eqeqeq
                  appointmentsData?.data?.check_in_date != selectedCheckInDate
                ) {
                  if (!selectedCheckInDate) {
                    return Promise.reject(
                      new Error(translate("validations.date"))
                    );
                  }
                  if (
                    !isToday(selectedCheckInDate) &&
                    currentDate > currentSelectCheckInDate
                  ) {
                    return Promise.reject(
                      new Error(
                        translate("validations.canNotChooseDateThePast")
                      )
                    );
                  }
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Space direction="vertical" size={12}>
            <DatePicker
              inputReadOnly={true}
              format={formatDateMonthYear}
              onChange={onChangeCheckInDate}
              value={selectedCheckInDate ? dayjs(selectedCheckInDate) : null}
              placeholder={translate("placeholder.selectDate")}
              getPopupContainer={(trigger: any) => trigger.parentNode}
            />
          </Space>
        </Form.Item>
        <Form.Item
          name="term"
          label={translate("pages.customerManagement.appointments.term")}
        >
          <Input
            type="number"
            prefix={translate("placeholder.numberOfMonth") + ":"}
            value={term}
            onChange={(e: any) => setTerm(e.target.value)}
            allowClear
            placeholder={translate("placeholder.pleaseEnterMonth")}
          />
        </Form.Item>
        <Form.Item
          name="notes"
          label={translate("pages.customerManagement.appointments.notes")}
        >
          <Input placeholder={translate("placeholder.pleaseEnterNotes")} />
        </Form.Item>
      </Form>

      <Title level={4}>
        {translate("pages.customerManagement.appointments.tableTransactions")}:
      </Title>

      <Table {...dataTransactionsTableProps} rowKey="id">
        <Table.Column
          dataIndex="amount"
          title={translate("pages.customerManagement.appointments.amount")}
          key="amount"
          render={(value) => {
            return formatPrice(value);
          }}
        />
        <Table.Column
          dataIndex="type"
          title={translate("pages.customerManagement.appointments.type")}
          render={(value, idx: number) => {
            return (
              <TextField
                key={idx}
                value={handleTranslateType(value, translate)}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="status"
          title={translate("pages.customerManagement.appointments.status")}
          render={(value, idx: number) => {
            return (
              <TextField
                key={idx}
                value={
                  value === "processing" || value === "paid" ? (
                    <Tag bordered={false} color="yellow">
                      {translate(
                        value === "paid"
                          ? "status.paidLandlord"
                          : `status.${value}`
                      )}
                    </Tag>
                  ) : (
                    <>
                      {value === "completed" ? (
                        <Tag bordered={false} color="success">
                          {translate(`status.${value}`)}
                        </Tag>
                      ) : (
                        <Tag bordered={false} color="error">
                          {translate(`status.${value}`)}
                        </Tag>
                      )}
                    </>
                  )
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="notes"
          title={translate("pages.customerManagement.appointments.notes")}
          render={(value) => {
            return <TextField value={value} />;
          }}
        />
        <Table.Column
          dataIndex="created_at"
          title={translate("pages.createdAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column
          width={400}
          dataIndex="updated_at"
          title={translate("pages.updatedAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        {isLoggedbyAdmin || isLoggedbyDataAdmin ? (
          <Table.Column<IMoneyTransferRequest>
            title={translate("table.actions")}
            dataIndex="actions"
            fixed="right"
            render={(_, record) => {
              return (
                <Space>
                  <EditButton
                    hideText
                    size="small"
                    onClick={() => {
                      setCurrentIdTransactions(record?.id);
                      handleOpenModalEditTransaction(record?.id);
                    }}
                  />
                </Space>
              );
            }}
          />
        ) : null}
      </Table>
      {isOpenConfirmModal ? (
        <ModalConfirm
          isOpen={isOpenConfirmModal}
          title={translate("const.confirm")}
          onCancel={() => {
            setIsOpenConfirmModal(false);
          }}
          subTitle={translate(
            "pages.customerManagement.appointments.updateDealStatus"
          )}
          onConfirm={() => {
            handleUpdateCompletedAppointmentStatus(
              formProps?.initialValues?.data
            );
          }}
        />
      ) : null}
    </Edit>
  );
};
