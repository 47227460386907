import Carousel from "react-multi-carousel";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { useTranslate } from "@refinedev/core";
import { ArrowProps } from "react-multi-carousel/lib/types";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

import "react-multi-carousel/lib/styles.css";

interface CustomLeftArrowProps extends ArrowProps {
  myOwnStuff?: string;
}
interface CustomRightArrowProps extends ArrowProps {
  myOwnStuff?: string;
}

import { ButtonRowLeft, ButtonRowRight, Wrapper } from "./index.style";
import { responsiveCarousel } from "helper";
import { S3_IMG_URL } from "api";

interface ISlider {
  item?: any;
  handleOpenCurrentIndex: any;
  isRemove?: boolean;
  handleRemove?: any;
  isCopyPath?: boolean;
  onCopyPath?: any;
}

const Slider = ({
  item,
  handleOpenCurrentIndex,
  isRemove,
  handleRemove,
  isCopyPath,
  onCopyPath,
}: ISlider) => {
  const translate = useTranslate();
  if (!item) {
    return;
  }

  const CustomLeftArrow = ({ onClick }: CustomLeftArrowProps) => {
    return (
      <ButtonRowLeft onClick={onClick}>
        <GoChevronLeft />
      </ButtonRowLeft>
    );
  };

  const CustomRightArrow = ({ onClick }: CustomRightArrowProps) => {
    return (
      <ButtonRowRight onClick={onClick}>
        <GoChevronRight />
      </ButtonRowRight>
    );
  };

  return (
    <Wrapper>
      <Carousel
        key={item && item?.map((_: any, index: any) => index)}
        className="customCarousel"
        responsive={responsiveCarousel}
        autoPlay={false}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={true}
        partialVisible={false}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        removeArrowOnDeviceType={'mobile'}
      >
        {item
          ? item?.map((imageUrl: string, index: number) => {
              return (
                <div key={index}>
                  <div key={index} className="imgsWrapper">
                    <img
                      className="image"
                      src={S3_IMG_URL + "/" + imageUrl}
                      onClick={() => handleOpenCurrentIndex(index)}
                    />
                    {isCopyPath ? (
                      <Button
                        className="copyPath"
                        onClick={(e) => {
                          e.stopPropagation();
                          onCopyPath([imageUrl]);
                          message.success(
                            translate("notifications.copyImagePathSuccess")
                          );
                        }}
                        type="primary"
                      >
                        {translate("pages.cartManagement.buildings.copy")}
                      </Button>
                    ) : null}
                  </div>
                  {isRemove ? (
                    <DeleteOutlined
                      className="removeIcon"
                      onClick={() => handleRemove(imageUrl)}
                      style={{ fontSize: 20 }}
                    />
                  ) : null}
                </div>
              );
            })
          : null}
      </Carousel>
    </Wrapper>
  );
};
export default Slider;
