import { AuthBindings } from "@refinedev/core";
import axios from "axios";

import { API_URL } from "api";

const axiosInstance = axios.create();

const authProvider: AuthBindings = {
  register: async ({ phone, password, otp }) => {
    if (phone && password && otp) {
      try {
        const { data } = await axios.post(`${API_URL}/auth/register`, {
          phone: phone,
          password: password,
          otp: otp,
        });
        if (data) {
          return { data, success: true };
        }
      } catch (error: any) {
        return {
          success: false,
          error: {
            message: error.response.data.data.errors[0].message,
            name: error.message,
          },
        };
      }
    }

    return {
      success: true,
      redirectTo: "/login",
    };
  },
  login: async ({ phone, password }) => {
    if (phone && password) {
      try {
        const { data } = await axios.post(`${API_URL}/auth/login`, {
          phone: phone,
          password: password,
        });
        if (data) {
          const user = data?.data?.user;
          if (user) {
            localStorage.setItem("auth", JSON.stringify(user));
          }
          const token = data?.data?.access_token;
          localStorage.setItem("token", token);
          // This sets the authorization headers on Axios instance
          axiosInstance.defaults.headers.common = {
            Authorization: `Bearer ${token}`,
          };
          return {
            success: true,
            redirectTo: "/",
          };
        }
      } catch (error: any) {
        return {
          success: false,
          error: {
            message: error.response.data.data.errors[0].message,
            name: error.message,
          },
        };
      }
    }
    return {
      success: true,
      redirectTo: "/",
    };
  },
  forgotPassword: async ({ phone, password, otp }) => {
    if (phone && password && otp) {
      try {
        const { data } = await axios.patch(`${API_URL}/auth/reset-password`, {
          phone: phone,
          password: password,
          otp: otp,
        });
        if (data) {
          return { data, redirectTo: "/login", success: true };
        }
      } catch (error: any) {
        return {
          success: false,
          error: {
            message: error.response.data.data.errors[0].message,
            name: error.message,
          },
        };
      }
    }
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  logout: async () => {
    localStorage.removeItem("phone");
    localStorage.removeItem("token");
    localStorage.removeItem("auth");
    return {
      success: true,
      redirectTo: "/login",
    };
  },

  onError: async (error: any) => {
    return {
      success: false,
      error,
    };
  },
  check: async () =>
    localStorage.getItem("auth") && localStorage.getItem("token")
      ? {
          authenticated: true,
        }
      : {
          authenticated: false,
          error: {
            message: "Check failed",
            name: "Not authenticated",
          },
          logout: true,
          redirectTo: "/login",
        },
  getPermissions: () => {
    const user = localStorage.getItem("auth");
    if (user) {
      const { role } = JSON.parse(user);
      return role.name;
    }
    return null;
  },
  getIdentity: async () => {
    const user = localStorage.getItem("auth");
    if (user) {
      const { phone, role, full_name } = JSON.parse(user);
      return {
        roles: role,
        name: full_name,
        phone: phone,
        avatar: "https://i.pravatar.cc/300",
      };
    }

    return null;
  },
};
export default authProvider;
