import { useState } from "react";
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { EditButton, Show, TextField } from "@refinedev/antd";
import { Space, Tag, Typography } from "antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IListContracts } from "interfaces";
import { formatPrice, formatTime } from "helper";
import { handleTranslate } from "./const";
import Slider from "components/slider-image";
import CustomImageModal from "components/modal/ImageDetail";

const { Title } = Typography;

export const ContractsShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IListContracts>();
  const { data, isLoading } = queryResult;
  const record: any = data?.data;

  const [currentOpenModalImage, setCurrentOpenModalImage] =
    useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<any>(0);

  const handleCancel = () => {
    setCurrentOpenModalImage(false);
  };

  const handleOpenCurrentImageModal = (index: number) => {
    setCurrentImageIndex(index);
    setCurrentOpenModalImage(true);
  };

  return (
    <Show
      isLoading={isLoading}
      title={translate("pages.payment.contracts.showContract")}
      headerButtons={({
        listButtonProps,
        editButtonProps,
        refreshButtonProps,
      }) => (
        <>
          {editButtonProps && (
            <EditButton
              {...editButtonProps}
              title={translate("pages.payment.contracts.editContract")}
            />
          )}
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
    >
      <Space>
        <Title level={5}>
          {translate("pages.payment.contracts.reservation")}:
        </Title>
        <TextField
          value={`${record?.data?.reservation?.apartment?.name} ${record?.data?.reservation?.apartment?.building?.name}`}
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.payment.contracts.price")}:</Title>
        <TextField value={formatPrice(record?.data?.price)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.contracts.validFrom")}:
        </Title>
        <TextField value={formatTime(record?.data?.validFrom)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.payment.contracts.validTo")}:</Title>
        <TextField value={formatTime(record?.data?.validTo)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.payment.contracts.status")}:</Title>
        <TextField
          value={
            <Tag
              bordered={false}
              color={
                record?.data?.status === "active"
                  ? "success"
                  : record?.data?.status === "pending"
                  ? "yellow"
                  : record?.data?.status === "terminated"
                  ? "error"
                  : "blue"
              }
            >
              {handleTranslate(record?.data?.status, translate)}
            </Tag>
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.payment.contracts.roommates")}:
        </Title>
        <TextField
          value={record?.data?.roommates?.map((item: any) => {
            return <Tag>{item?.full_name}</Tag>;
          })}
        />
      </Space>
      <br />
      <br />
      <CustomImageModal
        open={currentOpenModalImage}
        onCancel={handleCancel}
        images={record?.data?.images}
        initialIndex={currentImageIndex}
      />
      <Slider
        item={record?.data?.images}
        handleOpenCurrentIndex={(index: number) =>
          handleOpenCurrentImageModal(index)
        }
      />
      <br />
      {record?.data?.images?.length > 0 ? <br /> : null}
    </Show>
  );
};
