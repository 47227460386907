import { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input, Select } from "antd";

import { IListUser, IListSaleGroups } from "interfaces";
import { STATUS_ACTIVE_INACTIVE } from "const";
import { handleFilterOption, handleSortOption } from "helper";

export const SaleGroupsCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, onFinish, form } =
    useForm<IListSaleGroups>();
  const [optionsSales, setOptionsSales] = useState<any>([]);
  const [selectedItemsSales, setSelectedItemsSales] = useState<string[]>([]);

  // get sales data
  const { data: salesData } = useList<IListUser>({
    resource: "users?role_ids=4,5",
    errorNotification: false,
  });
  const handleOnFinish: any = (values: IListUser) => {
    onFinish({
      ...values,
    });
  };

  useEffect(() => {
    if (salesData) {
      const optionsSalesData: any = salesData?.data;
      setOptionsSales(
        optionsSalesData?.data.map((item: any) => {
          return {
            value: item.id,
            label: item.full_name,
          };
        })
      );
    }
  }, [salesData]);

  const filteredOptionsSales = optionsSales.filter(
    (o: any) => !selectedItemsSales.includes(o)
  );

  return (
    <Create
      title={translate(
        "pages.cartManagement.saleRightsManagement.saleGroups.createNewSaleGroups"
      )}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        layout="vertical"
        initialValues={{
          status: "active",
        }}
        form={form}
      >
        <Form.Item
          name="name"
          label={translate(
            "pages.cartManagement.saleRightsManagement.saleGroups.name"
          )}
          rules={[
            {
              required: true,
              message: translate("validations.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="sale_ids"
          label={translate(
            "pages.cartManagement.saleRightsManagement.saleGroups.sales"
          )}
          rules={[
            {
              required: true,
              message: translate("validations.sale"),
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={translate("placeholder.selectAOneOrManySales")}
            value={selectedItemsSales}
            onChange={setSelectedItemsSales}
            options={filteredOptionsSales}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <Form.Item
          name="status"
          label={translate(
            "pages.cartManagement.saleRightsManagement.saleGroups.status"
          )}
          rules={[
            {
              required: true,
              message: translate("validations.status"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectStatus")}
            options={STATUS_ACTIVE_INACTIVE?.map((item) => {
              return {
                label: translate(item.label),
                value: item.value,
              };
            })}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
