import styled from "styled-components";
import { COLORS } from "styles/variables";

export const Wrapper = styled.div`
  position: relative;

  .customCarousel {
    position: unset;
    padding: 0 15px;

    li {
      padding-right: 30px;
    }
  }
`;

export const ButtonRowLeft = styled.div`
  position: absolute;
  cursor: pointer;
  left: 3rem;
  width: 3rem;
  height: 3rem;
  border: 0.5px solid ${COLORS.GRAY_2};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
  background-color: ${COLORS.WHITE};

  svg {
    font-size: 24px;

    &:hover {
      fill: ${COLORS.GRAY};
    }
  }

  &:hover {
    border: 1px solid ${COLORS.GRAY};
    box-shadow: 0 0px 10px rgb(0 0 0 / 15%);

    svg {
      fill: ${COLORS.BLACK};
    }
  }
`;

export const ButtonRowRight = styled.div`
  position: absolute;
  cursor: pointer;
  right: 3rem;
  width: 3rem;
  height: 3rem;
  border: 0.5px solid ${COLORS.GRAY_2};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
  background-color: ${COLORS.WHITE};

  svg {
    font-size: 24px;

    &:hover {
      fill: ${COLORS.GRAY};
    }
  }

  &:hover {
    border: 1px solid ${COLORS.GRAY};
    box-shadow: 0 0px 10px rgb(0 0 0 / 15%);

    svg {
      fill: ${COLORS.BLACK};
    }
  }
`;
