import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Table, Typography } from "antd";
import { usePermissions, useTranslate } from "@refinedev/core";
import { TextField } from "@refinedev/antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  AreaChart,
  Area,
  Sector,
  PieChart,
  Pie,
  LabelList,
  Label,
  Cell,
} from "recharts";

import { API_URL, getDashboard } from "api";
import {
  ROLE_ADMIN,
  ROLE_LANDLORD,
  ROLE_SALE,
  formatPrice,
  toCamelCase,
} from "helper";
import { RemiderDashboard } from "components/modal/RemiderDashboard";

const { Title } = Typography;

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const Dashboard: React.FC = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [dataDashboard, setDataDashboard] = useState<any>([]);
  const [dataAppointmentInMonth, setDataAppointmentInMonth] = useState<any>([]);
  const [dataStatusAppointmentInMonth, setDataStatusAppointmentInMonth] =
    useState<[]>([]);
  const [reminderData, setReminderData] = useState<any>([]);
  const [dataDealInMonth, setDataDealInMonth] = useState<any>([]);
  const [apartmentsBarChartAdmin, setApartmentsBarChartAdmin] = useState<any>(
    []
  );
  const [apartmentsBarChartLandlord, setApartmentsBarChartLandlord] =
    useState<any>([]);
  const [transactionsBarChartLandlord, setTransactionsBarChartLandlord] =
    useState<any>([]);

  const [salesPerformanceAdmin, setSalesPerformanceAdmin] = useState<any>([]);
  const [unpaidBillListLandlord, setUnpaidBillListLandlord] = useState<any>([]);
  const [isOpenNearCheckinDate, setIsOpenNearCheckinDate] =
    useState<boolean>(false);
  const [isOpenNearDepositDate, setIsOpenNearDepositDate] =
    useState<boolean>(false);

  const salesPerformanceTableProps = {
    dataSource: salesPerformanceAdmin,
    pagination: {
      pageSize: 10,
    },
  };
  const unpaidBillListLandlordTableProps = {
    dataSource: unpaidBillListLandlord?.data,
    pagination: {
      pageSize: 10,
    },
  };

  const breakpoint = Grid.useBreakpoint();
  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const permissions = usePermissions<string>();
  const auth = JSON.parse(localStorage.getItem("auth") || "{}");

  const isLoggedbyAdmin = auth?.role?.name === ROLE_ADMIN;
  const isLoggedbySale = auth?.role?.name === ROLE_SALE;
  const isLoggedByLandlord = auth?.role?.name === ROLE_LANDLORD;
  const currentDate = new Date();

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FF8042",
    "#a742ff",
    "#FFBB28",
    "#bfe02d",
    "#fa0707",
    "#eb6c17",
    "#33ff00",
  ];

  const onPieEnter = (_: any, index: any) => {
    setActiveIndex(index);
  };

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const fontSize = isMobile ? 10 : 14;

    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={fill}
          fontSize={fontSize}
        >
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          dominantBaseline="central"
          fill="#333"
          fontSize={fontSize}
        >
          {isMobile ? value : `${payload.name} ${value}`}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          dominantBaseline="central"
          fill="#999"
          fontSize={fontSize - 2}
        >
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  useEffect(() => {
    if (isLoggedbySale) {
      getDashboard(currentDate, ROLE_SALE).then((response) => {
        setDataDashboard(toCamelCase(response?.data));
      });
    }
    if (isLoggedbyAdmin) {
      getDashboard(currentDate, ROLE_ADMIN).then((response) => {
        setDataDashboard(toCamelCase(response?.data));
      });
    }
    if (isLoggedByLandlord) {
      getDashboard(currentDate, ROLE_LANDLORD).then((response) => {
        setDataDashboard(toCamelCase(response?.data));
      });

      // get unpaid bills list
      axios
        .get(`${API_URL}/bills?status=unpaid,partial_paid`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((data) => setUnpaidBillListLandlord(data.data))
        .catch((error) => {
          console.error(error);
        });
    }
  }, [permissions?.data]);

  useEffect(() => {
    // data role Sale
    if (dataDashboard?.dealStats) {
      setDataDealInMonth(dataDashboard?.dealStats);
    }
    if (dataDashboard?.reminderStats) {
      setReminderData(dataDashboard?.reminderStats);
    }
    if (dataDashboard?.pieChartStats) {
      setDataStatusAppointmentInMonth(dataDashboard?.pieChartStats);
    }
    if (dataDashboard?.barChartStats) {
      setDataAppointmentInMonth(
        dataDashboard?.barChartStats?.map((item: any) => {
          return {
            name: translate("pages.dashboard.week") + " " + item.name,
            [translate("pages.dashboard.appointment")]: +item.appointment,
            [translate("pages.dashboard.deal")]: +item.deal,
          };
        })
      );
    }
    // end data role sale

    // data role Admin
    if (dataDashboard?.apartmentsBarChart) {
      setApartmentsBarChartAdmin(
        dataDashboard?.apartmentsBarChart?.map((item: any) => {
          return {
            name: item.name,
            [translate("pages.dashboard.vacant")]: +item.vacant,
            [translate("pages.dashboard.total")]: +item.total,
          };
        })
      );
    }
    if (dataDashboard?.salesPerformance) {
      setSalesPerformanceAdmin(
        dataDashboard?.salesPerformance?.map((item: any) => {
          return {
            ...item,
            conversionRate:
              item?.totalCustomers && item?.totalDeals
                ? parseFloat(
                    ((item?.totalDeals / item?.totalCustomers) * 100).toFixed(2)
                  )
                : 0,
          };
        })
      );
    }
    // end data role Admin

    // data role Landlord
    if (dataDashboard?.apartmentsBarChart) {
      setApartmentsBarChartLandlord(
        dataDashboard?.apartmentsBarChart?.map((item: any) => {
          return {
            name: item.name,
            [translate("pages.dashboard.vacant")]: +item.vacant,
            [translate("pages.dashboard.total")]: +item.total,
          };
        })
      );
    }
    if (dataDashboard?.transactionsBarChart) {
      setTransactionsBarChartLandlord(
        dataDashboard?.transactionsBarChart?.map((item: any) => {
          return {
            name: item.month,
            [translate("pages.dashboard.income")]: +item.income,
            [translate("pages.dashboard.expense")]: +item.expense,
          };
        })
      );
    }

    // end data role Landlord
  }, [dataDashboard, translate]);

  useEffect(() => {
    if (isLoggedbyAdmin || isLoggedbySale || isLoggedByLandlord) {
      navigate("/");
    } else {
      navigate("/apartments");
    }
  }, [isLoggedbyAdmin, isLoggedbySale, isLoggedByLandlord]);

  return (
    <>
      {isLoggedbySale ? (
        <>
          <Typography className="fontSizeLarge fontWeightMedium">
            {translate("pages.dashboard.customer")}
          </Typography>
          <div style={{ marginBottom: 20 }}>
            <Typography className="fontSizeNormal fontWeightThin">
              {translate("pages.dashboard.guestCheckin", {
                guests: reminderData?.nearCheckInDate?.total,
              })}{" "}
              <Button
                onClick={() => setIsOpenNearCheckinDate(true)}
                style={{ borderRadius: 20 }}
              >
                {translate("pages.dashboard.show")}
              </Button>
            </Typography>
            <Typography className="fontSizeNormal fontWeightThin">
              {translate("pages.dashboard.guestArriving", {
                guests: reminderData?.nearDepositDate?.total,
              })}{" "}
              <Button
                onClick={() => setIsOpenNearDepositDate(true)}
                style={{ borderRadius: 20 }}
              >
                {translate("pages.dashboard.show")}
              </Button>
            </Typography>
          </div>
          <Typography className="fontSizeLarge fontWeightMedium">
            {translate("pages.dashboard.statisticsLast4Weeks")}
          </Typography>
          <ResponsiveContainer width="100%" height="100%" minHeight="auto">
            <div
              className="dashboardDeals"
              style={{ display: "flex", marginBottom: 20 }}
            >
              {dataDealInMonth?.map((item: any, idx: number) => {
                return (
                  <div key={idx} className="dashboardDealsItem">
                    <Title level={3}>
                      {translate(`pages.dashboard.${item?.type}`)}
                    </Title>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Title level={4} style={{ fontWeight: "normal" }}>
                          {translate("pages.dashboard.total")}
                        </Title>
                        <div style={{ fontSize: 40 }}>{item?.total}</div>
                      </div>
                      <div>
                        <div className="colorRed">
                          <div className="fontSizeNormal fontWeightThin">
                            {translate("pages.dashboard.cancelDeposit")}
                          </div>
                          <div className="fontSizeLarge">
                            {item?.cancelDeposit}
                          </div>
                        </div>
                        <div className="colorYellow">
                          <div className="fontSizeNormal fontWeightThin">
                            {translate("pages.dashboard.partialDeposit")}
                          </div>
                          <div className="fontSizeLarge fontWeightThin">
                            {item?.partialDeposit}
                          </div>
                        </div>
                        <div className="colorGreen">
                          <div className="fontSizeNormal fontWeightThin">
                            {translate("pages.dashboard.fulfilledDeposit")}
                          </div>
                          <div className="fontSizeLarge">
                            {item?.fulfilledDeposit}
                          </div>
                        </div>
                        <div className="colorGreen">
                          <div className="fontSizeNormal fontWeightThin">
                            {translate("pages.dashboard.checkedIn")}
                          </div>
                          <div className="fontSizeLarge">{item?.checkedIn}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="dashboardDealsItemSecond">
                <Title level={3}>
                  {translate("pages.dashboard.appointment")}
                </Title>
                {dataStatusAppointmentInMonth &&
                  dataStatusAppointmentInMonth?.map(
                    (item: any, index: number) => {
                      return (
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          key={index}
                        >
                          <div
                            style={{
                              width: 15,
                              height: 15,
                              backgroundColor: `${COLORS[index]}`,
                            }}
                          ></div>
                          &nbsp;{" "}
                          <div className="fontSizeNormal fontWeightThin">
                            {item.name}
                          </div>
                        </div>
                      );
                    }
                  )}
                <PieChart
                  width={isMobile ? 300 : 400}
                  height={isMobile ? 300 : 400}
                >
                  <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={dataStatusAppointmentInMonth}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                  >
                    {dataStatusAppointmentInMonth?.map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </div>
            </div>
          </ResponsiveContainer>
          <Typography className="fontSizeLarge fontWeightMedium">
            {translate("pages.dashboard.totalWeeklyAppointmentInMonth")}
          </Typography>
          <ResponsiveContainer width="100%" height="100%" minHeight={"50vh"}>
            <BarChart
              width={isMobile ? 350 : 500}
              height={300}
              data={dataAppointmentInMonth}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barSize={20}
              title=""
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey={translate("pages.dashboard.appointment")}
                fill="#8884d8"
              />
              <Bar dataKey={translate("pages.dashboard.deal")} fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </>
      ) : null}

      {isLoggedbyAdmin ? (
        <>
          <Typography className="fontSizeLarge fontWeightMedium">
            {translate("pages.dashboard.listApartmentsByDistricts")}
          </Typography>
          <ResponsiveContainer width="100%" height="100%" minHeight={"50vh"}>
            <BarChart
              width={isMobile ? 350 : 500}
              height={300}
              data={apartmentsBarChartAdmin}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barSize={20}
              title=""
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey={translate("pages.dashboard.vacant")}
                fill="#8884d8"
              />
              <Bar
                dataKey={translate("pages.dashboard.total")}
                fill="#82ca9d"
              />
            </BarChart>
          </ResponsiveContainer>

          <Typography className="fontSizeLarge fontWeightMedium">
            {translate("pages.dashboard.saleStatistics")}
          </Typography>
          <div style={{ maxHeight: "500px", overflowY: "auto" }}>
            <Table
              {...salesPerformanceTableProps}
              rowKey="saleId"
              locale={{
                triggerAsc: translate("placeholder.clickToSortAscending"),
                triggerDesc: translate("placeholder.clickToSortDescending"),
                cancelSort: translate("placeholder.cancelSort"),
              }}
              className="tableDashboard"
            >
              <Table.Column
                dataIndex="saleId"
                title={translate("pages.dashboard.saleId")}
              />
              <Table.Column
                dataIndex="fullName"
                title={translate("pages.dashboard.fullName")}
              />
              <Table.Column
                dataIndex="totalCustomers"
                title={translate("pages.dashboard.totalCustomers")}
                sorter={(a: any, b: any) => a.totalCustomers - b.totalCustomers}
                sortDirections={["ascend", "descend"]}
              />
              <Table.Column
                dataIndex="totalDeals"
                title={translate("pages.dashboard.totalDeals")}
                sorter={(a: any, b: any) => a.totalDeals - b.totalDeals}
                sortDirections={["ascend", "descend"]}
              />
              <Table.Column
                dataIndex="totalRevenue"
                title={translate("pages.dashboard.totalRevenue")}
                sorter={(a: any, b: any) => a.totalRevenue - b.totalRevenue}
                sortDirections={["ascend", "descend"]}
                render={(value) => {
                  return <TextField value={formatPrice(value)} />;
                }}
              />
              <Table.Column
                dataIndex="conversionRate"
                title={translate("pages.dashboard.conversionRate")}
                sorter={(a: any, b: any) => a.conversionRate - b.conversionRate}
                render={(value) => {
                  return <TextField value={value + "%"} />;
                }}
                sortDirections={["ascend", "descend"]}
              />
            </Table>
          </div>
        </>
      ) : null}

      {isLoggedByLandlord ? (
        <>
          <Typography className="fontSizeLarge fontWeightMedium">
            {translate("pages.dashboard.listApartmentsByBuildings")}
          </Typography>
          <ResponsiveContainer width="100%" height="100%" minHeight={"50vh"}>
            <BarChart
              width={isMobile ? 350 : 500}
              height={300}
              data={apartmentsBarChartLandlord}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barSize={20}
              title=""
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey={translate("pages.dashboard.vacant")}
                fill="#8884d8"
              />
              <Bar
                dataKey={translate("pages.dashboard.total")}
                fill="#82ca9d"
              />
            </BarChart>
          </ResponsiveContainer>
          <br />

          <Typography className="fontSizeLarge fontWeightMedium">
            {translate("pages.dashboard.incomeAndExpenditureByMonth")}
          </Typography>
          <ResponsiveContainer width="100%" height="100%" minHeight={"50vh"}>
            <BarChart
              width={isMobile ? 350 : 500}
              height={300}
              data={transactionsBarChartLandlord}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barSize={20}
              title=""
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey={translate("pages.dashboard.income")}
                fill="#8884d8"
              />
              <Bar
                dataKey={translate("pages.dashboard.expense")}
                fill="#82ca9d"
              />
            </BarChart>
          </ResponsiveContainer>
          <br />

          <Typography className="fontSizeLarge fontWeightMedium">
            {translate("pages.dashboard.unpaidBillsList")}
          </Typography>
          <div style={{ maxHeight: "500px", overflowY: "auto" }}>
            <Table
              {...unpaidBillListLandlordTableProps}
              rowKey="id"
              locale={{
                triggerAsc: translate("placeholder.clickToSortAscending"),
                triggerDesc: translate("placeholder.clickToSortDescending"),
                cancelSort: translate("placeholder.cancelSort"),
              }}
              className="tableDashboard"
            >
              <Table.Column
                dataIndex="name"
                title={translate("pages.payment.landlordBills.name")}
              />
              <Table.Column
                dataIndex="reservationId"
                title={translate("pages.payment.landlordBills.reservationId")}
              />
              <Table.Column
                dataIndex="reservation"
                title={translate("pages.payment.landlordBills.apartment")}
                render={(value) => {
                  return <TextField value={value?.apartment?.name} />;
                }}
              />
              <Table.Column
                dataIndex="reservation"
                title={translate("pages.payment.landlordBills.building")}
                render={(value) => {
                  return <TextField value={value?.apartment?.building?.name} />;
                }}
              />
              <Table.Column
                dataIndex="reservation"
                title={translate("pages.payment.landlordBills.customer")}
                render={(value) => {
                  return <TextField value={value?.customer?.full_name} />;
                }}
              />
              <Table.Column
                dataIndex="totalFee"
                title={translate("pages.payment.landlordBills.totalFee")}
                render={(value) => {
                  return <TextField value={formatPrice(value)} />;
                }}
              />
              <Table.Column
                dataIndex="paidValue"
                title={translate("pages.payment.landlordBills.paidValue")}
                render={(value) => {
                  return <TextField value={formatPrice(value)} />;
                }}
              />
            </Table>
          </div>
        </>
      ) : null}

      {/* <Title>Tổng doanh thu ước tính (theo team, sales)</Title>
      <ResponsiveContainer width="100%" height="100%" minHeight={"50vh"}>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="pt"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>

      <Title>Tổng số lượng khách đi xem phòng (theo team, sales)</Title>
      <ResponsiveContainer width="100%" height="100%" minHeight={"50vh"}>
        <div style={{ width: "100%" }}>
          <h4>Theo team</h4>
          <ResponsiveContainer width="100%" height={200}>
            <AreaChart
              width={500}
              height={200}
              data={data}
              syncId="anyId"
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="uv"
                stroke="#8884d8"
                fill="#8884d8"
              />
            </AreaChart>
          </ResponsiveContainer>

          <p>Theo Sales</p>
          <ResponsiveContainer width="100%" height={200}>
            <AreaChart
              width={500}
              height={200}
              data={data}
              syncId="anyId"
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="pt"
                stroke="#82ca9d"
                fill="#82ca9d"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </ResponsiveContainer> */}

      {/* <Title>
        Tỷ lệ chuyển đổi từ khách đi xem ={">"} giao dịch (theo team, sales)
      </Title>
      <ResponsiveContainer width="100%" height="100%" minHeight={"50vh"}>
        <div style={{ width: "100%" }}>
          <h4>Theo team</h4>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pt" fill="#8884d8" />
              <Bar dataKey="uv" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>

          <p>Theo Sales</p>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pt" fill="#8884d8" />
              <Bar dataKey="uv" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </ResponsiveContainer> */}
      {isOpenNearCheckinDate ? (
        <RemiderDashboard
          isOpen={isOpenNearCheckinDate}
          setIsOpen={setIsOpenNearCheckinDate}
          title={translate("pages.dashboard.list")}
          data={reminderData?.nearCheckInDate}
        />
      ) : null}

      {isOpenNearDepositDate ? (
        <RemiderDashboard
          isOpen={isOpenNearDepositDate}
          setIsOpen={setIsOpenNearDepositDate}
          title={translate("pages.dashboard.list")}
          data={reminderData?.nearDepositDate}
        />
      ) : null}
    </>
  );
};
