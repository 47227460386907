import {
  CrudFilters,
  IResourceComponentsProps,
  useTranslate,
  useGetIdentity,
} from "@refinedev/core";
import {
  List,
  useTable,
  TextField,
  EditButton,
  ShowButton,
  CreateButton,
} from "@refinedev/antd";
import { Button, Form, Input, Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { CaretDownOutlined, FilterFilled } from "@ant-design/icons";

import {
  formatTime,
  getParamFilter,
  hasChecked,
  onCheckFilter,
  parsePhoneToVNWithoutSpace,
} from "helper";

import { IListUser } from "interfaces";
import { STATUS_LANDLORD } from "./constant";
import { FilterNormal } from "components/modal";
import {
  getCurrentPagination,
  getFilterLandlords,
  setCurrentPagination,
  setFilterLandlords,
} from "helper/local-storage";

export const LandlordsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const filter = JSON.parse(getFilterLandlords() || "{}");
  const [keyword, setKeyword] = useState<string>(filter?.keyword || "");
  const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);
  const [listStatus, setListStatus] = useState<any>(
    filter?.listStatus || STATUS_LANDLORD
  );
  const [pagination, setPagination] = useState(
    JSON.parse(getCurrentPagination() || "{}") || {
      current: 1,
      pageSize: 10,
    }
  );

  const { data: user } = useGetIdentity<{
    roles: any;
    phone: string;
    name: string;
    avatar: string;
  }>();

  // get lardloard data role id = 6
  const { searchFormProps, tableProps, setFilters } = useTable<IListUser>({
    resource: `landlords?role_id=${user?.roles?.id}&`,
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { keyword } = params;

      filters.push({
        field: "keyword",
        operator: "eq",
        value: keyword || null,
      });
      setFilterLandlords(JSON.stringify({ ...filter, keyword: keyword }));
      return filters;
    },
  });

  const dataSource: any = tableProps?.dataSource;
  let landlordsTableProps = {
    ...tableProps,
    pagination: pagination,
    dataSource: dataSource?.data,
  };

  const handleTableChange = (pagination: any) => {
    setPagination({ current: pagination, pageSize: 10 });
  };

  useEffect(() => {
    setCurrentPagination(JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    const storedPagination = getCurrentPagination();
    if (storedPagination) {
      setPagination(JSON.parse(storedPagination));
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchFormProps.form?.submit();
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    const statusParam = getParamFilter(listStatus);
    setFilters(
      [
        {
          field: "status",
          operator: "eq",
          value: statusParam,
        },
      ],
      "merge"
    );
    setFilterLandlords(JSON.stringify({ ...filter, listStatus }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listStatus]);

  return (
    <List
      title={translate("pages.cartManagement.landlords.list")}
      headerButtons={({ createButtonProps }) => (
        <>
          <Form {...searchFormProps}>
            <Form.Item
              name="keyword"
              initialValue={keyword}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={translate("placeholder.searchByNameLandlords")}
                onChange={(e) => setKeyword(e.target.value)}
                allowClear={true}
              />
            </Form.Item>
          </Form>
          {createButtonProps && <CreateButton />}
        </>
      )}
    >
      <Space className="listFilter">
        <Button style={{ pointerEvents: "none" }}>
          <FilterFilled />
          {translate("buttons.filter")}
        </Button>
        <Button
          onClick={() => setIsOpenModalStatus(true)}
          className={hasChecked(listStatus) ? "isEnabled" : ""}
        >
          {translate("pages.cartManagement.landlords.status")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalStatus && (
          <FilterNormal
            isOpen={isOpenModalStatus}
            setIsOpen={setIsOpenModalStatus}
            title={translate("pages.cartManagement.landlords.status")}
            data={listStatus}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listStatus, false);
              setListStatus(newData);
            }}
            onClear={() => setListStatus([...STATUS_LANDLORD])}
            multiple={false}
          />
        )}
      </Space>
      <Table
        {...landlordsTableProps}
        pagination={{
          defaultPageSize: 10,
          // showSizeChanger: true,
          // pageSizeOptions: ["10", "20", "30", "40"],
          current: pagination.current,
          onChange: handleTableChange,
        }}
        rowKey="id"
        className="list-users"
      >
        <Table.Column
          dataIndex="full_name"
          title={translate("pages.cartManagement.landlords.fullName")}
        />
        <Table.Column
          dataIndex="phone"
          title={translate("pages.cartManagement.landlords.phone")}
          render={(value) => {
            return (
              <TextField
                value={value ? parsePhoneToVNWithoutSpace(value) : ""}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="second_phone"
          title={translate("pages.cartManagement.landlords.secondPhone")}
          render={(value) => {
            return (
              <TextField
                value={value ? parsePhoneToVNWithoutSpace(value) : ""}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="email"
          title={translate("pages.cartManagement.landlords.email")}
        />
        <Table.Column
          dataIndex="telegram"
          title={translate("pages.cartManagement.landlords.telegram")}
        />
        <Table.Column
          dataIndex="zalo"
          title={translate("pages.cartManagement.landlords.zalo")}
          render={(value) => {
            return (
              <TextField
                value={value ? parsePhoneToVNWithoutSpace(value) : ""}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="identity_card_number"
          title={translate("pages.cartManagement.landlords.identityCardNumber")}
        />
        <Table.Column
          dataIndex="address"
          title={translate("pages.cartManagement.landlords.address")}
        />
        <Table.Column
          dataIndex="province"
          title={translate("pages.cartManagement.landlords.province")}
          key="province"
          render={(value) => {
            return <TextField value={value?.name} />;
          }}
        />
        <Table.Column
          dataIndex="district"
          title={translate("pages.cartManagement.landlords.district")}
          key="district"
          render={(value) => {
            return <TextField value={value?.name} />;
          }}
        />
        <Table.Column
          dataIndex="ward"
          title={translate("pages.cartManagement.landlords.ward")}
          key="ward"
          render={(value) => {
            return <TextField value={value?.name} />;
          }}
        />
        <Table.Column<IListUser>
          title={translate("pages.cartManagement.landlords.status")}
          dataIndex="is_active"
          render={(value) => {
            return (
              <TextField
                strong
                value={
                  value === true ? (
                    <Tag bordered={false} color="success">
                      {translate("pages.cartManagement.landlords.active")}
                    </Tag>
                  ) : (
                    <Tag bordered={false} color="error">
                      {translate("pages.cartManagement.landlords.inactive")}
                    </Tag>
                  )
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="created_at"
          title={translate("pages.createdAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column
          width={400}
          dataIndex="updated_at"
          title={translate("pages.updatedAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column<IListUser>
          title={translate("table.actions")}
          dataIndex="actions"
          fixed="right"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
