import {
  IResourceComponentsProps,
  usePermissions,
  useTranslate,
  CrudFilters,
} from "@refinedev/core";
import {
  List,
  useTable,
  EditButton,
  TextField,
  ShowButton,
  CreateButton,
} from "@refinedev/antd";
import { Button, Form, Input, Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { CaretDownOutlined, FilterFilled } from "@ant-design/icons";
import dayjs from "dayjs";

import { IListApointments } from "interfaces";
import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_SALE,
  ROLE_TEAM_LEADER,
  formatDateMonthYear,
  formatTime,
  getParamFilter,
  hasChecked,
  onCheckFilter,
} from "helper";
import {
  STATUS_APPOINTMENT,
  STATUS_TRANSACTIONS_MONEY,
  handleTranslate,
  statusColors,
} from "./constant";
import { FilterTimeFromTo, FilterNormal, FilterTime } from "components/modal";
import {
  getCurrentPagination,
  getFilterAppointments,
  setCurrentPagination,
  setFilterAppointments,
} from "helper/local-storage";

export const AppointmentsTeamList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const permissions = usePermissions<string>();
  const filter = JSON.parse(getFilterAppointments() || "{}");
  const [keyword, setKeyword] = useState<string>(filter?.keyword || "");
  const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);
  const [isOpenModalTime, setIsOpenModalTime] = useState<boolean>(false);
  const [listStatus, setListStatus] = useState<any>(
    filter?.listStatus || STATUS_APPOINTMENT
  );
  const [fromTime, setFromTime] = useState<number>(filter?.fromTime || 0);
  const [toTime, setToTime] = useState<number>(filter?.toTime || 0);
  const [isOpenModalTransferMoneyStatus, setIsOpenModalTransferMoneyStatus] =
    useState<boolean>(false);
  const [listTransferMoneyStatus, setListTransferMoneyStatus] = useState<any>(
    filter?.listTransferMoneyStatus || STATUS_TRANSACTIONS_MONEY
  );
  const [isOpenModalCheckIn, setIsOpenModalCheckIn] = useState<boolean>(false);
  const [timeCheckIn, setTimeCheckIn] = useState<number>(
    filter?.timeCheckIn || 0
  );
  const [isOpenModalDepositCompletion, setIsOpenModalDepositCompletion] =
    useState<boolean>(false);
  const [timeDepositCompletion, setTimeDepositCompletion] = useState<number>(
    filter?.timeDepositCompletion || 0
  );
  const [pagination, setPagination] = useState(
    JSON.parse(getCurrentPagination() || "{}") || {
      current: 1,
      pageSize: 10,
    }
  );

  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissions?.data === ROLE_DATA_ADMIN;
  const isLoggedbyTeamLeader = permissions?.data === ROLE_TEAM_LEADER;
  const isLoggedbySale = permissions?.data === ROLE_SALE;
  const { searchFormProps, setFilters, tableProps } =
    useTable<IListApointments>({
      resource: "appointments?including_members=true&",
      onSearch: (params: any) => {
        const filters: CrudFilters = [];
        const { newKeyword } = params;

        filters.push({
          field: "keyword",
          operator: "eq",
          value: newKeyword || null,
        });
        setFilterAppointments(
          JSON.stringify({ ...filter, keyword: newKeyword })
        );
        return filters;
      },
    });
  const dataSource: any = tableProps?.dataSource;
  let landlordsTableProps = {
    ...tableProps,
    pagination: pagination,
    dataSource: dataSource?.data,
  };

  const handleTableChange = (pagination: any) => {
    setPagination({ current: pagination, pageSize: 10 });
  };

  useEffect(() => {
    setCurrentPagination(JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    const storedPagination = getCurrentPagination();
    if (storedPagination) {
      setPagination(JSON.parse(storedPagination));
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchFormProps.form?.submit();
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    const statusParams = getParamFilter(listStatus);
    const transferMoneyStatusParam = getParamFilter(listTransferMoneyStatus);
    setFilters(
      [
        {
          field: "status",
          operator: "eq",
          value: statusParams,
        },
        {
          field: "from_time",
          operator: "eq",
          value: fromTime ? fromTime / 1000 : null,
        },
        {
          field: "to_time",
          operator: "eq",
          value: toTime ? toTime / 1000 : null,
        },
        {
          field: "money_transfer_request_status",
          operator: "eq",
          value: transferMoneyStatusParam,
        },
        {
          field: "check_in_date",
          operator: "eq",
          value: timeCheckIn ? timeCheckIn / 1000 : null,
        },
        {
          field: "deposit_completion_date",
          operator: "eq",
          value: timeDepositCompletion ? timeDepositCompletion / 1000 : null,
        },
      ],
      "merge"
    );
    setFilterAppointments(
      JSON.stringify({
        ...filter,
        listStatus,
        fromTime,
        toTime,
        listTransferMoneyStatus,
        timeCheckIn,
        timeDepositCompletion,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listStatus,
    fromTime,
    toTime,
    listTransferMoneyStatus,
    timeCheckIn,
    timeDepositCompletion,
  ]);

  return (
    <List
      title={translate("pages.customerManagement.appointments.list")}
      headerButtons={({ createButtonProps }) => (
        <>
          <Form {...searchFormProps}>
            <Form.Item
              name="newKeyword"
              initialValue={keyword}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={translate("placeholder.searchByInfoAppointment")}
                onChange={(e) => setKeyword(e.target.value)}
                allowClear={true}
              />
            </Form.Item>
          </Form>
          <CreateButton {...createButtonProps} />
        </>
      )}
    >
      <Space className="listFilter">
        <Button style={{ pointerEvents: "none" }}>
          <FilterFilled />
          {translate("buttons.filter")}
        </Button>
        <Button
          onClick={() => setIsOpenModalStatus(true)}
          className={hasChecked(listStatus) ? "isEnabled" : ""}
        >
          {translate("pages.customerManagement.appointments.status")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalStatus && (
          <FilterNormal
            isOpen={isOpenModalStatus}
            setIsOpen={setIsOpenModalStatus}
            title={translate("pages.customerManagement.appointments.status")}
            data={listStatus}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listStatus, true);
              setListStatus(newData);
            }}
            onClear={() => setListStatus([...STATUS_APPOINTMENT])}
            multiple={true}
          />
        )}
        <Button
          onClick={() => setIsOpenModalTime(true)}
          className={fromTime || toTime ? "isEnabled" : ""}
        >
          {translate("pages.customerManagement.appointments.date")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalTime && (
          <FilterTimeFromTo
            isOpen={isOpenModalTime}
            setIsOpen={setIsOpenModalTime}
            title={translate("pages.customerManagement.appointments.date")}
            fromTime={fromTime}
            setFromTime={setFromTime}
            toTime={toTime}
            setToTime={setToTime}
          />
        )}
        <Button
          onClick={() => setIsOpenModalTransferMoneyStatus(true)}
          className={hasChecked(listTransferMoneyStatus) ? "isEnabled" : ""}
        >
          {translate(
            "pages.customerManagement.appointments.transferMoneyStatus"
          )}
          <CaretDownOutlined />
        </Button>
        {isOpenModalTransferMoneyStatus && (
          <FilterNormal
            isOpen={isOpenModalTransferMoneyStatus}
            setIsOpen={setIsOpenModalTransferMoneyStatus}
            title={translate(
              "pages.customerManagement.appointments.transferMoneyStatus"
            )}
            data={listTransferMoneyStatus}
            onCheck={(value) => {
              const newData = onCheckFilter(
                value,
                listTransferMoneyStatus,
                false
              );
              setListTransferMoneyStatus(newData);
            }}
            onClear={() =>
              setListTransferMoneyStatus([...STATUS_TRANSACTIONS_MONEY])
            }
            multiple={false}
          />
        )}
        <Button
          onClick={() => setIsOpenModalCheckIn(true)}
          className={timeCheckIn ? "isEnabled" : ""}
        >
          {translate("pages.customerManagement.appointments.checkInDate")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalCheckIn && (
          <FilterTime
            isOpen={isOpenModalCheckIn}
            setIsOpen={setIsOpenModalCheckIn}
            title={translate(
              "pages.customerManagement.appointments.checkInDate"
            )}
            time={timeCheckIn}
            setTime={setTimeCheckIn}
          />
        )}
        <Button
          onClick={() => setIsOpenModalDepositCompletion(true)}
          className={timeDepositCompletion ? "isEnabled" : ""}
        >
          {translate(
            "pages.customerManagement.appointments.depositCompletionDate"
          )}
          <CaretDownOutlined />
        </Button>
        {isOpenModalDepositCompletion && (
          <FilterTime
            isOpen={isOpenModalDepositCompletion}
            setIsOpen={setIsOpenModalDepositCompletion}
            title={translate(
              "pages.customerManagement.appointments.depositCompletionDate"
            )}
            time={timeDepositCompletion}
            setTime={setTimeDepositCompletion}
          />
        )}
      </Space>
      <Table
        {...landlordsTableProps}
        pagination={{
          defaultPageSize: 10,
          // showSizeChanger: true,
          // pageSizeOptions: ["10", "20", "30", "40"],
          current: pagination.current,
          onChange: handleTableChange,
        }}
        rowKey="id"
        className="list-users"
      >
        <Table.Column
          dataIndex="customer"
          title={translate("pages.customerManagement.appointments.customers")}
          key="customer"
          render={(value) => {
            return (
              value?.full_name && (
                <Tag>
                  <TextField value={value?.full_name} />
                </Tag>
              )
            );
          }}
        />
        <Table.Column
          dataIndex="time"
          title={translate("pages.customerManagement.appointments.time")}
          render={(value) => {
            return <TextField value={value ? formatTime(value) : ""} />;
          }}
        />
        {isLoggedbySale ? (
          <Table.Column
            dataIndex="role"
            title={translate("pages.customerManagement.appointments.role")}
            render={(value) => {
              return value === "sale" ? (
                <Tag>
                  <TextField
                    value={translate(
                      "pages.customerManagement.appointments.inCharge"
                    )}
                  />
                </Tag>
              ) : (
                <Tag>
                  <TextField
                    value={translate(
                      "pages.customerManagement.appointments.support"
                    )}
                  />
                </Tag>
              );
            }}
          />
        ) : null}
        <Table.Column
          dataIndex="apartments"
          title={translate("pages.customerManagement.appointments.buildings")}
          key="apartments"
          render={(value) => {
            return value?.map((item: any, idx: number) => {
              return (
                <Tag key={idx}>
                  <TextField value={item?.building?.name} />
                </Tag>
              );
            });
          }}
        />
        <Table.Column
          dataIndex="apartments"
          title={translate("pages.customerManagement.appointments.apartments")}
          key="apartments"
          render={(value) => {
            return value?.map((item: any, idx: number) => {
              return (
                <Tag key={idx}>
                  <TextField value={item?.name} />
                </Tag>
              );
            });
          }}
        />
        {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyTeamLeader ? (
          <Table.Column
            dataIndex="sale"
            title={translate("pages.customerManagement.appointments.sale")}
            key="sale"
            render={(item, idx: number) => {
              return (
                <Tag key={idx}>
                  <TextField value={item?.full_name} />
                </Tag>
              );
            }}
          />
        ) : null}
        <Table.Column
          dataIndex="status"
          title={translate("pages.customerManagement.appointments.status")}
          render={(value, idx: number) => {
            return (
              <TextField
                key={idx}
                value={
                  <Tag bordered={false} color={statusColors(value)}>
                    {handleTranslate(value, translate)}
                  </Tag>
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="complete_deposit_date"
          title={translate(
            "pages.customerManagement.appointments.depositCompletionDate"
          )}
          render={(value) => {
            return (
              <TextField
                value={value ? dayjs(value).format(formatDateMonthYear) : ""}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="notes"
          title={translate("pages.customerManagement.appointments.notes")}
          render={(value) => {
            return <TextField value={value} />;
          }}
        />
        <Table.Column<IListApointments>
          title={translate("table.actions")}
          dataIndex="actions"
          fixed="right"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
