import { usePermissions, useTranslate } from "@refinedev/core";
import {
  Button,
  Space,
  InputNumber,
  Modal,
  Select,
  Typography,
  DatePicker,
  Input,
} from "antd";
import dayjs from "dayjs";
import { DatePickerProps } from "antd/lib";

import "./styles.css";
import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_FINANCE,
  ROLE_SALE,
  ROLE_TEAM_LEADER,
  formatDateMonthYear,
  formatInputPrice,
  parseInputPrice,
} from "helper";
import {
  TYPE_TRANSACTIONS_MONEY_FULL_ACCESS,
  TYPE_TRANSACTIONS_MONEY_WITHOUT_COMMISSION,
  TYPE_TRANSACTIONS_MONEY_DEPOSIT,
} from "pages/customer-management/appointments/constant";
import {
  STATUS_TRANSFER_MONEY_OPTIONS,
  STATUS_TRANSFER_MONEY_OPTIONS_DEPOSIT,
} from "const";
import { useEffect, useState } from "react";

const { Title } = Typography;

interface IFilterNormal {
  isOpen: boolean;
  setIsOpen: (value: any) => void;
  title: string;
  onClick: () => void;
  loading: boolean;
  amount: number;
  setAmount: (amount: number) => void;
  status: string;
  setStatus: (status: string) => void;
  term: number;
  setTerm: (term: number) => void;
  notes: string;
  setNotes: (notes: string) => void;
  type: string;
  setType: (type: string) => void;
  zalo: string;
  setZalo: (value: string) => void;
  fullName?: string;
  setFullName?: (value: string) => void;
  depositCompletionDate: string;
  onChangeDepositCompletionDate: (value: DatePickerProps["value"]) => void;
  checkInDate: string;
  onChangeCheckInDate: (value: DatePickerProps["value"]) => void;
  isEdit?: boolean;
  disableChange?: boolean;
  setDisableChange: (value: boolean) => void;
}

export const MoneyTransaction = ({
  isOpen,
  setIsOpen,
  title,
  onClick,
  loading,
  amount,
  setAmount,
  status,
  setStatus,
  term,
  setTerm,
  notes,
  setNotes,
  type,
  setType,
  zalo,
  setZalo,
  fullName,
  setFullName,
  depositCompletionDate,
  onChangeDepositCompletionDate,
  checkInDate,
  onChangeCheckInDate,
  isEdit,
  disableChange,
  setDisableChange,
}: IFilterNormal) => {
  const translate = useTranslate();
  const permissions = usePermissions<string>();
  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissions?.data === ROLE_DATA_ADMIN;
  const isLoggedbyTeamLeader = permissions?.data === ROLE_TEAM_LEADER;
  const isLoggedbySale = permissions?.data === ROLE_SALE;
  const isLoggedbyFinance = permissions?.data === ROLE_FINANCE;
  const [dataTypeTransactionsMoney, setDataTypeTransactionsMoney] =
    useState<any>(TYPE_TRANSACTIONS_MONEY_DEPOSIT);

  useEffect(() => {
    if (isLoggedbyTeamLeader) {
      setDataTypeTransactionsMoney([
        ...TYPE_TRANSACTIONS_MONEY_WITHOUT_COMMISSION,
      ]);
    }
    if (
      isLoggedbyAdmin ||
      isLoggedbyDataAdmin ||
      isLoggedbyFinance ||
      isLoggedbySale
    ) {
      if (disableChange) {
        setDataTypeTransactionsMoney([...TYPE_TRANSACTIONS_MONEY_FULL_ACCESS]);
      } else {
        setDataTypeTransactionsMoney([...TYPE_TRANSACTIONS_MONEY_DEPOSIT]);
      }
    }
  }, [
    isLoggedbyAdmin,
    isLoggedbyDataAdmin,
    isLoggedbySale,
    isLoggedbyTeamLeader,
    isLoggedbyFinance,
    disableChange,
  ]);

  return (
    <Modal
      style={{ width: "100%", resize: "none" }}
      open={isOpen}
      title={<h3>{title}</h3>}
      onCancel={() => {
        setIsOpen(false);
        setDisableChange(false);
        setType("deposit");
      }}
      footer={[
        <Button
          key="confirmBtn"
          type="primary"
          loading={loading}
          onClick={() => {
            onClick();
          }}
        >
          {translate("buttons.confirmBtn")}
        </Button>,
      ]}
    >
      <div className="listItem">
        <Space>
          <Title level={5} className="required">
            {translate("pages.customerManagement.appointments.type")}:
          </Title>
          <Select
            disabled={isEdit || disableChange}
            style={{ width: "150px" }}
            placeholder={translate("placeholder.selectStatus")}
            options={dataTypeTransactionsMoney?.map((item: any) => {
              return {
                label: translate(item.label),
                value: item.value,
              };
            })}
            value={type}
            onSelect={(value) => setType(value)}
          />
        </Space>
        <br />
        <Space>
          <Title level={5} className="required">
            {type === "cancel_deposit" || type === "other_fee"
              ? translate("pages.customerManagement.appointments.amountFee")
              : translate("pages.customerManagement.appointments.amount")}
            :
          </Title>
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            value={amount ? String(amount) : ""}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
            onChange={(value: any) => setAmount(value)}
            placeholder={translate("placeholder.pleaseEnterAmountMoney")}
          />
        </Space>
        <br />
        {type === "deposit" ? (
          <>
            <Space>
              <Title level={5} className="required">
                {translate("pages.customerManagement.appointments.zalo")}:
              </Title>
              <Input
                style={{ width: "100%" }}
                value={zalo}
                onChange={(e: any) => setZalo(e.target.value)}
                allowClear
                placeholder={translate("placeholder.pleaseEnterZaloAccount")}
              />
            </Space>
            <br />
            <Space>
              <Title level={5}>
                {translate("pages.customerManagement.appointments.fullName")}:
              </Title>
              <Input
                style={{ width: "100%" }}
                value={fullName}
                onChange={(e: any) => setFullName?.(e.target.value)}
                allowClear
                placeholder={translate("placeholder.pleaseEnterName")}
              />
            </Space>
            <br />
            <Space>
              <Title level={5} className="required">
                {translate(
                  "pages.customerManagement.appointments.depositCompletionDate"
                )}
                :
              </Title>
              <div style={{ position: "relative" }}>
                <DatePicker
                  inputReadOnly={true}
                  format={formatDateMonthYear}
                  onChange={onChangeDepositCompletionDate}
                  value={
                    depositCompletionDate ? dayjs(depositCompletionDate) : null
                  }
                  placeholder={translate("placeholder.selectDate")}
                  dropdownClassName="custom-dropdown"
                  // Specify the parent container for the dropdown
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                />
              </div>
            </Space>
            <br />
            <Space>
              <Title level={5} className="required">
                {translate("pages.customerManagement.appointments.checkInDate")}
                :
              </Title>
              <div style={{ position: "relative" }}>
                <DatePicker
                  inputReadOnly={true}
                  format={formatDateMonthYear}
                  onChange={onChangeCheckInDate}
                  value={checkInDate ? dayjs(checkInDate) : null}
                  placeholder={translate("placeholder.selectDate")}
                  dropdownClassName="custom-dropdown"
                  // Specify the parent container for the dropdown
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                />
              </div>
            </Space>
            <br />
          </>
        ) : null}

        <Space>
          <Title level={5} className="required">
            {translate("pages.customerManagement.appointments.term")}:
          </Title>
          <Input
            type="number"
            prefix={translate("placeholder.numberOfMonth") + ":"}
            value={term}
            onChange={(e: any) => setTerm(e.target.value)}
            allowClear
            placeholder={translate("placeholder.pleaseEnterMonth")}
          />
        </Space>
        <br />

        <Space>
          <Title level={5}>
            {translate("pages.customerManagement.appointments.notes")}:
          </Title>
          <Input
            placeholder={translate("placeholder.pleaseEnterNotes")}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </Space>
        {isEdit ? (
          <>
            <br />
            <Space>
              <Title level={5} className="required">
                {translate("pages.customerManagement.appointments.status")}:
              </Title>
              <Select
                style={{ width: "150px" }}
                placeholder={translate("placeholder.selectStatus")}
                options={(type === "deposit" &&
                (isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyFinance)
                  ? STATUS_TRANSFER_MONEY_OPTIONS_DEPOSIT
                  : STATUS_TRANSFER_MONEY_OPTIONS
                )?.map((item) => {
                  return {
                    label: translate(item.label),
                    value: item.value,
                  };
                })}
                value={status}
                onSelect={(value) => setStatus(value)}
              />
            </Space>
          </>
        ) : null}
      </div>
    </Modal>
  );
};
