import { useTranslate } from "@refinedev/core";
import { Button, Space, Modal, Typography, Input } from "antd";

import "./styles.css";

const { Title } = Typography;

interface IModalCreateNewCustomer {
  isOpen: boolean;
  setIsOpen: (value: any) => void;
  title: string;
  onSubmit: () => void;
  loading: boolean;
  name: string;
  setName: (_: string) => void;
  phone: string;
  setPhone: (_: string) => void;
}

export const CreateNewCustomer = ({
  isOpen,
  setIsOpen,
  title,
  onSubmit,
  loading,
  name,
  setName,
  phone,
  setPhone,
}: IModalCreateNewCustomer) => {
  const translate = useTranslate();

  return (
    <Modal
      style={{ width: "100%", resize: "none" }}
      open={isOpen}
      title={<h3>{title}</h3>}
      onCancel={() => {
        setIsOpen(false);
      }}
      footer={[
        <Button
          key="confirmBtn"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          {translate("buttons.confirmBtn")}
        </Button>,
      ]}
    >
      <div className="listItem">
        <div>
          <Space>
            <Title level={5} className="required">
              {translate("pages.operation.reservations.name")}:
            </Title>
            <Input
              style={{ width: "100%" }}
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              allowClear
              placeholder={translate("placeholder.pleaseEnterName")}
            />
          </Space>
          <br />
        </div>
        <div>
          <Space>
            <Title level={5} className="required">
              {translate("pages.operation.reservations.phone")}:
            </Title>
            <Input
              type="number"
              style={{ width: "100%" }}
              value={phone}
              onChange={(e: any) => setPhone(e.target.value)}
              allowClear
              placeholder={translate("placeholder.pleaseEnterPhone")}
            />
          </Space>
          <br />
        </div>
      </div>
    </Modal>
  );
};
