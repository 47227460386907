import axios from "axios";

import { API_URL } from "./apiUrl";

export const getDashboard = async (currentDate: any, role: string) => {
  return await axios
    .get(`${API_URL}/dashboard/${role}?check_date=${currentDate}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((data) => data.data)
    .catch((error) => {
      console.error(error);
    });
};
