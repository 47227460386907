import React, { useEffect, useState } from "react";
import {
  useForgotPassword,
  useNavigation,
  useNotification,
  useTranslate,
} from "@refinedev/core";
import {
  Row,
  Col,
  Layout as AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Image,
} from "antd";

import { IForgotPasswordForm } from "interfaces";
import {
  FastBackwardOutlined,
  FormOutlined,
  NumberOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { isReallyPhone, isValidOTP, isValidPassword, parsePhone } from "helper";
import "./styles.css";

import { getOtp } from "api";

const { Text, Title } = Typography;

export const ForgotPassword: React.FC = () => {
  const translate = useTranslate();
  const { push } = useNavigation();
  const [phone, setPhone] = useState<any>("");
  const [isSubmitPhone, setIsSubmitPhone] = useState<boolean>(false);
  const [statusSubmitPhone, setStatusSubmitPhone] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [statusForm, setStatusForm] = useState<boolean>(false);
  const { open } = useNotification();

  const { mutate: forgotPassword, isLoading } =
    useForgotPassword<IForgotPasswordForm>();

  const CardTitle = (
    <div>
      {statusSubmitPhone && statusForm ? (
        <FastBackwardOutlined
          style={{ fontSize: "30px", cursor: "pointer" }}
          onClick={() => setStatusForm(false)}
        />
      ) : null}
      <Title level={3} className="title">
        {translate("pages.forgotPassword.resetYourAccount")}
      </Title>
    </div>
  );

  const onPhoneFormSubmit = (values: Pick<IForgotPasswordForm, "phone">) => {
    setPhone(values.phone);
    setIsSubmitPhone(true);
    setLoading(true);
    setStatusForm(true);
  };

  const onOTPFormSubmit = async (
    values: Pick<IForgotPasswordForm, "password" | "otp">
  ) => {
    forgotPassword(
      {
        phone: parsePhone(phone),
        otp: values.otp,
        password: values.password,
      },
      {
        onSuccess: (data) => {
          if (data.success) {
            open?.({
              type: "success",
              message: translate("notifications.success"),
              description: translate("notifications.resetPasswordSuccess"),
            });
            setTimeout(() => {
              push("/login");
            }, 1500);
          }
        },
      }
    );
  };

  const renderPhoneForm = () => (
    <Form
      layout="vertical"
      requiredMark={false}
      onFinish={onPhoneFormSubmit}
      autoComplete="off"
    >
      <Form.Item
        name="phone"
        label={translate("pages.forgotPassword.phone")}
        rules={[
          {
            required: true,
            message: translate("validations.phone"),
          },
          () => ({
            validator(_, value) {
              if (!isReallyPhone(value)) {
                return Promise.reject(
                  new Error(translate("validations.notAPhoneNumber"))
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input
          allowClear={true}
          onInput={() => setLoading(false)}
          prefix={<PhoneOutlined style={{ color: "#00000040" }} />}
          maxLength={10}
          placeholder="___-___-____"
        />
      </Form.Item>
      <Form.Item noStyle>
        <Button
          className="buttonSubmit"
          size="large"
          htmlType="submit"
          loading={loading}
          block
        >
          {translate("pages.forgotPassword.send")}
        </Button>
      </Form.Item>
    </Form>
  );

  const renderOTPForm = () => (
    <Form
      layout="vertical"
      requiredMark={false}
      onFinish={onOTPFormSubmit}
      autoComplete="off"
    >
      <Form.Item
        name="otp"
        label={translate("pages.forgotPassword.otp")}
        rules={[
          {
            required: true,
            message: translate("validations.otp"),
          },
          () => ({
            validator(_, value) {
              if (isValidOTP(value)) {
                return Promise.reject(
                  new Error(translate(value && isValidOTP(value)))
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input
          maxLength={6}
          prefix={<NumberOutlined style={{ color: "#00000040" }} />}
        />
      </Form.Item>
      <Form.Item
        name="password"
        label={translate("pages.forgotPassword.password")}
        rules={[
          {
            required: true,
            message: translate("validations.password"),
          },
          () => ({
            validator(_, value) {
              if (isValidPassword(value, true)) {
                return Promise.reject(
                  new Error(translate(value && isValidPassword(value, true)))
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input.Password
          autoComplete="new-password"
          prefix={<FormOutlined style={{ color: "#00000040" }} />}
        />
      </Form.Item>
      <Form.Item noStyle>
        <Button
          className="buttonSubmit"
          size="large"
          htmlType="submit"
          loading={isLoading}
          block
        >
          {translate("pages.forgotPassword.resetAccount")}
        </Button>
      </Form.Item>
    </Form>
  );

  useEffect(() => {
    if (isSubmitPhone) {
      getOtp(phone).then((response: any) => {
        setStatusSubmitPhone(response?.status);
      });
    }
  }, [isSubmitPhone, phone]);

  useEffect(() => {
    if (statusForm && statusSubmitPhone) {
      open?.({
        type: "success",
        message: translate("notifications.success"),
        description: translate("notifications.sendOTPSuccess"),
      });
    }
  }, [statusSubmitPhone, open, statusForm, translate]);

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <Image preview={false} src={"/logo.png"} width={200} />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Card
                style={{
                  maxWidth: "400px",
                  margin: "auto",
                  borderRadius: "10px",
                }}
              >
                {statusSubmitPhone && statusForm
                  ? renderOTPForm()
                  : renderPhoneForm()}
              </Card>
              <div style={{ marginTop: 16 }}>
                <Text style={{ fontSize: 12 }}>
                  {translate("pages.forgotPassword.ifHaveAnAcount")}
                  <span className="signIn" onClick={() => push("/login")}>
                    {translate("pages.forgotPassword.signin")}
                  </span>
                </Text>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
