import { useEffect, useState } from "react";
import axios from "axios";
import {
  CrudFilters,
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import {
  List,
  useTable,
  EditButton,
  TextField,
  ShowButton,
  CreateButton,
} from "@refinedev/antd";
import { Button, Form, Input, Space, Table, Tag, message } from "antd";

import {
  IListApartments,
  IListBuildings,
  IListContracts,
  IListReservation,
} from "interfaces";
import {
  formatPrice,
  formatTime,
  getParamFilter,
  hasChecked,
  mergeArraysByProperty,
  onCheckFilter,
  onClearFilter,
} from "helper";
import {
  getCurrentPagination,
  setFilterBills,
  getFilterBills,
  setCurrentPagination,
  getSelectedRowKeysBills,
  setSelectedRowKeysBills,
} from "helper/local-storage";
import { CaretDownOutlined, FilterFilled } from "@ant-design/icons";
import { FilterNormal, FilterTimeFromTo } from "components/modal";
import { STATUS_LANDLORD_BILLS, STATUS_SEND_ZNS } from "./const";

import { API_URL } from "api";

export const LandlordBillsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const filter = JSON.parse(getFilterBills() || "null");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const reservationIdUrlParam = urlParams.get("reservationId");
  const { searchFormProps, tableProps, setFilters } = useTable<IListContracts>({
    resource: "bills",
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { newKeyword } = params;

      filters.push({
        field: "keyword",
        operator: "eq",
        value: newKeyword || null,
      });
      setFilterBills(JSON.stringify({ ...filter, keyword: newKeyword }));
      return filters;
    },
  });

  // get building data
  const { data: buildingsData } = useList<IListBuildings>({
    resource: "buildings",
    errorNotification: false,
  });

  // get apartments data
  const { data: apartmentsData } = useList<IListApartments>({
    resource: "apartments",
    errorNotification: false,
  });

  // get reservations data
  const { data: reservationsData } = useList<IListReservation>({
    resource: "reservations",
    errorNotification: false,
  });
  const selectRowKeysBillsLocalStorage = JSON.parse(
    getSelectedRowKeysBills() || "[]"
  );

  const [isOpenModalBuilding, setIsOpenModalBuilding] =
    useState<boolean>(false);
  const [isOpenModalApartment, setIsOpenModalApartment] =
    useState<boolean>(false);
  const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);
  const [isOpenModalTime, setIsOpenModalTime] = useState<boolean>(false);
  const [isOpenModalReservation, setIsOpenModalReservation] =
    useState<boolean>(false);
  const [isOpenModalSentZns, setIsOpenModalSentZns] = useState<boolean>(false);
  const [currentBillsTableData, setCurrentBillsTableData] = useState<any>([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<any>(
    selectRowKeysBillsLocalStorage ?? []
  );

  const [keyword, setKeyword] = useState<string>("");
  const [listBuilding, setListBuilding] = useState<any>(
    filter?.listBuilding || []
  );
  const [listApartments, setListApartments] = useState<any>(
    filter?.listApartment || []
  );
  const [listStatus, setListStatus] = useState<any>(
    filter?.listStatus || STATUS_LANDLORD_BILLS
  );
  const [listReservation, setListReservation] = useState<any>(
    filter?.listReservation || []
  );
  const [listSentZns, setListSentZns] = useState<any>(
    filter?.listSentZns || STATUS_SEND_ZNS
  );

  const [validFrom, setValidFrom] = useState<number>(filter?.validFrom || 0);
  const [validTo, setValidTo] = useState<number>(filter?.validTo || 0);

  const [pagination, setPagination] = useState(
    JSON.parse(getCurrentPagination() || "{}") || {
      current: 1,
      pageSize: 10,
    }
  );
  const dataSource: any = tableProps?.dataSource;
  let billsTableProps = {
    ...tableProps,
    pagination: pagination,
    dataSource:
      currentBillsTableData.length > 0
        ? currentBillsTableData
        : dataSource?.data,
  };

  useEffect(() => {
    if (buildingsData && !hasChecked(listBuilding)) {
      const buildingOptionsData: any = buildingsData?.data;
      setListBuilding(
        buildingOptionsData?.data?.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            isChecked: false,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingsData]);

  useEffect(() => {
    if (apartmentsData && !hasChecked(listApartments)) {
      const apartmentsOptionsData: any = apartmentsData?.data;
      setListApartments(
        apartmentsOptionsData?.data?.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            isChecked: false,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apartmentsData]);

  useEffect(() => {
    const reservationsOptionsData: any = reservationsData?.data;
    const reservationOption2 = reservationsOptionsData?.data;

    if (reservationOption2?.length > 0 && !hasChecked(listReservation)) {
      setListReservation(
        reservationOption2.map((item: any) => {
          return {
            value: item.id,
            label: `#${item.id}-${item.apartment.name}-${item.apartment.building.name}`,
            isChecked: false,
          };
        })
      );
    }
    if (reservationIdUrlParam) {
      const currentReservationListFull = reservationOption2?.map(
        (item: any) => {
          return {
            value: item.id,
            label: `#${item.id}-${item.apartment.name}-${item.apartment.building.name}`,
            isChecked: false,
          };
        }
      );

      const currentReservation = currentReservationListFull
        ?.filter((item: any) => item.value === +reservationIdUrlParam)
        .map((item: any) => {
          return {
            ...item,
            isChecked: true,
          };
        });

      if (
        currentReservation?.length > 0 &&
        currentReservationListFull?.length > 0
      ) {
        // merged current isChecked = true into current array isChecked = false
        const newReservationList = mergeArraysByProperty(
          currentReservation,
          currentReservationListFull,
          "value"
        );
        setListReservation(newReservationList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationsData, reservationIdUrlParam]);

  const handleTranslate = (value: string) => {
    switch (value) {
      case "unpaid":
        return translate("status.unpaid");
      case "partial_paid":
        return translate("status.partialPaid");
      case "paid":
        return translate("status.paid");
      default:
        break;
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination({ current: pagination, pageSize: 10 });
  };

  const onSelectedRowKeysChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRowKeysBills(selectedRowKeys);
  };

  const selectRow = (record: any) => {
    const selectedKeys = [...selectedRowKeys];

    if (selectedKeys.indexOf(record.key) >= 0) {
      selectedKeys.splice(selectedKeys.indexOf(record.key), 1);
    } else {
      selectedKeys.push(record.key);
    }
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectedRowKeysChange,
  };
  const getNewBillsList = async () => {
    return await axios
      .get(`${API_URL}/bills`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((data) => data.data)
      .catch((error) => {
        console.error(error);
      });
  };

  const onSentZnsToCustomer = async () => {
    if (rowSelection?.selectedRowKeys?.length === 0) {
      message.error(translate("validations.mustChooseCustomerOnTableToSent"));
      return;
    }

    const response: any = await axios
      .post(
        `${API_URL}/notification/bills`,
        {
          billIds: rowSelection?.selectedRowKeys,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((data) => data.data)
      .catch((error) => {
        const { response } = error;
        message.error(translate("notifications.errorSentZns"));
        response?.data?.data?.errors?.map((item: any) => {
          return message.error(item?.message);
        });
        console.error(error);
      });

    if (response?.status) {
      // Handle success
      message.success(translate("notifications.successfullySentZns"));
      const newBillsData = await getNewBillsList();
      setCurrentBillsTableData(newBillsData?.data);
      // reset filter send after call api success
      setListSentZns([...STATUS_SEND_ZNS]);
    }
  };

  useEffect(() => {
    const reservationsOptionsData: any = reservationsData?.data;
    const reservationOption2 = reservationsOptionsData?.data;

    if (reservationOption2?.length > 0 && !hasChecked(listReservation)) {
      setListReservation(
        reservationOption2.map((item: any) => {
          return {
            value: item.id,
            label: `#${item.id}-${item.apartment.name}-${item.apartment.building.name}`,
            isChecked: false,
          };
        })
      );
    }
    if (reservationIdUrlParam) {
      const currentReservationListFull = reservationOption2?.map(
        (item: any) => {
          return {
            value: item.id,
            label: `#${item.id}-${item.apartment.name}-${item.apartment.building.name}`,
            isChecked: false,
          };
        }
      );

      const currentReservation = currentReservationListFull
        ?.filter((item: any) => item.value === +reservationIdUrlParam)
        .map((item: any) => {
          return {
            ...item,
            isChecked: true,
          };
        });

      if (
        currentReservation?.length > 0 &&
        currentReservationListFull?.length > 0
      ) {
        // merged current isChecked = true into current array isChecked = false
        const newReservationList = mergeArraysByProperty(
          currentReservation,
          currentReservationListFull,
          "value"
        );
        setListReservation(newReservationList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationsData, reservationIdUrlParam]);

  useEffect(() => {
    setCurrentPagination(JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    const storedPagination = getCurrentPagination();
    if (storedPagination) {
      setPagination(JSON.parse(storedPagination));
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchFormProps.form?.submit();
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    const buildingParam = getParamFilter(listBuilding);
    const apartmentParam = getParamFilter(listApartments);
    const statusParam = getParamFilter(listStatus);
    const reservationParam = getParamFilter(listReservation);
    const sentZnsParam = getParamFilter(listSentZns);

    // const dateValidFrom = new Date(validFrom).toISOString();
    // const dateValidTo = new Date(validTo).toISOString();
    const dateValidFrom = new Date(validFrom);
    const dateValidTo = new Date(validTo);

    setFilters(
      [
        {
          field: "buildingId",
          operator: "eq",
          value: buildingParam,
        },
        {
          field: "apartmentId",
          operator: "eq",
          value: apartmentParam,
        },
        {
          field: "status",
          operator: "eq",
          value: statusParam,
        },
        {
          field: "validFrom",
          operator: "eq",
          value: validFrom ? dateValidFrom : null,
        },
        {
          field: "validTo",
          operator: "eq",
          value: validTo ? dateValidTo : null,
        },
        {
          field: "reservationId",
          operator: "eq",
          value: reservationParam,
        },
        {
          field: "isSentZns",
          operator: "eq",
          value: sentZnsParam,
        },
      ],
      "merge"
    );
    setFilterBills(
      JSON.stringify({
        ...filter,
        listBuilding,
        listApartments,
        listStatus,
        validFrom,
        validTo,
        listReservation,
        listSentZns,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listBuilding,
    listApartments,
    listStatus,
    validFrom,
    validTo,
    listReservation,
    listSentZns,
  ]);

  return (
    <List
      title={translate("pages.payment.landlordBills.list")}
      headerButtons={({ createButtonProps }) => (
        <>
          <Form {...searchFormProps}>
            <Form.Item
              name="newKeyword"
              initialValue={keyword}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={translate("placeholder.searchInContract")}
                onChange={(e) => setKeyword(e.target.value)}
                allowClear={true}
              />
            </Form.Item>
          </Form>
          <Button onClick={onSentZnsToCustomer}>
            {translate("pages.payment.landlordBills.sentZns")}
          </Button>

          <CreateButton {...createButtonProps} />
        </>
      )}
    >
      <Space className="listFilter">
        <Button style={{ pointerEvents: "none" }}>
          <FilterFilled />
          {translate("buttons.filter")}
        </Button>

        <Button
          onClick={() => setIsOpenModalBuilding(true)}
          className={hasChecked(listBuilding) ? "isEnabled" : ""}
        >
          {translate("pages.payment.landlordBills.building")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalBuilding && (
          <FilterNormal
            isOpen={isOpenModalBuilding}
            setIsOpen={setIsOpenModalBuilding}
            title={translate("pages.payment.landlordBills.building")}
            data={listBuilding}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listBuilding, false);
              setListBuilding(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listBuilding);
              setListBuilding(newData);
            }}
            multiple={false}
          />
        )}

        <Button
          onClick={() => setIsOpenModalApartment(true)}
          className={hasChecked(listApartments) ? "isEnabled" : ""}
        >
          {translate("pages.payment.landlordBills.apartment")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalApartment && (
          <FilterNormal
            isOpen={isOpenModalApartment}
            setIsOpen={setIsOpenModalApartment}
            title={translate("pages.payment.landlordBills.apartment")}
            data={listApartments}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listApartments, false);
              setListApartments(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listApartments);
              setListApartments(newData);
            }}
            multiple={false}
          />
        )}

        <Button
          onClick={() => setIsOpenModalStatus(true)}
          className={hasChecked(listStatus) ? "isEnabled" : ""}
        >
          {translate("pages.payment.landlordBills.status")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalStatus && (
          <FilterNormal
            isOpen={isOpenModalStatus}
            setIsOpen={setIsOpenModalStatus}
            title={translate("pages.cartManagement.landlords.status")}
            data={listStatus}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listStatus, false);
              setListStatus(newData);
            }}
            onClear={() => setListStatus([...STATUS_LANDLORD_BILLS])}
            multiple={false}
          />
        )}

        <Button
          onClick={() => setIsOpenModalTime(true)}
          className={validFrom || validTo ? "isEnabled" : ""}
        >
          {translate("pages.payment.landlordBills.date")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalTime && (
          <FilterTimeFromTo
            isOpen={isOpenModalTime}
            setIsOpen={setIsOpenModalTime}
            title={translate("pages.payment.landlordBills.date")}
            fromTime={validFrom}
            setFromTime={setValidFrom}
            toTime={validTo}
            setToTime={setValidTo}
          />
        )}

        <Button
          onClick={() => setIsOpenModalReservation(true)}
          className={hasChecked(listReservation) ? "isEnabled" : ""}
        >
          {translate("pages.payment.contracts.reservation")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalReservation && (
          <FilterNormal
            isOpen={isOpenModalReservation}
            setIsOpen={setIsOpenModalReservation}
            title={translate("pages.payment.contracts.reservation")}
            data={listReservation}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listReservation, false);
              setListReservation(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listReservation);
              setListReservation(newData);
            }}
            multiple={false}
          />
        )}

        <Button
          onClick={() => setIsOpenModalSentZns(true)}
          className={hasChecked(listSentZns) ? "isEnabled" : ""}
        >
          {translate("pages.payment.landlordBills.showSentZns")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalSentZns && (
          <FilterNormal
            isOpen={isOpenModalSentZns}
            setIsOpen={setIsOpenModalSentZns}
            title={translate("pages.payment.landlordBills.showSentZns")}
            data={listSentZns}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listSentZns, false);
              setListSentZns(newData);
            }}
            onClear={() => setListSentZns([...STATUS_SEND_ZNS])}
            multiple={false}
          />
        )}
      </Space>
      <Table
        {...billsTableProps}
        pagination={{
          defaultPageSize: 10,
          // showSizeChanger: true,
          // pageSizeOptions: ["10", "20", "30", "40"],
          current: pagination.current,
          onChange: handleTableChange,
        }}
        rowKey="id"
        rowSelection={rowSelection}
        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
      >
        <Table.Column
          dataIndex="name"
          title={translate("pages.payment.landlordBills.name")}
        />
        <Table.Column
          dataIndex="reservationId"
          title={translate("pages.payment.landlordBills.reservationId")}
        />
        <Table.Column
          dataIndex="reservation"
          title={translate("pages.payment.landlordBills.apartment")}
          render={(value) => {
            return <TextField value={value?.apartment?.name} />;
          }}
        />
        <Table.Column
          dataIndex="reservation"
          title={translate("pages.payment.landlordBills.building")}
          render={(value) => {
            return <TextField value={value?.apartment?.building?.name} />;
          }}
        />
        <Table.Column
          dataIndex="reservation"
          title={translate("pages.payment.landlordBills.customer")}
          render={(value) => {
            return <TextField value={value?.customer?.full_name} />;
          }}
        />
        <Table.Column
          dataIndex="totalFee"
          title={translate("pages.payment.landlordBills.totalFee")}
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />
        <Table.Column
          dataIndex="paidValue"
          title={translate("pages.payment.landlordBills.paidValue")}
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />
        <Table.Column
          dataIndex="fromDate"
          title={translate("pages.payment.landlordBills.fromDate")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column
          dataIndex="toDate"
          title={translate("pages.payment.landlordBills.toDate")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />

        <Table.Column
          title={translate("pages.payment.landlordBills.status")}
          dataIndex="status"
          render={(value) => {
            return (
              <TextField
                strong
                value={
                  <Tag
                    bordered={false}
                    color={
                      value === "paid"
                        ? "success"
                        : value === "partial_paid"
                        ? "yellow"
                        : "error"
                    }
                  >
                    {handleTranslate(value)}
                  </Tag>
                }
              />
            );
          }}
        />
        <Table.Column
          title={translate("pages.payment.landlordBills.showSentZns")}
          dataIndex="isSentZns"
          render={(value) => {
            return (
              <TextField
                strong
                value={
                  <Tag
                    bordered={false}
                    color={value === true ? "success" : "error"}
                  >
                    {value === true
                      ? translate("status.alreadySentZns")
                      : translate("status.doestNotSentZns")}
                  </Tag>
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="createdAt"
          title={translate("pages.createdAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column
          width={400}
          dataIndex="updatedAt"
          title={translate("pages.updatedAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column<IListContracts>
          title={translate("table.actions")}
          dataIndex="actions"
          fixed="right"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
