import axios from "axios";

import { API_URL } from "./apiUrl";

export const getDistricts = async (idProvince: number) => {
  return await axios
    .get(`${API_URL}/addresses/districts?province_id=${idProvince}`)
    .then((data) => data.data)
    .catch((error) => {
      console.error(error);
    });
};
