import { useState } from "react";
import {
  IResourceComponentsProps,
  usePermissions,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { useNavigate } from "react-router-dom";
import { EditButton, Show, TextField } from "@refinedev/antd";

import { Button, Space, Tag, Typography } from "antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IListApartments } from "interfaces";
import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_SALE,
  ROLE_TEAM_LEADER,
  fetchAndDownloadImage,
  formatPrice,
} from "helper";
import { S3_IMG_URL } from "api";
import Slider from "components/slider-image";
import CustomImageModal from "components/modal/ImageDetail";

const { Title } = Typography;

export const ApartmentsShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const permissions = usePermissions<string>();
  const isLoggedBySale = permissions?.data === ROLE_SALE;
  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissions?.data === ROLE_DATA_ADMIN;
  const isLoggedbyTeamLeader = permissions?.data === ROLE_TEAM_LEADER;

  const { queryResult } = useShow<IListApartments>();
  const { data, isLoading } = queryResult;
  const [currentOpenModalImage, setCurrentOpenModalImage] =
    useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<any>(0);

  const record: any = data?.data;
  const navigate = useNavigate();

  const handleCancel = () => {
    setCurrentOpenModalImage(false);
  };

  const handleOpenCurrentImageModal = (index: number) => {
    setCurrentImageIndex(index);
    setCurrentOpenModalImage(true);
  };

  // Function to download all images
  const downloadImages = () => {
    record?.data?.images?.forEach((url: any, index: number) => {
      fetchAndDownloadImage(`${S3_IMG_URL}/${url}`, index);
    });
  };

  // initial value furniture
  let currentListFurniture = [];
  record?.data?.fridge && currentListFurniture.push("fridge");
  record?.data?.air_conditioner && currentListFurniture.push("airConditioner");
  record?.data?.television && currentListFurniture.push("television");
  record?.data?.washing_machine && currentListFurniture.push("washingMachine");
  record?.data?.in_room_washing_machine &&
    currentListFurniture.push("inRoomWashingMachine");
  record?.data?.water_heater && currentListFurniture.push("waterHeater");
  record?.data?.bed && currentListFurniture.push("bed");
  record?.data?.mattress && currentListFurniture.push("mattress");
  record?.data?.kitchen_shelf && currentListFurniture.push("kitchenShelf");
  record?.data?.sink && currentListFurniture.push("sink");
  record?.data?.wardrobe && currentListFurniture.push("wardrobe");

  return (
    <Show
      isLoading={isLoading}
      title={translate("pages.cartManagement.apartments.showApartment")}
      headerButtons={({
        listButtonProps,
        editButtonProps,
        refreshButtonProps,
      }) => (
        <>
          {isLoggedbyAdmin ||
          isLoggedbyDataAdmin ||
          isLoggedbyTeamLeader ||
          isLoggedBySale ? (
            <Button
              onClick={() =>
                navigate(`/buildings/show/${record?.data?.building?.id}`)
              }
            >
              {translate(
                "pages.cartManagement.apartments.viewInformationBuildings"
              )}
            </Button>
          ) : null}
          {editButtonProps && (
            <>
              {isLoggedbyAdmin || isLoggedbyDataAdmin ? (
                <EditButton
                  {...editButtonProps}
                  title={translate(
                    "pages.cartManagement.apartments.editApartment"
                  )}
                />
              ) : null}
            </>
          )}
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
    >
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.apartments.name")}:
        </Title>
        <TextField value={record?.data?.name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.apartments.building")}:
        </Title>
        <TextField value={record?.data?.building?.name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.apartments.square")}:
        </Title>
        <TextField value={record?.data?.square} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.apartments.windows")}:
        </Title>
        <TextField
          value={
            record?.data?.windows
              ? translate("const.true")
              : translate("const.false")
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.apartments.balconies")}:
        </Title>
        <TextField
          value={
            record?.data?.balconies
              ? translate("const.true")
              : translate("const.false")
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.apartments.type")}:
        </Title>
        <TextField
          value={
            record?.data?.status === "vacant"
              ? translate("const.vacant")
              : record?.data?.status === "deposited"
              ? translate("const.deposited")
              : record?.data?.status === "occupied"
              ? translate("const.occupied")
              : translate("const.nearVacant")
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.apartments.price")}:
        </Title>
        <TextField value={formatPrice(record?.data?.price)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.apartments.depositPrice")}:
        </Title>
        <TextField value={formatPrice(record?.data?.deposit_price)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.apartments.furniture")}:
        </Title>
        <TextField
          value={currentListFurniture?.map((item: any, idx: number) => {
            return (
              <Tag key={idx}>
                <TextField value={translate(`const.${item}`)} />
              </Tag>
            );
          })}
        />
      </Space>
      <br />
      {isLoggedbyAdmin || isLoggedbyDataAdmin ? (
        <>
          <Space>
            <Title level={5}>
              {translate(
                "pages.cartManagement.apartments.sixMonthsCommissionPct"
              )}
              :
            </Title>
            <TextField value={record?.data?.six_months_commission_pct + " %"} />
          </Space>
          <br />
          <Space>
            <Title level={5}>
              {translate(
                "pages.cartManagement.apartments.oneYearCommissionPct"
              )}
              :
            </Title>
            <TextField value={record?.data?.one_year_commission_pct + " %"} />
          </Space>
          <br />
        </>
      ) : null}
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.apartments.notes")}:
        </Title>
        <TextField value={record?.data?.notes} />
      </Space>
      <br />
      <br />

      {/* <Space>
        <Button onClick={downloadImages}>
          {translate("pages.cartManagement.apartments.downloadImage")}
        </Button>
      </Space>
      <br />
      <br /> */}

      <CustomImageModal
        open={currentOpenModalImage}
        onCancel={handleCancel}
        images={record?.data?.images}
        initialIndex={currentImageIndex}
      />
      <Slider
        item={record?.data?.images}
        handleOpenCurrentIndex={(index: number) =>
          handleOpenCurrentImageModal(index)
        }
      />
    </Show>
  );
};
