import { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Checkbox, Form, Input, Select } from "antd";

import { IListUser, IProvinces } from "interfaces";
import { handleFilterOption, handleSortOption } from "helper";
import { IListTeams } from "interfaces";

export const TeamsManagementCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const [optionsDataSales, setOptionsDataSales] = useState<[]>([]);
  const [optionsTeamLeader, setOptionsTeamLeader] = useState<[]>([]);

  const [selectedItemsSales, setSelectedItemsSales] = useState<string[]>([]);
  const [isActive, setIsActive] = useState<boolean>(true);

  const { formProps, saveButtonProps, onFinish, form } = useForm<IListUser>({
    errorNotification: (data) => {
      const errorMessage: any = data?.response;
      const message: any = errorMessage?.data?.data?.errors[0].message;
      return {
        message: message,
        description: "Error",
        type: "error",
      };
    },
  });

  // get list sale role
  const { data: dataSales } = useList<IProvinces>({
    resource: "users?role_id=5",
    errorNotification: false,
  });

  // get list team-leader role
  const { data: teamLeaderData } = useList<IProvinces>({
    resource: "users?role_id=4",
    errorNotification: false,
  });

  useEffect(() => {
    const queryResult: any = dataSales?.data;
    if (queryResult) {
      const optionDataSales = queryResult?.data?.map((item: any) => {
        return {
          label: item.full_name,
          value: item.id,
        };
      });
      setOptionsDataSales(optionDataSales);
    }
  }, [dataSales]);

  useEffect(() => {
    const queryResult: any = teamLeaderData?.data;
    if (queryResult) {
      const optionsTeamLeader = queryResult?.data?.map((item: any) => {
        return {
          label: item.full_name,
          value: item.id,
        };
      });
      setOptionsTeamLeader(optionsTeamLeader);
    }
  }, [teamLeaderData]);

  const handleOnFinish: any = (values: IListTeams) => {
    onFinish({
      ...values,
      status: isActive,
    });
  };

  const filteredOptionsSales = optionsDataSales?.filter(
    (o: any) => !selectedItemsSales?.includes(o)
  );

  return (
    <Create
      title={translate("pages.teamsManagement.createNewTeam")}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        initialValues={{ status: isActive }}
        layout="vertical"
        form={form}
      >
        <Form.Item
          name="name"
          label={translate("pages.teamsManagement.name")}
          rules={[
            {
              required: true,
              message: translate("validations.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("pages.teamsManagement.teamLeader")}
          name="leader_id"
        >
          <Select
            options={optionsTeamLeader}
            placeholder={translate("placeholder.selectATeamLeader")}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <Form.Item
          name="sale_ids"
          label={translate("pages.teamsManagement.sales")}
        >
          <Select
            mode="multiple"
            placeholder={translate("placeholder.selectAOneOrManySales")}
            options={filteredOptionsSales}
            showSearch
            onChange={setSelectedItemsSales}
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <Form.Item
          label={translate("pages.teamsManagement.status")}
        >
          <Form.Item valuePropName="checked">
            <Checkbox
              style={{
                fontSize: "12px",
              }}
              onChange={() => setIsActive(true)}
              checked={isActive ? true : false}
            >
              {translate("status.active")}
            </Checkbox>
            <Checkbox
              style={{
                fontSize: "12px",
              }}
              onChange={() => setIsActive(false)}
              checked={!isActive ? true : false}
            >
              {translate("status.inactive")}
            </Checkbox>
          </Form.Item>
        </Form.Item>
      </Form>
    </Create>
  );
};
