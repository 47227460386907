import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
  usePermissions,
  useParsed,
} from "@refinedev/core";
import { EditButton, Show, TextField } from "@refinedev/antd";

import { Button, Space, Tag, Typography, message } from "antd";
import "react-mde/lib/styles/css/react-mde-all.css";

import { IListBuildings } from "interfaces";
import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_LANDLORD,
  ROLE_SALE,
  ROLE_TEAM_LEADER,
  formatPrice,
  onCopyPath,
} from "helper";

import Slider from "components/slider-image";
import CustomImageModal from "components/modal/ImageDetail";

const { Title } = Typography;

export const RealEstateBuildingsShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { id } = useParsed();
  const { queryResult } = useShow<IListBuildings>({ resource: "buildings", id });
  const { data, isLoading } = queryResult;
  const record: any = data?.data;
  const [currentOpenModalImage, setCurrentOpenModalImage] =
    useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<any>(0);

  const { data: permissionsData }: any = usePermissions();
  const auth = JSON.parse(localStorage.getItem("auth") || "{}");

  const permissions = usePermissions<string>();
  const isLoggedbySale = permissions?.data === ROLE_SALE;
  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissions?.data === ROLE_DATA_ADMIN;
  const isLoggedbyTeamLeader = permissions?.data === ROLE_TEAM_LEADER;
  const isLoggedbyLandlord = permissions?.data === ROLE_LANDLORD;

  const handleTranslate = (value: string) => {
    switch (value) {
      case "magnetic_card":
        return translate("const.magneticCard");
      case "guard":
        return translate("const.guard");
      case "no_guard":
        return translate("const.noGuard");
      default:
        break;
    }
  };

  const handleCancel = () => {
    setCurrentOpenModalImage(false);
  };

  const handleOpenCurrentImageModal = (index: number) => {
    setCurrentImageIndex(index);
    setCurrentOpenModalImage(true);
  };

  return (
    <Show
      isLoading={isLoading}
      title={translate("pages.cartManagement.buildings.showBuilding")}
      headerButtons={({
        listButtonProps,
        editButtonProps,
        refreshButtonProps,
      }) => (
        <>
          {editButtonProps && (
            <>
              {isLoggedbyAdmin || isLoggedbyDataAdmin ? (
                <EditButton
                  {...editButtonProps}
                  title={translate(
                    "pages.cartManagement.buildings.editBuilding"
                  )}
                />
              ) : (
                <>
                  {isLoggedbyTeamLeader &&
                  record?.data?.actor_id === auth?.id &&
                  !record?.data?.approved ? (
                    <EditButton
                      {...editButtonProps}
                      title={translate(
                        "pages.cartManagement.buildings.editBuilding"
                      )}
                    />
                  ) : null}
                </>
              )}
            </>
          )}
          {listButtonProps && null}
          {refreshButtonProps && null}
          <>
            {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyLandlord ? (
              <Button
                onClick={() =>
                  navigate(
                    `/buildings-apartments?building_id=${record?.data?.id}`
                  )
                }
              >
                {translate("pages.cartManagement.buildings.viewListApartments")}
              </Button>
            ) : (
              <>
                {isLoggedbyTeamLeader &&
                record?.data?.actor_id === auth?.id &&
                !record?.data?.approved ? (
                  <Button
                    onClick={() =>
                      navigate(
                        `/buildings-apartments?building_id=${record?.data?.id}`
                      )
                    }
                  >
                    {translate(
                      "pages.cartManagement.buildings.viewListApartments"
                    )}
                  </Button>
                ) : null}
              </>
            )}
          </>

          {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyLandlord ? (
            <>
              <Button
                onClick={() =>
                  navigate("/apartments/create", {
                    state: {
                      currentBuilding: {
                        ...record,
                      },
                    },
                  })
                }
              >
                {translate(
                  "pages.cartManagement.apartments.createNewApartment"
                )}
              </Button>
              {!isLoggedbyLandlord ? (
                <Button
                  onClick={() =>
                    navigate(`/landlords/show/${record?.data?.landlord_id}`)
                  }
                >
                  {translate("pages.cartManagement.landlords.showLandlord")}
                </Button>
              ) : null}
            </>
          ) : null}
        </>
      )}
    >
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.id")}:
        </Title>
        <TextField value={record?.data?.id} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.name")}:
        </Title>
        <TextField value={record?.data?.name} />
      </Space>
      <br />
      {!isLoggedbyLandlord ? (
        <>
          <Space>
            <Title level={5}>{translate("pages.users.status")}:</Title>
            <TextField
              strong
              value={
                record?.data?.approved ? (
                  <Tag bordered={false} color="success">
                    {translate("status.approved")}
                  </Tag>
                ) : (
                  <Tag bordered={false} color="error">
                    {translate("status.notApprovedYet")}
                  </Tag>
                )
              }
            />
          </Space>
          <br />
        </>
      ) : null}

      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.description")}:
        </Title>
        <TextField value={record?.data?.description} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.amountApartments")}:
        </Title>
        <TextField value={record?.data?.amount_apartments} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.amountVacantApartments")}:
        </Title>
        <TextField value={record?.data?.amount_vacant_apartments} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.linkGoogleMap")}:
        </Title>
        <Link className="linkMap" to={record?.data?.link_google_map}>
          {record?.data?.link_google_map}
        </Link>
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.address")}:
        </Title>
        <TextField value={record?.data?.address} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.gateGuard")}:
        </Title>
        <TextField value={handleTranslate(record?.data?.gate_guard)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.province")}:
        </Title>
        <TextField value={record?.data?.province?.name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.district")}:
        </Title>
        <TextField value={record?.data?.district?.name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.ward")}:
        </Title>
        <TextField value={record?.data?.ward?.name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.owner")}:
        </Title>
        <TextField value={record?.data?.landlord?.full_name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.electricityPrice")}:
        </Title>
        <TextField value={formatPrice(record?.data?.electricity_price)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.chargeWater")}:
        </Title>
        <TextField
          value={
            record?.data?.charge_water === "person"
              ? translate("const.person")
              : translate("const.usedAmount")
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.waterPrice")}:
        </Title>
        <TextField value={formatPrice(record?.data?.water_price)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.internetPrice")}:
        </Title>
        <TextField value={formatPrice(record?.data?.internet_price)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.parkingPrice")}:
        </Title>
        <TextField value={formatPrice(record?.data?.parking_price)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.managementPrice")}:
        </Title>
        <TextField value={formatPrice(record?.data?.management_price)} />
      </Space>
      <br />
      {!isLoggedbySale ||
        (!isLoggedbyLandlord && (
          <>
            <Space>
              <Title level={5}>
                {translate(
                  "pages.cartManagement.buildings.sixMonthsCommissionPct"
                )}
                :
              </Title>
              <TextField
                value={record?.data?.six_months_commission_pct + " %"}
              />
            </Space>
            <br />
            <Space>
              <Title level={5}>
                {translate(
                  "pages.cartManagement.buildings.oneYearCommissionPct"
                )}
                :
              </Title>
              <TextField value={record?.data?.one_year_commission_pct + " %"} />
            </Space>
            <br />
          </>
        ))}
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.pet")}:
        </Title>
        <TextField value={record?.data?.pet} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.rinseClothesline")}:
        </Title>
        <TextField value={record?.data?.rinse_clothesline} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.amountPeopleVehicle")}:
        </Title>
        <TextField value={record?.data?.amount_people_vehicle} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.cartManagement.buildings.notes")}:
        </Title>
        <TextField value={record?.data?.notes} />
      </Space>
      <br />
      <br />

      {["admin", "data-admin"].includes(permissionsData) &&
        record?.data?.images?.length > 0 && (
          <Button
            type="primary"
            onClick={() => {
              onCopyPath(record?.data?.images);
              message.success(translate("notifications.copyImagePathSuccess"));
            }}
            style={{
              marginBottom: 8,
              whiteSpace: "pre-wrap",
              height: "auto",
            }}
          >
            {translate("pages.cartManagement.buildings.copyImagesPath")}
          </Button>
        )}

      <CustomImageModal
        open={currentOpenModalImage}
        onCancel={handleCancel}
        images={record?.data?.images}
        initialIndex={currentImageIndex}
      />
      <Slider
        item={record?.data?.images}
        handleOpenCurrentIndex={(index: number) =>
          handleOpenCurrentImageModal(index)
        }
      />
      <br />
    </Show>
  );
};
