import { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Checkbox, Form, Input, Select } from "antd";

import { IListUser, IProvinces } from "interfaces";
import { getDistricts } from "api";
import { handleFilterOption, handleSortOption } from "helper";
import { IListArea } from "interfaces";

export const AreasCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const [selectedProvice, setSelectedProvince] = useState<any>(1);
  const [optionsDistricts, setOptionsDistricts] = useState<[]>([]);
  const [optionsDataAdmin, setOptionsDataAdmin] = useState<[]>([]);
  const [optionsTeamLeader, setOptionsTeamLeader] = useState<[]>([]);
  const [selectedItemsDistrict, setSelectedItemsDistrict] = useState<string[]>(
    []
  );
  const [selectedItemsTeamLeader, setSelectedItemsTeamLeader] = useState<
    string[]
  >([]);
  const [isActive, setIsActive] = useState<boolean>(true);

  const { formProps, saveButtonProps, onFinish, form } = useForm<IListUser>({
    errorNotification: (data) => {
      const errorMessage: any = data?.response;
      const message: any = errorMessage?.data?.data?.errors[0].message;
      return {
        message: message,
        description: "Error",
        type: "error",
      };
    },
  });

  // get list data-admin role
  const { data: dataAdminData } = useList<IProvinces>({
    resource: "users?role_id=2",
    errorNotification: false,
  });

  // get list team-leader role
  const { data: teamLeaderData } = useList<IProvinces>({
    resource: "users?role_id=4",
    errorNotification: false,
  });

  useEffect(() => {
    const queryResult: any = dataAdminData?.data;
    if (queryResult) {
      const optionsDataAdmin = queryResult?.data?.map((item: any) => {
        return {
          label: item.full_name,
          value: item.id,
        };
      });
      setOptionsDataAdmin(optionsDataAdmin);
    }
  }, [dataAdminData]);

  useEffect(() => {
    const queryResult: any = teamLeaderData?.data;
    if (queryResult) {
      const optionsTeamLeader = queryResult?.data?.map((item: any) => {
        return {
          label: item.full_name,
          value: item.id,
        };
      });
      setOptionsTeamLeader(optionsTeamLeader);
    }
  }, [teamLeaderData]);

  useEffect(() => {
    setSelectedProvince(1);
  }, []);

  useEffect(() => {
    if (selectedProvice) {
      getDistricts(selectedProvice).then((response) => {
        const optionsDistricts = response?.data.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
        setOptionsDistricts(optionsDistricts);
      });
    }
  }, [selectedProvice]);

  const handleOnFinish: any = (values: IListArea) => {
    onFinish({
      ...values,
      data_admin_id: Number(values?.data_admin_id),
      status: isActive,
    });
  };

  const filteredOptionsTeamLeader = optionsTeamLeader?.filter(
    (o: any) => !selectedItemsTeamLeader?.includes(o)
  );

  const filteredOptionsDistrict = optionsDistricts?.filter(
    (o: any) => !selectedItemsDistrict?.includes(o)
  );

  return (
    <Create
      title={translate("pages.cartManagement.areasManagement.createNewArea")}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        initialValues={{ status: isActive }}
        layout="vertical"
        form={form}
      >
        <Form.Item
          name="name"
          label={translate("pages.cartManagement.areasManagement.name")}
          rules={[
            {
              required: true,
              message: translate("validations.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("pages.cartManagement.areasManagement.dataAdmin")}
          name="data_admin_id"
        >
          <Select
            options={optionsDataAdmin}
            placeholder={translate("placeholder.selectADataAdmin")}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <Form.Item
          name="team_leader_ids"
          label={translate("pages.cartManagement.areasManagement.teamLeader")}
        >
          <Select
            mode="multiple"
            placeholder={translate("placeholder.selectAOneOrManyTeamLeader")}
            options={filteredOptionsTeamLeader}
            showSearch
            onChange={setSelectedItemsTeamLeader}
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>

        <Form.Item
          name="district_ids"
          label={translate("pages.cartManagement.areasManagement.district")}
        >
          <Select
            mode="multiple"
            showSearch
            placeholder={translate("placeholder.selectAOneOrManyDistricts")}
            options={filteredOptionsDistrict}
            onChange={setSelectedItemsDistrict}
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
          />
        </Form.Item>

        <Form.Item
          label={translate("pages.cartManagement.areasManagement.status")}
        >
          <Form.Item valuePropName="checked">
            <Checkbox
              style={{
                fontSize: "12px",
              }}
              onChange={() => setIsActive(true)}
              checked={isActive ? true : false}
            >
              {translate("status.active")}
            </Checkbox>
            <Checkbox
              style={{
                fontSize: "12px",
              }}
              onChange={() => setIsActive(false)}
              checked={!isActive ? true : false}
            >
              {translate("status.inactive")}
            </Checkbox>
          </Form.Item>
        </Form.Item>
      </Form>
    </Create>
  );
};
