export const STATUS_BILLS = [
  {
    value: "pending",
    label: "status.pending",
  },
  {
    value: "paid",
    label: "status.paid",
  },
  {
    value: "overdue",
    label: "status.overdue",
  },
];
