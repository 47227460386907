import { useNavigate } from "react-router-dom";
import { useTranslate } from "@refinedev/core";
import { Modal, Button } from "antd";

import "./styles.css";
import { parsePhoneToVNWithoutSpace } from "helper";

interface IRemiderDashboard {
  isOpen: boolean;
  setIsOpen: (value: any) => void;
  title: string;
  data: { appointments: [] };
}

export const RemiderDashboard = ({
  isOpen,
  setIsOpen,
  title,
  data,
}: IRemiderDashboard) => {
  const translate = useTranslate();
  const navigate = useNavigate();

  return (
    <Modal
      style={{ width: "100%", resize: "none" }}
      open={isOpen}
      title={<h3>{title}</h3>}
      onCancel={() => {
        setIsOpen(false);
      }}
      footer={null}
    >
      {data?.appointments?.map((item: any, idx: number) => {
        return (
          <div key={idx} className="listItemReminder">
            <div
              className="fullName"
              onClick={() => navigate(`/deals/show/${item?.id}`)}
            >
              <span>{idx + 1}.</span> {item?.customer?.fullName}
            </div>
            <Button
              onClick={() => {
                window.open(
                  `tel:${parsePhoneToVNWithoutSpace(item?.customer?.phone)}`
                );
              }}
              style={{ borderRadius: 20 }}
            >
              {translate("pages.dashboard.callCustomer")}
            </Button>
          </div>
        );
      })}
    </Modal>
  );
};
