import { useTranslate } from "@refinedev/core";
import { Button, DatePicker, Modal, Space } from "antd";
import dayjs from "dayjs";

import "./styles.css";
import { getTimestampStartDate } from "helper";

interface IFilterTime {
  isOpen: boolean;
  setIsOpen: (value: any) => void;
  title: string;
  time: number;
  setTime: (value: any) => void;
}

export const FilterTime = ({
  isOpen,
  setIsOpen,
  title,
  time,
  setTime,
}: IFilterTime) => {
  const translate = useTranslate();

  return (
    <Modal
      open={isOpen}
      title={title}
      onCancel={() => setIsOpen(false)}
      footer={[
        <Button
          key="clear"
          danger
          onClick={() => {
            setTime(0);
            setIsOpen(false);
          }}
        >
          {translate("buttons.clear")}
        </Button>,
        <Button key="seeResult" type="primary" onClick={() => setIsOpen(false)}>
          {translate("buttons.seeResult")}
        </Button>,
      ]}
      className="filterTime"
    >
      <div className="listItem">
        <Space className="wrapper" direction="vertical" size={12}>
          <DatePicker
            inputReadOnly={true}
            placeholder={translate("placeholder.selectDateTime")}
            onChange={(e: any) => {
              if (e) {
                setTime(getTimestampStartDate(new Date(e)));
              } else {
                setTime(0);
              }
            }}
            value={time ? dayjs(time) : null}
            getPopupContainer={(trigger: any) => trigger.parentNode}
          />
        </Space>
      </div>
    </Modal>
  );
};
