import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { EditButton, Show, TextField } from "@refinedev/antd";

import { Space, Tag, Typography } from "antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IListSaleRights } from "interfaces";

const { Title } = Typography;

export const SaleRightsShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IListSaleRights>();
  const { data, isLoading } = queryResult;
  const record: any = data?.data;

  return (
    <Show
      isLoading={isLoading}
      title={translate(
        "pages.cartManagement.saleRightsManagement.saleRights.showSaleRights"
      )}
      headerButtons={({
        listButtonProps,
        editButtonProps,
        refreshButtonProps,
      }) => (
        <>
          {editButtonProps && (
            <EditButton
              {...editButtonProps}
              title={translate(
                "pages.cartManagement.saleRightsManagement.saleRights.editSaleRights"
              )}
            />
          )}
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
    >
      <Space>
        <Title level={5}>
          {translate(
            "pages.cartManagement.saleRightsManagement.saleRights.name"
          )}
          :
        </Title>
        <TextField value={record?.data?.name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate(
            "pages.cartManagement.saleRightsManagement.saleRights.buildingGroups"
          )}
          :
        </Title>
        <TextField
          value={record?.data?.building_groups.map((item: any, idx: number) => {
            return (
              <Tag key={idx}>
                <TextField value={item?.name} />
              </Tag>
            );
          })}
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate(
            "pages.cartManagement.saleRightsManagement.saleRights.saleGroups"
          )}
          :
        </Title>
        <TextField
          value={record?.data?.sale_groups.map((item: any, idx: number) => {
            return (
              <Tag key={idx}>
                <TextField value={item?.name} />
              </Tag>
            );
          })}
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate(
            "pages.cartManagement.saleRightsManagement.saleRights.status"
          )}
          :
        </Title>
        <TextField
          strong
          value={
            record?.data?.status === "active" ? (
              <Tag bordered={false} color="success">
                {translate(
                  "pages.cartManagement.saleRightsManagement.saleRights.active"
                )}
              </Tag>
            ) : (
              <Tag bordered={false} color="error">
                {translate(
                  "pages.cartManagement.saleRightsManagement.saleRights.inactive"
                )}
              </Tag>
            )
          }
        />
      </Space>
      <br />
    </Show>
  );
};
