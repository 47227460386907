export const STATUS_LANDLORD_BILLS = [
  // [ unpaid, partial_paid, paid ]
  {
    value: "unpaid",
    label: "status.unpaid",
    isChecked: false,
  },
  {
    value: "partial_paid",
    label: "status.partialPaid",
    isChecked: false,
  },
  {
    value: "paid",
    label: "status.paid",
    isChecked: false,
  },
];

export const STATUS_SEND_ZNS = [
  {
    value: true,
    label: "status.alreadySentZns",
    isChecked: false,
  },
  {
    value: false,
    label: "status.doestNotSentZns",
    isChecked: false,
  }
]
