export const STATUS_CONTRACTS = [
  {
    value: "pending",
    label: "status.pendingContract",
    isChecked: false,
  },
  {
    value: "active",
    label: "status.activeContract",
    isChecked: false,
  },
  {
    value: "terminated",
    label: "status.terminatedContract",
    isChecked: false,
  },
  {
    value: "overdue",
    label: "status.overdueContract",
    isChecked: false,
  },
];

export const handleTranslate = (value: string, translate: any) => {
  switch (value) {
    case "pending":
      return translate("status.pendingContract");
    case "active":
      return translate("status.activeContract");
    case "terminated":
      return translate("status.terminatedContract");
    case "overdue":
      return translate("status.overdueContract");
    default:
      break;
  }
};
