import {
  IResourceComponentsProps,
  useTranslate,
  CrudFilters,
  useList,
  useNavigation,
} from "@refinedev/core";
import {
  List,
  useTable,
  TextField,
  EditButton,
  ShowButton,
  CreateButton,
} from "@refinedev/antd";
import { Button, Form, Input, Space, Table, Tag } from "antd";
import { useState, useEffect } from "react";
import { CaretDownOutlined, FilterFilled } from "@ant-design/icons";

import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_SALE,
  ROLE_TEAM_LEADER,
  formatTime,
  getParamFilter,
  hasChecked,
  onCheckFilter,
  onClearFilter,
  parsePhoneToVNWithoutSpace,
} from "helper";

import { IListUser } from "interfaces";
import { STATUS_USER } from "./constant";
import { FilterNormal } from "components/modal";
import { IRoles } from "interfaces/roles";
import {
  getCurrentPagination,
  getFilterUsers,
  setCurrentPagination,
  setFilterUsers,
} from "helper/local-storage";
import { useNavigate } from "react-router-dom";

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const filter = JSON.parse(getFilterUsers() || "{}");
  const auth = JSON.parse(localStorage.getItem("auth") || "{}");
  const isLoggedbyDataAdmin = auth?.role?.name === ROLE_DATA_ADMIN;
  const isLoggedbyAdmin = auth?.role?.name === ROLE_ADMIN;

  const [keyword, setKeyword] = useState<string>(filter?.keyword || "");
  const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);
  const [isOpenModalRole, setIsOpenModalRole] = useState<boolean>(false);
  const [listStatus, setListStatus] = useState<any>(
    filter?.listStatus || STATUS_USER
  );
  const [listRole, setListRole] = useState<any>(filter?.listRole || []);
  const [pagination, setPagination] = useState(
    JSON.parse(getCurrentPagination() || "{}") || {
      current: 1,
      pageSize: 10,
    }
  );

  // get role data
  const { data: rolesData } = useList<IRoles>({
    resource: "roles",
    errorNotification: false,
  });

  const { searchFormProps, tableProps, setFilters } = useTable<IListUser>({
    resource: "users",
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { newKeyword } = params;

      filters.push({
        field: "keyword",
        operator: "eq",
        value: newKeyword || null,
      });
      setFilterUsers(JSON.stringify({ ...filter, keyword: newKeyword }));
      return filters;
    },
  });
  const dataSource: any = tableProps?.dataSource;
  let usersTableProps = {
    ...tableProps,
    pagination: pagination,
    dataSource: dataSource?.data,
  };

  const handleTableChange = (pagination: any) => {
    setPagination({ current: pagination, pageSize: 10 });
  };

  useEffect(() => {
    setCurrentPagination(JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    const storedPagination = getCurrentPagination();
    if (storedPagination) {
      setPagination(JSON.parse(storedPagination));
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchFormProps.form?.submit();
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    if (!hasChecked(listRole)) {
      const roles: any = rolesData?.data ?? [];
      const queryResult = roles?.data;

      if (roles?.status) {
        const optionsRoles = queryResult?.map((item: any) => ({
          label: translate(`roles.${item.name}`),
          value: item.id,
          isChecked: false,
        }));
        setListRole(optionsRoles);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesData]);

  useEffect(() => {
    const statusParam = getParamFilter(listStatus);
    const roleParam = getParamFilter(listRole);
    setFilters(
      [
        {
          field: "status",
          operator: "eq",
          value: statusParam,
        },
        {
          field: "role_id",
          operator: "eq",
          value: roleParam,
        },
      ],
      "merge"
    );
    setFilterUsers(JSON.stringify({ ...filter, listStatus, listRole }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listStatus, listRole]);

  useEffect(() => {
    // redirect if not authen
    if (isLoggedbyAdmin || isLoggedbyDataAdmin) {
      return;
    } else {
      navigate("/");
    }
  }, [isLoggedbyAdmin, isLoggedbyDataAdmin]);

  return (
    <List
      title={translate("pages.users.list")}
      headerButtons={({ createButtonProps }) => (
        <>
          <Form {...searchFormProps}>
            <Form.Item
              name="newKeyword"
              initialValue={keyword}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={translate("placeholder.searchByInfoUser")}
                onChange={(e) => setKeyword(e.target.value)}
                allowClear={true}
              />
            </Form.Item>
          </Form>

          <CreateButton {...createButtonProps} />
        </>
      )}
    >
      <Space className="listFilter">
        <Button style={{ pointerEvents: "none" }}>
          <FilterFilled />
          {translate("buttons.filter")}
        </Button>
        <Button
          onClick={() => setIsOpenModalStatus(true)}
          className={hasChecked(listStatus) ? "isEnabled" : ""}
        >
          {translate("pages.users.status")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalStatus && (
          <FilterNormal
            isOpen={isOpenModalStatus}
            setIsOpen={setIsOpenModalStatus}
            title={translate("pages.users.status")}
            data={listStatus}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listStatus, false);
              setListStatus(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listStatus);
              setListStatus(newData);
            }}
            multiple={false}
          />
        )}
        <Button
          onClick={() => setIsOpenModalRole(true)}
          className={hasChecked(listRole) ? "isEnabled" : ""}
        >
          {translate("pages.users.role")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalRole && (
          <FilterNormal
            isOpen={isOpenModalRole}
            setIsOpen={setIsOpenModalRole}
            title={translate("pages.users.role")}
            data={listRole}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listRole, false);
              setListRole(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listRole);
              setListRole(newData);
            }}
            multiple={false}
          />
        )}
      </Space>
      <Table
        {...usersTableProps}
        pagination={{
          defaultPageSize: 10,
          // showSizeChanger: true,
          // pageSizeOptions: ["10", "20", "30", "40"],
          current: pagination.current,
          onChange: handleTableChange,
        }}
        rowKey="id"
        className="list-users"
      >
        <Table.Column
          dataIndex="full_name"
          title={translate("pages.users.fullName")}
        />
        <Table.Column
          dataIndex="phone"
          title={translate("pages.users.phone")}
          render={(value) => {
            return (
              <TextField
                value={value ? parsePhoneToVNWithoutSpace(value) : ""}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="second_phone"
          title={translate("pages.users.secondPhone")}
          render={(value) => {
            return (
              <TextField
                value={value ? parsePhoneToVNWithoutSpace(value) : ""}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="email"
          title={translate("pages.users.email")}
        />
        <Table.Column
          dataIndex="telegram"
          title={translate("pages.users.telegram")}
        />
        <Table.Column
          dataIndex="zalo"
          title={translate("pages.users.zalo")}
          render={(value) => {
            return (
              <TextField
                value={value ? parsePhoneToVNWithoutSpace(value) : ""}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="identity_card_number"
          title={translate("pages.users.identityCardNumber")}
        />
        <Table.Column
          dataIndex="address"
          title={translate("pages.users.address")}
        />
        <Table.Column
          dataIndex="province"
          title={translate("pages.users.province")}
          key="province"
          render={(value) => {
            return <TextField value={value?.name} />;
          }}
        />
        <Table.Column
          dataIndex="district"
          title={translate("pages.users.district")}
          key="district"
          render={(value) => {
            return <TextField value={value?.name} />;
          }}
        />
        <Table.Column
          dataIndex="ward"
          title={translate("pages.users.ward")}
          key="ward"
          render={(value) => {
            return <TextField value={value?.name} />;
          }}
        />
        <Table.Column
          dataIndex="role"
          title={translate("pages.users.role")}
          key="role"
          render={(value) => {
            return <TextField value={translate(`roles.${value?.name}`)} />;
          }}
        />
        <Table.Column<IListUser>
          title={translate("pages.users.status")}
          dataIndex="is_active"
          render={(value) => {
            return (
              <TextField
                strong
                value={
                  value === true ? (
                    <Tag bordered={false} color="success">
                      {translate("pages.users.active")}
                    </Tag>
                  ) : (
                    <Tag bordered={false} color="error">
                      {translate("pages.users.inactive")}
                    </Tag>
                  )
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="created_at"
          title={translate("pages.createdAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />
        <Table.Column
          width={400}
          dataIndex="updated_at"
          title={translate("pages.updatedAt")}
          render={(value) => {
            return <TextField value={formatTime(value)} />;
          }}
        />

        <Table.Column<IListUser>
          title={translate("table.actions")}
          dataIndex="actions"
          fixed="right"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
