export const STATUS_RESERVATIONS = [
  {
    label: "const.waitingForDeposit",
    value: "waiting-for-deposit",
    isChecked: false,
  },
  { label: "const.deposited", value: "deposited", isChecked: false },
  {
    label: "const.checkedIn",
    value: "checked-in",
    isChecked: false,
  },
  {
    label: "const.checkedOut",
    value: "checked-out",
    isChecked: false,
  },
  {
    label: "const.canceled",
    value: "canceled",
    isChecked: false,
  },
];

export const statusColors = (value: string) => {
  switch (value) {
    case "checked-out":
      return "default";
    case "deposited":
    case "checked-in":
      return "success";
    case "canceled":
      return "error";
  }
};

export const handleTranslate = (value: string, translate: any) => {
  switch (value) {
    case "checked-in":
      return translate("const.checkedIn");
    case "checked-out":
      return translate("const.checkedOut");
    case "deposited":
      return translate("const.deposited");
    case "canceled":
      return translate("const.canceled");
    case "waiting-for-deposit":
      return translate("const.waitingForDeposit");
    default:
      break;
  }
};

export const PERIOD_RESERVATIONS = [
  { label: "const.daily", value: "daily", isChecked: false },
  {
    label: "const.monthly",
    value: "monthly",
    isChecked: false,
  },
];
