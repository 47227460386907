import {
  CrudFilters,
  IResourceComponentsProps,
  usePermissions,
  useTranslate,
  useList,
} from "@refinedev/core";
import {
  CreateButton,
  EditButton,
  List,
  ShowButton,
  TextField,
  useTable,
} from "@refinedev/antd";
import { Button, Form, Input, Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { CaretDownOutlined, FilterFilled } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

import { IListBuildings, IListUser, IProvinces } from "interfaces";
import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_LANDLORD,
  ROLE_SALE,
  ROLE_TEAM_LEADER,
  formatPrice,
  getParamFilter,
  hasChecked,
  onCheckFilter,
  onClearFilter,
} from "helper";
import { FilterNormal } from "components/modal";
import { getDistricts, getWards } from "api";
import {
  getCurrentPagination,
  getFilterBuildings,
  setCurrentPagination,
  setFilterBuildings,
} from "helper/local-storage";
import { STATUS_APPROVED } from "./constant";

export const RealEstateBuildingsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const permissions = usePermissions<string>();
  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissions?.data === ROLE_DATA_ADMIN;
  const isLoggedbyTeamLeader = permissions?.data === ROLE_TEAM_LEADER;
  const isLoggedBySale = permissions?.data === ROLE_SALE;
  const isLoggedbyLandlord = permissions?.data === ROLE_LANDLORD;

  const auth = JSON.parse(localStorage.getItem("auth") || "{}");
  const location = useLocation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const landlordIdUrlParams = urlParams.get("landlord_id");
  const filter = JSON.parse(getFilterBuildings() || "null");
  const [keyword, setKeyword] = useState<string>(filter?.keyword || "");
  const [isOpenModalLandlord, setIsOpenModalLandlord] =
    useState<boolean>(false);
  const [isOpenModalProvince, setIsOpenModalProvince] =
    useState<boolean>(false);
  const [isOpenModalDistrict, setIsOpenModalDistrict] =
    useState<boolean>(false);
  const [isOpenModalWard, setIsOpenModalWard] = useState<boolean>(false);
  const [isOpenModalApproved, setIsOpenModalApproved] =
    useState<boolean>(false);
  const [listLandlord, setListLandlord] = useState<any>(
    filter?.listLandlord || []
  );
  const [listProvince, setListProvince] = useState<any>(
    filter?.listProvince || []
  );
  const [listDistrict, setListDistrict] = useState<any>(
    filter?.listDistrict || []
  );
  const [listApproved, setListApproved] = useState<any>(
    filter?.listApproved || STATUS_APPROVED
  );
  const [listWard, setListWard] = useState<any>(filter?.listWard || []);
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  const [skipGetWardsFirst, setSkipGetWardsFirst] = useState<boolean>(
    filter ? true : false
  );
  const [pagination, setPagination] = useState(
    JSON.parse(getCurrentPagination() || "{}") || {
      current: 1,
      pageSize: 10,
    }
  );

  // get landlord data
  const { data: landLordsData } = useList<IListUser>({
    resource: "landlords",
    errorNotification: false,
  });

  // get province data
  const { data: provincesData } = useList<IProvinces>({
    resource: "addresses/provinces",
    errorNotification: false,
  });

  const { searchFormProps, tableProps, setFilters } = useTable<IListBuildings>({
    resource: `buildings${location?.search}`,
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { newKeyword } = params;

      filters.push({
        field: "keyword",
        operator: "eq",
        value: newKeyword || null,
      });
      setFilterBuildings(JSON.stringify({ ...filter, keyword: newKeyword }));
      return filters;
    },
  });

  const dataSource: any = tableProps?.dataSource;

  let buildingsTableProps = {
    ...tableProps,
    pagination: pagination,
    dataSource: dataSource?.data,
  };

  const handleTranslate = (value: string) => {
    switch (value) {
      case "magnetic_card":
        return translate("const.magneticCard");
      case "guard":
        return translate("const.guard");
      case "no_guard":
        return translate("const.noGuard");
      default:
        break;
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination({ current: pagination, pageSize: 10 });
  };

  useEffect(() => {
    setCurrentPagination(JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    const storedPagination = getCurrentPagination();
    if (storedPagination) {
      setPagination(JSON.parse(storedPagination));
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchFormProps.form?.submit();
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    if (!hasChecked(listLandlord)) {
      const landlords: any = landLordsData?.data ?? [];
      const queryResult = landlords?.data;
      if (landlords?.status) {
        const optionsLandlords = queryResult?.map((item: any) => ({
          label: item.full_name,
          value: item.id,
          isChecked: false,
        }));
        setListLandlord(optionsLandlords);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landLordsData]);

  useEffect(() => {
    if (!hasChecked(listProvince)) {
      const provinces: any = provincesData?.data ?? [];
      const queryResult = provinces?.data;
      if (provinces?.status) {
        const optionsProvinces = queryResult?.map((item: any) => ({
          label: item.name,
          value: item.id,
          isChecked: false,
        }));
        setListProvince(optionsProvinces);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provincesData]);

  useEffect(() => {
    const selectedProvince = listProvince?.find((item: any) => item.isChecked);
    if (
      selectedProvince &&
      selectedProvince?.value !== listDistrict[0]?.provinceId
    ) {
      getDistricts(selectedProvince?.value).then((response) => {
        const optionsDistricts = response?.data.map((item: any) => ({
          label: item.name,
          value: item.id,
          provinceId: item.province_id,
          isChecked: false,
        }));
        setListDistrict(optionsDistricts);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listProvince]);

  useEffect(() => {
    if (skipGetWardsFirst) {
      setSkipGetWardsFirst(false);
      return;
    }
    const districtChecked = listDistrict?.find(
      (item: any) => item.value === selectedDistrict
    );
    if (districtChecked) {
      if (districtChecked.isChecked) {
        // eslint-disable-next-line array-callback-return
        getWards(districtChecked.value).then((response) => {
          const optionsWards = response?.data.map((item: any) => ({
            label: item.name,
            value: item.id,
            districtId: item.district_id,
            isChecked: false,
          }));
          setListWard([...listWard, ...optionsWards]);
        });
      } else {
        const newListWard = listWard.filter(
          (item: any) => item.districtId !== districtChecked.value
        );
        setListWard(newListWard);
      }
    } else {
      setListWard([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listDistrict, selectedDistrict]);

  useEffect(() => {
    const landlordParam = getParamFilter(listLandlord);
    const provinceParam = getParamFilter(listProvince);
    const districtParams = getParamFilter(listDistrict);
    const wardParams = getParamFilter(listWard);
    const approvedParams = getParamFilter(listApproved);

    setFilters(
      [
        {
          field: "landlord_id",
          operator: "eq",
          value: landlordParam,
        },
        {
          field: "province_id",
          operator: "eq",
          value: provinceParam,
        },
        {
          field: "district_ids",
          operator: "eq",
          value: districtParams,
        },
        {
          field: "ward_ids",
          operator: "eq",
          value: wardParams,
        },
        {
          field: "approved",
          operator: "eq",
          value: approvedParams,
        },
      ],
      "merge"
    );
    setFilterBuildings(
      JSON.stringify({
        ...filter,
        listLandlord,
        listProvince,
        listDistrict,
        listWard,
        listApproved,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listLandlord, listProvince, listDistrict, listWard, listApproved]);

  return (
    <List
      title={
        landlordIdUrlParams
          ? translate("pages.cartManagement.buildings.listOfLandlord") +
            ` ${dataSource?.data[0]?.landlord?.full_name}`
          : translate("pages.cartManagement.buildings.list")
      }
      headerButtons={({ createButtonProps }) => (
        <>
          <Form {...searchFormProps}>
            <Form.Item
              name="newKeyword"
              initialValue={keyword}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={translate("placeholder.searchByNameBuildings")}
                onChange={(e) => setKeyword(e.target.value)}
                allowClear={true}
              />
            </Form.Item>
          </Form>
          {isLoggedbyAdmin ||
          isLoggedbyDataAdmin ||
          isLoggedbyTeamLeader ||
          isLoggedbyLandlord ? (
            <>{createButtonProps && <CreateButton />}</>
          ) : null}
        </>
      )}
    >
      <Space className="listFilter">
        <Button style={{ pointerEvents: "none" }}>
          <FilterFilled />
          {translate("buttons.filter")}
        </Button>
        {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyTeamLeader ? (
          <Button
            onClick={() => setIsOpenModalLandlord(true)}
            className={hasChecked(listLandlord) ? "isEnabled" : ""}
          >
            {translate("pages.cartManagement.buildings.landlord")}
            <CaretDownOutlined />
          </Button>
        ) : null}

        {isOpenModalLandlord && (
          <FilterNormal
            isOpen={isOpenModalLandlord}
            setIsOpen={setIsOpenModalLandlord}
            title={translate("pages.cartManagement.buildings.landlord")}
            data={listLandlord}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listLandlord, false);
              setListLandlord(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listLandlord);
              setListLandlord(newData);
            }}
            multiple={false}
          />
        )}
        <Button
          onClick={() => setIsOpenModalProvince(true)}
          className={hasChecked(listProvince) ? "isEnabled" : ""}
        >
          {translate("pages.cartManagement.buildings.province")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalProvince && (
          <FilterNormal
            isOpen={isOpenModalProvince}
            setIsOpen={setIsOpenModalProvince}
            title={translate("pages.cartManagement.buildings.province")}
            data={listProvince}
            onCheck={(value) => {
              setListDistrict([]);
              setListWard([]);
              const newData = onCheckFilter(value, listProvince, false);
              setListProvince(newData);
            }}
            onClear={() => {
              setListDistrict([]);
              setListWard([]);
              const newData = onClearFilter(listProvince);
              setListProvince(newData);
            }}
            multiple={false}
          />
        )}
        <Button
          onClick={() => setIsOpenModalDistrict(true)}
          className={hasChecked(listDistrict) ? "isEnabled" : ""}
          disabled={hasChecked(listProvince) ? false : true}
        >
          {translate("pages.cartManagement.buildings.district")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalDistrict && (
          <FilterNormal
            isOpen={isOpenModalDistrict}
            setIsOpen={setIsOpenModalDistrict}
            title={translate("pages.cartManagement.buildings.district")}
            data={listDistrict}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listDistrict, true);
              setListDistrict(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listDistrict);
              setListDistrict(newData);
            }}
            multiple={true}
            setSelectedValue={setSelectedDistrict}
          />
        )}
        <Button
          onClick={() => setIsOpenModalWard(true)}
          className={hasChecked(listWard) ? "isEnabled" : ""}
          disabled={hasChecked(listDistrict) ? false : true}
        >
          {translate("pages.cartManagement.buildings.ward")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalWard && (
          <FilterNormal
            isOpen={isOpenModalWard}
            setIsOpen={setIsOpenModalWard}
            title={translate("pages.cartManagement.buildings.ward")}
            data={listWard}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listWard, true);
              setListWard(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listWard);
              setListWard(newData);
            }}
            multiple={true}
          />
        )}
      </Space>

      {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyTeamLeader ? (
        <Button
          onClick={() => setIsOpenModalApproved(true)}
          className={hasChecked(listApproved) ? "isEnabled" : ""}
        >
          {translate("pages.cartManagement.buildings.status")}
          <CaretDownOutlined />
        </Button>
      ) : null}

      {isOpenModalApproved && (
        <FilterNormal
          isOpen={isOpenModalApproved}
          setIsOpen={setIsOpenModalApproved}
          title={translate("pages.cartManagement.buildings.status")}
          data={listApproved}
          onCheck={(value) => {
            const newData = onCheckFilter(value, listApproved, false);
            setListApproved(newData);
          }}
          onClear={() => {
            const newData = onClearFilter(listApproved);
            setListApproved(newData);
          }}
          multiple={false}
        />
      )}

      <Table
        {...buildingsTableProps}
        pagination={{
          defaultPageSize: 10,
          // showSizeChanger: true,
          // pageSizeOptions: ["10", "20", "30", "40"],
          current: pagination.current,
          onChange: handleTableChange,
        }}
        rowKey="id"
      >
        <Table.Column
          dataIndex="id"
          title={translate("pages.cartManagement.buildings.id")}
        />
        <Table.Column
          dataIndex="name"
          title={translate("pages.cartManagement.buildings.name")}
        />
        {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyTeamLeader ? (
          <Table.Column
            dataIndex="approved"
            title={translate("pages.cartManagement.buildings.status")}
            render={(value) => {
              return (
                <TextField
                  strong
                  value={
                    value ? (
                      <Tag bordered={false} color="success">
                        {translate("status.approved")}
                      </Tag>
                    ) : (
                      <Tag bordered={false} color="error">
                        {translate("status.notApprovedYet")}
                      </Tag>
                    )
                  }
                />
              );
            }}
          />
        ) : null}
        <Table.Column
          dataIndex="description"
          title={translate("pages.cartManagement.buildings.description")}
        />
        <Table.Column
          dataIndex="link_google_map"
          title={translate("pages.cartManagement.buildings.linkGoogleMap")}
          key="link_google_map"
          render={(value) => {
            return <Link to={value}>{value}</Link>;
          }}
        />
        <Table.Column
          dataIndex="address"
          title={translate("pages.cartManagement.buildings.address")}
        />
        <Table.Column
          dataIndex="gate_guard"
          title={translate("pages.cartManagement.buildings.gateGuard")}
          key="gate_guard"
          render={(value) => {
            return <TextField value={handleTranslate(value)} />;
          }}
        />
        <Table.Column
          dataIndex="province"
          title={translate("pages.cartManagement.buildings.province")}
          key="province"
          render={(value) => {
            return <TextField value={value?.name} />;
          }}
        />
        <Table.Column
          dataIndex="district"
          title={translate("pages.cartManagement.buildings.district")}
          key="district"
          render={(value) => {
            return <TextField value={value?.name} />;
          }}
        />
        <Table.Column
          dataIndex="ward"
          title={translate("pages.cartManagement.buildings.ward")}
          key="ward"
          render={(value) => {
            return <TextField value={value?.name} />;
          }}
        />

        {!isLoggedbyLandlord ? (
          <Table.Column
            dataIndex="landlord"
            title={translate("pages.cartManagement.buildings.owner")}
            key="landlord"
            render={(value) => {
              return <TextField value={value?.full_name} />;
            }}
          />
        ) : null}

        <Table.Column
          dataIndex="electricity_price"
          title={translate("pages.cartManagement.buildings.electricityPrice")}
          key="electricity_price"
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />
        <Table.Column
          dataIndex="charge_water"
          title={translate("pages.cartManagement.buildings.chargeWater")}
          key="charge_water"
          render={(value) => {
            return (
              <TextField
                value={
                  value === "person"
                    ? translate("const.person")
                    : translate("const.usedAmount")
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex="water_price"
          title={translate("pages.cartManagement.buildings.waterPrice")}
          key="water_price"
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />
        <Table.Column
          dataIndex="internet_price"
          title={translate("pages.cartManagement.buildings.internetPrice")}
          key="internet_price"
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />
        <Table.Column
          dataIndex="parking_price"
          title={translate("pages.cartManagement.buildings.parkingPrice")}
          key="parking_price"
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />
        <Table.Column
          dataIndex="management_price"
          title={translate("pages.cartManagement.buildings.managementPrice")}
          key="management_price"
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />
        <Table.Column
          dataIndex="pet"
          title={translate("pages.cartManagement.buildings.pet")}
        />
        <Table.Column
          dataIndex="rinse_clothesline"
          title={translate("pages.cartManagement.buildings.rinseClothesline")}
        />
        <Table.Column
          dataIndex="amount_people_vehicle"
          title={translate(
            "pages.cartManagement.buildings.amountPeopleVehicle"
          )}
        />
        {!isLoggedBySale ||
          (!isLoggedbyLandlord && (
            <>
              <Table.Column
                dataIndex="six_months_commission_pct"
                title={translate(
                  "pages.cartManagement.buildings.sixMonthsCommissionPct"
                )}
                key="six_months_commission_pct"
                render={(value) => {
                  if (value) {
                    return <TextField value={value + " %"} />;
                  } else {
                    return <TextField value={0 + " %"} />;
                  }
                }}
              />
              <Table.Column
                dataIndex="one_year_commission_pct"
                title={translate(
                  "pages.cartManagement.buildings.oneYearCommissionPct"
                )}
                key="one_year_commission_pct"
                render={(value) => {
                  if (value) {
                    return <TextField value={value + " %"} />;
                  } else {
                    return <TextField value={0 + " %"} />;
                  }
                }}
              />
            </>
          ))}
        <Table.Column
          dataIndex="notes"
          title={translate("pages.cartManagement.buildings.notes")}
        />
        {isLoggedbyAdmin ||
        isLoggedbyDataAdmin ||
        isLoggedbyTeamLeader ||
        isLoggedBySale ? (
          <Table.Column
            dataIndex="actor"
            title={translate("pages.cartManagement.buildings.actor")}
            render={(value) => {
              return <TextField strong value={value?.full_name} />;
            }}
          />
        ) : null}

        {isLoggedbyAdmin ||
        isLoggedbyDataAdmin ||
        isLoggedbyTeamLeader ||
        isLoggedbyLandlord ? (
          <Table.Column<IListBuildings>
            title={translate("table.actions")}
            dataIndex="actions"
            fixed="right"
            render={(_, record) => {
              return (
                <Space>
                  {isLoggedbyAdmin ||
                  isLoggedbyDataAdmin ||
                  isLoggedbyLandlord ? (
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  ) : (
                    <>
                      {isLoggedbyTeamLeader &&
                      record?.actor_id === auth?.id &&
                      !record?.approved ? (
                        <EditButton
                          hideText
                          size="small"
                          recordItemId={record.id}
                        />
                      ) : null}
                    </>
                  )}
                  <ShowButton hideText size="small" recordItemId={record.id} />
                </Space>
              );
            }}
          />
        ) : null}
      </Table>
    </List>
  );
};
