import {
  IResourceComponentsProps,
  useParsed,
  usePermissions,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { EditButton, Show, TextField } from "@refinedev/antd";
import { Space, Tag, Typography } from "antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IListTeams } from "interfaces";
import { ROLE_ADMIN, formatTime } from "helper";

const { Title } = Typography;

export const TeamsManagementShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { id } = useParsed();
  const permissions = usePermissions<string>();
  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const { queryResult } = useShow<IListTeams>({ resource: "teams", id });
  const { data, isLoading } = queryResult;
  const record: any = data?.data;

  return (
    <Show
      isLoading={isLoading}
      title={translate("pages.teamsManagement.showTeam")}
      headerButtons={({
        listButtonProps,
        editButtonProps,
        refreshButtonProps,
      }) => (
        <>
          {editButtonProps && (
            <>
              {isLoggedbyAdmin ? (
                <EditButton
                  {...editButtonProps}
                  title={translate("pages.teamsManagement.editArea")}
                />
              ) : null}
            </>
          )}
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
    >
      <Space>
        <Title level={5}>{translate("pages.teamsManagement.name")}:</Title>
        <TextField value={record?.data?.name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>
          {translate("pages.teamsManagement.teamLeader")}:
        </Title>
        <TextField value={record?.data?.leader?.full_name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.teamsManagement.sales")}:</Title>
        <TextField
          value={record?.data?.sales?.map((item: any, idx: number) => {
            return (
              <Tag key={idx}>
                <TextField value={item?.full_name} />
              </Tag>
            );
          })}
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.teamsManagement.status")}:</Title>
        <TextField
          strong
          value={
            record?.data?.status ? (
              <Tag bordered={false} color="success">
                {translate("status.active")}
              </Tag>
            ) : (
              <Tag bordered={false} color="error">
                {translate("status.active")}
              </Tag>
            )
          }
        />
      </Space>
      <br />

      <Space>
        <Title level={5}>{translate("pages.createdAt")}:</Title>
        <TextField value={formatTime(record?.data?.created_at)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.updatedAt")}:</Title>
        <TextField value={formatTime(record?.data?.updated_at)} />
      </Space>
      <br />
    </Show>
  );
};
