import { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import { Edit, SaveButton, useForm } from "@refinedev/antd";

import {
  DatePicker,
  DatePickerProps,
  Form,
  InputNumber,
  Select,
  Space,
} from "antd";
import { usePermissions } from "@refinedev/core";
import "react-mde/lib/styles/css/react-mde-all.css";
import { IListBills } from "interfaces";

import dayjs from "dayjs";
import { STATUS_BILLS } from "const";
import {
  formatInputPrice,
  handleFilterOption,
  handleSortOption,
  isValidPrice,
  parseInputPrice,
} from "helper";

export const BillsEdit: React.FC<IResourceComponentsProps> = (props) => {
  const { formProps, queryResult, saveButtonProps, onFinish, form } =
    useForm<IListBills>({
      resource: "bills",
    });
  const billsData: any = queryResult?.data?.data ?? "";

  const { data: permissionsData }: any = usePermissions();
  const translate = useTranslate();
  const [optionsContracts, setOptionsContracts] = useState<[]>([]);
  const [selectedFromDate, setSelectedFromDate] = useState<any>("");
  const [selectedToDate, setSelectedToDate] = useState<any>("");

  // get contracts data
  const { data: contractsData } = useList<IListBills>({
    resource: "contracts",
    errorNotification: false,
  });

  const handleOnFinish: any = (values: IListBills) => {
    onFinish({
      ...values,
      electricity_price: Number(values?.electricity_price),
      electricity_units: Number(values?.electricity_units),
      water_price: Number(values?.water_price),
      water_units: Number(values?.water_units),
      parking_price: Number(values?.parking_price),
      parking_units: Number(values?.parking_units),
      management_price: Number(values?.management_price),
      internet_price: Number(values?.internet_price),
      contract_id: Number(values?.contract_id),
      from_date: selectedFromDate,
      to_date: selectedToDate,
    });
  };

  const onChangeDateFromDate = (value: DatePickerProps["value"]) => {
    setSelectedFromDate(value?.format("YYYY-MM-DD"));
  };

  const onChangeDateToDate = (value: DatePickerProps["value"]) => {
    setSelectedToDate(value?.format("YYYY-MM-DD"));
  };

  useEffect(() => {
    if (contractsData) {
      const optionsContractsData: any = contractsData?.data;
      setOptionsContracts(
        optionsContractsData?.data.map((item: any) => {
          return {
            value: item.id,
            label: item.id,
          };
        })
      );
    }
  }, [contractsData]);

  useEffect(() => {
    if (billsData) {
      const selectedFromDate: string = billsData?.data?.from_date;
      const selectedToDate: string = billsData?.data?.to_date;
      setSelectedFromDate(selectedFromDate);
      setSelectedToDate(selectedToDate);
    }
  }, [billsData]);

  return (
    <Edit
      {...props}
      title={translate("pages.financialManagement.bills.editBill")}
      canDelete={permissionsData?.includes["admin"]}
      headerButtons={({ listButtonProps, refreshButtonProps }) => (
        <>
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} />
        </>
      )}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        layout="vertical"
        initialValues={{
          ...billsData?.data,
          from_date: selectedFromDate,
          to_date: selectedToDate,
        }}
        form={form}
      >
        <Form.Item
          name="electricity_price"
          label={translate("pages.financialManagement.bills.electricityPrice")}
          rules={[
            {
              required: true,
              message: translate("validations.electricityPrice"),
            },
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
          />
        </Form.Item>
        <Form.Item
          name="electricity_units"
          label={translate("pages.financialManagement.bills.electricityUnits")}
          rules={[
            {
              required: true,
              message: translate("validations.electricityUnits"),
            },
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="water_price"
          label={translate("pages.financialManagement.bills.waterPrice")}
          rules={[
            {
              required: true,
              message: translate("validations.waterPrice"),
            },
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
          />
        </Form.Item>
        <Form.Item
          name="water_units"
          label={translate("pages.financialManagement.bills.waterUnits")}
          rules={[
            {
              required: true,
              message: translate("validations.waterUnits"),
            },
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="parking_price"
          label={translate("pages.financialManagement.bills.parkingPrice")}
          rules={[
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
          />
        </Form.Item>
        <Form.Item
          name="parking_units"
          label={translate("pages.financialManagement.bills.parkingUnits")}
          rules={[
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="internet_price"
          label={translate("pages.financialManagement.bills.internetPrice")}
          rules={[
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
          />
        </Form.Item>
        <Form.Item
          name="management_price"
          label={translate("pages.financialManagement.bills.managementPrice")}
          rules={[
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
          />
        </Form.Item>
        <Form.Item
          label={translate("pages.financialManagement.bills.contractID")}
          name="contract_id"
          rules={[
            {
              required: true,
              message: translate("validations.contractID"),
            },
          ]}
        >
          <Select
            options={optionsContracts}
            placeholder={translate("placeholder.selectAContractID")}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <Form.Item
          label={translate("pages.financialManagement.bills.status")}
          name="status"
          rules={[
            {
              required: true,
              message: translate("validations.status"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectStatus")}
            options={STATUS_BILLS?.map((item) => {
              return {
                label: translate(item.label),
                value: item.value,
              };
            })}
          />
        </Form.Item>
        <Form.Item
          name="from_date"
          label={translate("pages.financialManagement.bills.fromDate")}
          rules={[
            () => ({
              validator() {
                if (!selectedFromDate) {
                  return Promise.reject(
                    new Error(translate("validations.fromDate"))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Space direction="vertical" size={12}>
            <DatePicker
              inputReadOnly={true}
              defaultValue={dayjs(selectedFromDate)}
              placeholder={translate("placeholder.selectDate")}
              onChange={onChangeDateFromDate}
              getPopupContainer={(trigger: any) => trigger.parentNode}
            />
          </Space>
        </Form.Item>
        <Form.Item
          name="to_date"
          label={translate("pages.financialManagement.bills.toDate")}
          rules={[
            () => ({
              validator() {
                if (!selectedToDate) {
                  return Promise.reject(
                    new Error(translate("validations.toDate"))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Space direction="vertical" size={12}>
            <DatePicker
              inputReadOnly={true}
              defaultValue={dayjs(selectedToDate)}
              placeholder={translate("placeholder.selectDate")}
              onChange={onChangeDateToDate}
              getPopupContainer={(trigger: any) => trigger.parentNode}
            />
          </Space>
        </Form.Item>
      </Form>
    </Edit>
  );
};
