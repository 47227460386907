import { useEffect, useState } from "react";
import axios from "axios";
import {
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import { Edit, SaveButton, useForm } from "@refinedev/antd";
import { Form, Input, Modal, Select, Upload, message } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";

import {
  IListContracts,
  IListLandlordBills,
  IListReservation,
} from "interfaces";
import {
  formatTime,
  getBase64,
  handleFilterOption,
  handleSortOption,
  isValidPrice,
  uploadButton,
} from "helper";

import {
  CASH_FLOW_LANDLORD_TRANSACTIONS,
  TYPES_LANDLORD_TRANSACTIONS,
} from "./const";
import NumberInput from "components/InputNumber";
import { API_URL } from "api";
import CustomImageModal from "components/modal/ImageDetail";
import Slider from "components/slider-image";

export const LandlordTransactionsEdit: React.FC<IResourceComponentsProps> = (
  props
) => {
  const translate = useTranslate();
  const { formProps, queryResult, saveButtonProps, onFinish, form } =
    useForm<IListContracts>({
      resource: "landlord-transactions",
    });
  const landlordTransactionsData: any = queryResult?.data?.data ?? "";

  const [optionsBills, setOptionsBills] = useState<[]>([]);
  const [optionsBuildings, setOptionsBuildings] = useState<[]>([]);
  const [createdAt, setCreatedAt] = useState<string>("");
  const [updatedAt, setUpdatedAt] = useState<string>("");

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<any>();
  const [imageUrls, setImageUrls] = useState<any>([]);
  const [currentOpenModalImage, setCurrentOpenModalImage] =
    useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<any>(0);

  // get bills data
  const { data: billsData } = useList<IListLandlordBills>({
    resource: "bills",
    errorNotification: false,
  });

  // get buildings data
  const { data: buildingsData } = useList<IListReservation>({
    resource: "buildings",
    errorNotification: false,
  });

  const uploadImage = async (options: any) => {
    const { file, onSuccess, onError, onProgress } = options;
    const formData = new FormData();
    formData.append("mFiles", file);
    const response: any = await axios.post<{ url: string }>(
      `${API_URL}/s3/upload`,
      formData,
      {
        withCredentials: false,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round(
            (progressEvent?.loaded * 100) / progressEvent?.total
          );
          onProgress({ percent: percentCompleted });
        },
      }
    );

    if (response?.status) {
      // Handle success
      message.success(translate("notifications.imageUploadSuccess"));
      onSuccess(response?.data, file);
      setImageUrls((prev: any) => [...prev, ...response?.data?.data]);
    } else {
      // Handle error
      message.error(translate("notifications.imageUploadFailed"));
      onError();
    }
  };

  const handleCancel = () => {
    setCurrentOpenModalImage(false);
    setPreviewOpen(false);
  };

  const handleOpenCurrentImageModal = (index: number) => {
    setCurrentImageIndex(index);
    setCurrentOpenModalImage(true);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const handleRemove = (fileId: any) => {
    setImageUrls(imageUrls.filter((item: any) => item !== fileId));
    message.success(translate("notifications.imageDeletedSuccess"));
  };

  const handleOnFinish: any = (values: IListContracts) => {
    onFinish({
      ...values,
      images: imageUrls,
    });
  };

  useEffect(() => {
    const bills: any = billsData?.data ?? [];
    const queryResult = bills?.data;
    if (bills?.status) {
      const optionsBills = queryResult?.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
      setOptionsBills(optionsBills);
    }
  }, [billsData]);

  useEffect(() => {
    const buildings: any = buildingsData?.data ?? [];
    const queryResult = buildings?.data;
    if (buildings?.status) {
      const optionsBuildings = queryResult?.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
      setOptionsBuildings(optionsBuildings);
    }
  }, [buildingsData]);

  useEffect(() => {
    if (landlordTransactionsData?.data?.images?.length > 0) {
      setImageUrls(landlordTransactionsData?.data?.images);
      setCreatedAt(landlordTransactionsData?.data?.createdAt);
      setUpdatedAt(landlordTransactionsData?.data?.updatedAt);
    }
  }, [landlordTransactionsData]);

  return (
    <Edit
      {...props}
      title={translate("pages.payment.landlordTransactions.edit")}
      headerButtons={({ listButtonProps, refreshButtonProps }) => (
        <>
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} />
        </>
      )}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        layout="vertical"
        initialValues={{
          ...landlordTransactionsData?.data,
        }}
        form={form}
      >
        <Form.Item
          name="billId"
          label={translate("pages.payment.landlordTransactions.bill")}
          rules={[
            {
              required: true,
              message: translate("validations.bills"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectABill")}
            options={optionsBills}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>

        <Form.Item
          name="buildingId"
          label={translate("pages.payment.landlordTransactions.building")}
          rules={[
            {
              required: true,
              message: translate("validations.building"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectABuilding")}
            options={optionsBuildings}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>

        <Form.Item
          label={translate("pages.payment.landlordTransactions.types")}
          name="type"
          rules={[
            {
              required: true,
              message: translate("validations.selectTypeBill"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectTypeBill")}
            options={TYPES_LANDLORD_TRANSACTIONS?.map((item) => {
              return {
                label: translate(item.label),
                value: item.value,
              };
            })}
          />
        </Form.Item>
        <Form.Item
          label={translate("pages.payment.landlordTransactions.cashFlow")}
          name="cashFlow"
          rules={[
            {
              required: true,
              message: translate("validations.cashFlow"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.cashFlow")}
            options={CASH_FLOW_LANDLORD_TRANSACTIONS?.map((item) => {
              return {
                label: translate(item.label),
                value: item.value,
              };
            })}
          />
        </Form.Item>
        <Form.Item
          name="amount"
          label={translate("pages.payment.landlordTransactions.amount")}
          rules={[
            {
              required: true,
              message: translate("validations.amount"),
            },
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <NumberInput />
        </Form.Item>
        <Form.Item
          label={translate("pages.payment.landlordTransactions.notes")}
          name="notes"
        >
          <Input />
        </Form.Item>
        <Form.Item label={translate("pages.createdAt")}>
          <Input readOnly disabled placeholder={formatTime(createdAt)} />
        </Form.Item>
        <Form.Item label={translate("pages.updatedAt")}>
          <Input readOnly disabled placeholder={formatTime(updatedAt)} />
        </Form.Item>
        <Form.Item
          label={translate("pages.payment.landlordTransactions.images")}
        >
          <Form.Item name="images" noStyle>
            <Upload
              multiple={true}
              accept="image/*"
              customRequest={uploadImage}
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {uploadButton}
            </Upload>

            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="images apartments"
                style={{ width: "100%", height: "100%" }}
                src={previewImage}
              />
            </Modal>

            <CustomImageModal
              open={currentOpenModalImage}
              onCancel={handleCancel}
              images={imageUrls}
              initialIndex={currentImageIndex}
            />

            {/* Render images from backend as picture-cards */}
            <Slider
              item={imageUrls}
              handleOpenCurrentIndex={(index: number) =>
                handleOpenCurrentImageModal(index)
              }
              isRemove={true}
              handleRemove={handleRemove}
            />
          </Form.Item>
        </Form.Item>
      </Form>
    </Edit>
  );
};
