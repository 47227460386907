import { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  useList,
  usePermissions,
  useTranslate,
} from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Checkbox, Form, Input, Select } from "antd";

import { IListUser, IProvinces } from "interfaces";
import { getDistricts, getWards } from "api";
import { FormOutlined, PhoneOutlined } from "@ant-design/icons";
import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_FINANCE,
  handleFilterOption,
  handleSortOption,
  isReallyPhone,
  isValidPassword,
  parsePhone,
} from "helper";

export const LandlordsCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const [selectedProvice, setSelectedProvince] = useState<any>();
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  const [selectedWard, setSelectedWard] = useState<any>();
  const [optionsProvinces, setOptionProvinces] = useState<[]>([]);
  const [optionsDistricts, setOptionsDistricts] = useState<[]>([]);
  const [optionsRoles, setOptionsRoles] = useState<[]>([]);
  const [optionsWards, setOptionsWards] = useState<[]>([]);
  const [isActive, setIsActive] = useState<boolean>(true);

  const { data: permissionsData }: any = usePermissions();
  const isLoggedbyAdmin = permissionsData === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissionsData === ROLE_DATA_ADMIN;
  const isLoggedbyFinance = permissionsData === ROLE_FINANCE;

  const { formProps, saveButtonProps, onFinish, form } = useForm<IListUser>({
    errorNotification: (data) => {
      const errorMessage: any = data?.response;
      const message: any = errorMessage?.data?.data?.errors[0].message;
      return {
        message: message,
        description: "Error",
        type: "error",
      };
    },
  });

  const { data: provincesData } = useList<IProvinces>({
    resource: "addresses/provinces",
    errorNotification: false,
  });

  const { data: rolesData } = useList<IProvinces>({
    resource: "roles",
    errorNotification: false,
  });

  useEffect(() => {
    const provinces: any = provincesData?.data ?? [];
    const queryResult = provinces?.data;
    if (provinces?.status) {
      const optionsProvinces = queryResult?.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
      setOptionProvinces(optionsProvinces);
    }
  }, [provincesData]);

  useEffect(() => {
    const queryResult: any = rolesData?.data;
    if (queryResult) {
      const optionsRoles = queryResult?.data.map((item: any) => {
        return {
          label: translate(`roles.${item.name}`),
          value: item.id,
        };
      });
      setOptionsRoles(optionsRoles);
    }
  }, [rolesData]);

  useEffect(() => {
    if (selectedProvice) {
      getDistricts(selectedProvice).then((response) => {
        const optionsDistricts = response?.data.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
        setOptionsDistricts(optionsDistricts);
      });
    }
  }, [selectedProvice]);

  useEffect(() => {
    if (selectedDistrict) {
      getWards(selectedDistrict).then((response) => {
        const optionsWards = response?.data.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
        setOptionsWards(optionsWards);
      });
    }
  }, [selectedDistrict]);

  const onChangeSelectedProvince = (value: any) => {
    setSelectedProvince(value);
    setSelectedDistrict(null);
    setSelectedWard(null);
    form.setFieldValue("district_id", null);
    form.setFieldValue("ward_id", null);
  };

  const handleOnFinish: any = (values: IListUser) => {
    onFinish({
      ...values,
      phone: values?.phone ? parsePhone(values?.phone) : null,
      second_phone: values?.second_phone
        ? parsePhone(values?.second_phone)
        : null,
      first_manager_phone: values?.first_manager_phone
        ? parsePhone(values?.first_manager_phone)
        : null,
      second_manager_phone: values?.second_manager_phone
        ? parsePhone(values?.second_manager_phone)
        : null,
      is_active: isActive,
    });
  };

  return (
    <Create
      title={translate("pages.users.createNewUser")}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        initialValues={{ role_id: 6, is_active: isActive }}
        layout="vertical"
      >
        <Form.Item
          name="full_name"
          label={translate("pages.users.fullName")}
          rules={[
            {
              required: true,
              message: translate("validations.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label={translate("pages.users.phone")}
          rules={[
            {
              required: true,
              message: translate("validations.phone"),
            },
            () => ({
              validator(_, value) {
                if (!isReallyPhone(value)) {
                  return Promise.reject(
                    new Error(translate("validations.notAPhoneNumber"))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            allowClear={true}
            prefix={<PhoneOutlined style={{ color: "#00000040" }} />}
            maxLength={10}
            placeholder="___-___-____"
          />
        </Form.Item>
        <Form.Item
          name="second_phone"
          label={translate("pages.users.secondPhone")}
          rules={[
            () => ({
              validator(_, value) {
                if (value && !isReallyPhone(value)) {
                  return Promise.reject(
                    new Error(translate("validations.notAPhoneNumber"))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            allowClear={true}
            prefix={<PhoneOutlined style={{ color: "#00000040" }} />}
            maxLength={10}
            placeholder="___-___-____"
          />
        </Form.Item>
        <Form.Item name="email" label={translate("pages.users.email")}>
          <Input />
        </Form.Item>
        <Form.Item name="telegram" label={translate("pages.users.telegram")}>
          <Input />
        </Form.Item>
        <Form.Item name="zalo" label={translate("pages.users.zalo")}>
          <Input />
        </Form.Item>
        <Form.Item
          name="identity_card_number"
          label={translate("pages.users.identityCardNumber")}
        >
          <Input />
        </Form.Item>
        <Form.Item name="address" label={translate("pages.users.address")}>
          <Input />
        </Form.Item>

        <Form.Item
          label={translate("pages.users.province")}
          name={["province_id"]}
        >
          <Select
            options={optionsProvinces}
            placeholder={translate("placeholder.selectAProvince")}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
            onChange={onChangeSelectedProvince}
          />
        </Form.Item>

        {selectedProvice ? (
          <Form.Item
            label={translate("pages.users.district")}
            name={["district_id"]}
          >
            <Select
              placeholder={translate("placeholder.selectADistrict")}
              options={optionsDistricts}
              showSearch
              filterOption={(input, option: any) =>
                handleFilterOption(input, option)
              }
              filterSort={(optionA, optionB) =>
                handleSortOption(optionA, optionB)
              }
              value={selectedDistrict}
              onChange={(val) => {
                setSelectedDistrict(val);
                form.setFieldValue("ward_id", null);
              }}
            />
          </Form.Item>
        ) : null}
        {selectedDistrict ? (
          <Form.Item label={translate("pages.users.ward")} name={["ward_id"]}>
            <Select
              placeholder={translate("placeholder.selectAWard")}
              options={optionsWards}
              showSearch
              filterOption={(input, option: any) =>
                handleFilterOption(input, option)
              }
              filterSort={(optionA, optionB) =>
                handleSortOption(optionA, optionB)
              }
              value={selectedWard}
              onChange={(val) => setSelectedWard(val)}
            />
          </Form.Item>
        ) : null}

        {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyFinance ? (
          <>
            <Form.Item
              name="first_manager_name"
              label={translate("pages.users.firstManagerName")}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="first_manager_phone"
              label={translate("pages.users.firstManagerPhone")}
              rules={[
                () => ({
                  validator(_, value) {
                    if (value && !isReallyPhone(value)) {
                      return Promise.reject(
                        new Error(translate("validations.notAPhoneNumber"))
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                allowClear={true}
                prefix={<PhoneOutlined style={{ color: "#00000040" }} />}
                maxLength={10}
                placeholder="___-___-____"
              />
            </Form.Item>
            <Form.Item
              name="second_manager_name"
              label={translate("pages.users.secondManagerName")}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="second_manager_phone"
              label={translate("pages.users.secondManagerPhone")}
              rules={[
                () => ({
                  validator(_, value) {
                    if (value && !isReallyPhone(value)) {
                      return Promise.reject(
                        new Error(translate("validations.notAPhoneNumber"))
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                allowClear={true}
                prefix={<PhoneOutlined style={{ color: "#00000040" }} />}
                maxLength={10}
                placeholder="___-___-____"
              />
            </Form.Item>
          </>
        ) : null}

        <Form.Item name="role_id" label={translate("pages.users.role")}>
          <Select
            disabled={true}
            placeholder={translate("placeholder.selectARole")}
            options={optionsRoles}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>

        <Form.Item
          name="password"
          label={translate("pages.users.password")}
          rules={[
            {
              required: true,
              message: translate("validations.password"),
            },
            () => ({
              validator(_, value) {
                if (isValidPassword(value, true)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPassword(value, true)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password
            autoComplete="new-password"
            prefix={<FormOutlined style={{ color: "#00000040" }} />}
          />
        </Form.Item>
        <Form.Item label={translate("pages.users.active")}>
          <Form.Item valuePropName="checked">
            <Checkbox
              style={{
                fontSize: "12px",
              }}
              onChange={() => setIsActive(true)}
              checked={isActive ? true : false}
            >
              {translate("pages.users.yes")}
            </Checkbox>
            <Checkbox
              style={{
                fontSize: "12px",
              }}
              onChange={() => setIsActive(false)}
              checked={!isActive ? true : false}
            >
              {translate("pages.users.no")}
            </Checkbox>
          </Form.Item>
        </Form.Item>
      </Form>
    </Create>
  );
};
