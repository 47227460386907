import { useState, useEffect } from "react";
import {
  IResourceComponentsProps,
  useTranslate,
  useList,
} from "@refinedev/core";
import {
  List,
  useTable,
  TextField,
  EditButton,
  ShowButton,
} from "@refinedev/antd";
import { Button, Input, Space, Table, Tag } from "antd";
import { CaretDownOutlined, FilterFilled } from "@ant-design/icons";

import {
  formatPrice,
  getParamFilter,
  hasChecked,
  onCheckFilter,
  onClearFilter,
  parsePhoneToVNWithoutSpace,
} from "helper";

import { IListBuildings, IListUser } from "interfaces";
import { FilterNormal } from "components/modal";
import {
  getCurrentPagination,
  getFilterCommissions,
  setCurrentPagination,
  setFilterCommissions,
} from "helper/local-storage";
import { IListTransactions } from "interfaces/transactions";
import { handleTranslateType } from "pages/customer-management/appointments/constant";
import { STATUS_TRANSACTIONS, handleTranslateStatus } from "./constant";

export const CommissionList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const filter = JSON.parse(getFilterCommissions() || "{}");
  const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);
  const [isOpenModalBuilding, setIsOpenModalBuilding] =
    useState<boolean>(false);
  const [listStatus, setListStatus] = useState<any>(
    filter?.listStatus || STATUS_TRANSACTIONS
  );
  const [listBuilding, setListBuilding] = useState<any>(
    filter?.listBuilding || []
  );
  const [saleName, setSaleName] = useState<string>(filter?.saleName || "");
  const [apartmentName, setApartmentName] = useState<any>(
    filter?.apartmentName || ""
  );
  const [customerPhone, setCustomerPhone] = useState<any>(
    filter?.customerPhone || ""
  );
  const [pagination, setPagination] = useState(
    JSON.parse(getCurrentPagination() || "{}") || {
      current: 1,
      pageSize: 10,
    }
  );

  // get building data
  const { data: buildingsData } = useList<IListBuildings>({
    resource: "buildings",
    errorNotification: false,
  });

  const { tableProps, setFilters } = useTable<IListTransactions>({
    resource: "transactions?type=commission,cancel_deposit&",
  });
  const dataSource: any = tableProps?.dataSource;
  let commissionsTableProps = {
    ...tableProps,
    pagination: pagination,
    dataSource: dataSource?.data,
  };

  const handleTableChange = (pagination: any) => {
    setPagination({ current: pagination, pageSize: 10 });
  };

  useEffect(() => {
    setCurrentPagination(JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    const storedPagination = getCurrentPagination();
    if (storedPagination) {
      setPagination(JSON.parse(storedPagination));
    }
  }, []);

  useEffect(() => {
    if (buildingsData && !hasChecked(listBuilding)) {
      const buildingOptionsData: any = buildingsData?.data;
      setListBuilding(
        buildingOptionsData?.data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            isChecked: false,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingsData]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setFilters(
        [
          {
            field: "room_name",
            operator: "eq",
            value: apartmentName?.trim() || null,
          },
          {
            field: "customer_phone",
            operator: "eq",
            value: customerPhone?.trim() || null,
          },
          {
            field: "sale_name",
            operator: "eq",
            value: saleName?.trim() || null,
          },
        ],
        "merge"
      );
    }, 1000);
    setFilterCommissions(
      JSON.stringify({ ...filter, apartmentName, customerPhone, saleName })
    );
    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apartmentName, customerPhone, saleName]);

  useEffect(() => {
    const statusParam = getParamFilter(listStatus);
    const buildingParam = getParamFilter(listBuilding);
    setFilters(
      [
        {
          field: "status",
          operator: "eq",
          value: statusParam,
        },
        {
          field: "building_id",
          operator: "eq",
          value: buildingParam,
        },
      ],
      "merge"
    );
    setFilterCommissions(
      JSON.stringify({ ...filter, listStatus, listBuilding })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listStatus, listBuilding]);

  return (
    <List title={translate("pages.financialManagement.commissions.list")}>
      <Space className="listFilter">
        <Button style={{ pointerEvents: "none" }}>
          <FilterFilled />
          {translate("buttons.filter")}
        </Button>
        <Button
          onClick={() => setIsOpenModalStatus(true)}
          className={hasChecked(listStatus) ? "isEnabled" : ""}
        >
          {translate("pages.financialManagement.commissions.status")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalStatus && (
          <FilterNormal
            isOpen={isOpenModalStatus}
            setIsOpen={setIsOpenModalStatus}
            title={translate("pages.financialManagement.commissions.status")}
            data={listStatus}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listStatus, false);
              setListStatus(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listStatus);
              setListStatus(newData);
            }}
            multiple={false}
          />
        )}

        <Button
          onClick={() => setIsOpenModalBuilding(true)}
          className={hasChecked(listBuilding) ? "isEnabled" : ""}
        >
          {translate("pages.financialManagement.commissions.building")}
          <CaretDownOutlined />
        </Button>
        {isOpenModalBuilding && (
          <FilterNormal
            isOpen={isOpenModalBuilding}
            setIsOpen={setIsOpenModalBuilding}
            title={translate("pages.financialManagement.commissions.building")}
            data={listBuilding}
            onCheck={(value) => {
              const newData = onCheckFilter(value, listBuilding, false);
              setListBuilding(newData);
            }}
            onClear={() => {
              const newData = onClearFilter(listBuilding);
              setListBuilding(newData);
            }}
            multiple={false}
          />
        )}
      </Space>
      <Space className="listFilter">
        <Space>
          <TextField
            value={
              translate("pages.financialManagement.commissions.apartmentName") +
              ":"
            }
          />
          <Input
            value={apartmentName}
            placeholder={translate("placeholder.searchByApartmentName")}
            onChange={(e) => setApartmentName(e.target.value)}
            allowClear
          ></Input>
        </Space>
        <Space>
          <TextField
            value={
              translate("pages.financialManagement.commissions.customerPhone") +
              ":"
            }
          />
          <Input
            value={customerPhone}
            placeholder={translate("placeholder.searchByPhoneNumber")}
            onChange={(e) => setCustomerPhone(e.target.value)}
            allowClear
          ></Input>
        </Space>
        <Space>
          <TextField
            value={
              translate("pages.financialManagement.commissions.saleName") + ":"
            }
          />
          <Input
            value={saleName}
            placeholder={translate("placeholder.searchBySaleName")}
            onChange={(e) => setSaleName(e.target.value)}
            allowClear
          ></Input>
        </Space>
      </Space>
      <Table
        {...commissionsTableProps}
        pagination={{
          defaultPageSize: 10,
          // showSizeChanger: true,
          // pageSizeOptions: ["10", "20", "30", "40"],
          current: pagination.current,
          onChange: handleTableChange,
        }}
        rowKey="id"
      >
        <Table.Column
          dataIndex="id"
          title={translate("pages.financialManagement.commissions.id")}
        />
        <Table.Column
          dataIndex="type"
          title={translate("pages.financialManagement.commissions.type")}
          key="type"
          render={(value) => {
            return (
              <Tag>
                <TextField value={handleTranslateType(value, translate)} />
              </Tag>
            );
          }}
        />
        <Table.Column
          dataIndex="amount"
          title={translate("pages.financialManagement.commissions.amount")}
          key="amount"
          render={(value) => {
            return <TextField value={formatPrice(value)} />;
          }}
        />
        <Table.Column
          dataIndex="status"
          title={translate("pages.financialManagement.commissions.status")}
          key="status"
          render={(value) => {
            return (
              <Tag>
                <TextField value={handleTranslateStatus(value, translate)} />
              </Tag>
            );
          }}
        />

        <Table.Column
          dataIndex="appointment"
          title={translate("pages.financialManagement.commissions.building")}
          key="building"
          render={(value) => {
            return value?.apartments?.map((item: any, idx: number) => {
              return (
                <Tag key={idx}>
                  <TextField value={item?.building?.name} />
                </Tag>
              );
            });
          }}
        />
        <Table.Column
          dataIndex="appointment"
          title={translate("pages.financialManagement.commissions.apartment")}
          key="apartment"
          render={(value) => {
            return value?.apartments?.map((item: any, idx: number) => {
              return (
                <Tag key={idx}>
                  <TextField value={item?.name} />
                </Tag>
              );
            });
          }}
        />

        <Table.Column
          dataIndex="appointment"
          title={translate("pages.financialManagement.commissions.sale")}
          key="sale"
          render={(value) => {
            return <TextField value={value?.sale?.full_name} />;
          }}
        />
        <Table.Column
          dataIndex="appointment"
          title={translate("pages.financialManagement.commissions.zalo")}
          key="zalo"
          render={(value) => {
            return (
              <TextField
                value={value ? parsePhoneToVNWithoutSpace(value?.customer?.phone) : ""}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="actor"
          title={translate(
            "pages.financialManagement.commissions.peopleCreate"
          )}
          key="actor"
          render={(value) => {
            return <TextField value={value?.full_name} />;
          }}
        />
        <Table.Column
          dataIndex="notes"
          title={translate("pages.financialManagement.commissions.notes")}
        />

        <Table.Column<IListUser>
          title={translate("table.actions")}
          dataIndex="actions"
          fixed="right"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
