import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import { Edit, SaveButton, useForm } from "@refinedev/antd";
import {
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Select,
  Space,
  Tag,
} from "antd";
import dayjs from "dayjs";

import { IListLandlordBills, IListReservation } from "interfaces";
import {
  formatDateMonthYear,
  getHighestPrice,
  handleFilterOption,
  handleNotIncreaseDecreaseNumber,
  handleSortOption,
  isValidPrice,
} from "helper";
import { STATUS_LANDLORD_BILLS } from "./const";
import NumberInput from "components/InputNumber";

export const LandlordBillsEdit: React.FC<IResourceComponentsProps> = (
  props
) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { formProps, queryResult, onFinish, form, saveButtonProps } =
    useForm<IListLandlordBills>({
      resource: "bills",
    });
  const landlordBillsData: any = queryResult?.data?.data ?? "";

  const [optionsReservations, setOptionsReservations] = useState<[]>([]);
  const [selectedItemReservation, setSelectedItemReservation] = useState<[]>(
    []
  );
  const [selectedFromDate, setSelectedFromDate] = useState<any>("");
  const [selectedToDate, setSelectedToDate] = useState<any>("");

  // electricity logic
  const [previousElectricityIndex, setPreviousElectricityIndex] =
    useState<number>(0);
  const [currentElectricityIndex, setCurrentElectricityIndex] =
    useState<number>(0);
  const [usedElectricityIndex, setUsedElectricityIndex] = useState<number>(0);
  const [electricityPrice, setElectricityPrice] = useState<number>(0);
  const [electricityFee, setElectricityFee] = useState<number>(0);
  // water logic
  const [previousWaterIndex, setPreviousWaterIndex] = useState<number>(0);
  const [currentWaterIndex, setCurrentWaterIndex] = useState<number>(0);
  const [usedWaterIndex, setUsedWaterIndex] = useState<number>(0);
  const [waterPrice, setWaterPrice] = useState<number>(0);
  const [waterFee, setWaterFee] = useState<number>(0);
  const [paidValue, setPaidValue] = useState<number>(0);

  const [apartmentRentalFee, setApartmentRentalFee] = useState<number>(0);
  const [parkingFee, setParkingFee] = useState<number>(0);
  const [managementFee, setManagementFee] = useState<number>(0);
  const [internetFee, setInternetFee] = useState<number>(0);
  const [otherFee, setOtherFee] = useState<number>(0);
  const [totalFee, setTotalFee] = useState<number>(0);

  const onChangeDateFromDate = (value: DatePickerProps["value"]) => {
    setSelectedFromDate(value);
  };

  const onChangeDateToDate = (value: DatePickerProps["value"]) => {
    setSelectedToDate(value);
  };

  const onChangePreviousElectricityIndex = (value: any) => {
    let currentValue = value.target.value
      ? parseInt(value.target.value, 10)
      : 0;
    setPreviousElectricityIndex(currentValue);
    setUsedElectricityIndex(currentElectricityIndex - currentValue);
    // Trigger validation check for currentElectricityIndex
    form.validateFields(["currentElectricityIndex"]);
  };
  const onChangeCurrentElectricityIndex = (value: any) => {
    let currentValue = value.target.value
      ? parseInt(value.target.value, 10)
      : 0;
    setCurrentElectricityIndex(currentValue);
    setUsedElectricityIndex(currentValue - previousElectricityIndex);
    // Trigger validation check for previousElectricityIndex
    form.validateFields(["previousElectricityIndex"]);
  };

  const onChangeElectricityPrice = (value: any) => {
    value ? setElectricityPrice(value) : setElectricityPrice(0);
    if (usedElectricityIndex && value) {
      setElectricityFee(usedElectricityIndex * value);
    }
  };

  const onChangePreviousWaterIndex = (value: any) => {
    let currentValue = value.target.value
      ? parseInt(value.target.value, 10)
      : 0;
    setPreviousWaterIndex(currentValue);
    setUsedWaterIndex(currentWaterIndex - currentValue);
    // Trigger validation check for currentWaterIndex
    form.validateFields(["currentWaterIndex"]);
  };
  const onChangeCurrentWaterIndex = (value: any) => {
    let currentValue = value.target.value
      ? parseInt(value.target.value, 10)
      : 0;
    setCurrentWaterIndex(currentValue);
    setUsedWaterIndex(currentValue - previousWaterIndex);
    // Trigger validation check for previousWaterIndex
    form.validateFields(["previousWaterIndex"]);
  };

  const onChangeWaterPrice = (value: any) => {
    value ? setWaterPrice(value) : setWaterPrice(0);
    if (usedWaterIndex && value) {
      setWaterFee(usedWaterIndex * value);
    }
  };

  const onChangeSelectedReservation = (reservationId: any) => {
    const resData: any = reservationsData?.data;
    const reservations = resData?.data ?? [];
    const selectedReservation = reservations?.find(
      (reservation: any) => reservation.id === reservationId
    );

    const latestContractPrice =
      selectedReservation?.contracts?.sort((prev: any, current: any) => {
        return (
          new Date(current.validTo).getTime() - new Date(prev.validTo).getTime()
        );
      })[0]?.price || 0;

    setSelectedItemReservation(reservationId);
    setApartmentRentalFee(latestContractPrice);
    form.setFieldValue("apartmentRentalFee", latestContractPrice);
  };

  const onChangePaidValue = (value: number = 0) => {
    if (value === 0) {
      form.setFieldValue("status", "unpaid");
    } else if (value < totalFee) {
      form.setFieldValue("status", "partial_paid");
    } else {
      form.setFieldValue("status", "paid");
    }
    setPaidValue(value);
  };

  const onChangeStatus = (billStatus: string) => {
    if (billStatus === "unpaid") {
      form.setFieldValue("paidValue", 0);
    }

    if (billStatus === "paid") {
      form.setFieldValue("paidValue", totalFee);
    }
    if (
      billStatus === "partial_paid" &&
      !form.getFieldValue("paidValue") &&
      totalFee
    ) {
      form.setFields([
        {
          name: "paidValue",
          errors: [
            translate(
              "validations.paidValueMustBeMoreThanZeroBecauseTheBillStatusIsPartialPaid"
            ),
          ],
        },
      ]);
    } else {
      form.setFields([
        {
          name: "paidValue",
          errors: [],
        },
      ]);
    }
  };

  // get reservations data
  const { data: reservationsData } = useList<IListReservation>({
    resource: "reservations",
    errorNotification: false,
  });

  const handleOnFinish: any = (values: IListLandlordBills) => {
    onFinish({
      ...values,
      reservationId: selectedItemReservation,
      previousElectricityIndex: previousElectricityIndex,
      currentElectricityIndex: currentElectricityIndex,
      previousWaterIndex: previousWaterIndex,
      currentWaterIndex: currentWaterIndex,
      totalFee: totalFee,
      fromDate: selectedFromDate,
      toDate: selectedToDate,
    });
  };

  useEffect(() => {
    const reservation: any = reservationsData?.data ?? [];
    const queryResult = reservation?.data;
    if (reservation?.status) {
      const optionsReservations = queryResult?.map((item: any) => ({
        label: `${item.customer.full_name} (${item.apartment.name}, ${item.apartment.building.name})`,
        value: item.id,
      }));
      setOptionsReservations(optionsReservations);
    }
  }, [reservationsData]);

  useEffect(() => {
    if (landlordBillsData) {
      const selectedFromDate: string = landlordBillsData?.data?.fromDate;
      const selectedToDate: string = landlordBillsData?.data?.toDate;
      const reservationId = landlordBillsData?.data?.reservationId;
      // electricity
      const previousElectricityIndex =
        landlordBillsData?.data?.previousElectricityIndex;
      const currentElectricityIndex =
        landlordBillsData?.data?.currentElectricityIndex;
      const electricityPrice = landlordBillsData?.data?.electricityPrice;
      const usedElectricityIndex =
        currentElectricityIndex - previousElectricityIndex;
      // water
      const previousWaterIndex = landlordBillsData?.data?.previousWaterIndex;
      const currentWaterIndex = landlordBillsData?.data?.currentWaterIndex;
      const waterPrice = landlordBillsData?.data?.waterPrice;

      const apartmentRentalFee = landlordBillsData?.data?.apartmentRentalFee;
      const parkingFee = landlordBillsData?.data?.parkingFee;
      const managementFee = landlordBillsData?.data?.managementFee;
      const internetFee = landlordBillsData?.data?.internetFee;
      const otherFee = landlordBillsData?.data?.otherFee;
      const usedWaterIndex = currentWaterIndex - previousWaterIndex;
      const paidValue = landlordBillsData?.data?.paidValue;

      setSelectedItemReservation(reservationId);
      setSelectedFromDate(selectedFromDate);
      setSelectedToDate(selectedToDate);
      // electricity
      setPreviousElectricityIndex(previousElectricityIndex);
      setCurrentElectricityIndex(currentElectricityIndex);
      setUsedElectricityIndex(usedElectricityIndex);
      setElectricityPrice(electricityPrice);
      // water
      setPreviousWaterIndex(previousWaterIndex);
      setCurrentWaterIndex(currentWaterIndex);
      setUsedWaterIndex(usedWaterIndex);
      setWaterPrice(waterPrice);
      // all fee
      setApartmentRentalFee(apartmentRentalFee);
      setParkingFee(parkingFee);
      setManagementFee(managementFee);
      setInternetFee(internetFee);
      setOtherFee(otherFee);
      setPaidValue(paidValue);
      setTotalFee(totalFee);
    }
  }, [landlordBillsData]);

  useEffect(() => {
    let currentElectricityPrice = electricityPrice ? electricityPrice : 0;
    if (usedElectricityIndex) {
      setElectricityFee(currentElectricityPrice * usedElectricityIndex);
    } else {
      setElectricityFee(currentElectricityPrice * 0);
    }
  }, [usedElectricityIndex, electricityPrice]);

  useEffect(() => {
    let currentWaterPrice = waterPrice ? waterPrice : 0;
    if (usedWaterIndex) {
      setWaterFee(currentWaterPrice * usedWaterIndex);
    } else {
      setWaterFee(currentWaterPrice * 0);
    }
  }, [usedWaterIndex, waterPrice]);

  useEffect(() => {
    // Calculate total fee whenever any of the relevant fields change
    const newTotalFee =
      apartmentRentalFee +
      electricityFee +
      waterFee +
      parkingFee +
      managementFee +
      internetFee +
      otherFee;
    setTotalFee(newTotalFee);

    // trigger validation for 'paidValue' field
    form.validateFields(["paidValue"]);
  }, [
    apartmentRentalFee,
    electricityFee,
    waterFee,
    parkingFee,
    managementFee,
    internetFee,
    otherFee,
    paidValue,
    form,
  ]);

  useEffect(() => {
    if (paidValue === 0) {
      form.setFieldValue("status", "unpaid");
    } else if (paidValue < totalFee) {
      form.setFieldValue("status", "partial_paid");
    } else {
      form.setFieldValue("status", "paid");
    }
    // trigger validation for 'paidValue' field
    form.validateFields(["paidValue"]);
  }, [paidValue, totalFee]);

  return (
    <Edit
      {...props}
      title={translate("pages.payment.landlordBills.edit")}
      headerButtons={({ listButtonProps, refreshButtonProps }) => (
        <>
          {listButtonProps && null}
          {refreshButtonProps && null}

          <Button
            onClick={() =>
              navigate(
                `/reservations/show/${landlordBillsData?.data?.reservationId}`
              )
            }
          >
            {translate("pages.operation.reservations.showReservation")}
          </Button>
        </>
      )}
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} />
        </>
      )}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        layout="vertical"
        initialValues={{
          ...landlordBillsData?.data,
        }}
        form={form}
      >
        <Form.Item
          name="name"
          label={translate("pages.payment.landlordBills.name")}
          rules={[
            {
              required: true,
              message: translate("validations.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="reservationId"
          label={translate("pages.payment.landlordBills.reservation")}
          rules={[
            {
              required: true,
              message: translate("validations.reservation"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectAReservation")}
            options={optionsReservations}
            onChange={(value: any) => {
              onChangeSelectedReservation(value);
            }}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <Form.Item
          className="requiredLabel"
          name="fromDate"
          label={translate("pages.payment.landlordBills.fromDate")}
          rules={[
            () => ({
              validator() {
                if (!selectedFromDate) {
                  return Promise.reject(
                    new Error(translate("validations.fromDate"))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Space direction="vertical" size={12}>
            <DatePicker
              inputReadOnly={true}
              format={`${formatDateMonthYear}`}
              defaultValue={dayjs(selectedFromDate)}
              placeholder={translate("placeholder.selectDate")}
              onChange={onChangeDateFromDate}
              getPopupContainer={(trigger: any) => trigger.parentNode}
            />
          </Space>
        </Form.Item>
        <Form.Item
          className="requiredLabel"
          name="toDate"
          label={translate("pages.payment.landlordBills.toDate")}
          rules={[
            () => ({
              validator() {
                if (!selectedToDate) {
                  return Promise.reject(
                    new Error(translate("validations.toDate"))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Space direction="vertical" size={12}>
            <DatePicker
              inputReadOnly={true}
              format={`${formatDateMonthYear}`}
              defaultValue={dayjs(selectedToDate)}
              placeholder={translate("placeholder.selectDate")}
              onChange={onChangeDateToDate}
              getPopupContainer={(trigger: any) => trigger.parentNode}
            />
          </Space>
        </Form.Item>

        <Form.Item
          name="apartmentRentalFee"
          label={translate("pages.payment.landlordBills.apartmentRentalFee")}
          rules={[
            {
              required: true,
              message: translate("validations.apartmentRentalFee"),
            },
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && isValidPrice(value)))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <NumberInput
            onChange={(val: any) =>
              val ? setApartmentRentalFee(val) : setApartmentRentalFee(0)
            }
          />
        </Form.Item>

        <Form.Item
          name="previousElectricityIndex"
          label={translate(
            "pages.payment.landlordBills.previousElectricityIndex"
          )}
          rules={[
            () => ({
              validator(_, value) {
                if (Number(value) < 0) {
                  return Promise.reject(
                    new Error(
                      translate(
                        "validations.previousElectricityIndexMustBeMoreThanOrEqual0"
                      )
                    )
                  );
                }
                if (Number(value) > currentElectricityIndex) {
                  return Promise.reject(
                    new Error(
                      translate(
                        "validations.previousAndCurrentElectricityIndex"
                      )
                    )
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            type="number"
            onWheel={(e) => handleNotIncreaseDecreaseNumber(e)}
            onChange={onChangePreviousElectricityIndex}
          />
        </Form.Item>
        <Form.Item
          name="currentElectricityIndex"
          label={translate(
            "pages.payment.landlordBills.currentElectricityIndex"
          )}
          rules={[
            () => ({
              validator(_, value) {
                if (Number(value) < 0) {
                  return Promise.reject(
                    new Error(
                      translate(
                        "validations.currentElectricityIndexMustBeMoreThanOrEqual0"
                      )
                    )
                  );
                }
                if (Number(value) < previousElectricityIndex) {
                  return Promise.reject(
                    new Error(
                      translate(
                        "validations.currentAndPreviousElectricityIndex"
                      )
                    )
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            type="number"
            onWheel={(e) => handleNotIncreaseDecreaseNumber(e)}
            onChange={onChangeCurrentElectricityIndex}
          />
        </Form.Item>

        <Form.Item
          label={translate("pages.payment.landlordBills.usedElectricityIndex")}
        >
          <Input
            value={usedElectricityIndex ? usedElectricityIndex : 0}
            disabled={true}
            type="number"
            onWheel={(e) => handleNotIncreaseDecreaseNumber(e)}
          />
        </Form.Item>

        <Form.Item
          name="electricityPrice"
          label={translate("pages.payment.landlordBills.electricityPrice")}
        >
          <NumberInput onChange={(e) => onChangeElectricityPrice(e)} />
        </Form.Item>

        <Form.Item
          label={translate("pages.payment.landlordBills.electricityFee")}
        >
          <NumberInput value={electricityFee} disabled={true} />
        </Form.Item>

        <Form.Item
          name="previousWaterIndex"
          label={translate("pages.payment.landlordBills.previousWaterIndex")}
          rules={[
            () => ({
              validator(_, value) {
                if (Number(value) < 0) {
                  return Promise.reject(
                    new Error(
                      translate(
                        "validations.previousWaterIndexMustBeMoreThanOrEqual0"
                      )
                    )
                  );
                }
                if (Number(value) > currentWaterIndex) {
                  return Promise.reject(
                    new Error(
                      translate("validations.previousAndCurrentWaterIndex")
                    )
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            type="number"
            onWheel={(e) => handleNotIncreaseDecreaseNumber(e)}
            onChange={onChangePreviousWaterIndex}
          />
        </Form.Item>
        <Form.Item
          name="currentWaterIndex"
          label={translate("pages.payment.landlordBills.currentWaterIndex")}
          rules={[
            () => ({
              validator(_, value) {
                if (Number(value) < 0) {
                  return Promise.reject(
                    new Error(
                      translate(
                        "validations.currentWaterIndexMustBeMoreThanOrEqual0"
                      )
                    )
                  );
                }
                if (Number(value) < previousWaterIndex) {
                  return Promise.reject(
                    new Error(
                      translate("validations.currentAndPreviousWaterIndex")
                    )
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            type="number"
            onWheel={(e) => handleNotIncreaseDecreaseNumber(e)}
            onChange={onChangeCurrentWaterIndex}
          />
        </Form.Item>

        <Form.Item
          label={translate("pages.payment.landlordBills.usedWaterIndex")}
        >
          <Input
            value={usedWaterIndex ? usedWaterIndex : 0}
            disabled={true}
            type="number"
            onWheel={(e) => handleNotIncreaseDecreaseNumber(e)}
          />
        </Form.Item>

        <Form.Item
          name="waterPrice"
          label={translate("pages.payment.landlordBills.waterPrice")}
        >
          <NumberInput onChange={(e) => onChangeWaterPrice(e)} />
        </Form.Item>

        <Form.Item label={translate("pages.payment.landlordBills.waterFee")}>
          <NumberInput value={waterFee} disabled={true} />
        </Form.Item>

        <Form.Item
          name="parkingFee"
          label={translate("pages.payment.landlordBills.parkingFee")}
        >
          <NumberInput
            onChange={(val: any) =>
              val ? setParkingFee(val) : setParkingFee(0)
            }
          />
        </Form.Item>
        <Form.Item
          name="managementFee"
          label={translate("pages.payment.landlordBills.managementFee")}
        >
          <NumberInput
            onChange={(val: any) =>
              val ? setManagementFee(val) : setManagementFee(0)
            }
          />
        </Form.Item>
        <Form.Item
          name="internetFee"
          label={translate("pages.payment.landlordBills.internetFee")}
        >
          <NumberInput
            onChange={(val: any) =>
              val ? setInternetFee(val) : setInternetFee(0)
            }
          />
        </Form.Item>
        <Form.Item
          name="otherFee"
          label={translate("pages.payment.landlordBills.otherFee")}
        >
          <NumberInput
            onChange={(val: any) => (val ? setOtherFee(val) : setOtherFee(0))}
          />
        </Form.Item>
        <Form.Item
          label={translate("pages.payment.contracts.status")}
          name="status"
          rules={[
            {
              required: true,
              message: translate("validations.status"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectStatus")}
            options={STATUS_LANDLORD_BILLS?.map((item) => {
              return {
                label: (
                  <Tag
                    bordered={false}
                    color={
                      item.value === "paid"
                        ? "success"
                        : item.value === "partial_paid"
                        ? "yellow"
                        : "error"
                    }
                  >
                    {translate(item.label)}
                  </Tag>
                ),
                value: item.value,
              };
            })}
            onChange={onChangeStatus}
          />
        </Form.Item>
        <Form.Item
          name="paidValue"
          label={translate("pages.payment.landlordBills.paidValue")}
          rules={[
            () => ({
              validator(_, value) {
                if (Number(value) > totalFee) {
                  return Promise.reject(
                    new Error(translate("validations.paidValueOverTotalBill"))
                  );
                }
                if (Number(value) < 0) {
                  return Promise.reject(
                    new Error(
                      translate("validations.paidValueMustBeMoreThanOrEqual0")
                    )
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <NumberInput onChange={onChangePaidValue} />
        </Form.Item>
        <Form.Item label={translate("pages.payment.landlordBills.totalFee")}>
          <NumberInput
            disabled={true}
            value={totalFee}
            onChange={(val: any) => (val ? setTotalFee(val) : setTotalFee(0))}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
