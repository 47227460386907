export const STATUS_APARTMENT = [
  { label: "const.vacant", value: "vacant", isChecked: false },
  {
    label: "const.nearVacant",
    value: "near_vacant",
    isChecked: false,
  },
  {
    label: "const.deposited",
    value: "deposited",
    isChecked: false,
  },
  {
    label: "const.occupied",
    value: "occupied",
    isChecked: false,
  },
];

export const TYPE_ROOM = [
  { label: "const.duplex", value: "duplex", isChecked: false },
  {
    label: "const.studio",
    value: "studio",
    isChecked: false,
  },
  {
    label: "const.oneBedroom",
    value: "one_bedroom",
    isChecked: false,
  },
  {
    label: "const.twoBedroom",
    value: "two_bedroom",
    isChecked: false,
  },
];

export const RANGE_PRICE = [
  { from: 4000000, to: 5900000, isChecked: false },
  { from: 6000000, to: 7900000, isChecked: false },
  { from: 8000000, to: 9900000, isChecked: false },
  { from: 10000000, to: 14000000, isChecked: false },
];

export const CHARGE_WATER = [
  {
    label: "const.person",
    value: "person",
  },
  {
    label: "const.usedAmount",
    value: "used_amount",
  },
];



export const STATUS_APPROVED = [
  { label: "status.active", value: true, isChecked: false },
  {
    label: "status.inactive",
    value: false,
    isChecked: false,
  },
];