import { useEffect, useState } from "react";
import axios from "axios";
import {
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import { Edit, SaveButton, useForm } from "@refinedev/antd";
import {
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  Modal,
  Select,
  Space,
  Tag,
  Upload,
  message,
} from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import dayjs from "dayjs";

import { IListContracts, IListReservation, IListUser } from "interfaces";
import CustomImageModal from "components/modal/ImageDetail";
import Slider from "components/slider-image";
import {
  formatDateMonthYear,
  formatHour,
  getBase64,
  handleFilterOption,
  handleSortOption,
  parsePhone,
  parsePhoneToVN,
  parsePhoneToVNWithoutSpace,
  uploadButton,
} from "helper";

import { API_URL } from "api";
import { STATUS_CONTRACTS } from "./const";
import { CreateNewCustomer } from "components/modal";
import NumberInput from "components/InputNumber";

export const ContractsEdit: React.FC<IResourceComponentsProps> = (props) => {
  const translate = useTranslate();
  const { formProps, queryResult, saveButtonProps, onFinish, form } =
    useForm<IListContracts>({
      resource: "contracts",
    });
  const contractsData: any = queryResult?.data?.data ?? "";

  // get customers data
  const { data: customersData } = useList<IListUser>({
    resource: "customers",
    errorNotification: false,
  });

  // get reservations data
  const { data: reservationsData } = useList<IListReservation>({
    resource: "reservations",
    errorNotification: false,
  });

  const [selectedValidFrom, setSelectedValidFrom] = useState<any>(
    contractsData?.data?.validFrom
  );
  const [selectedValidTo, setSelectedValidTo] = useState<any>(
    contractsData?.data?.validTo
  );

  const [optionsReservations, setOptionsReservations] = useState<[]>([]);
  const [selectedItemReservation, setSelectedItemReservation] = useState<[]>(
    []
  );

  const [optionsManyRoommates, setOptionsManyRoomates] = useState<any>([]);
  const [selectedItemsManyRoomates, setSelectedItemsManyRoommates] = useState<
    string[]
  >([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<any>();
  const [imageUrls, setImageUrls] = useState<any>([]);
  const [currentOpenModalImage, setCurrentOpenModalImage] =
    useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<any>(0);
  const [isOpenModalCreateNewCustomer, setIsOpenModalCreateNewCustomer] =
    useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const uploadImage = async (options: any) => {
    const { file, onSuccess, onError, onProgress } = options;
    const formData = new FormData();
    formData.append("mFiles", file);
    const response: any = await axios.post<{ url: string }>(
      `${API_URL}/s3/upload`,
      formData,
      {
        withCredentials: false,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round(
            (progressEvent?.loaded * 100) / progressEvent?.total
          );
          onProgress({ percent: percentCompleted });
        },
      }
    );

    if (response?.status) {
      // Handle success
      message.success(translate("notifications.imageUploadSuccess"));
      onSuccess(response?.data, file);
      setImageUrls((prev: any) => [...prev, ...response?.data?.data]);
    } else {
      // Handle error
      message.error(translate("notifications.imageUploadFailed"));
      onError();
    }
  };

  const onCreateNewCustomer = async () => {
    const response: any = await axios
      .post(
        `${API_URL}/customers`,
        {
          full_name: name,
          phone: phone ? parsePhone(phone) : "",
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((data) => data.data)
      .catch((error) => {
        const { response } = error;
        message.error(translate("notifications.createNewCustomerError"));
        response?.data?.data?.errors?.map((item: any) => {
          return message.error(item?.message);
        });
        console.error(error);
      });

    if (response?.status) {
      // Handle success
      message.success(translate("notifications.createNewCustomerSuccess"));
      setIsOpenModalCreateNewCustomer(false);
      getNewCustomerList();
    }
  };

  const getNewCustomerList = async () => {
    return await axios
      .get(`${API_URL}/customers`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const { data } = res.data;
        setOptionsManyRoomates(
          data?.map((item: any) => {
            return {
              value: item?.id,
              label: item?.full_name,
              subLabel:
                parsePhoneToVN(item?.phone) +
                " " +
                parsePhoneToVNWithoutSpace(item?.phone) +
                " " +
                item?.phone,
            };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCancel = () => {
    setCurrentOpenModalImage(false);
    setPreviewOpen(false);
  };

  const handleOpenCurrentImageModal = (index: number) => {
    setCurrentImageIndex(index);
    setCurrentOpenModalImage(true);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const handleRemove = (fileId: any) => {
    setImageUrls(imageUrls.filter((item: any) => item !== fileId));
    message.success(translate("notifications.imageDeletedSuccess"));
  };

  const handleOnFinish: any = (values: IListContracts) => {
    onFinish({
      ...values,
      images: imageUrls?.map((item: any) => item),
      reservationId: selectedItemReservation,
      roommateIds: selectedItemsManyRoomates,
      validFrom: selectedValidFrom,
      validTo: selectedValidTo,
    });
  };

  const onChangeDateValidFrom = (value: DatePickerProps["value"]) => {
    setSelectedValidFrom(value?.format("YYYY-MM-DD"));
  };

  const onChangeDateValidTo = (value: DatePickerProps["value"]) => {
    setSelectedValidTo(value?.format("YYYY-MM-DD"));
  };

  const onSelectedItemReservation = (value: any) => {
    setSelectedItemReservation(value);
    const reservation: any = reservationsData?.data ?? [];
    const queryResult = reservation?.data;

    const currentItemSelected = queryResult?.filter(
      (item: any) => item?.id === value
    );
    form.setFieldValue(
      "price",
      currentItemSelected && currentItemSelected[0]?.apartment?.price
    );
  };

  useEffect(() => {
    const users: any = customersData?.data ?? [];
    const queryResult = users?.data;
    if (users?.status) {
      const optionsCustomers = queryResult?.map((item: any) => ({
        label: item.full_name,
        value: item.id,
      }));
      setOptionsManyRoomates(optionsCustomers);
    }
  }, [customersData]);

  useEffect(() => {
    const reservation: any = reservationsData?.data ?? [];
    const queryResult = reservation?.data;
    if (reservation?.status) {
      const optionsReservations = queryResult?.map((item: any) => ({
        label: `${item.customer.full_name} (${item.apartment.name}, ${item.apartment.building.name})`,
        value: item.id,
      }));
      setOptionsReservations(optionsReservations);
    }
  }, [reservationsData]);

  useEffect(() => {
    if (contractsData) {
      const selectedValidFrom: string = contractsData?.data?.validFrom;
      const selectedValidTo: string = contractsData?.data?.validTo;
      const reservationId = contractsData?.data?.reservationId;
      const roommateIds = contractsData?.data?.roommates.map(
        (item: any) => item.id
      );

      if (contractsData?.data?.images?.length > 0) {
        setImageUrls(contractsData?.data?.images);
      }

      setSelectedItemReservation(reservationId);
      setSelectedItemsManyRoommates(roommateIds);
      setSelectedValidFrom(selectedValidFrom);
      setSelectedValidTo(selectedValidTo);
    }
  }, [contractsData]);

  return (
    <Edit
      {...props}
      title={translate("pages.payment.contracts.editContract")}
      headerButtons={({ listButtonProps, refreshButtonProps }) => (
        <>
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} />
        </>
      )}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        layout="vertical"
        initialValues={{
          ...contractsData?.data,
          roommateIds: contractsData?.data?.roommates?.map(
            (item: any) => item.id
          ),
        }}
        form={form}
      >
        <Form.Item
          name="reservationId"
          label={translate("pages.payment.contracts.reservation")}
          rules={[
            {
              required: true,
              message: translate("validations.reservation"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectAReservation")}
            options={optionsReservations}
            onChange={(val: any) => onSelectedItemReservation(val)}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <Form.Item
          name="price"
          label={translate("pages.payment.contracts.price")}
          rules={[
            {
              required: true,
              message: translate("validations.price"),
            },
          ]}
        >
          <NumberInput />
        </Form.Item>
        <Form.Item
          name="validFrom"
          label={translate("pages.payment.contracts.validFrom")}
          rules={[
            () => ({
              validator() {
                if (!selectedValidFrom) {
                  return Promise.reject(
                    new Error(translate("validations.validFrom"))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Space direction="vertical" size={12}>
            <DatePicker
              inputReadOnly={true}
              format={`${formatDateMonthYear} ${formatHour}`}
              defaultValue={dayjs(selectedValidFrom)}
              showTime={{ format: formatHour }}
              placeholder={translate("placeholder.selectDate")}
              onChange={onChangeDateValidFrom}
              getPopupContainer={(trigger: any) => trigger.parentNode}
            />
          </Space>
        </Form.Item>
        <Form.Item
          name="validTo"
          label={translate("pages.payment.contracts.validTo")}
          rules={[
            () => ({
              validator() {
                if (!selectedValidTo) {
                  return Promise.reject(
                    new Error(translate("validations.validTo"))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Space direction="vertical" size={12}>
            <DatePicker
              inputReadOnly={true}
              format={`${formatDateMonthYear} ${formatHour}`}
              defaultValue={dayjs(selectedValidTo)}
              showTime={{ format: formatHour }}
              placeholder={translate("placeholder.selectDate")}
              onChange={onChangeDateValidTo}
              getPopupContainer={(trigger: any) => trigger.parentNode}
            />
          </Space>
        </Form.Item>
        <Form.Item
          label={translate("pages.payment.contracts.status")}
          name="status"
          rules={[
            {
              required: true,
              message: translate("validations.status"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectStatus")}
            options={STATUS_CONTRACTS?.map((item) => {
              return {
                label: (
                  <Tag
                    bordered={false}
                    color={
                      item.value === "active"
                        ? "success"
                        : item.value === "pending"
                        ? "yellow"
                        : item.value === "terminated"
                        ? "error"
                        : "blue"
                    }
                  >
                    {translate(item.label)}
                  </Tag>
                ),
                value: item.value,
              };
            })}
          />
        </Form.Item>
        <div
          style={{
            minWidth: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Form.Item
            style={{ width: "100%" }}
            label={translate("pages.payment.contracts.roommates")}
            name="roommateIds"
          >
            <Select
              mode="multiple"
              placeholder={translate("placeholder.selectAOneOrRoommates")}
              value={selectedItemsManyRoomates}
              onChange={setSelectedItemsManyRoommates}
              options={optionsManyRoommates}
              showSearch
              allowClear
              filterOption={(input, option: any) =>
                handleFilterOption(input, option)
              }
              filterSort={(optionA, optionB) =>
                handleSortOption(optionA, optionB)
              }
            />
          </Form.Item>
          <Button
            style={{ marginTop: "5px" }}
            onClick={() => setIsOpenModalCreateNewCustomer(true)}
          >
            {translate("pages.operation.reservations.createNewCustomer")}
          </Button>
        </div>
        <Form.Item label={translate("pages.payment.contracts.images")}>
          <Form.Item name="images" noStyle>
            <Upload
              multiple={true}
              accept="image/*"
              customRequest={uploadImage}
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {uploadButton}
            </Upload>

            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="images apartments"
                style={{ width: "100%", height: "100%" }}
                src={previewImage}
              />
            </Modal>

            <CustomImageModal
              open={currentOpenModalImage}
              onCancel={handleCancel}
              images={imageUrls}
              initialIndex={currentImageIndex}
            />

            {/* Render images from backend as picture-cards */}
            <Slider
              item={imageUrls}
              handleOpenCurrentIndex={(index: number) =>
                handleOpenCurrentImageModal(index)
              }
              isRemove={true}
              handleRemove={handleRemove}
            />
          </Form.Item>
        </Form.Item>
      </Form>
      {isOpenModalCreateNewCustomer ? (
        <CreateNewCustomer
          isOpen={isOpenModalCreateNewCustomer}
          setIsOpen={setIsOpenModalCreateNewCustomer}
          title={translate("pages.operation.reservations.createNewCustomer")}
          onSubmit={onCreateNewCustomer}
          loading={false}
          name={name}
          setName={setName}
          phone={phone}
          setPhone={setPhone}
        />
      ) : null}
    </Edit>
  );
};
