import { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  useList,
  usePermissions,
  useTranslate,
} from "@refinedev/core";
import { Edit, SaveButton, useForm } from "@refinedev/antd";
import { Form, Input, Select } from "antd";

import { IListUser, IListSaleGroups } from "interfaces";
import { STATUS_ACTIVE_INACTIVE } from "const";
import { handleFilterOption, handleSortOption } from "helper";

export const SaleGroupsEdit: React.FC<IResourceComponentsProps> = (props) => {
  const translate = useTranslate();
  const { data: permissionsData }: any = usePermissions();
  const { formProps, queryResult, saveButtonProps, onFinish, form } =
    useForm<IListSaleGroups>({
      resource: "sale-groups",
    });
  const saleGroupsData: any = queryResult?.data?.data ?? "";

  // get sales data
  const { data: salesData } = useList<IListUser>({
    resource: "users?role_ids=4,5",
    errorNotification: false,
  });

  const [optionsSales, setOptionsSales] = useState<any>([]);
  const [selectedItemsSales, setSelectedItemsSales] = useState<string[]>([]);

  const handleOnFinish: any = (values: IListSaleGroups) => {
    onFinish({
      ...values,
    });
  };

  useEffect(() => {
    if (salesData) {
      const optionsSalesData: any = salesData?.data;
      setOptionsSales(
        optionsSalesData?.data.map((item: any) => {
          return {
            value: item.id,
            label: item.full_name,
          };
        })
      );
    }
  }, [salesData]);

  const filteredOptionsSales = optionsSales.filter(
    (o: any) => !selectedItemsSales.includes(o)
  );

  return (
    <Edit
      {...props}
      title={translate(
        "pages.cartManagement.saleRightsManagement.saleGroups.editSaleGroups"
      )}
      canDelete={permissionsData?.includes["admin"]}
      headerButtons={({ listButtonProps, refreshButtonProps }) => (
        <>
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} />
        </>
      )}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        layout="vertical"
        initialValues={{
          ...saleGroupsData?.data,
          sale_ids: saleGroupsData?.data?.sales?.map((item: any) => item.id),
        }}
        form={form}
      >
        <Form.Item
          name="name"
          label={translate(
            "pages.cartManagement.saleRightsManagement.saleGroups.name"
          )}
          rules={[
            {
              required: true,
              message: translate("validations.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="sale_ids"
          label={translate(
            "pages.cartManagement.saleRightsManagement.saleGroups.sales"
          )}
          rules={[
            {
              required: true,
              message: translate("validations.sales"),
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={translate("placeholder.selectAOneOrManysales")}
            value={selectedItemsSales}
            onChange={setSelectedItemsSales}
            options={filteredOptionsSales}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
          />
        </Form.Item>
        <Form.Item
          name="status"
          label={translate(
            "pages.cartManagement.saleRightsManagement.saleGroups.status"
          )}
          rules={[
            {
              required: true,
              message: translate("validations.status"),
            },
          ]}
        >
          <Select
            placeholder={translate("placeholder.selectStatus")}
            showSearch
            options={STATUS_ACTIVE_INACTIVE?.map((item) => {
              return {
                label: translate(item.label),
                value: item.value,
              };
            })}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
