import {
  IResourceComponentsProps,
  useParsed,
  usePermissions,
  useTranslate,
} from "@refinedev/core";
import { Edit, SaveButton, useForm } from "@refinedev/antd";
import { useNavigate } from "react-router-dom";

import { Button, Form, Input, InputNumber, Select } from "antd";
import "react-mde/lib/styles/css/react-mde-all.css";

import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_FINANCE,
  formatInputPrice,
  isValidPrice,
  parseInputPrice,
} from "helper";
import {
  STATUS_TRANSFER_MONEY_OPTIONS,
  STATUS_TRANSFER_MONEY_OPTIONS_DEPOSIT,
} from "const";
import { IListTransactions } from "interfaces/transactions";

export const CommissionEdit: React.FC<IResourceComponentsProps> = (props) => {
  const { id } = useParsed();
  const { formProps, queryResult, saveButtonProps, onFinish } =
    useForm<IListTransactions>({
      resource: "transactions",
      id,
      errorNotification: (data) => {
        const errorMessage: any = data?.response;
        const message: any = errorMessage?.data?.data?.errors[0].message;
        return {
          message: message,
          description: "Error",
          type: "error",
        };
      },
      redirect: false,
    });
  const commissionsData: any = queryResult?.data?.data ?? "";
  const translate = useTranslate();
  const navigate = useNavigate();
  const permissions = usePermissions<string>();
  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissions?.data === ROLE_DATA_ADMIN;
  const isLoggedbyFinance = permissions?.data === ROLE_FINANCE;

  const handleOnFinish: any = async (values: any) => {
    await onFinish({ ...values });
    navigate("/commissions");
  };

  return (
    <Edit
      {...props}
      title={translate("pages.financialManagement.commissions.editCommission")}
      headerButtons={({ listButtonProps, refreshButtonProps }) => (
        <>
          <Button
            onClick={() =>
              navigate(
                `/appointments/show/${commissionsData?.data?.appointment?.id}`
              )
            }
          >
            {translate("pages.financialManagement.commissions.showAppointment")}
          </Button>
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
      footerButtons={() => <SaveButton {...saveButtonProps} />}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        initialValues={{
          ...commissionsData?.data,
        }}
        layout="vertical"
      >
        <Form.Item
          label={translate("pages.financialManagement.commissions.status")}
          name={["status"]}
        >
          <Select
            style={{ width: "150px" }}
            placeholder={translate("placeholder.selectStatus")}
            options={(commissionsData?.data?.type === "deposit" &&
            (isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyFinance)
              ? STATUS_TRANSFER_MONEY_OPTIONS_DEPOSIT
              : STATUS_TRANSFER_MONEY_OPTIONS
            )?.map((item) => {
              return {
                label: translate(item.label),
                value: item.value,
              };
            })}
          />
        </Form.Item>

        <Form.Item
          name="amount"
          label={translate("pages.financialManagement.commissions.amount")}
          rules={[
            {
              required: true,
              message: translate("validations.amountMoney"),
            },
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && "validations.amountMoney"))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
            placeholder={translate("placeholder.pleaseEnterAmountMoney")}
          />
        </Form.Item>
        <Form.Item
          name="notes"
          label={translate("pages.financialManagement.commissions.notes")}
        >
          <Input placeholder={translate("placeholder.pleaseEnterNotes")} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
