import React, { useContext, useEffect, useState } from "react";
import {
  useNavigation,
  useLogin,
  useNotification,
  useTranslate,
} from "@refinedev/core";
import {
  Row,
  Col,
  Layout as AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  Image,
} from "antd";

import { ILoginForm } from "interfaces";
import { FormOutlined, PhoneOutlined } from "@ant-design/icons";
import { isReallyPhone, parsePhone } from "helper";
import { ColorModeContext } from "contexts/color-mode";
import "./styles.css";

const { Text, Title } = Typography;

export const Login: React.FC = () => {
  const translate = useTranslate();
  const [form] = Form.useForm<ILoginForm>();
  const { mutate: login, isLoading } = useLogin<ILoginForm>();
  const { push } = useNavigation();
  const { open } = useNotification();
  const [isValidPhone, setIsValidPhone] = useState<boolean>(false);
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
  const { mode, setMode } = useContext(ColorModeContext);

  const onSubmitLogin = (values: Pick<ILoginForm, "phone" | "password">) => {
    const phone = values?.phone;
    const password = values?.password;

    if (phone && password) {
      login(
        {
          phone: parsePhone(phone),
          password: password,
        },
        {
          onSuccess: (data) => {
            if (data.success) {
              open?.({
                type: "success",
                message: translate("notifications.success"),
                description: translate("notifications.loginSuccess"),
              });
            }
          },
        }
      );
    }
  };

  const CardTitle = (
    <Title level={3} className="title">
      {translate("pages.login.signInYourAccount")}
    </Title>
  );

  useEffect(() => {
    if (mode === "dark") {
      setMode("light");
    }
  }, [mode, setMode]);

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <Image preview={false} src={"/logo.png"} width={200} />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<ILoginForm>
                autoComplete="off"
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  onSubmitLogin(values);
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}
              >
                <Form.Item
                  name="phone"
                  label={translate("pages.login.phone")}
                  rules={[
                    {
                      required: true,
                      message: translate("validations.phone"),
                    },
                    () => ({
                      validator(_, value) {
                        if (!isReallyPhone(value)) {
                          return Promise.reject(
                            new Error(translate("validations.notAPhoneNumber"))
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    allowClear={true}
                    size="large"
                    autoComplete="off"
                    prefix={<PhoneOutlined style={{ color: "#00000040" }} />}
                    maxLength={12}
                    placeholder="___-___-____"
                    onChange={(e: any) => {
                      isReallyPhone(e.target.value)
                        ? setIsValidPhone(true)
                        : setIsValidPhone(false);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={translate("pages.login.password")}
                  rules={[
                    {
                      required: true,
                      message: translate("validations.password"),
                    },
                  ]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input.Password
                    allowClear={true}
                    size="large"
                    type="password"
                    placeholder="●●●●●●●●"
                    autoComplete="new-password"
                    prefix={<FormOutlined style={{ color: "#00000040" }} />}
                    onChange={(e: any) => {
                      e.target.value
                        ? setIsValidPassword(true)
                        : setIsValidPassword(false);
                    }}
                  />
                </Form.Item>
                <div style={{ marginBottom: "12px" }}>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    {/* <Checkbox
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {translate("pages.login.remember")}
                    </Checkbox> */}
                  </Form.Item>
                  <Text>
                    <span
                      className="forgotPassword"
                      onClick={() => push("/forgot-password")}
                    >
                      {translate("pages.login.forgotPassword")}
                    </span>
                  </Text>
                </div>
                <Button
                  className={`buttonSubmit ${
                    isValidPhone && isValidPassword ? "enabled" : ""
                  }`}
                  size="large"
                  htmlType="submit"
                  loading={isLoading}
                  block
                >
                  {translate("pages.login.signin")}
                </Button>
              </Form>
              <div style={{ marginTop: 8 }}>
                <Text style={{ fontSize: 12 }}>
                  {translate("pages.login.noAccount")}
                  <span className="signUp" onClick={() => push("/register")}>
                    {translate("pages.login.signup")}
                  </span>
                </Text>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
