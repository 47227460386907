import { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input, Select } from "antd";

import { IListUser, IProvinces } from "interfaces";
import { getDistricts, getWards } from "api";
import { PhoneOutlined } from "@ant-design/icons";
import {
  handleFilterOption,
  handleSortOption,
  isReallyPhone,
  parsePhone,
} from "helper";

export const CustomersCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const [selectedProvice, setSelectedProvince] = useState<any>();
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  const [selectedWard, setSelectedWard] = useState<any>();
  const [optionsProvinces, setOptionProvinces] = useState<[]>([]);
  const [optionsDistricts, setOptionsDistricts] = useState<[]>([]);

  const [optionsWards, setOptionsWards] = useState<[]>([]);

  const { formProps, saveButtonProps, onFinish, form } = useForm<IListUser>({
    errorNotification: (data) => {
      const errorMessage: any = data?.response;
      const message: any = errorMessage?.data?.data?.errors[0].message;
      return {
        message: message,
        description: "Error",
        type: "error",
      };
    },
  });

  const { data: provincesData } = useList<IProvinces>({
    resource: "addresses/provinces",
    errorNotification: false,
  });

  useEffect(() => {
    const provinces: any = provincesData?.data ?? [];
    const queryResult = provinces?.data;
    if (provinces?.status) {
      const optionsProvinces = queryResult?.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
      setOptionProvinces(optionsProvinces);
    }
  }, [provincesData]);

  useEffect(() => {
    if (selectedProvice) {
      getDistricts(selectedProvice).then((response) => {
        const optionsDistricts = response?.data.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
        setOptionsDistricts(optionsDistricts);
      });
    }
  }, [selectedProvice]);

  useEffect(() => {
    if (selectedDistrict) {
      getWards(selectedDistrict).then((response) => {
        const optionsWards = response?.data.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
        setOptionsWards(optionsWards);
      });
    }
  }, [selectedDistrict]);

  const onChangeSelectedProvince = (value: any) => {
    setSelectedProvince(value);
    setSelectedDistrict(null);
    setSelectedWard(null);
    form.setFieldValue("district_id", null);
    form.setFieldValue("ward_id", null);
  };

  const handleOnFinish: any = (values: IListUser) => {
    onFinish({
      ...values,
      phone: values?.phone ? parsePhone(values?.phone) : "",
      second_phone: values?.second_phone
        ? parsePhone(values?.second_phone)
        : null,
    });
  };

  return (
    <Create
      title={translate("pages.customerManagement.customers.createNewCustomer")}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        initialValues={{ role_id: 7 }}
        layout="vertical"
      >
        <Form.Item
          name="full_name"
          label={translate("pages.customerManagement.customers.fullName")}
          rules={[
            {
              required: true,
              message: translate("validations.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label={translate("pages.customerManagement.customers.phone")}
          rules={[
            {
              required: true,
              message: translate("validations.phone"),
            },
            () => ({
              validator(_, value) {
                if (!isReallyPhone(value)) {
                  return Promise.reject(
                    new Error(translate("validations.notAPhoneNumber"))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            allowClear={true}
            prefix={<PhoneOutlined style={{ color: "#00000040" }} />}
            maxLength={10}
            placeholder="___-___-____"
          />
        </Form.Item>
        <Form.Item
          name="second_phone"
          label={translate("pages.customerManagement.customers.secondPhone")}
          rules={[
            () => ({
              validator(_, value) {
                if (value && !isReallyPhone(value)) {
                  return Promise.reject(
                    new Error(translate("validations.notAPhoneNumber"))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            allowClear={true}
            prefix={<PhoneOutlined style={{ color: "#00000040" }} />}
            maxLength={10}
            placeholder="___-___-____"
          />
        </Form.Item>
        <Form.Item
          name="email"
          label={translate("pages.customerManagement.customers.email")}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="telegram"
          label={translate("pages.customerManagement.customers.telegram")}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="zalo"
          label={translate("pages.customerManagement.customers.zalo")}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="identity_card_number"
          label={translate(
            "pages.customerManagement.customers.identityCardNumber"
          )}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="address"
          label={translate("pages.customerManagement.customers.address")}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate("pages.customerManagement.customers.province")}
          name={["province_id"]}
        >
          <Select
            options={optionsProvinces}
            placeholder={translate("placeholder.selectAProvince")}
            showSearch
            filterOption={(input, option: any) =>
              handleFilterOption(input, option)
            }
            filterSort={(optionA, optionB) =>
              handleSortOption(optionA, optionB)
            }
            onChange={onChangeSelectedProvince}
          />
        </Form.Item>

        {selectedProvice ? (
          <Form.Item
            label={translate("pages.customerManagement.customers.district")}
            name={["district_id"]}
          >
            <Select
              placeholder={translate("placeholder.selectADistrict")}
              options={optionsDistricts}
              showSearch
              filterOption={(input, option: any) =>
                handleFilterOption(input, option)
              }
              filterSort={(optionA, optionB) =>
                handleSortOption(optionA, optionB)
              }
              value={selectedDistrict}
              onChange={(val) => {
                setSelectedDistrict(val);
                form.setFieldValue("ward_id", null);
              }}
            />
          </Form.Item>
        ) : null}
        {selectedDistrict ? (
          <Form.Item
            label={translate("pages.customerManagement.customers.ward")}
            name={["ward_id"]}
          >
            <Select
              placeholder={translate("placeholder.selectAWard")}
              options={optionsWards}
              showSearch
              filterOption={(input, option: any) =>
                handleFilterOption(input, option)
              }
              filterSort={(optionA, optionB) =>
                handleSortOption(optionA, optionB)
              }
              value={selectedWard}
              onChange={(val) => setSelectedWard(val)}
            />
          </Form.Item>
        ) : null}
      </Form>
    </Create>
  );
};
