export const STATUS_ACTIVE_INACTIVE = [
  {
    value: "active",
    label: "status.active",
  },
  {
    value: "inactive",
    label: "status.inactive",
  },
];
