import {
  IResourceComponentsProps,
  usePermissions,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { EditButton, Show, TextField } from "@refinedev/antd";
import { useNavigate } from "react-router-dom";

import { Button, Space, Tag, Typography } from "antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IListUser } from "interfaces";
import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_FINANCE,
  formatTime,
  parsePhoneToVNWithoutSpace,
} from "helper";

const { Title } = Typography;

export const LandlordsShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<IListUser>();
  const { data, isLoading } = queryResult;
  const record: any = data?.data;
  const navigate = useNavigate();
  const { data: permissionsData }: any = usePermissions();
  const isLoggedbyAdmin = permissionsData === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissionsData === ROLE_DATA_ADMIN;
  const isLoggedbyFinance = permissionsData === ROLE_FINANCE;

  return (
    <Show
      isLoading={isLoading}
      title={translate("pages.cartManagement.landlords.showLandlord")}
      headerButtons={({
        listButtonProps,
        editButtonProps,
        refreshButtonProps,
      }) => (
        <>
          <Button
            onClick={() =>
              navigate(`/buildings?landlord_id=${record?.data?.id}`)
            }
          >
            {translate("pages.cartManagement.landlords.viewListBuildings")}
          </Button>
          {editButtonProps && (
            <EditButton
              {...editButtonProps}
              title={translate("pages.users.editUser")}
            />
          )}
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
    >
      <Space>
        <Title level={5}>{translate("pages.users.fullName")}:</Title>
        <TextField strong value={record?.data?.full_name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.users.phone")}:</Title>
        <TextField
          value={
            record?.data?.phone
              ? parsePhoneToVNWithoutSpace(record?.data?.phone)
              : ""
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.users.secondPhone")}:</Title>
        <TextField
          value={
            record?.data?.second_phone
              ? parsePhoneToVNWithoutSpace(record?.data?.second_phone)
              : ""
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.users.email")}:</Title>
        <TextField value={record?.data?.email} />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.users.telegram")}:</Title>
        <TextField value={record?.data?.telegram} />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.users.zalo")}:</Title>
        <TextField
          value={
            record?.data?.zalo
              ? parsePhoneToVNWithoutSpace(record?.data?.zalo)
              : ""
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.users.identityCardNumber")}:</Title>
        <TextField value={record?.data?.identity_card_number} />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.users.address")}:</Title>
        <TextField value={record?.data?.address} />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.users.province")}:</Title>
        <TextField value={record?.data?.province?.name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.users.district")}:</Title>
        <TextField value={record?.data?.district?.name} />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.users.ward")}:</Title>
        <TextField value={record?.data?.ward?.name} />
      </Space>
      <br />
      {isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyFinance ? (
        <>
          <Space>
            <Title level={5}>
              {translate("pages.users.firstManagerName")}:
            </Title>
            <TextField value={record?.data?.first_manager_name} />
          </Space>
          <br />
          <Space>
            <Title level={5}>
              {translate("pages.users.firstManagerPhone")}:
            </Title>
            <TextField
              value={
                record?.data?.first_manager_phone
                  ? parsePhoneToVNWithoutSpace(
                      record?.data?.first_manager_phone
                    )
                  : ""
              }
            />
          </Space>
          <br />
          <Space>
            <Title level={5}>
              {translate("pages.users.secondManagerName")}:
            </Title>
            <TextField value={record?.data?.second_manager_name} />
          </Space>
          <br />
          <Space>
            <Title level={5}>
              {translate("pages.users.secondManagerPhone")}:
            </Title>
            <TextField
              value={
                record?.data?.second_manager_phone
                  ? parsePhoneToVNWithoutSpace(
                      record?.data?.second_manager_phone
                    )
                  : ""
              }
            />
          </Space>
          <br />
        </>
      ) : null}

      <Space>
        <Title level={5}>{translate("pages.users.role")}:</Title>
        <TextField value={translate(`roles.${record?.data?.role?.name}`)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.users.status")}:</Title>
        <TextField
          strong
          value={
            record?.data?.is_active === true ? (
              <Tag bordered={false} color="success">
                {translate("pages.users.active")}
              </Tag>
            ) : (
              <Tag bordered={false} color="error">
                {translate("pages.users.inactive")}
              </Tag>
            )
          }
        />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.createdAt")}:</Title>
        <TextField value={formatTime(record?.data?.created_at)} />
      </Space>
      <br />
      <Space>
        <Title level={5}>{translate("pages.updatedAt")}:</Title>
        <TextField value={formatTime(record?.data?.updated_at)} />
      </Space>
      <br />
    </Show>
  );
};
