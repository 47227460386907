import {
  IResourceComponentsProps,
  useCustomMutation,
  usePermissions,
  useTranslate,
} from "@refinedev/core";
import { Edit, SaveButton, useForm } from "@refinedev/antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Button, Form, Input, InputNumber, Select, message } from "antd";
import "react-mde/lib/styles/css/react-mde-all.css";

import {
  ROLE_ADMIN,
  ROLE_DATA_ADMIN,
  ROLE_FINANCE,
  formatInputPrice,
  isValidPrice,
  parseInputPrice,
} from "helper";
import {
  STATUS_TRANSFER_MONEY_OPTIONS,
  STATUS_TRANSFER_MONEY_OPTIONS_DEPOSIT,
} from "const";
import { IListTransactions } from "interfaces/transactions";
import { ModalConfirm } from "components/modal";
import { IListApointments } from "interfaces";
import { API_URL } from "api";

export const TransactionEdit: React.FC<IResourceComponentsProps> = (props) => {
  const { formProps, queryResult, saveButtonProps, onFinish, redirect } =
    useForm<IListTransactions>({
      resource: "transactions",
      errorNotification: (data) => {
        const errorMessage: any = data?.response;
        const message: any = errorMessage?.data?.data?.errors[0].message;
        return {
          message: message,
          description: "Error",
          type: "error",
        };
      },
      redirect: false,
    });
  const transactionData: any = queryResult?.data?.data ?? "";
  const translate = useTranslate();
  const navigate = useNavigate();
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [statusInitial, setStatusInitial] = useState<string>("");

  const { mutate: patchAppointment } = useCustomMutation<IListApointments>();
  const permissions = usePermissions<string>();
  const isLoggedbyAdmin = permissions?.data === ROLE_ADMIN;
  const isLoggedbyDataAdmin = permissions?.data === ROLE_DATA_ADMIN;
  const isLoggedbyFinance = permissions?.data === ROLE_FINANCE;

  const getAppointment = async (id: any) => {
    return await axios
      .get(`${API_URL}/appointments/${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((data) => data.data)
      .catch((error) => {
        console.error(error);
      });
  };

  const handleOnFinish: any = async (values: any) => {
    const data = await onFinish({ ...values });

    if (
      data?.data?.status &&
      transactionData?.data?.type === "deposit" &&
      values?.status === "completed" &&
      values?.status !== statusInitial
    ) {
      setIsOpenConfirmModal(true);
    } else {
      redirect("list");
    }
  };

  const handleUpdateCompletedAppointmentStatus: any = async () => {
    const appointmentData: any = await getAppointment(
      transactionData?.data?.appointment?.id
    ).then((response: any) => {
      if (response?.status) {
        return response;
      } else {
        message.error(response?.message);
      }
    });

    if (!appointmentData?.status) {
      return;
    }

    setIsOpenConfirmModal(false);
    const values = appointmentData?.data || "";
    patchAppointment(
      {
        url: `${API_URL}/appointments/${values?.id}`,
        method: "patch",
        values: {
          apartment_ids: values?.apartments?.map((item: any) => item.id),
          check_in_date: values?.check_in_date,
          complete_deposit_date: values?.complete_deposit_date,
          customer_id: values?.customer?.id,
          sale_id: values?.sale_id,
          status: "fulfilled_deposit",
          supported_sale_ids: values?.supported_sales?.map(
            (item: any) => item.id
          ),
          time: values?.time,
        },
      },
      {
        onError: (error: any) => {
          message.error(error?.response?.data?.data?.errors[0]?.message);
        },
        onSuccess: (data: any) => {
          if (data?.data?.status ?? data?.data?.data?.status) {
            message.success(
              translate("notifications.appointmentUpdatedSuccess")
            );
          }
          redirect("list");
        },
      }
    );
  };

  useEffect(() => {
    setStatusInitial(transactionData?.data?.status);
  }, [transactionData]);

  return (
    <Edit
      {...props}
      title={translate(
        "pages.financialManagement.transactions.editTransaction"
      )}
      headerButtons={({ listButtonProps, refreshButtonProps }) => (
        <>
          <Button
            onClick={() =>
              navigate(
                `/appointments/show/${transactionData?.data?.appointment?.id}`
              )
            }
          >
            {translate(
              "pages.financialManagement.transactions.showAppointment"
            )}
          </Button>
          {listButtonProps && null}
          {refreshButtonProps && null}
        </>
      )}
      footerButtons={() => <SaveButton {...saveButtonProps} />}
    >
      <Form
        {...formProps}
        onFinish={handleOnFinish}
        initialValues={{
          ...transactionData?.data,
        }}
        layout="vertical"
      >
        <Form.Item
          label={translate("pages.financialManagement.transactions.status")}
          name={["status"]}
        >
          <Select
            style={{ width: "150px" }}
            placeholder={translate("placeholder.selectStatus")}
            options={(transactionData?.data?.type === "deposit" &&
            (isLoggedbyAdmin || isLoggedbyDataAdmin || isLoggedbyFinance)
              ? STATUS_TRANSFER_MONEY_OPTIONS_DEPOSIT
              : STATUS_TRANSFER_MONEY_OPTIONS
            )?.map((item) => {
              return {
                label: translate(item.label),
                value: item.value,
              };
            })}
          />
        </Form.Item>

        <Form.Item
          name="amount"
          label={translate("pages.financialManagement.transactions.amount")}
          rules={[
            {
              required: true,
              message: translate("validations.amountMoney"),
            },
            () => ({
              validator(_, value) {
                if (isValidPrice(value)) {
                  return Promise.reject(
                    new Error(translate(value && "validations.amountMoney"))
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            prefix="đ"
            style={{ width: "100%" }}
            formatter={(value) => formatInputPrice(value)}
            parser={(value) => parseInputPrice(value)}
            placeholder={translate("placeholder.pleaseEnterAmountMoney")}
          />
        </Form.Item>
        <Form.Item
          name="notes"
          label={translate("pages.financialManagement.transactions.notes")}
        >
          <Input placeholder={translate("placeholder.pleaseEnterNotes")} />
        </Form.Item>
      </Form>
      {isOpenConfirmModal ? (
        <ModalConfirm
          isOpen={isOpenConfirmModal}
          title={translate("const.confirm")}
          onCancel={() => {
            setIsOpenConfirmModal(false);
            redirect("list");
          }}
          subTitle={translate(
            "pages.customerManagement.appointments.updateDealStatus"
          )}
          onConfirm={() => {
            handleUpdateCompletedAppointmentStatus();
          }}
        />
      ) : null}
    </Edit>
  );
};
